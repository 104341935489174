import React from 'react';
import styles from './Header.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  FormControl,
  Dropdown,
  Navbar,
  InputGroup,
  Nav,
} from 'react-bootstrap';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/actions';
import { selectWebsiteData } from '../../redux/general/selectors';
import { selectAuthUser } from '../../redux/auth/selectors';

const Header = () => {
  const userData = useSelector(selectAuthUser);
  const websiteData = useSelector(selectWebsiteData);
  const dispatch = useDispatch();
  const location = useLocation();

  const routeChange = () => {
    dispatch(logoutUser());
  };

  var elem = document.documentElement;
  var i = true;

  const Fullscreen = (vale) => {
    switch (vale) {
      case true:
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
        i = false;
        break;
      case false:
        document.exitFullscreen();
        i = true;
        break;
      default:
        break;
    }
  };

  //leftsidemenu
  const SideMenuIcon = () => {
    document.querySelector('.app').classList.toggle('sidenav-toggled');
  };

  // Darkmode
  const DarkMode = () => {
    if (document.querySelector('.app').classList.contains('dark-mode')) {
      document.querySelector('.app').classList.remove('dark-mode');
      let DarkMenu1 = document.querySelector('#myonoffswitch1');
      DarkMenu1.checked = true;
    } else {
      document.querySelector('.app').classList.add('dark-mode');
      let DarkMenu1 = document.querySelector('#myonoffswitch2');
      DarkMenu1.checked = true;
    }
  };

  // console.log('website data in header', websiteData)
  return (
    <div className={styles.Header}>
      <div className="header sticky app-header header1 custom-header">
        <div className="container-fluid main-container">
          <div style={{ height: '40px' }} className="d-flex">
            <Link
              aria-label="Hide Sidebar"
              className="app-sidebar__toggle"
              data-bs-toggle="sidebar"
              to="#"
              onClick={() => SideMenuIcon()}
            />
            <Link
              className="logo-horizontal"
              to={`${process.env.PUBLIC_URL}/Dashboard`}
            >
              {/* <img
                style={{ height: '35px' }}
                src={websiteData.logo}
                className="header-brand-img desktop-logo"
                alt="logo"
              />
              <img
                style={{ height: '35px' }}
                src={websiteData.logo}
                className="header-brand-img light-logo1"
                alt="logo"
              /> */}
            </Link>
            <div className="main-header-center ms-3 d-none d-lg-block">
              {/* <FormControl placeholder="Search for results..." type="search" />
              <button className="btn px-0 pt-2">
                <i className="fe fe-search" aria-hidden="false"></i>
              </button> */}
            </div>
            <Navbar className="d-flex order-lg-2 ms-auto header-right-icons">
              <Dropdown className="dropdown d-none">
                <Link to="#" className="nav-link icon ">
                  <i className="fe fe-search"></i>
                </Link>
                <Dropdown.Menu className="header-search dropdown-menu-start ">
                  <InputGroup className="input-group w-100 p-2">
                    <FormControl type="text" placeholder="Search...." />
                    <InputGroup.Text className="btn btn-primary">
                      <i className="fe fe-search" aria-hidden="true"></i>
                    </InputGroup.Text>
                  </InputGroup>
                </Dropdown.Menu>
              </Dropdown>
              <Navbar.Toggle className="d-lg-none ms-auto header2 navbar-toggler navresponsive-toggler">
                <span className="navbar-toggler-icon fe fe-more-vertical"></span>
              </Navbar.Toggle>
              <div className="responsive-navbar navbar p-0">
                <Navbar.Collapse className="" id="navbarSupportedContent-4">
                  <div className="d-flex order-lg-2">
                    <Dropdown className="d-flex profile-1">
                      <Dropdown.Toggle
                        variant=""
                        className="nav-link leading-none d-flex no-caret"
                      >
                        <Avatar
                          fgColor={
                            location.pathname == '/'
                              ? 'black'
                              : websiteData.primary
                          }
                          color={
                            location.pathname == '/'
                              ? 'red'
                              : websiteData.secondary
                          }
                          round
                          size="35"
                          name={userData.first_name+" "+userData.last_name}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-end dropdown-menu-arrow">
                        <div className="drop-heading">
                          <div className="text-center">
                            <h5 className="text-dark mb-0 fs-14 fw-semibold">
                              {userData.first_name}
                            </h5>
                            {/* <small className="text-muted">Senior Admin</small> */}
                          </div>
                        </div>
                        <div className="dropdown-divider m-0"></div>
                        <Dropdown.Item
                          className="dropdown-item"
                          onClick={() => {
                            routeChange();
                            localStorage.removeItem("menuitems") // The menuitems are removed from localstorage when a user logs-out of a certain tile.
                          }}
                        >
                          <i className="dropdown-icon fe fe-alert-circle"></i>{' '}
                          Sign out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {/* <div className="dropdown  d-flex">
                    <Nav.Link
                      className="nav-link icon theme-layout nav-link-bg layout-setting"
                      onClick={() => DarkMode()}
                    >
                      <span className="dark-layout">
                        <i className="fe fe-moon"></i>
                      </span>
                      <span className="light-layout">
                        <i className="fe fe-sun"></i>
                      </span>
                    </Nav.Link>
                  </div> */}

                  <div className="dropdown d-flex">
                    <Nav.Link
                      className="nav-link icon full-screen-link nav-link-bg"
                      onClick={() => Fullscreen(i)}
                    >
                      <i className="fe fe-minimize fullscreen-button"></i>
                    </Nav.Link>
                  </div>

                  {/* <Dropdown className="d-flex notifications">
                    <Dropdown.Toggle
                      variant=""
                      className="nav-link icon no-caret"
                    >
                      <i className="fe fe-bell"></i>
                      <span className=" pulse"></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-end dropdown-menu-arrow">
                      <div className="drop-heading border-bottom">
                        <div className="d-flex">
                          <h6 className="mt-1 mb-0 fs-16 fw-semibold text-dark">
                            Notifications
                          </h6>
                        </div>
                      </div>
                      <div className="notifications-menu">
                        <Dropdown.Item
                          className="d-flex"
                          // href={`${process.env.PUBLIC_URL}/Pages/notificationlist`}
                        >
                          <div className="me-3 notifyimg  bg-primary brround box-shadow-primary">
                            <i className="fe fe-mail"></i>
                          </div>
                          <div className="mt-1">
                            <h5 className="notification-label mb-1">
                              Ride completed
                            </h5>
                            <span className="notification-subtext">
                              3 days ago
                            </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex"
                          // href={`${process.env.PUBLIC_URL}/Pages/notificationlist`}
                        >
                          <div className="me-3 notifyimg  bg-secondary brround box-shadow-secondary">
                            <i className="fe fe-check-circle"></i>
                          </div>
                          <div className="mt-1">
                            <h5 className="notification-label mb-1">
                              Ride Started
                            </h5>
                            <span className="notification-subtext">
                              2 hours ago
                            </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex"
                          // href={`${process.env.PUBLIC_URL}/Pages/notificationlist`}
                        >
                          <div className="me-3 notifyimg  bg-success brround box-shadow-success">
                            <i className="fe fe-shopping-cart"></i>
                          </div>
                          <div className="mt-1">
                            <h5 className="notification-label mb-1">
                              Battery discharged for vehicle V01
                            </h5>
                            <span className="notification-subtext">
                              30 min ago
                            </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex"
                          // href={`${process.env.PUBLIC_URL}/Pages/notificationlist`}
                        >
                          <div className="me-3 notifyimg bg-pink brround box-shadow-pink">
                            <i className="fe fe-user-plus"></i>
                          </div>
                          <div className="mt-1">
                            <h5 className="notification-label mb-1">
                              New order
                            </h5>
                            <span className="notification-subtext">
                              1 day ago
                            </span>
                          </div>
                        </Dropdown.Item>
                      </div>
                      <div className="dropdown-divider m-0"></div>
                      <Dropdown.Item
                        // href={`${process.env.PUBLIC_URL}/Pages/notificationlist`}
                        className="dropdown-item text-center p-3 text-muted"
                      >
                        View all Notification
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  {/* <Dropdown className="d-flex message">
                    <Dropdown.Toggle
                      variant=""
                      className="nav-link icon text-center no-caret"
                    >
                      <i className="fe fe-message-square"></i>
                      <span className="pulse-danger"></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-end dropdown-menu-arrow">
                      <div className="drop-heading border-bottom">
                        <div className="d-flex">
                          <h6 className="mt-1 mb-0 fs-16 fw-semibold text-dark">
                            You have 5 Messages
                          </h6>
                          <div className="ms-auto">
                            <Link to="#" className="text-muted p-0 fs-12">
                              make all unread
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="message-menu">
                        <Dropdown.Item
                          className="d-flex"
                          href={`${process.env.PUBLIC_URL}/chat`}
                        >
                          <img
                            className="avatar avatar-md brround me-3 align-self-center cover-image"
                            alt="user-1"
                            src={require('../../assets/images/users/1.jpg')}
                          />
                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-1">Peter Theil</h5>
                              <small className="text-muted ms-auto text-end">
                                6:45 am
                              </small>
                            </div>
                            <span>Commented on file Guest list....</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex"
                          href={`${process.env.PUBLIC_URL}/chat`}
                        >
                          <img
                            className="avatar avatar-md brround me-3 align-self-center cover-image"
                            alt="user-15"
                            src={require('../../assets/images/users/15.jpg')}
                          />
                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-1">Abagael Luth</h5>
                              <small className="text-muted ms-auto text-end">
                                10:35 am
                              </small>
                            </div>
                            <span>New Meetup Started......</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex"
                          href={`${process.env.PUBLIC_URL}/chat`}
                        >
                          <img
                            className="avatar avatar-md brround me-3 align-self-center cover-image"
                            alt="user-12"
                            src={require('../../assets/images/users/12.jpg')}
                          />
                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-1">Brizid Dawson</h5>
                              <small className="text-muted ms-auto text-end">
                                2:17 pm
                              </small>
                            </div>
                            <span>Brizid is in the Warehouse...</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex"
                          href={`${process.env.PUBLIC_URL}/chat`}
                        >
                          <img
                            className="avatar avatar-md brround me-3 align-self-center cover-image"
                            alt="user-4"
                            src={require('../../assets/images/users/4.jpg')}
                          />
                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-1">Shannon Shaw</h5>
                              <small className="text-muted ms-auto text-end">
                                7:55 pm
                              </small>
                            </div>
                            <span>New Product Realease......</span>
                          </div>
                        </Dropdown.Item>
                      </div>
                      <div className="dropdown-divider m-0"></div>
                      <Dropdown.Item
                        to="#"
                        className="text-center p-3 text-muted"
                      >
                        See all Messages
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </Navbar.Collapse>
              </div>

              {/* <div className="demo-icon nav-link icon">
                <i className="fe fe-settings fa-spin  text_primary"></i>
              </div> */}
            </Navbar>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
