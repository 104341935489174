import moment from "moment";
import 'moment-timezone';

export const convertUTCToLocal = (utcDt, utcDtFormat) => {
  let zone_name = moment.tz.guess();
  var toDt = moment.utc(utcDt, utcDtFormat).toDate();
  let utcepoc = moment(moment(toDt).format('YYYY-MM-DD hh:mm:ss A')).unix();
  let current_time = moment.unix(utcepoc).tz(zone_name);

  let diffInMinutes = moment().diff(current_time, 'minutes');

  return {
    difference: diffInMinutes,
    time: current_time.format('YYYY-MM-DD hh:mm:ss a'),
    obj: current_time
  };
};
