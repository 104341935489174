import React from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import styles from './Dashboard.module.scss';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectWebsiteData } from '../../redux/general/selectors';
import { DashboardStatisticsWavSmart } from '../../Data/WebsiteData/moby';
import { useInfoCtx } from '../../providers/InfoCardProvider';

const WavSmartDashboard = () => {
  const websiteData = useSelector(selectWebsiteData);
  const { renderInfoCardData } = useInfoCtx();

  return (
    <div className={styles.Dashboard}>
      <Helmet>
        <title>{websiteData.name || ''} | Efleety</title>
      </Helmet>
      <PageHeader titles="Dashboard" active="Dashboard" items={['Home']} />

      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            {DashboardStatisticsWavSmart.filter(
              (item) => item.module === 'dashboard'
            ).map((card, index) => (
              <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                <Card className="overflow-hidden">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="mt-2">
                        <h6 className="">{card.heading}</h6>
                        <h2 className="mb-0 number-font">
                          {renderInfoCardData(card)}
                        </h2>
                      </div>
                      <div className="ms-auto">
                        <div className="chart-wrapper mt-1"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default WavSmartDashboard