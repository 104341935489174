import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  IssuesDatatable,
  VehicleDatatable,
  WorkOrderDatatable,
} from '../../Data/DummyData';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import AssetLocationMapNew from '../Maps/AssetLocationMapNew';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import styles from './DataTable.module.scss';
import { DashboardStatistics } from '../../Data/DummyData';
import { useInfoCtx } from '../../providers/InfoCardProvider';
import Modal from '../../components/Modal';
import { useModalCtx } from '../../providers/ModalProvider';
import { EndTripValidation, VehicleFormValidations } from '../../validations';
import { useFormik } from 'formik';
import { FormHelperText, Grid, MenuItem, TextField } from '@mui/material';
import { addData, deleteData, editData } from '../../redux/actions';
import queryString from 'query-string';
import {
  addPage,
  fetchData,
  setFilteredData,
  setModule,
  setModuleData,
  updateVehiclePosition,
} from '../../redux/templateData/actions';
import {
  selectData,
  selectDatatable,
  selectFilteredData,
  selectModuleSpecificData,
} from '../../redux/templateData/selectors';
import { initialState } from '../../redux/templateData/init';
import { unlockDevice } from '../../redux/iot/actions';
import JMap from '../../components/Map/JMap';
import MapViewIcons from '../../assets/images/images';
import { selectWebsiteData } from '../../redux/general/selectors';

let APIInterval;

function BSVehicles() {
  const vehicleData = useSelector(selectDatatable);
  const deviceData = useSelector(selectModuleSpecificData('iot'));
  const modalContext = useModalCtx();
  const modalContext2 = useModalCtx();
  let {
    modalStatus: formModalStatus,
    modalStatusHandler: handleFormModalStatus,
  } = modalContext;

  const websiteData = useSelector(selectWebsiteData);
  const [endRideModal, setEndRideModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [vehicleDataState, setVehicleDataState] = useState(vehicleData.data);
  const vehicleHisotory = useSelector(
    selectModuleSpecificData('vehicle_history')
  );
  const [dialogConfirmationStatus, setDialogConfirmationStatus] =
    useState(false);
  const [lockDialogConfirmationStatus, setLockDialogConfirmationStatus] =
    useState(false);

  const [dialogLatLngData, setDialogLatLngData] = useState({});
  const dispatch = useDispatch();
  const { renderInfoCardData } = useInfoCtx();
  const batteryData = useSelector(selectModuleSpecificData('batteries'));
  const [historyModalStatus, setHistoryModalStatus] = useState(false);
  const filteredResult = useSelector(selectFilteredData);
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();
  const search = queryString.parse(location.search);

  const endTripForm = useFormik({
    initialValues: {
      trip_id: parseInt(search.id),
      description: '',
    },
    onSubmit: () => {
      let obj = { ...values };
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === '') {
          obj[key] = null;
        }
      });
      dispatch(
        addData({ ...obj, trip_id: parseInt(search.id), module: 'end_ride' })
      );
      setEndRideModal(false);
    },
    validationSchema: EndTripValidation,
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    handleBlur,
    handleChange,
    setValues,
    submitForm,
  } = useFormik({
    initialValues: {
      vehicle_id: '',
      battery_id: '',
      number_of_rides: '',
      performance: '',
      current_location: '',
      current_status: '',
      distance_travelled: '',
      vehicle_active_time: '',
      vehicle_down_time: '',
      number_of_different_riders: '',
      distance_since_last_inspection: '',
      controller_firmware_version: '',
      controller_id: null,
      imei: null,
    },
    onSubmit: () => {
      let obj = { ...values };
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === '') {
          obj[key] = null;
        }
      });

      if (isEditMode) {
        dispatch(editData(obj));
      } else {
        dispatch(addData(obj));
      }
    },
    validationSchema: VehicleFormValidations,
  });

  const columns = React.useMemo(
    () =>
      computeColumns({
        data: vehicleDataState,
        // meta: {
        //   status: {
        //     type: 'styled_lock_status',
        //     filter: 'equals',
        //     filters: { disableFilter: false, filterType: 'select' },
        //   },
        // },
        meta: vehicleData.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [vehicleDataState]
  );

  const columns2 = React.useMemo(
    () =>
      computeColumns({
        data: vehicleHisotory,
        meta: vehicleData.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [vehicleHisotory]
  );

  // const data = React.useMemo(() => vehicleData, [vehicleData]);

  const data = React.useMemo(() => vehicleData.data, [vehicleData.data]);

  useEffect(() => {
    let _data = vehicleData.data.map((item) => {
      return {
        ...item,
        // status: simulation.find((data) => data.vehicle_id == item.vehicle_id)
        //   ?.status,
      };
    });

    setVehicleDataState([..._data]);
  }, [vehicleData.data]);

  useEffect(() => {
    dispatch(setModule('vehicles'));
    // dispatch(fetchData({ module: 'vehicles' }));
    APIInterval = setInterval(() => {
      dispatch(
        fetchData({ module: 'vehicles' }, { reUpdateSourceData: false })
      );
      // dispatch(
      //   updateVehiclePosition(
      //     { module: 'vehicles' },
      //     { reUpdateSourceData: true }
      //   )
      // );
    }, 5000);

    return () => {
      clearInterval(APIInterval);
    };
  }, []);

  useEffect(() => {
    if (!initialState.hasOwnProperty('iot')) {
      dispatch(
        addPage({
          key: 'iot',
          data: initialState.vehicles,
        })
      );
    }

    dispatch(fetchData({ module: 'iot' }));
  }, []);

  const configurations = useMemo(
    () => ({
      center: {
        latitude: 24.57127,
        longitude: 73.691544,
      },
    }),
    []
  );

  const mapIcons = useCallback((d, type) => {
    return type == 'station_id'
      ? d.type == 'pickup'
        ? MapViewIcons.pick_point
        : d.type == 'dropoff'
        ? MapViewIcons.drop_point
        : MapViewIcons.pick_drop_point
      : websiteData.id == 'bombay_softwares'
      ? d.vehicle_type == 'Bike'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? MapViewIcons.motorcycleGreen
          : MapViewIcons.motorcycleRed
        : d.vehicle_type == 'Scooter'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? MapViewIcons.scooterGreen
          : MapViewIcons.scooterRed
        : d.vehicle_type == 'Delivery - KG3'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? MapViewIcons.kg3Green
          : MapViewIcons.kg3Red
        : d.vehicle_type == 'Delivery - KG4'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? MapViewIcons.kg4Green
          : MapViewIcons.kg4Red
        : websiteData.icon_type == 'scooter'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? MapViewIcons.scooterGreen
          : MapViewIcons.scooterRed
        : websiteData.icon_type == 'van'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? MapViewIcons.vanGreen1
          : MapViewIcons.vanRed1
        : websiteData.icon_type == 'solar'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? MapViewIcons.solarPanelGreen
          : MapViewIcons.solarPanelRed
        : websiteData.icon_type == 'bin'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? MapViewIcons.binGreen
          : MapViewIcons.binRed
        : d.motion_status == 'moving' && d.ignition == 'on'
        ? MapViewIcons.motorcycleGreen
        : MapViewIcons.motorcycleRed
      : d.vehicle_type == 'Bike'
      ? d.trip_status == 'inprogress'
        ? MapViewIcons.motorcycleBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? MapViewIcons.motorcycleGreen
        : MapViewIcons.motorcycleRed
      : d.vehicle_type == 'Scooter'
      ? d.trip_status == 'inprogress'
        ? MapViewIcons.scooterBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? MapViewIcons.scooterGreen
        : MapViewIcons.scooterRed
      : d.vehicle_type == 'Delivery - KG3'
      ? d.trip_status == 'inprogress'
        ? MapViewIcons.kg3Blue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? MapViewIcons.kg3Green
        : MapViewIcons.kg3Red
      : d.vehicle_type == 'Delivery - KG4'
      ? d.trip_status == 'inprogress'
        ? MapViewIcons.kg4Blue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? MapViewIcons.kg4Green
        : MapViewIcons.kg4Red
      : websiteData.icon_type == 'scooter'
      ? d.trip_status == 'inprogress'
        ? MapViewIcons.scooterBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? MapViewIcons.scooterGreen
        : MapViewIcons.scooterRed
      : websiteData.icon_type == 'van'
      ? d.trip_status == 'inprogress'
        ? MapViewIcons.vanBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? MapViewIcons.vanGreen1
        : MapViewIcons.vanRed1
      : websiteData.icon_type == 'solar'
      ? d.trip_status == 'inprogress'
        ? MapViewIcons.solarPanelGreen
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? MapViewIcons.solarPanelGreen
        : MapViewIcons.solarPanelRed
      : websiteData.icon_type == 'bin'
      ? d.trip_status == 'inprogress'
        ? MapViewIcons.binGreen
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? MapViewIcons.binGreen
        : MapViewIcons.binRed
      : d.trip_status == 'inprogress'
      ? MapViewIcons.motorcycleBlue
      : d.status == 'Locked' && d.current_status == 'Available for Service'
      ? MapViewIcons.motorcycleGreen
      : MapViewIcons.motorcycleRed;
  }, []);

  const markers = useMemo(() => {
    let result = filteredResult.map((data) => ({
      ...data,
      lat: data.lat,
      lng: data.long,
    }));

    return result;
  }, [filteredResult]);

  return (
    <div className={styles.DataTable}>
      <PageHeader titles="Vehicles" active="Vehicles" items={['Tables']} />
      <Row>
        {DashboardStatistics.filter((item) => item.module === 'vehicle').map(
          (card, index) => (
            <Col lg={6} key={index} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">{card.heading}</h6>
                      <h2 className="mb-0 number-font">
                        {renderInfoCardData(card)}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </Row>
      <div id="map">
        {/* <JMap
          config={configurations}
          icons={mapIcons}
          type={'vehicle_id'}
          markerLocation={markers}
        /> */}
        <AssetLocationMapNew
          ids={VehicleDatatable.data.map((d) => d.vehicle_id)}
          type={'vehicle_id'}
          filteredResult={filteredResult}
          clickedVehicleLocation={dialogLatLngData}
        />
      </div>
      {/* <Row>
        <Col lg={12} style={{ textAlign: 'right' }}>
          <div style={{ marginBottom: '20px' }}>
            <Button
              onClick={() => {
                setEditMode(false);
                handleFormModalStatus(true);
                resetForm();
              }}
              variant="secondary"
            >
              Add Vehicle
            </Button>
          </div>
        </Col>
      </Row> */}
      <div className="row">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Datatable
                addActionName="Add Vehicle"
                addAction={() => {
                  setEditMode(false);
                  handleFormModalStatus(true);
                  resetForm();
                }}
                datatableTitle="Vehicle"
                refreshAction={() => {
                  dispatch(fetchData({ module: 'vehicles' }));
                }}
                sortable_key="vehicle_id"
                modalSize="xl"
                modalHeading="Details"
                actions={[
                  {
                    name: 'Send',
                    type: 'lock',
                    buttonType: 'icon',
                  },
                  { name: 'Delete', type: 'end_ride', buttonType: 'icon' },
                  { name: 'history', type: 'history', buttonType: 'icon' },
                  { name: 'Edit', type: 'edit', buttonType: 'icon' },
                  { name: 'Delete', type: 'delete', buttonType: 'icon' },
                  { name: 'location', type: 'location', buttonType: 'icon' },
                ]}
                onActionClick={(event, { type, data, trip_id }) => {
                  setValues(data.original);
                  if (type == 'edit') {
                    setEditMode(true);
                    handleFormModalStatus(true);
                  } else if (type == 'delete') {
                    setDialogConfirmationStatus(true);
                  } else if (type == 'location') {
                    setDialogLatLngData({
                      lat: data.original.lat,
                      lng: data.original.long,
                    });
                    document.getElementById('map').scrollIntoView();
                  } else if (type == 'end_ride') {
                    window.history.replaceState(
                      '',
                      '',
                      '?id=' + data.original.trip_id
                    );
                    endTripForm.setFieldValue('trip_id', data.original.trip_id);
                    setEndRideModal(true);
                  } else if (type == 'history') {
                    dispatch(
                      addPage({
                        key: 'vehicle_history',
                        data: initialState.vehicles,
                      })
                    );
                    dispatch(
                      fetchData({
                        module: 'vehicle_history',
                        vehicle_id: data.values.vehicle_id,
                      })
                    );
                    setHistoryModalStatus(true);
                  } else if (type == 'lock') {
                    setLockDialogConfirmationStatus(true);
                  }
                }}
                isMultiSelect={false}
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
              />
            </Card.Body>
          </Card>
        </Col>
      </div>
      <Modal
        modalHeading="Update Reason"
        fullWidth
        maxWidth="sm"
        status={endRideModal}
        onClose={() => {
          resetForm();
          setEndRideModal(false);
        }}
        onSave={endTripForm.submitForm}
      >
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              multiline
              label="Reason"
              name="description"
              inputProps={{
                style: {
                  fontSize: 14,
                  height: 100,
                  width: 272,
                },
              }}
              error={
                endTripForm.errors.description &&
                endTripForm.touched.description
                  ? true
                  : false
              }
              helperText={
                endTripForm.errors.description &&
                endTripForm.touched.description
                  ? endTripForm.errors.description
                  : false
              }
              value={endTripForm.values.description}
              onChange={endTripForm.handleChange}
              onBlur={endTripForm.handleBlur}
              fullWidth
              placeholder="Enter Reason"
            />
          </Grid>
        </Grid>
      </Modal>
      <Modal
        modalHeading={isEditMode ? 'Edit' : 'Add'}
        fullWidth
        maxWidth="md"
        status={formModalStatus}
        onClose={() => {
          resetForm();
          handleFormModalStatus(false);
        }}
        onSave={submitForm}
      >
        <br />
        {isEditMode ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Vehicle ID*"
                name="vehicle_id"
                disabled={isEditMode ? true : false}
                error={errors.vehicle_id && touched.vehicle_id ? true : false}
                helperText={
                  errors.vehicle_id && touched.vehicle_id
                    ? errors.vehicle_id
                    : false
                }
                value={values.vehicle_id}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Vehicle ID"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Device Id*"
                select={true}
                // disabled={isEditMode ? true : false}
                name="iot_id"
                value={values.iot_id}
                error={errors.iot_id && touched.iot_id ? true : false}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Device Id"
                fullWidth
              >
                <MenuItem selected disabled value="select">
                  Select Device Id
                </MenuItem>
                {deviceData?.map((device, index) => (
                  <MenuItem key={index} value={device.id}>
                    {`${device.name} (${device.imei})`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid xs={6} item>
              <TextField
                label="Battery*"
                placeholder="Select battery"
                select={true}
                name="battery_id"
                value={values.battery_id}
                error={errors.battery_id && touched.battery_id ? true : false}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              >
                <MenuItem selected disabled value="select">
                  Select battery
                </MenuItem>
                {batteryData?.map((battery, index) => (
                  <MenuItem key={index} value={battery.battery_id}>
                    {battery.battery_id}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText
                error={errors.battery_id && touched.battery_id ? true : false}
              >
                {errors.battery_id && touched.battery_id
                  ? errors.battery_id
                  : ''}
              </FormHelperText>
            </Grid> 
            <Grid xs={6} item>
              <TextField
                label="IMEI"
                name="imei"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                error={errors.imei && touched.imei ? true : false}
                helperText={errors.imei && touched.imei ? errors.imei : false}
                value={values.imei}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="IMEI Number"
              />
            </Grid>
            {/* <Grid xs={6} item>
              <TextField
                label="Controller ID"
                type="number"
                name="controller_id"
                InputProps={{ inputProps: { min: 0 } }}
                error={
                  errors.controller_id && touched.controller_id ? true : false
                }
                helperText={
                  errors.controller_id && touched.controller_id
                    ? errors.controller_id
                    : false
                }
                value={values.controller_id}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Controller ID"
              />
            </Grid> */}
            {/* <Grid xs={6} item>
              <TextField
                label="Controller Firmware Version"
                name="controller_firmware_version"
                error={
                  errors.controller_firmware_version &&
                  touched.controller_firmware_version
                    ? true
                    : false
                }
                helperText={
                  errors.controller_firmware_version &&
                  touched.controller_firmware_version
                    ? errors.controller_firmware_version
                    : false
                }
                value={values.controller_firmware_version}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Controller Firmware Version"
              />
            </Grid> */}
            <Grid xs={6} item>
              <TextField
                label="Number of Rides"
                type="number"
                name="number_of_rides"
                InputProps={{ inputProps: { min: 0 } }}
                error={
                  errors.number_of_rides && touched.number_of_rides
                    ? true
                    : false
                }
                helperText={
                  errors.number_of_rides && touched.number_of_rides
                    ? errors.number_of_rides
                    : false
                }
                value={values.number_of_rides}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Number of Rides"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                label="Performance"
                name="performance"
                type="string"
                error={errors.performance && touched.performance ? true : false}
                helperText={
                  errors.performance && touched.performance
                    ? errors.performance
                    : false
                }
                value={values.performance}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Performance"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                label="Current Location"
                name="current_location"
                error={
                  errors.current_location && touched.current_location
                    ? true
                    : false
                }
                helperText={
                  errors.current_location && touched.current_location
                    ? errors.current_location
                    : false
                }
                value={values.current_location}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Current location"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                label="Current Status"
                name="current_status"
                error={
                  errors.current_status && touched.current_status ? true : false
                }
                helperText={
                  errors.current_status && touched.current_status
                    ? errors.current_status
                    : false
                }
                value={values.current_status}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Current status"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                label="Distance travelled"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                name="distance_travelled"
                error={
                  errors.distance_travelled && touched.distance_travelled
                    ? true
                    : false
                }
                helperText={
                  errors.distance_travelled && touched.distance_travelled
                    ? errors.distance_travelled
                    : false
                }
                value={values.distance_travelled}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Distance travelled"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                label="Distance Since Last Inspection"
                name="distance_since_last_inspection"
                error={
                  errors.distance_since_last_inspection &&
                  touched.distance_since_last_inspection
                    ? true
                    : false
                }
                helperText={
                  errors.distance_since_last_inspection &&
                  touched.distance_since_last_inspection
                    ? errors.distance_since_last_inspection
                    : false
                }
                value={values.distance_since_last_inspection}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="distance since travelled"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                label="Vehicle active time"
                name="vehicle_active_time"
                error={
                  errors.vehicle_active_time && touched.vehicle_active_time
                    ? true
                    : false
                }
                helperText={
                  errors.vehicle_active_time && touched.vehicle_active_time
                    ? errors.vehicle_active_time
                    : false
                }
                value={values.vehicle_active_time}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Vehicle active time"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                label="Vehicle down time"
                name="vehicle_down_time"
                error={
                  errors.vehicle_down_time && touched.vehicle_down_time
                    ? true
                    : false
                }
                helperText={
                  errors.vehicle_down_time && touched.vehicle_down_time
                    ? errors.vehicle_down_time
                    : false
                }
                value={values.vehicle_down_time}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Vehicle down time"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                label="Number of different rides"
                name="number_of_different_riders"
                error={
                  errors.number_of_different_riders &&
                  touched.number_of_different_riders
                    ? true
                    : false
                }
                helperText={
                  errors.number_of_different_riders &&
                  touched.number_of_different_riders
                    ? errors.number_of_different_riders
                    : false
                }
                value={values.number_of_different_riders}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Number of rides"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Vehicle Type"
                name="vehicle_type"
                select={true}
                error={
                  errors.vehicle_type && touched.vehicle_type ? true : false
                }
                helperText={
                  errors.vehicle_type && touched.vehicle_type
                    ? errors.vehicle_type
                    : false
                }
                value={values.vehicle_type}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Vehicle Type"
              >
                <MenuItem value={'Bike'}>Bike</MenuItem>
                <MenuItem value={'Scooter'}>Scooter</MenuItem>
                <MenuItem value={'Delivery - KG3'}>Delivery - KG3</MenuItem>
                <MenuItem value={'Delivery - KG4'}>Delivery - KG4</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Vehicle ID*"
                name="vehicle_id"
                disabled={isEditMode ? true : false}
                error={errors.vehicle_id && touched.vehicle_id ? true : false}
                helperText={
                  errors.vehicle_id && touched.vehicle_id
                    ? errors.vehicle_id
                    : false
                }
                value={values.vehicle_id}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Vehicle ID"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Device Id"
                select={true}
                // disabled={isEditMode ? true : false}
                name="iot_id"
                value={values.iot_id}
                error={errors.iot_id && touched.iot_id ? true : false}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Device Id"
                fullWidth
              >
                <MenuItem selected disabled value="select">
                  Select Device Id
                </MenuItem>
                {deviceData?.map((device, index) => (
                  <MenuItem key={index} value={device.id}>
                    {`${device.name} (${device.imei})`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Vehicle Type"
                name="vehicle_type"
                select={true}
                error={
                  errors.vehicle_type && touched.vehicle_type ? true : false
                }
                helperText={
                  errors.vehicle_type && touched.vehicle_type
                    ? errors.vehicle_type
                    : false
                }
                value={values.vehicle_type}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Vehicle Type"
              >
                <MenuItem value={'Bike'}>Bike</MenuItem>
                <MenuItem value={'Scooter'}>Scooter</MenuItem>
                <MenuItem value={'Delivery - KG3'}>Delivery - KG3</MenuItem>
                <MenuItem value={'Delivery - KG4'}>Delivery - KG4</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        )}
      </Modal>
      <Modal
        fullWidth
        maxWidth="sm"
        onClose={() => setDialogConfirmationStatus(false)}
        modalHeading="Confirm"
        status={dialogConfirmationStatus}
        onSave={() => {
          dispatch(deleteData(values));
          setDialogConfirmationStatus(false);
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            Are you sure want to delete this record?
          </Grid>
        </Grid>
      </Modal>
      <Modal
        fullWidth
        maxWidth="sm"
        onClose={() => setLockDialogConfirmationStatus(false)}
        modalHeading="Confirm"
        status={lockDialogConfirmationStatus}
        onSave={() => {
          dispatch(
            unlockDevice({
              imei: values.imei,
              // module:""
            })
          );
          dispatch(fetchData({ module: 'iot' }));
          setLockDialogConfirmationStatus(false);
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            Are you sure want to unlock this IOT?
          </Grid>
        </Grid>
      </Modal>
      <Modal
        disableSave={true}
        fullWidth
        maxWidth="md"
        onClose={() => setHistoryModalStatus(false)}
        modalHeading="Vehicle Ride History"
        status={historyModalStatus}
        onSave={() => {
          setHistoryModalStatus(false);
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Datatable
              modalSize="xl"
              isMultiSelect={false}
              columns={columns2}
              data={vehicleHisotory}
            />
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

export default BSVehicles;
