import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Websites } from '../../Data/WebsiteData/efleety';
import Header from '../../Layouts/Header/Header';
import { fetchVendorVehicleIcons, openWebsite } from '../../redux/actions';
import { selectAuthUser, selectWebsites } from '../../redux/auth/selectors';
import { Helmet } from 'react-helmet';
import {
  selectGeneralApiData,
  selectWebsiteData,
} from '../../redux/general/selectors';

import _ from 'lodash';
import { Box, CircularProgress } from '@mui/material';
import { generalApiCall } from '../../redux/general/actions';
import { renderStyleSheet } from '../../css/styles';

export const MapLogo = {
  master: [require('../../assets/images/media/master.png')],
  keego: [
    require('../../assets/images/media/motorcycle_green.png'),
    require('../../assets/images/media/scooter_green.png'),
  ],
  ewaka: [
    require('../../assets/images/media/motorcycle_green.png'),
    require('../../assets/images/media/scooter_green.png'),
  ],
  gramobility: [require('../../assets/images/media/scooter_green.png')],
  citkar: [require('../../assets/images/media/van_green.png')],
  diageo: [require('../../assets/images/media/van_green.png')],
  abc_solar: [require('../../assets/images/media/solar_panel_green.png')],
  superfy: [require('../../assets/images/media/bin_green.png')],
  moby: [require('../../assets/images/media/motorcycle_green.png')],
  bombay_softwares: [require('../../assets/images/media/motorcycle_green.png')],
  biketek: [require('../../assets/images/media/motorcycle_green.png')],
  volti: [require('../../assets/images/media/scooter_green.png')],
  move_scooters: [require('../../assets/images/media/scooter_green.png')],
};

export const MAP_LOGOS = {
  master: require('../../assets/images/media/master.png'),
  solar_panel: require('../../assets/images/media/solar_panel_green.png'),
  bike: require('../../assets/images/media/motorcycle_green.png'),
  van: require('../../assets/images/media/van_green.png'),
  delivery_van: require('../../assets/images/media/van_green.png'),
  dustbin: require('../../assets/images/media/bin_green.png'),
  'Delivery - KG3': require('../../assets/images/media/kg3_green.png'),
  Bike: require('../../assets/images/media/motorcycle_green.png'),
  'Delivery - KG4': require('../../assets/images/media/kg4_green.png'),
  Scooter: require('../../assets/images/media/scooter_green.png'),
  container: require('../../assets/images/media/green-tank.png'),
};

let IOT_TYPES_ICONS = [
  require('../../assets/images/media/motorcycle_green.png'),
];

function Website() {
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const Websites = useSelector(selectWebsites);
  const websiteTypes = useSelector(
    selectGeneralApiData({ key: 'vendor_website_types' })
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (Websites.length == 1) {
      let website = Websites[0];
      sessionStorage.setItem('app', website.id);
      sessionStorage.setItem('app_key', website.app_key);
      localStorage.setItem('app_key', website.app_key);
      localStorage.setItem('app', website.id);
      dispatch(openWebsite(website));
      window.location.href = '/dashboard';
      // navigate('/dashboard');
    } else if (Websites.length > 1) {
      dispatch(
        generalApiCall({
          endpoint: 'api/v1/vehicle/types',
          params: {},
          method: 'GET',
          key: 'vendor_website_types',
        })
      );
      // dispatch(openWebsite({}));
    }
  }, []);

  if (Websites.length < 2) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '100px',
          justifyItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {renderStyleSheet({})}
      <Helmet>
        <title>Efleety</title>
      </Helmet>
      <div className="horizontalMenucontainer">
        <div className="page-main">
          <Header />
          <div style={{ marginTop: '100px' }}>
            {/* <Row>
              <Col
                style={{
                  padding: '30px',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Websites
              </Col>
            </Row> */}
            <Row>
              <br />
              {Websites.map((website, index) => {
                return (
                  <Col key={index} lg={3}>
                    <Card
                      onClick={() => {
                        console.log("website: ",website)
                        localStorage.setItem('app', website.id);
                        sessionStorage.setItem('app_key', website.app_key);
                        sessionStorage.setItem('app', website.id);
                        // console.log('website: ', website);
                        window.location.href = '/dashboard/';
                      }}
                      style={{ cursor: 'pointer', width: '100%' }}
                    >
                      <Card.Body>
                        <Card.Text style={{ textAlign: 'center' }}>
                          <img src={website.logo} height="100px" width="auto" />
                          <div
                            style={{
                              fontSize: '18px',
                              display: 'flex',
                              justifyContent: 'center',
                              paddingTop: '12px',
                            }}
                          >
                            {websiteTypes &&
                              websiteTypes[website.app_key].map((value) => (
                                <Box marginLeft="10px">
                                  <img
                                    src={MAP_LOGOS[value]}
                                    height="50px"
                                    width="auto"
                                  />
                                </Box>
                              ))}
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Website;
