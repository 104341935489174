import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Form, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { auth } from "../../../Firebase/firebase";
import * as yup from 'yup';
import { authenticateUser } from '../redux/actions';
import { selectAuthUser, selectIsAuthenticated } from '../redux/auth/selectors';
import { selectPageLoadingState } from '../redux/general/selectors';
import Logo from '../assets/images/logo.png';
import { AdminUsersData } from '../Data/WebsiteData/efleety';

const loginSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

const SignIn = () => {
  const [err, setError] = useState('');
  const [loading] = useState(false);
  const [passwordshow, setpasswordshow] = useState(false);
  const pageLoadingState = useSelector(selectPageLoadingState);
  const authUser = useSelector(selectAuthUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = '/';
    }
  }, [isAuthenticated]);

  const { values, errors, handleChange, handleBlur, submitForm } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: () => {
      // let credentials = AdminUsersData.data;
      // let data = credentials.find((details) => details.email == values.email.toLowerCase());

      // if (data.password == values.password) {
        dispatch(authenticateUser(values));
      // } else alert('Invalid Credentials');
    },
  });

  return (
    <div>
      <div className="login-img">
        <div className="page">
          <div className="col-login mx-auto mt-7">
            <div className="text-center">
              <img
                style={{ height: '80px' }}
                src={Logo}
                className="header-brand-img desktop-logo"
                alt="logo1"
              />
              <div style={{ fontWeight: 'bold' }}>Efleety</div>
            </div>
          </div>
          <div className="container-login100">
            <div className="wrap-login100 p-6">
              <form className="login100-form validate-form">
                <span className="login100-form-title pb-5">Login</span>
                <div>
                  {err && <Alert variant="danger">{err}</Alert>}
                  <div className="wrap-input100 validate-input input-group">
                    <Form.Control
                      className="input100 border-start-0 form-control ms-0"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <InputGroup
                    className="wrap-input100 validate-input"
                    id="Password-toggle"
                  >
                    <Form.Control
                      className="input100 border-start-0 ms-0"
                      type={passwordshow ? 'text' : 'password'}
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      required
                    />{' '}
                  </InputGroup>
                  <div className="container-login100-form-btn">
                    <Link
                      to="#"
                      onClick={submitForm}
                      className="login100-form-btn btn-secondary"
                    >
                      Login
                      {loading ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ms-2"
                        ></span>
                      ) : (
                        ''
                      )}
                    </Link>
                  </div>

                  <div className="text-center pt-3">
                    <p className="text-dark mb-0">
                      Not a member?{' '}
                      <Link to={`${process.env.PUBLIC_URL}`}>Sign UP</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* // <!-- CONTAINER CLOSED --> */}
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
