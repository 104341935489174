import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectModalStatus } from '../redux/general/selectors';
import { setModalStatus } from '../redux/actions';

const ModalContext = createContext({
  modalStatus: false,
  modalStatusHandler: () => {},
});

function ModalProvider({ children }) {
  const dispatch = useDispatch();
  const status = useSelector(selectModalStatus);
  const handleChange = () => {
    dispatch(setModalStatus(!status));
  };

  return (
    <ModalContext.Provider
      value={{ modalStatus: status, modalStatusHandler: handleChange }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModalCtx = () => {
  const ctx = useContext(ModalContext);
  return ctx;
};

export default ModalProvider;
