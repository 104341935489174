import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import persistStore from 'redux-persist/es/persistStore';
import rootReducers from './reducers';
import rootSaga from './rootSaga';
import storageSession from 'redux-persist/lib/storage/session';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import persistReducer from 'redux-persist/es/persistReducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
});

// let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export {
  store,
  // persistor
};
