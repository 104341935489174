import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  GoogleMap,
  MarkerClusterer,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import { DrawingManager, Polygon } from '@react-google-maps/api';
import {
  Form,
  Button,
  Col,
  ButtonGroup,
  Modal,
  Row,
  Card,
} from 'react-bootstrap';
import { polygonData, vehicleLocation } from '../../Data/DummyData';
import { renderStyleSheet } from '../../css/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectWebsiteData } from '../../redux/general/selectors';
import { useNavigate } from 'react-router-dom';
import { selectDatatable } from '../../redux/templateData/selectors';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { GeofencingDatatable } from '../../Data/WebsiteData/moby';
import { initialState } from '../../redux/templateData/init';
import {
  addPage,
  fetchData,
  setModule,
} from '../../redux/templateData/actions';
import { addData } from '../../redux/actions';

const containerStyle = {
  width: '100%',
  height: '600px',
};

const center_polygon = (coordinates) => {
  let x = coordinates.map((c) => c.lat);
  let y = coordinates.map((c) => c.lng);

  let minX = Math.min.apply(null, x);
  let maxX = Math.max.apply(null, x);

  let minY = Math.min.apply(null, y);
  let maxY = Math.max.apply(null, y);

  return {
    lat: (minX + maxX) / 2,
    lng: (minY + maxY) / 2,
  };
};

function CreateGeofencing() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const datatable = useSelector(selectDatatable);
  const module = 'geofencing';

  const { setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx();

  const columns = useMemo(
    () =>
      computeColumns({
        data: datatable.data,
        meta: datatable.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [datatable.data]
  );

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        })
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  const tableData = useMemo(() => datatable.data, [datatable.data]);

  let newRef = useRef();

  const websiteData = useSelector(selectWebsiteData);
  renderStyleSheet(websiteData);

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let reference_id = urlParams.get('id');
  let secondaryStyles = { background: 'red', color: 'black' };

  let { isLoaded } = useJsApiLoader({
    id: 'drawing-manager-example',
    googleMapsApiKey: 'AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac',
    libraries: ['drawing'],
  });

  const [mapData, setMapData] = useState(datatable?.data || []);
  const [newMapData, setNewMapData] = useState([]);

  // const [currentCenterPoint, setCurrentPosition] = useState(center_polygon(mapData[reference_id || 0].map_data));
  const [centerPosition, setCurrentPosition] = useState(
    localStorage.getItem(websiteData.name + 'map_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'map_data'))
      : {
          lat: parseFloat(websiteData.home_location_lat),
          lng: parseFloat(websiteData.home_location_long),
        }
  );
  const [zoomLevel, setZoomLevel] = useState(
    localStorage.getItem(websiteData.name + 'zoom_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'zoom_data'))
      : 5
  );
  const [currentCenterIndex, changeCurrentCenterIndex] = useState(0);
  const [savePolygonModal, setSavePolygonModal] = useState(false);
  const [geofenceName, setGeofenceName] = useState('');
  const [geofenceVehicles, setGeofenceVehicles] = useState([]);

  const onMapLoad = (map) => {
    google.maps.event.addListener(map, 'dragend', function () {
      const newCenter = map.getCenter();
      localStorage.setItem(
        websiteData.name + 'map_data',
        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() })
      );
    });

    google.maps.event.addListener(map, 'zoom_changed', function () {
      const newCenter = map.getZoom();
      localStorage.setItem(
        websiteData.name + 'zoom_data',
        JSON.stringify(newCenter)
      );
      setZoomLevel(newCenter);
    });
  };

  const onPolygonComplete = (polygon) => {
    if (newRef.current.drawingMode) {
      setNewMapData(
        polygon
          .getPath()
          .getArray()
          .map((coord) => {
            return { lat: coord.lat(), lng: coord.lng() };
          })
      );

      setSavePolygonModal(true);
    }

    polygon.setMap(null);
    newRef.current.setDrawingMode(null);
  };

  const handlerLoadDrawingManager = (drawingManagerInstance) => {
    newRef.current = drawingManagerInstance;
  };

  const changeCenterPoint = (status) => {
    let dataLength = mapData.length - 1;
    if (dataLength != -1) {
      if (status == 'up') {
        let currentIndex =
          currentCenterIndex < dataLength
            ? currentCenterIndex + 1
            : currentCenterIndex;
        changeCurrentCenterIndex(currentIndex);
        setCurrentPosition(
          mapData ? center_polygon(mapData[currentIndex].map_data) : ''
        );
      } else {
        let currentIndex =
          currentCenterIndex > 0 ? currentCenterIndex - 1 : currentCenterIndex;
        changeCurrentCenterIndex(currentIndex);
        setCurrentPosition(
          mapData ? center_polygon(mapData[currentIndex].map_data) : ''
        );
      }
    }
  };

  const setGeoData = (id) => {
    let arr = [...geofenceVehicles];
    if (!arr.includes(id)) {
      arr.push(id);
    } else {
      arr.splice(arr.indexOf(id), 1);
    }

    setGeofenceVehicles(arr);
  };

  const saveNewGeoFencing = () => {
    let data = {
      title: geofenceName,
      map_data: newMapData,
    };

    setNewMapData([]);
    setSavePolygonModal(false);
    setGeofenceName('');
    setGeofenceVehicles([]);

    dispatch(addData(data));
  };

  useEffect(() => {
    if (reference_id) {
      setCurrentPosition(
        mapData ? center_polygon(mapData[reference_id].map_data) : ''
      );
    }
  }, []);

  useEffect(() => {
    setMapData(datatable?.data || []);
  }, [datatable?.data]);

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Users</h6>
                      <h2 className="mb-0 number-font">44,278</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Profit</h6>
                      <h2 className="mb-0 number-font">67,987</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Expenses</h6>
                      <h2 className="mb-0 number-font">
                        {websiteData.currency.symbol}76,965
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col> */}
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Fencing</h6>
                      <h2 className="mb-0 number-font">{tableData.length}</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <div className="mb-2">
            <Button
              size={'sm'}
              variant="secondary"
              onClick={() => newRef.current.setDrawingMode(null)}
            >
              Drag
            </Button>
            {/* &nbsp;&nbsp;
            <Button
              size={'sm'}
              variant="secondary"
              onClick={() => newRef.current.setDrawingMode('polygon')}
            >
              Create <i class="bi bi-plus-lg"></i>
            </Button> */}
            {/* <ButtonGroup style={{ marginTop: '3px', marginLeft: '10px' }}>
              <Button
                size={'sm'}
                variant="secondary"
                onClick={() => changeCenterPoint('down')}
              >
                {'<'}
              </Button>
              <Button
                size={'sm'}
                variant="secondary"
                onClick={() => changeCenterPoint('up')}
              >
                {'>'}
              </Button>
            </ButtonGroup> */}
          </div>
          <div>
            {isLoaded ? (
              <GoogleMap
                id="drawing-manager-example"
                mapContainerStyle={containerStyle}
                zoom={zoomLevel}
                onLoad={(map) => onMapLoad(map)}
                center={centerPosition}
              >
                <div style={{ position: 'absolute', top: 12, right: 60 }}>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setCurrentPosition({
                        lat: parseFloat(websiteData.home_location_lat),
                        lng: parseFloat(websiteData.home_location_long),
                      });
                      localStorage.setItem(
                        websiteData.name + 'map_data',
                        JSON.stringify({
                          lat: parseFloat(websiteData.home_location_lat),
                          lng: parseFloat(websiteData.home_location_long),
                        })
                      );
                    }}
                  >
                    Home Location
                  </Button>
                </div>
                <DrawingManager
                  ref={newRef}
                  options={{
                    drawingControl: false,
                    drawingControlOptions: {
                      drawingModes: ['polygon'],
                    },
                    polygonOptions: { editable: true },
                  }}
                  onLoad={handlerLoadDrawingManager}
                  onPolygonComplete={onPolygonComplete}
                />
                {mapData
                  ? mapData.map((d) => {
                      return (
                        <Polygon
                          draggable={false}
                          editable={false}
                          options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#FF0000',
                            fillOpacity: 0.35,
                          }}
                          onClick={() => {}}
                          clickable
                          path={d.map_data}
                        />
                      );
                    })
                  : null}
                {newMapData ? (
                  <Polygon
                    draggable={false}
                    editable={false}
                    options={{
                      strokeColor: '#FF0000',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: '#FF0000',
                      fillOpacity: 0.35,
                    }}
                    onClick={() => {}}
                    clickable
                    path={newMapData}
                  />
                ) : null}
                {/* <MarkerClusterer minimumClusterSize={2}>
                  {(clusterer) =>
                    vehicleLocation.map((d, i) => {
                      return (
                        <MarkerF
                          key={i}
                          icon={
                            'https://findicons.com/files/icons/2482/icons8_ever_growing_set_of_windows_8_metro_style_icons/26/motorcycle.png'
                          }
                          // label={{text: ' ', color:'black', fontSize: '18px'}}
                          position={{ lat: d.latitude, lng: d.longitude }}
                          clusterer={clusterer}
                        />
                      );
                    })
                  }
                </MarkerClusterer> */}
              </GoogleMap>
            ) : null}
            <br />
          </div>
        </Col>
      </Row>
      <Modal
        show={savePolygonModal}
        onHide={() => {
          setNewMapData([]),
            setSavePolygonModal(false),
            setGeofenceName(''),
            setGeofenceVehicles([]);
        }}
      >
        <Modal.Header closeButton>Save Geofencing</Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <Col sm={4} className="my-3">
                <div>
                  <b>Geofence Name</b>
                </div>
                <div style={{ paddingTop: '10px' }}>
                  <input
                    className="border-start-2 form-control ms-0"
                    type="name"
                    placeholder="Name"
                    style={{ minWidth: '430px' }}
                    onInput={(e) => setGeofenceName(e.target.value)}
                  ></input>
                </div>
              </Col>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            size={'sm'}
            className={'mt-2 fs-11-375px'}
            onClick={() => {
              setNewMapData([]),
                setSavePolygonModal(false),
                setGeofenceName(''),
                setGeofenceVehicles([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            className={'mt-2 fs-11-375px'}
            size={'sm'}
            onClick={() => saveNewGeoFencing()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="row-sm">
        <Col lg={12}>
          <Card>
            {/* <Card.Header>
                        <Card.Title as='h3'>My Geofencing</Card.Title>
                    </Card.Header> */}
            <Card.Body>
              <Datatable
                addAction={() => {
                  newRef.current.setDrawingMode('polygon');
                }}
                addActionName="Create"
                refreshAction={() => {
                  dispatch(fetchData({ module }));
                }}
                datatableTitle="Geo-fencing"
                sortable_key="id"
                columns={columns}
                data={tableData}
                actions={[{ name: 'Open Geofencing', type: 'open_geofencing' }]}
                onActionClick={(event, { type, data }) => {
                  if (type == 'open_geofencing') {
                    //history(`/geofencing/create-geofencing?id=${allData.index}`)
                    let currentIndexValue = tableData.findIndex(
                      (d) => d.title == data.original.title
                    );
                    changeCurrentCenterIndex(currentIndexValue);
                    localStorage.getItem(websiteData.name + 'zoom_data') &&
                    JSON.parse(
                      localStorage.getItem(websiteData.name + 'zoom_data')
                    ) < 12
                      ? setZoomLevel(15)
                      : '';
                    setCurrentPosition(
                      data.original
                        ? center_polygon(data.original.map_data)
                        : ''
                    );
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CreateGeofencing;
