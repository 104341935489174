import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

import pic1 from '../../assets/images/orders/10.jpg';
import pic2 from '../../assets/images/orders/12.jpg';
import pic3 from '../../assets/images/orders/4.jpg';
import pic4 from '../../assets/images/orders/8.jpg';
import pic5 from '../../assets/images/orders/6.jpg';
import pic6 from '../../assets/images/orders/1.jpg';
import pic7 from '../../assets/images/orders/11.jpg';
import pic8 from '../../assets/images/orders/2.jpg';

import moment from 'moment';
import { ACCESS_TYPES, PERMISSIONS } from '../../redux/redux_constants';

export const IOT_INSTRUCTIONS = [
  {
    name: 'Unlock',
    action: 'unlockDevice',
  },
  {
    name: 'LOCK',
  },
  {
    name: 'Engine On',
  },
  {
    name: 'Engine Off',
  },
  {
    name: 'Mode',
  },
  {
    name: 'Head Lights On',
  },
  {
    name: 'Head Lights Off',
  },
  {
    name: 'Head Lights Controller On',
  },
  {
    name: 'Head Lights Controller Off',
  },
  {
    name: 'Tail Lights On',
  },
  {
    name: 'IOT Status',
  },
  {
    name: 'GPS confirm',
  },
];

export const RouterMetaData = {
  id: {
    hide: true,
  },
  number_of_rides: {
    hide: true,
  },
  performance: {
    hide: true,
  },
  current_location: {
    hide: true,
  },
  imei: {
    hide: true,
  },
  battery_firmware_version: {
    hide: true,
  },
  controller_id: {
    hide: true,
  },
  battery_cell_voltage: {
    hide: true,
  },
  controller_firmware_version: {
    hide: true,
  },
  distance_travelled: {
    hide: true,
  },
  distance_since_last_inspection: {
    hide: true,
  },
  vehicle_active_time: {
    hide: true,
  },
  vehicle_down_time: {
    hide: true,
  },
  average_battery_distance: {
    hide: true,
  },
  number_of_different_riders: {
    hide: true,
  },
};

export const defaultMeta = {
  vehicle_id: {
    showDefault: true,
    type: 'button',
    action: 'openModal()',
  },
  current_status: {
    showDefault: true,
    filters: { disableFilter: false, filterType: 'select' },
    hide: false,
    icons: {
      'Vehicle with Warning': '../assets/images/warning-icon.jpeg',
      'Out of service': '../assets/images/offline-icon.png',
      'Available for Service': '../assets/images/online.png',
    },
  },
  battery_charging_state: {
    showDefault: true,
    filters: { disableFilter: false, filterType: 'select' },
    hide: false,
    icons: {
      charging: '../assets/images/warning-icon.jpeg',
      discharged: '../assets/images/offline-icon.png',
      charged: '../assets/images/online.png',
    },
  },
  service_status: {
    showDefault: true,
    icons: {
      offline: '../assets/images/offline-icon.png',
      online: '../assets/images/online.png',
    },
  },
  status: {
    filters: { filterType: 'select' },
    icons: {
      filled: '../assets/images/offline-icon.png',
      cleaned: '../assets/images/online.png',
    },
  },
  service_id: {
    // header: 'Service_id',
    showDefault: true,
    type: 'button', //button, link
    action: 'openServiceDetails()',
  },
  battery_id: {
    hide: false,
    type: 'button',
    action: 'getBatteryDetails()',
  },
};

// export const RidersData = {
//   COLUMNS: [
//     {
//       name: 'NAME',
//       accessor: 'name',
//       className: 'text-center wd-15p border-bottom-0',
//     },
//     {
//       name: 'TOTAL RIDES',
//       accessor: 'total_rides',
//       className: 'text-center wd-15p border-bottom-0 ',
//     },
//     {
//       name: 'PERFORMANCE',
//       accessor: 'performance',
//       className: 'text-center wd-15p border-bottom-0 ',
//     },
//     {
//       name: 'IS RIDING',
//       accessor: 'is_riding',
//       className: 'text-center wd-15p border-bottom-0 ',
//     },
//     {
//       name: 'AUTO ALLOCATION',
//       accessor: 'auto_allocation',
//       className: 'text-center wd-15p border-bottom-0 ',
//     },
//   ],
//   DATATABLE: [
//     {
//       name: 'Ty Ayelloribbin',
//       total_rides: '0',
//       performance: '0.98',
//       is_riding: 'Yes',
//       auto_allocation: '',
//     },
//     {
//       name: 'Hugo First',
//       total_rides: '0',
//       performance: '0.9299999999999999',
//       is_riding: '',
//       auto_allocation: '1',
//     },
//     {
//       name: 'Percy Vere',
//       total_rides: '0',
//       performance: '0.8799999999999999',
//       is_riding: '',
//       auto_allocation: '2',
//     },
//     {
//       name: 'Jack Aranda',
//       total_rides: '0',
//       performance: '0.8299999999999998',
//       is_riding: 'Yes',
//       auto_allocation: '',
//     },
//     {
//       name: 'Olive Tree',
//       total_rides: '0',
//       performance: '0.7799999999999998',
//       is_riding: '',
//       auto_allocation: '3',
//     },
//     {
//       name: 'Fran G. Pani',
//       total_rides: '0',
//       performance: '0.7299999999999998',
//       is_riding: '',
//       auto_allocation: '4',
//     },
//     {
//       name: 'John Quil',
//       total_rides: '0',
//       performance: '0.6799999999999997',
//       is_riding: 'Yes',
//       auto_allocation: '',
//     },
//   ],
// };

export const VehicleIOTDatatable = {
  meta: {
    id: {
      hide: true,
    },
    number_of_rides: {
      hide: true,
    },
    performance: {
      hide: true,
    },
    current_location: {
      hide: true,
    },
    imei: {
      hide: true,
    },
    battery_charging_state: {
      hide: true,
    },
    battery_firmware_version: {
      hide: true,
    },
    controller_id: {
      hide: true,
    },
    battery_cell_voltage: {
      hide: true,
    },
    battery_firmware_version: {
      hide: true,
    },
    distance_travelled: {
      hide: true,
    },
    controller_firmware_version: {
      hide: true,
    },
    vehicle_active_time: {
      hide: true,
    },
    vehicle_down_time: {
      hide: true,
    },
    battery_charging_state: {
      hide: true,
    },
    average_battery_distance: {
      hide: true,
    },
    number_of_different_riders: {
      hide: true,
    },
    distance_since_last_inspection: {
      hide: true,
    },
    capacity_total: {
      showDefault: false,
    },
    battery_level: {
      suffix: '%',
    },
  },
  data: [
    {
      id: '1',
      vehicle_id: 'VN01',
      number_of_rides: '1',
      performance: '0.98',
      battery_level: 35,
      current_location: 'City Bus Keego',
      current_status: 'Out of service',
      imei: '1234567891011214',
      battery_id: '211876',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '300 Km',
      distance_since_last_inspection: '100 Km',
      vehicle_active_time: 78,
      vehicle_down_time: 2,
      average_battery_distance: '50 km',
      number_of_different_riders: '10',
      type: 'bike',
    },
    {
      id: '2',
      vehicle_id: 'VN02',
      number_of_rides: '2',
      performance: '0.73',
      battery_level: 29,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '1234567891011214',
      battery_id: '256782',
      battery_charging_state: 'charging',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '400 Km',
      distance_since_last_inspection: '250 Km',
      vehicle_active_time: 96,
      vehicle_down_time: 42,
      average_battery_distance: '98 Km',
      number_of_different_riders: '8',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '3',
      vehicle_id: 'VN03',
      number_of_rides: '1',
      performance: '0.68',
      battery_level: 12,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '1234567891011123',
      battery_id: '312445',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      vehicle_active_time: 24,
      distance_travelled: '280 Km',
      distance_since_last_inspection: '165 Km',
      vehicle_down_time: 66,
      average_battery_distance: '136 Km',
      number_of_different_riders: '12',
      type: 'bike',
    },
    {
      id: '4',
      vehicle_id: 'VN04',
      number_of_rides: '2',
      performance: '0.63',
      battery_level: 85,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '1123278910112543',
      battery_id: '213441',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '530 Km',
      distance_since_last_inspection: '184 Km',
      vehicle_active_time: 78,
      vehicle_down_time: 23,
      average_battery_distance: '78 Km',
      number_of_different_riders: '8',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '5',
      vehicle_id: 'VN05',
      number_of_rides: '1',
      performance: '0.58',
      battery_level: 90,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '9042876556711214',
      battery_id: '678435',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '640 Km',
      distance_since_last_inspection: '180 Km',
      vehicle_active_time: 132,
      vehicle_down_time: 6,
      average_battery_distance: '69 Km',
      number_of_different_riders: '7',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '6',
      vehicle_id: 'VN06',
      number_of_rides: '2',
      performance: '0.3',
      battery_level: 19,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '1234567891011212',
      battery_id: '987234',
      battery_charging_state: 'discharged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '780 Km ',
      distance_since_last_inspection: '230 Km',
      vehicle_active_time: 90,
      vehicle_down_time: 26,
      average_battery_distance: '52 Km',
      number_of_different_riders: '3',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '7',
      vehicle_id: 'VN07',
      number_of_rides: '1',
      performance: '0.4',
      battery_level: 77,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '7648567891011214',
      battery_id: '567234',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '265 Km',
      distance_since_last_inspection: '275 Km',
      vehicle_active_time: 25,
      vehicle_down_time: 44,
      average_battery_distance: '44 Km',
      number_of_different_riders: '5',
      type: 'bike',
    },
    {
      id: '8',
      vehicle_id: 'VN08',
      number_of_rides: '1',
      performance: '0.58',
      battery_level: 22,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '9042876556711214',
      battery_id: '892739',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '630 Km',
      distance_since_last_inspection: '423 Lm',
      vehicle_active_time: 78,
      vehicle_down_time: 53,
      average_battery_distance: '12 km',
      number_of_different_riders: '10',
      type: 'bike',
    },
    {
      id: '9',
      vehicle_id: 'VN09',
      number_of_rides: '2',
      performance: '0.3',
      battery_level: 93,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '1234567891011212',
      battery_id: '222442',
      battery_charging_state: 'discharged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '700 Km',
      distance_since_last_inspection: '100 Km',
      vehicle_active_time: 44,
      vehicle_down_time: 82,
      average_battery_distance: '72 Km',
      number_of_different_riders: '8',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '10',
      vehicle_id: 'VN10',
      number_of_rides: '1',
      performance: '0.4',
      battery_level: 90,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '7648567891011214',
      battery_id: '527223',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.1',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '468 Km',
      distance_since_last_inspection: '89 Km ',
      vehicle_active_time: 62,
      vehicle_down_time: 12,
      average_battery_distance: '86 km',
      number_of_different_riders: '5',
      type: 'bike',
    },
    {
      id: '11',
      vehicle_id: 'VN11',
      number_of_rides: '1',
      performance: '0.4',
      battery_level: 53,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '7648567891011214',
      battery_id: '567234',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '520 Km',
      distance_since_last_inspection: '72 Km',
      vehicle_active_time: 78,
      vehicle_down_time: 92,
      average_battery_distance: '72 Km',
      number_of_different_riders: '6',
      type: 'bike',
    },
  ],
};

export const RidersData = {
  meta: {
    name: {
      showDefault: true,
      filters: { disableFilter: false, filterType: 'select' },
      hide: false,
      type: 'button',
      action: 'getRidersDetails()',
    },
    employee_type: {
      filters: { disableFilter: false, filterType: 'select' },
      hide: false,
    },
    is_riding: {
      filters: { disableFilter: false, filterType: 'select' },
      hide: false,
    },
    on_duty: {
      filters: { disableFilter: true, filterType: 'select' },
      hide: true,
    },
  },
  data: [
    {
      name: 'Aldous Hudghton',
      email: 'aldous.hudghton@gmail.com',
      contact_number: '2345678901',
      total_rides: '0',
      employee_type: 'rider',
      performance: '0.98',
      is_riding: 'Yes',
      on_duty: true,
      auto_allocation: '',
    },
    {
      name: 'Ty Ayelloribbin',
      email: 'ty.ayelloribbin@gmail.com',
      contact_number: '2345678901',
      total_rides: '0',
      employee_type: 'rider',
      performance: '0.98',
      is_riding: 'Yes',
      on_duty: true,
      auto_allocation: '',
    },
    {
      name: 'Hugo First',
      email: 'hugo.first@gmail.com',
      contact_number: '8233478901',
      total_rides: '0',
      employee_type: 'rider',
      performance: '0.9299999999999999',
      is_riding: '',
      on_duty: false,
      auto_allocation: '1',
    },
    {
      name: 'Aldous Hudghton',
      email: 'aldous.hudghton.quil@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'service',
      performance: '0.8799999999999999',
      is_riding: '',
      on_duty: false,
      auto_allocation: '2',
    },
    {
      name: 'Jack Aranda',
      email: 'jack.aranda@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      performance: '0.8299999999999998',
      is_riding: 'Yes',
      on_duty: false,
      employee_type: 'all',
      auto_allocation: '',
    },
    {
      name: 'Olive Tree',
      email: 'olice.tree@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      performance: '0.7799999999999998',
      is_riding: '',
      on_duty: false,
      employee_type: 'service',
      auto_allocation: '3',
    },
    {
      name: 'Fran G. Pani',
      email: 'fran.pani@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      performance: '0.7299999999999998',
      is_riding: '',
      on_duty: false,
      employee_type: 'all',
      auto_allocation: '4',
    },
    {
      name: 'John Quil',
      email: 'john.quil@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'all',
      on_duty: true,
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      auto_allocation: '',
    },
    {
      name: 'Percy Vere',
      email: 'percy.vere@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'all',
      on_duty: false,
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      auto_allocation: '',
    },
    {
      name: 'Deonne Philo',
      email: 'deonne.philo@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'rider',
      on_duty: true,
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      auto_allocation: '',
    },
    {
      name: 'Brady Blamire',
      email: 'brady.blamire@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'all',
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      on_duty: false,
      auto_allocation: '',
    },
    {
      name: 'Maud Caverhill',
      email: 'maud.caverhill@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'all',
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      on_duty: true,
      auto_allocation: '',
    },
    {
      name: 'Alika Brayley',
      email: 'alika.brayley@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'rider',
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      on_duty: true,
      auto_allocation: '',
    },
    {
      name: 'Bank Wellen',
      email: 'bank.wellen@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      on_duty: true,
      employee_type: 'all',
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      auto_allocation: '',
    },
    {
      name: 'Aleda Ridgewell',
      email: 'aleda.ridgewell@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'rider',
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      on_duty: true,
      auto_allocation: '',
    },
    {
      name: 'Thorn Stapele',
      email: 'thornstapele@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'all',
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      on_duty: true,
      auto_allocation: '',
    },
    {
      name: 'Sandy Reide',
      email: 'sandy.reide@gmail.com',
      contact_number: '72890654321',
      total_rides: '0',
      employee_type: 'all',
      performance: '0.6799999999999997',
      is_riding: 'Yes',
      on_duty: true,
      auto_allocation: '',
    },
  ],
};

export const PendingDeliveriesData = {
  COLUMNS: [
    {
      Header: 'Allocated Rider',
      accessor: 'rider',
      className: 'text-center wd-15p border-bottom-0',
    },
    {
      Header: 'FROM',
      accessor: 'from',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'TO',
      accessor: 'to',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: '	ESTIMATED TRIP DURATION',
      accessor: 'estimated_trip_duration',
      className: 'text-center wd-15p border-bottom-0 ',
    },
  ],
  DATATABLE: [
    {
      FNAME: 'Hugo First',
      LNAME: 'Tower of City',
      POSITION: 'City Museum',
      START: '15 Minutes',
    },
    {
      FNAME: 'Percy Vere',
      LNAME: 'City Museum',
      POSITION: 'Med Rouge',
      START: '90 Minutes',
    },
    {
      FNAME: 'Olive Tree',
      LNAME: 'Med Rouge',
      POSITION: 'Cathedral',
      START: '90 Minutes',
    },
    {
      FNAME: 'Fran G. Pani',
      LNAME: 'Cathedral',
      POSITION: 'Tower of City',
      START: '90 Minutes',
    },
  ],
};

export const EventsDatatable = {
  data: [
    {
      tracking_id: '#98765490',
      product: 'La Pinioz Pizza',
      customer: 'Cherry Blossom',
      date: '30 Aug 2021',
      amount: '$6.721.5',
      payment_mode: 'Online Payment',
      status: 'Shipped',
      classname: 'success-transparent rounded-pill text-success p-2 px-3',
    },
    {
      tracking_id: '#98765490',
      icon: pic2,
      product: 'Flower Pot',
      customer: 'Simon Sais',
      date: '15 Nov 2021',
      amount: '$35,7863',
      payment_mode: 'Online Payment',
      status: 'Cancelled',
      Action: '0.36%',
      classname: 'danger-transparent rounded-pill text-danger p-2 px-3',
    },
    {
      tracking_id: '#98765490',
      icon: pic3,
      product: 'Grains and Bread',
      customer: 'Manny Jah',
      date: '27 Jan 2021',
      amount: '$5,89,6437',
      payment_mode: 'Cash on Delivery',
      status: 'Pending',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
    {
      tracking_id: '#98765490',
      icon: pic4,
      product: 'White Pasta',
      customer: 'Florinda Carasco',
      date: '19 Sep 2021',
      amount: '$17.98',
      payment_mode: 'Online Payment',
      status: 'Shipped',
      Action: '0.36%',
      classname: 'success-transparent rounded-pill text-success p-2 px-3',
    },
    {
      tracking_id: '#98765490',
      icon: pic5,
      product: 'Rice',
      customer: 'Ivan Notheridiya',
      date: '06 Oct 2021',
      amount: '$8.654.4',
      payment_mode: 'Cash on Delivery',
      status: 'Cancelled',
      Action: '0.36%',
      classname: 'danger-transparent rounded-pill text-danger p-2 px-3',
    },
    {
      tracking_id: '#98765490',
      icon: pic6,
      product: 'Butter, yogurt',
      customer: 'Willie Findit',
      date: '10 Jul 2021',
      amount: '$8.654.4',
      payment_mode: 'Cash on Delivery',
      status: 'Pending',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
    {
      tracking_id: '#98765490',
      icon: pic7,
      product: 'Red Sause Pasta',
      customer: 'Addie Minstra',
      date: '25 Jun 2021',
      amount: '$7,34,9768',
      payment_mode: 'Online Payment',
      status: 'Shipped',
      Action: '0.36%',
      classname: 'success-transparent rounded-pill text-success p-2 px-3',
    },
    {
      tracking_id: '#98765490',
      icon: pic8,
      product: 'Combo coldrink and pizza',
      customer: 'Laura Biding',
      date: '22 Feb 2021',
      amount: '$7.76.654',
      payment_mode: 'Cash on Delivery',
      status: 'Pending',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
  ],
  columns: [
    {
      name: 'TRACKING ID',
      selector: (row) => [row.tracking_id],
      sortable: false,
      cell: (row) => <span className="align-middle"> {row.tracking_id} </span>,
    },
    {
      name: 'PRODUCT',
      selector: (row) => [row.icon],
      sortable: true,
      cell: (row) => (
        <div className="align-middle">
          <span>{row.product}</span>
        </div>
      ),
    },
    {
      name: '	CUSTOMER',
      selector: (row) => [row.customer],
      sortable: true,
      cell: (row) => (
        <span className="text-nowrap align-middle">{row.customer}</span>
      ),
    },
    {
      name: 'DATE',
      selector: (row) => [row.date],
      sortable: true,
      cell: (row) => (
        <span className="text-nowrap align-middle">{row.date}</span>
      ),
    },
    {
      name: 'AMOUNT',
      sortable: false,
      cell: (row) => (
        <span className="text-center align-middle">{row.amount}</span>
      ),
    },
    {
      name: 'PAYMENT MODE',
      sortable: false,
      cell: (row) => (
        <span className="text-center align-middle">{row.payment_mode}</span>
      ),
    },
    {
      name: 'STATUS',
      sortable: true,
      cell: (row) => (
        <Badge bg={row.classname} className="text-center align-middle">
          {row.status}
        </Badge>
      ),
    },
    {
      name: 'ACTION',
      sortable: false,
      cell: () => (
        <div>
          <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <span className="fe fe-edit fs-18 text-primary me-4"></span>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <span className="fe fe-trash-2 text-danger fs-18 me-4"></span>
          </OverlayTrigger>
        </div>
      ),
    },
  ],
};

export const ShippedEventData = {
  data: [
    {
      trid: '#98765490',
      icon: pic4,
      product: 'White Pasta',
      customer: 'Florinda Carasco',
      date: '19 Sep 2021',
      amount: '$17.98',
      payment: 'Online Payment',
      status: 'Shipped',
      Action: '0.36%',
      classname: 'success-transparent rounded-pill text-success p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic5,
      product: 'Rice',
      customer: 'Ivan Notheridiya',
      date: '06 Oct 2021',
      amount: '$8.654.4',
      payment: 'Cash on Delivery',
      status: 'Cancelled',
      Action: '0.36%',
      classname: 'success-transparent rounded-pill text-success p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic6,
      product: 'Butter, yogurt',
      customer: 'Willie Findit',
      date: '10 Jul 2021',
      amount: '$8.654.4',
      payment: 'Cash on Delivery',
      status: 'Pending',
      Action: '0.36%',
      classname: 'success-transparent rounded-pill text-success p-2 px-3',
    },
  ],
  columns: [
    {
      name: 'TRACKING ID',
      selector: (row) => [row.trid],
      cell: (row) => <span className="align-middle"> {row.trid} </span>,
    },
    {
      name: 'PRODUCT',
      selector: (row) => [row.icon],
      cell: (row) => (
        <div className="align-middle">
          <span>{row.product}</span>
        </div>
      ),
    },
    {
      name: '	CUSTOMER',
      selector: (row) => [row.customer],
      cell: (row) => (
        <span className="text-nowrap align-middle">{row.customer}</span>
      ),
    },
    {
      name: 'DATE',
      selector: (row) => [row.date],
      cell: (row) => (
        <span className="text-nowrap align-middle">{row.date}</span>
      ),
    },
    {
      name: 'AMOUNT',
      cell: (row) => (
        <span className="text-center align-middle">{row.amount}</span>
      ),
    },
    {
      name: 'PAYMENT MODE',
      cell: (row) => (
        <span className="text-center align-middle">{row.payment}</span>
      ),
    },
    {
      name: 'STATUS',
      cell: (row) => <Badge bg={row.classname}>Shipped</Badge>,
    },
    {
      name: 'ACTION',
      sortable: false,
      cell: () => (
        <div>
          <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <span className="fe fe-edit fs-18 text-primary me-4"></span>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <span className="fe fe-trash-2 text-danger fs-18 me-4"></span>
          </OverlayTrigger>
        </div>
      ),
    },
  ],
};

export const CancelledEventData = {
  data: [
    {
      trid: '#98765490',
      icon: pic1,
      product: 'La Pinioz Pizza',
      customer: 'Cherry Blossom',
      date: '30 Aug 2021',
      amount: '$6.721.5',
      payment: 'Online Payment',
      status: 'Shipped',
      Action: '0.36%',
      classname: 'danger-transparent rounded-pill text-danger p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic2,
      product: 'Flower Pot',
      customer: 'Simon Sais',
      date: '15 Nov 2021',
      amount: '$35,7863',
      payment: 'Online Payment',
      status: 'Cancelled',
      Action: '0.36%',
      classname: 'danger-transparent rounded-pill text-danger p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic3,
      product: 'Grains and Bread',
      customer: 'Manny Jah',
      date: '27 Jan 2021',
      amount: '$5,89,6437',
      payment: 'Cash on Delivery',
      status: 'Pending',
      Action: '0.36%',
      classname: 'danger-transparent rounded-pill text-danger p-2 px-3',
    },
  ],
  columns: [
    {
      name: 'TRACKING ID',
      selector: (row) => [row.trid],
      cell: (row) => <span className="align-middle"> {row.trid} </span>,
    },
    {
      name: 'PRODUCT',
      selector: (row) => [row.icon],
      cell: (row) => (
        <div className="align-middle">
          <span>{row.product}</span>
        </div>
      ),
    },
    {
      name: '	CUSTOMER',
      selector: (row) => [row.customer],
      cell: (row) => (
        <span className="text-nowrap align-middle">{row.customer}</span>
      ),
    },
    {
      name: 'DATE',
      selector: (row) => [row.date],
      cell: (row) => (
        <span className="text-nowrap align-middle">{row.date}</span>
      ),
    },
    {
      name: 'AMOUNT',
      cell: (row) => (
        <span className="text-center align-middle">{row.amount}</span>
      ),
    },
    {
      name: 'PAYMENT MODE',
      cell: (row) => (
        <span className="text-center align-middle">{row.payment}</span>
      ),
    },
    {
      name: 'STATUS',
      cell: (row) => (
        <Badge bg={row.classname} className="">
          Cancelled
        </Badge>
      ),
    },
    {
      name: 'ACTION',
      sortable: false,
      cell: () => (
        <div>
          <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <span className="fe fe-edit fs-18 text-primary me-4"></span>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <span className="fe fe-trash-2 text-danger fs-18 me-4"></span>
          </OverlayTrigger>
        </div>
      ),
    },
  ],
};

export const batteryDatatable = {
  meta: {
    vehicle_id: {
      showDefault: true,
      type: 'button',
      action: 'openModal()',
    },
    battery_id: {
      showDefault: true,
      type: 'button',
      action: 'getBatteryDetails()',
    },
  },
  data: [
    {
      battery_id: '211876',
      vehicle_id: 'VN01',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      battery_level: 42,
      isOverhitting: false,
      number_of_recharges: 32,
      distance_since_last_recharge: '23 Km ',
    },
    {
      vehicle_id: 'VN02',
      battery_id: '256782',
      battery_charging_state: 'charging',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      battery_level: 29,
      isOverhitting: false,
      number_of_recharges: 10,
      distance_since_last_recharge: '42 Km',
    },
    {
      vehicle_id: 'VN03',
      battery_id: '312445',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      battery_cell_voltage: '100V',
      battery_level: 57,
      isOverhitting: false,
      number_of_recharges: 28,
      distance_since_last_recharge: '55 Km',
    },
    {
      vehicle_id: 'VN04',
      battery_id: '213441',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      battery_level: 62,
      isOverhitting: false,
      number_of_recharges: 30,
      distance_since_last_recharge: '12 Km',
    },
    {
      vehicle_id: 'VN05',
      battery_id: '678435',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      battery_cell_voltage: '100V',
      battery_level: 57,
      isOverhitting: false,
      number_of_recharges: 41,
      distance_since_last_recharge: '82 Km',
    },
    {
      vehicle_id: 'VN06',
      battery_id: '987234',
      battery_charging_state: 'discharged',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      battery_level: 29,
      isOverhitting: true,
      number_of_recharges: 10,
      distance_since_last_recharge: '92 Km',
    },
    {
      vehicle_id: 'VN07',
      battery_id: '567234',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      battery_level: 41,
      isOverhitting: true,
      number_of_recharges: 82,
      distance_since_last_recharge: '17 Km',
    },
    {
      vehicle_id: '',
      battery_id: '567234',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      battery_cell_voltage: '100V',
      battery_level: 12,
      isOverhitting: true,
      number_of_recharges: 42,
      distance_since_last_recharge: '5 Km',
    },
    {
      vehicle_id: '',
      battery_id: '567231',
      battery_charging_state: 'discharged',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      battery_level: 29,
      isOverhitting: true,
      number_of_recharges: 8,
      distance_since_last_recharge: '32 Km',
    },
    {
      vehicle_id: '',
      battery_id: '567232',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      battery_level: 61,
      number_of_recharges: 27,
      isOverhitting: false,
      distance_since_last_recharge: '72 Km',
    },
    {
      vehicle_id: '',
      battery_id: '567233',
      battery_charging_state: 'discharged',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      isOverhitting: false,
      battery_level: 38,
      number_of_recharges: 96,
      distance_since_last_recharge: '82 Km',
    },
    {
      vehicle_id: '',
      battery_id: '567238',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      battery_cell_voltage: '100V',
      isOverhitting: false,
      battery_level: 33,
      number_of_recharges: 12,
      distance_since_last_recharge: '53 Km',
    },
  ],
};

export const vehicleBatteryHistory = [
  {
    start_date: 'Thu Dec 22 2022 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN01',
    battery_id: '213441',
  },
  {
    start_date: 'Fri Jan 01 2021 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Tue Jan 18 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN01',
    battery_id: '211876',
  },
  {
    start_date: 'Fri Jan 01 2020 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Sun Apr 10 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN01',
    battery_id: '256782',
  },
  {
    start_date: 'Thu Dec 22 2022 00:00:00 GMT+0530 (India Standard Time)',
    end_data: 'Sun Jan 15 2023 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN02',
    battery_id: '256782',
  },
  {
    start_date: 'Thu Nov 24 2022 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Fri Dec 30 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN03',
    battery_id: '213441',
  },
  {
    start_date: 'Sat Nov 12 2021 00:00:00 GMT+0530 (India Standard Time)',
    end_data: 'Mon Dec 12 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN03',
    battery_id: '567234',
  },
  {
    start_date: 'Tue Oct 12 2021 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Thu Nov 10 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN05',
    battery_id: '987234',
  },
  {
    start_date: 'Sat Jan 04 2020 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Wed Oct 05 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN05',
    battery_id: '987234',
  },
  {
    start_date: 'Tue Oct 12 2021 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Thu Nov 10 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN02',
    battery_id: '987234',
  },
  {
    start_date: 'Sun Sep 12 2022 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Wed Dec 28 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN06',
    battery_id: '678435',
  },
  {
    start_date: 'Sun Sep 12 2021 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Wed Dec 28 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN06',
    battery_id: '213441',
  },
  {
    start_date: 'Sat Jan 04 2020 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Wed Oct 05 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN04',
    battery_id: '987234',
  },
  {
    start_date: 'Sun Sep 12 2021 00:00:00 GMT+0530 (India Standard Time)',
    end_date: 'Wed Dec 28 2022 00:00:00 GMT+0530 (India Standard Time)',
    vehicle_id: 'VN07',
    battery_id: '312445',
  },
];

export const DeliveryVehicleDatatable = {
  meta: {
    vehicle_id: {
      showDefault: false,
    },
    performance: {
      showDefault: false,
    },
    number_of_rides: {
      showDefault: false,
    },
    battery_level: {
      showDefault: false,
      suffix: '%',
    },
    battery_id: {
      showDefault: false,
    },
    battery_charging_state: {
      showDefault: false,
    },
    battery_firmware_version: {
      showDefault: false,
    },
    battery_cell_voltage: {
      showDefault: false,
    },
    type: {
      showDefault: false,
    },
  },
};

export const VehicleDatatable = {
  meta: {
    capacity_total: {
      showDefault: false,
    },
  },
  data: [
    {
      id: '1',
      vehicle_id: 'VN01',
      number_of_rides: '1',
      performance: '0.98',
      battery_level: 90,
      current_location: 'City Bus Moby',
      current_status: 'Out of service',
      imei: '1234567891011214',
      battery_id: '211876',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '300 Km',
      distance_since_last_inspection: '100 Km',
      vehicle_active_time: 78,
      vehicle_down_time: 2,
      average_battery_distance: '50 km',
      number_of_different_riders: '10',
      type: 'bike',
    },
    {
      id: '2',
      vehicle_id: 'VN02',
      number_of_rides: '2',
      performance: '0.73',
      battery_level: 21,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '1234567891011214',
      battery_id: '256782',
      battery_charging_state: 'charging',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '400 Km',
      distance_since_last_inspection: '250 Km',
      vehicle_active_time: 96,
      vehicle_down_time: 42,
      average_battery_distance: '98 Km',
      number_of_different_riders: '8',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '3',
      vehicle_id: 'VN03',
      number_of_rides: '1',
      performance: '0.68',
      battery_level: 22,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '1234567891011123',
      battery_id: '312445',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      vehicle_active_time: 24,
      distance_travelled: '280 Km',
      distance_since_last_inspection: '165 Km',
      vehicle_down_time: 66,
      average_battery_distance: '136 Km',
      number_of_different_riders: '12',
      type: 'bike',
    },
    {
      id: '4',
      vehicle_id: 'VN04',
      number_of_rides: '2',
      performance: '0.63',
      battery_level: 56,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '1123278910112543',
      battery_id: '213441',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '530 Km',
      distance_since_last_inspection: '184 Km',
      vehicle_active_time: 78,
      vehicle_down_time: 23,
      average_battery_distance: '78 Km',
      number_of_different_riders: '8',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '5',
      vehicle_id: 'VN05',
      number_of_rides: '1',
      performance: '0.58',
      battery_level: 89,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '9042876556711214',
      battery_id: '678435',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '640 Km',
      distance_since_last_inspection: '180 Km',
      vehicle_active_time: 132,
      vehicle_down_time: 6,
      average_battery_distance: '69 Km',
      number_of_different_riders: '7',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '6',
      vehicle_id: 'VN06',
      number_of_rides: '2',
      performance: '0.3',
      battery_level: 29,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '1234567891011212',
      battery_id: '987234',
      battery_charging_state: 'discharged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '780 Km ',
      distance_since_last_inspection: '230 Km',
      vehicle_active_time: 90,
      vehicle_down_time: 26,
      average_battery_distance: '52 Km',
      number_of_different_riders: '3',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '7',
      vehicle_id: 'VN07',
      number_of_rides: '1',
      performance: '0.4',
      battery_level: 30,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '7648567891011214',
      battery_id: '567234',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '265 Km',
      distance_since_last_inspection: '275 Km',
      vehicle_active_time: 25,
      vehicle_down_time: 44,
      average_battery_distance: '44 Km',
      number_of_different_riders: '5',
      type: 'bike',
    },
    {
      id: '8',
      vehicle_id: 'VN08',
      number_of_rides: '1',
      performance: '0.58',
      battery_level: 12,
      current_location: 'City Bus Stand',
      current_status: 'Available for Service',
      imei: '9042876556711214',
      battery_id: '892739',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.15',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '630 Km',
      distance_since_last_inspection: '423 Lm',
      vehicle_active_time: 78,
      vehicle_down_time: 53,
      average_battery_distance: '12 km',
      number_of_different_riders: '10',
      type: 'bike',
    },
    {
      id: '9',
      vehicle_id: 'VN09',
      number_of_rides: '2',
      performance: '0.3',
      battery_level: 33,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '1234567891011212',
      battery_id: '222442',
      battery_charging_state: 'discharged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '700 Km',
      distance_since_last_inspection: '100 Km',
      vehicle_active_time: 44,
      vehicle_down_time: 82,
      average_battery_distance: '72 Km',
      number_of_different_riders: '8',
      type: 'van',
      capacity_total: '150 KG',
    },
    {
      id: '10',
      vehicle_id: 'VN10',
      number_of_rides: '1',
      performance: '0.4',
      battery_level: 76,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '7648567891011214',
      battery_id: '527223',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.1',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '468 Km',
      distance_since_last_inspection: '89 Km ',
      vehicle_active_time: 62,
      vehicle_down_time: 12,
      average_battery_distance: '86 km',
      number_of_different_riders: '5',
      type: 'bike',
    },
    {
      id: '11',
      vehicle_id: 'VN11',
      number_of_rides: '1',
      performance: '0.4',
      battery_level: 56,
      current_location: 'City Bus Stand',
      current_status: 'Vehicle with Warning',
      imei: '7648567891011214',
      battery_id: '567234',
      battery_charging_state: 'charged',
      battery_firmware_version: 'v1.0',
      controller_id: '2343',
      battery_cell_voltage: '100V',
      controller_firmware_version: 'v1.1.0',
      distance_travelled: '520 Km',
      distance_since_last_inspection: '72 Km',
      vehicle_active_time: 78,
      vehicle_down_time: 92,
      average_battery_distance: '72 Km',
      number_of_different_riders: '6',
      type: 'bike',
    },
  ],
};

export const PendingEventsData = {
  data: [
    {
      trid: '#98765490',
      icon: pic3,
      product: 'Grains and Bread',
      customer: 'Manny Jah',
      date: '27 Jan 2021',
      amount: '$5,89,6437',
      payment: 'Cash on Delivery',
      status: 'Pending',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic4,
      product: 'White Pasta',
      customer: 'Florinda Carasco',
      date: '19 Sep 2021',
      amount: '$17.98',
      payment: 'Online Payment',
      status: 'Shipped',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic5,
      product: 'Rice',
      customer: 'Ivan Notheridiya',
      date: '06 Oct 2021',
      amount: '$8.654.4',
      payment: 'Cash on Delivery',
      status: 'Cancelled',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic6,
      product: 'Butter, yogurt',
      customer: 'Willie Findit',
      date: '10 Jul 2021',
      amount: '$8.654.4',
      payment: 'Cash on Delivery',
      status: 'Pending',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic7,
      product: 'Red Sause Pasta',
      customer: 'Addie Minstra',
      date: '25 Jun 2021',
      amount: '$7,34,9768',
      payment: 'Online Payment',
      status: 'Shipped',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
    {
      trid: '#98765490',
      icon: pic8,
      product: 'Combo coldrink and pizza',
      customer: 'Laura Biding',
      date: '22 Feb 2021',
      amount: '$7.76.654',
      payment: 'Cash on Delivery',
      status: 'Pending',
      Action: '0.36%',
      classname: 'warning-transparent rounded-pill text-warning p-2 px-3',
    },
  ],
  columns: [
    {
      name: 'TRACKING ID',
      selector: (row) => [row.trid],
      cell: (row) => <span className="align-middle"> {row.trid} </span>,
    },
    {
      name: 'PRODUCT',
      selector: (row) => [row.icon],
      cell: (row) => (
        <div className="align-middle">
          <span>{row.product}</span>
        </div>
      ),
    },
    {
      name: '	CUSTOMER',
      selector: (row) => [row.customer],
      cell: (row) => (
        <span className="text-nowrap align-middle">{row.customer}</span>
      ),
    },
    {
      name: 'DATE',
      selector: (row) => [row.date],
      cell: (row) => (
        <span className="text-nowrap align-middle">{row.date}</span>
      ),
    },
    {
      name: 'AMOUNT',
      cell: (row) => (
        <span className="text-center align-middle">{row.amount}</span>
      ),
    },
    {
      name: 'PAYMENT MODE',
      cell: (row) => (
        <span className="text-center align-middle">{row.payment}</span>
      ),
    },
    {
      name: 'STATUS',
      cell: (row) => (
        <Badge bg={row.classname} className="">
          Pending
        </Badge>
      ),
    },
    {
      name: 'ACTION',
      sortable: false,
      cell: () => (
        <div>
          <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <span className="fe fe-edit fs-18 text-primary me-4"></span>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <span className="fe fe-trash-2 text-danger fs-18 me-4"></span>
          </OverlayTrigger>
        </div>
      ),
    },
  ],
};

export const SolarPanelDatatable = {
  meta: [],
  data: [
    {
      panel_id: 2345677,
      current_location: 'Stellingen',
      controller_id: 65786543,
      latitude: 53.58888,
      longitude: 9.926479,
      controller_firmware_version: 'v1.20',
      power_rating: 2,
      power_generated: '20KV',
      service_status: 'online',
      imei: 789098765678,
    },
    {
      panel_id: 8765456,
      current_location: 'Waltershof',
      controller_id: 34567543,
      latitude: 53.523153,
      longitude: 9.912688,
      controller_firmware_version: 'v1.22',
      power_rating: 3,
      power_generated: '40W',
      service_status: 'offline',
      imei: 678900987656,
    },
    {
      panel_id: 3456783,
      current_location: 'Altenwerder',
      controller_id: 90876789,
      latitude: 53.514988,
      longitude: 9.927794,
      controller_firmware_version: 'v1.20',
      power_rating: 3,
      power_generated: '12W',
      service_status: 'online',
      imei: 332568765678,
    },
    {
      panel_id: 8761234,
      current_location: 'Rothenburgsort',
      controller_id: 65789098,
      latitude: 53.536214,
      longitude: 10.041778,
      controller_firmware_version: 'v1.22',
      power_rating: 4,
      power_generated: '9W',
      service_status: 'online',
      imei: 659890987654,
    },
    {
      panel_id: 9865345,
      current_location: 'Eimsbüttel',
      controller_id: 54678909,
      latitude: 53.587601,
      longitude: 9.968993,
      controller_firmware_version: 'v1.22',
      power_rating: 2,
      power_generated: '25W',
      service_status: 'online',
      imei: 435678909876,
    },
    {
      panel_id: 2376523,
      current_location: 'Bahrenfeld',
      controller_id: 23345665,
      latitude: 53.57211,
      longitude: 9.915435,
      controller_firmware_version: 'v1.20',
      power_rating: 1,
      power_generated: '10W',
      service_status: 'online',
      imei: 234567876543,
    },
    {
      panel_id: 2987667,
      current_location: 'Altona',
      controller_id: 12234564,
      latitude: 53.560692,
      longitude: 9.85913,
      controller_firmware_version: 'v1.20',
      power_rating: 2,
      power_generated: '9W',
      service_status: 'offline',
      imei: 345678213456,
    },
    {
      panel_id: 2678456,
      current_location: 'Poppenbüttel',
      controller_id: 96578907,
      latitude: 53.661717,
      longitude: 10.089843,
      controller_firmware_version: 'v1.22',
      power_rating: 4,
      power_generated: '12W',
      service_status: 'offline',
      imei: 768905432151,
    },
    {
      panel_id: 6565789,
      current_location: 'Harburg',
      controller_id: 54678907,
      latitude: 53.433252,
      longitude: 9.999206,
      controller_firmware_version: 'v1.20',
      power_rating: 4,
      power_generated: '30W',
      service_status: 'online',
      imei: 123234509890,
    },
    {
      panel_id: 4321123,
      current_location: 'Bergedorf',
      controller_id: 23456543,
      latitude: 53.485581,
      longitude: 10.093963,
      controller_firmware_version: 'v1.22',
      power_rating: 3,
      power_generated: '10W',
      service_status: 'online',
      imei: 234567654399,
    },
    {
      panel_id: 2343234,
      current_location: 'Wohldorf-Ohlstedt',
      controller_id: 13456753,
      latitude: 53.690189,
      longitude: 10.122802,
      controller_firmware_version: 'v1.20',
      power_rating: 2,
      power_generated: '25W',
      service_status: 'online',
      imei: 987654389076,
    },
    {
      panel_id: 5889098,
      current_location: 'Rahlstedt',
      controller_id: 89076567,
      latitude: 53.595752,
      longitude: 10.142028,
      controller_firmware_version: 'v1.20',
      power_rating: 2,
      power_generated: '9W',
      service_status: 'online',
      imei: 879087654356,
    },
    {
      panel_id: 8790986,
      current_location: 'Heimfeld',
      controller_id: 87654567,
      latitude: 53.461878,
      longitude: 9.962127,
      controller_firmware_version: 'v1.20',
      power_rating: 4,
      power_generated: '9W',
      service_status: 'online',
      imei: 879065789067,
    },
    {
      panel_id: 6578943,
      current_location: 'Kirchwerder',
      controller_id: 98765456,
      latitude: 53.426706,
      longitude: 10.207946,
      controller_firmware_version: 'v1.20',
      power_rating: 3,
      power_generated: '12W',
      service_status: 'online',
      imei: 356789065745,
    },
    {
      panel_id: 5432567,
      current_location: 'Duvenstedt',
      controller_id: 34567854,
      latitude: 53.708075,
      longitude: 10.113189,
      controller_firmware_version: 'v1.20',
      power_rating: 2,
      power_generated: '9W',
      service_status: 'online',
      imei: 234567898765,
    },
  ],
};

export const DustbinDatatable = {
  meta: [],
  data: [
    {
      dustbin_id: 123653,
      current_location: 'Stellingen',
      controller_id: 76123334,
      latitude: 53.58888,
      longitude: 9.926479,
      controller_firmware_version: 'v1.20',
      capacity_total: '20L',
      fill_rate: 3,
      service_status: 'online',
      status: 'filled',
      imei: 123456789010,
      sensor_id: 23456788,
    },
    {
      dustbin_id: 124568,
      current_location: 'Waltershof',
      controller_id: 89706545,
      latitude: 53.523153,
      longitude: 9.912688,
      controller_firmware_version: 'v1.20',
      capacity_total: '15L',
      fill_rate: 2,
      service_status: 'offline',
      status: 'cleaned',
      imei: 234568912345,
      sensor_id: 34567123,
    },
    {
      dustbin_id: 128976,
      current_location: 'Altenwerder',
      controller_id: 56789043,
      latitude: 53.514988,
      longitude: 9.927794,
      controller_firmware_version: 'v1.20',
      capacity_total: '18L',
      fill_rate: 4,
      service_status: 'online',
      status: 'filled',
      imei: 987656789012,
      sensor_id: 45637356,
    },
    {
      dustbin_id: 124567,
      current_location: 'Rothenburgsort',
      controller_id: 65432189,
      latitude: 53.536214,
      longitude: 10.041778,
      controller_firmware_version: 'v1.22',
      capacity_total: '20L',
      fill_rate: 3,
      service_status: 'online',
      status: 'filled',
      imei: 876543219087,
      sensor_id: 67854329,
    },
    {
      dustbin_id: 120987,
      current_location: 'Eimsbüttel',
      controller_id: 13456783,
      latitude: 53.587601,
      longitude: 9.968993,
      controller_firmware_version: 'v1.22',
      capacity_total: '15L',
      fill_rate: 3,
      service_status: 'online',
      status: 'filled',
      imei: 654778900987,
      sensor_id: 96554327,
    },
    {
      dustbin_id: 128890,
      current_location: 'Bahrenfeld',
      controller_id: 98723162,
      latitude: 53.57211,
      longitude: 9.915435,
      controller_firmware_version: 'v1.20',
      capacity_total: '15L',
      fill_rate: 2,
      service_status: 'online',
      status: 'filled',
      imei: 654321887654,
      sensor_id: 67888908,
    },
    {
      dustbin_id: 134567,
      current_location: 'Altona',
      controller_id: 23675489,
      latitude: 53.560692,
      longitude: 9.85913,
      controller_firmware_version: 'v1.20',
      capacity_total: '20L',
      fill_rate: 2,
      service_status: 'online',
      status: 'filled',
      imei: 211130987890,
      sensor_id: 65547489,
    },
    {
      dustbin_id: 138743,
      current_location: 'Poppenbüttel',
      controller_id: 90823416,
      latitude: 53.661717,
      longitude: 10.089843,
      controller_firmware_version: 'v1.20',
      capacity_total: '15L',
      fill_rate: 3,
      service_status: 'offline',
      status: 'cleaned',
      imei: 885554321780,
      sensor_id: 75678903,
    },
    {
      dustbin_id: 147890,
      current_location: 'Harburg',
      controller_id: 65431990,
      latitude: 53.433252,
      longitude: 9.999206,
      controller_firmware_version: 'v1.20',
      capacity_total: '15L',
      fill_rate: 4,
      service_status: 'offline',
      status: 'filled',
      imei: 999098766789,
      sensor_id: 89076544,
    },
    {
      dustbin_id: 146578,
      current_location: 'Bergedorf',
      controller_id: 885432279,
      latitude: 53.485581,
      longitude: 10.093963,
      controller_firmware_version: 'v1.22',
      capacity_total: '20L',
      fill_rate: 4,
      service_status: 'online',
      status: 'cleaned',
      imei: 776589076543,
      sensor_id: 88765432,
    },
    {
      dustbin_id: 143219,
      current_location: 'Wohldorf-Ohlstedt',
      controller_id: 67890990,
      latitude: 53.690189,
      longitude: 10.122802,
      controller_firmware_version: 'v1.20',
      capacity_total: '18L',
      fill_rate: 4,
      service_status: 'online',
      status: 'filled',
      imei: 987654567890,
      sensor_id: 12345543,
    },
    {
      dustbin_id: 150987,
      current_location: 'Rahlstedt',
      controller_id: 99987654,
      latitude: 53.595752,
      longitude: 10.142028,
      controller_firmware_version: 'v1.20',
      capacity_total: '18L',
      fill_rate: 1,
      service_status: 'online',
      status: 'cleaned',
      imei: 909998711123,
      sensor_id: 44889977,
    },
    {
      dustbin_id: 156789,
      current_location: 'Heimfeld',
      controller_id: 45456787,
      latitude: 53.461878,
      longitude: 9.962127,
      controller_firmware_version: 'v1.20',
      capacity_total: '20L',
      fill_rate: 3,
      service_status: 'online',
      status: 'filled',
      imei: 654322234567,
      sensor_id: 87656789,
    },
    {
      dustbin_id: 154432,
      current_location: 'Kirchwerder',
      controller_id: 23345578,
      latitude: 53.426706,
      longitude: 10.207946,
      controller_firmware_version: 'v1.20',
      capacity_total: '20L',
      fill_rate: 2,
      service_status: 'online',
      status: 'filled',
      imei: 989087765432,
      sensor_id: 67678909,
    },
    {
      dustbin_id: 167890,
      current_location: 'Duvenstedt',
      controller_id: 54678990,
      latitude: 53.708075,
      longitude: 10.113189,
      controller_firmware_version: 'v1.20',
      capacity_total: '20L',
      fill_rate: 3,
      service_status: 'online',
      status: 'cleaned',
      imei: 667890984321,
      sensor_id: 12309567,
    },
  ],
};

export const CompletedDeliveriesData = {
  COLUMNS: [
    {
      Header: 'RIDER',
      accessor: 'rider',
      className: 'text-center wd-15p border-bottom-0',
    },
    {
      Header: 'VEHICLE NUMBER',
      accessor: 'vehicle_id',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'FROM',
      accessor: 'from',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'TO',
      accessor: 'to',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'RIDE PERFORMANCE',
      accessor: 'rider_performance',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'VEHICLE PERFORMANCE',
      accessor: 'vehicle_performance',
      className: 'text-center wd-15p border-bottom-0 ',
    },
  ],
  DATATABLE: [
    {
      rider: 'Ty Ayelloribbin',
      vehicle: '5',
      from: 'Tower of City',
      to: 'Cathedral',
      rider_performance: '15 Minutes',
      vehicle_performance: '96%',
    },
    {
      rider: 'Hugo First',
      vehicle: '5',
      from: 'City Museum',
      to: 'Med Rouge',
      rider_performance: '20 Minutes',
      vehicle_performance: '95%',
    },
    {
      rider: 'Percy Vere',
      vehicle: '6',
      from: 'Cathedral',
      to: 'City Museum',
      rider_performance: '90 Minutes',

      vehicle_performance: '94%',
    },
    {
      rider: 'Jack Aranda',
      vehicle: '5',
      from: 'Tower of City',
      to: 'Cathedral',
      rider_performance: '15 Minutes',
      vehicle_performance: '96%',
    },
    {
      rider: 'Olive Tree',
      vehicle: '5',
      from: 'City Museum',
      to: 'Med Rouge',
      rider_performance: '20 Minutes',
      vehicle_performance: '95%',
    },
    {
      rider: 'Fran G. Pani',
      vehicle: '6',
      from: 'Cathedral',
      to: 'City Museum',
      rider_performance: '90 Minutes',
      vehicle_performance: '94%',
    },
    {
      rider: 'John Quil',
      vehicle: '5',
      from: 'Tower of City',
      to: 'Cathedral',
      rider_performance: '15 Minutes',
      vehicle_performance: '96%',
    },
    {
      rider: 'Percy Vere',
      vehicle: '5',
      from: 'City Museum',
      to: 'Med Rouge',
      rider_performance: '20 Minutes',
      vehicle_performance: '95%',
    },
    {
      rider: 'Olive Tree',
      vehicle: '6',
      from: 'Cathedral',
      to: 'City Museum',
      rider_performance: '90 Minutes',
      vehicle_performance: '94%',
    },
    {
      rider: 'Percy Vere',
      vehicle: '6',
      from: 'Cathedral',
      to: 'City Museum',
      rider_performance: '90 Minutes',
      vehicle_performance: '94%',
    },
  ],
};

export const ActiveData = {
  COLUMNS: [
    {
      Header: 'RIDER',
      accessor: 'rider',
      className: 'text-center wd-15p border-bottom-0',
    },
    {
      Header: 'FROM',
      accessor: 'from',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'TO',
      accessor: 'to',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: '	ESTIMATED TRIP DURATION',
      accessor: 'estimated_trip_duration',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'CURRENT TRIP DURATION',
      accessor: 'current_trip_duration',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'CO2 Emission',
      accessor: 'carbon_emission',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'VEHICLE PERFORMANCE',
      accessor: 'vehicle_performance',
      className: 'text-center wd-15p border-bottom-0 ',
    },
  ],
  DATATABLE: [
    {
      rider: 'Ty Ayelloribbin',
      from: 'Tower of City',
      to: 'Cathedral',
      estimated_trip_duration: '15 Minutes',
      current_trip_duration: '12 Minutes',
      carbon_emission: '3%',
      vehicle_performance: '96%',
    },
    {
      rider: 'John Quil',
      from: 'City Museum',
      to: 'Med Rouge',
      estimated_trip_duration: '20 Minutes',
      current_trip_duration: '20 Minutes',
      carbon_emission: '4%',
      vehicle_performance: '95%',
    },
    {
      rider: 'John Quil',
      from: 'Cathedral',
      to: 'City Museum',
      estimated_trip_duration: '90 Minutes',
      current_trip_duration: '70 Minutes',
      carbon_emission: '5%',
      vehicle_performance: '94%',
    },
  ],
};

export const SubscriptionData = {
  COLUMNS: [
    {
      Header: 'PLAN NAME',
      accessor: 'FNAME',
      className: 'text-center wd-15p border-bottom-0',
    },
    {
      Header: 'PRICE',
      accessor: 'LNAME',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'DURATION',
      accessor: 'POSITION',
      className: 'text-center wd-15p border-bottom-0 ',
    },
  ],
  DATATABLE: [
    {
      FNAME: 'Premium Plan',
      LNAME: '1200',
      POSITION: '12 Month',
    },
    {
      FNAME: 'Standard Plan',
      LNAME: '800',
      POSITION: '12 Month',
    },
    {
      FNAME: 'Regular Plan',
      LNAME: '400',
      POSITION: '12 Month',
    },
  ],
};

export const BillingData = {
  COLUMNS: [
    {
      Header: 'RIDE',
      accessor: 'FNAME',
      className: 'text-center wd-15p border-bottom-0',
    },
    {
      Header: 'COMMISSION',
      accessor: 'LNAME',
      className: 'text-center wd-15p border-bottom-0 ',
    },
  ],
  DATATABLE: [
    {
      FNAME: '1 KMS',
      LNAME: '$1.00',
    },
    {
      FNAME: '2 KMS',
      LNAME: '$2.00',
    },
    {
      FNAME: '3 KMS',
      LNAME: '$3.00',
    },
    {
      FNAME: '5 KMS',
      LNAME: '$4.00',
    },
    {
      FNAME: '5 KMS',
      LNAME: '$5.00',
    },
    {
      FNAME: '6 KMS',
      LNAME: '$6.00',
    },
  ],
};

export const BillingDataDatatable = {
  meta: {},
  data: [
    {
      ride: '1 KMS',
      commission: '$1.00',
    },
    {
      ride: '2 KMS',
      commission: '$2.00',
    },
    {
      ride: '3 KMS',
      commission: '$3.00',
    },
    {
      ride: '5 KMS',
      commission: '$4.00',
    },
    {
      ride: '5 KMS',
      commission: '$5.00',
    },
    {
      ride: '6 KMS',
      commission: '$6.00',
    },
  ],
};

export const CusotmersData = {
  COLUMNS: [
    {
      Header: 'CUSTOMER NAME',
      accessor: 'customer_name',
      className: 'text-center wd-15p border-bottom-0',
    },
    {
      Header: 'MOBILE NUMBER',
      accessor: 'mobile_number',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'ORDERS',
      accessor: 'orders',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: '	ORDER VALUE',
      accessor: 'order_value',
      className: 'text-center wd-15p border-bottom-0 ',
    },
    {
      Header: 'FOODIE RATING',
      accessor: 'foodie_rank',
      className: 'text-center wd-15p border-bottom-0 ',
    },
  ],
  DATATABLE: [
    {
      customer_name: 'Lukas',
      mobile_number: '8888888888',
      orders: '1',
      order_value: '$5.00',
      foodie_rank: '10',
    },
    {
      customer_name: 'Dymphna',
      mobile_number: '8888888888',
      orders: '2',
      order_value: '$10.00',
      foodie_rank: '9',
    },
    {
      customer_name: 'Devanie ',
      mobile_number: '8888888888',
      orders: '3',
      order_value: '$15.00',
      foodie_rank: '8',
    },

    {
      customer_name: 'Finn',
      mobile_number: '8888888888',
      orders: '4',
      order_value: '$5.00',
      foodie_rank: '7',
    },
    {
      customer_name: 'Tobias',
      mobile_number: '8888888888',
      orders: '5',
      order_value: '$10.00',
      foodie_rank: '6',
    },
    {
      customer_name: 'Jonas',
      mobile_number: '8888888888',
      orders: '6',
      order_value: '$15.00',
      foodie_rank: '5',
    },
    {
      customer_name: 'Ben',
      mobile_number: '8888888888',
      orders: '7',
      order_value: '$5.00',
      foodie_rank: '4',
    },
    {
      customer_name: 'Aislinn',
      mobile_number: '8888888888',
      orders: '8',
      order_value: '$10.00',
      foodie_rank: '3',
    },
    {
      customer_name: 'Aideen',
      mobile_number: '8888888888',
      orders: '9',
      order_value: '$15.00',
      foodie_rank: '2',
    },
    {
      customer_name: 'Bari',
      mobile_number: '8888888888',
      orders: '10',
      order_value: '$15.00',
      foodie_rank: '1',
    },
  ],
};

export const polygonData = [
  {
    map_data: [
      {
        lat: 53.58499371245946,
        lng: 9.898873412381434,
      },
      {
        lat: 53.59314518926144,
        lng: 9.982644164334559,
      },
      {
        lat: 53.567054928327686,
        lng: 9.971657836209559,
      },
      {
        lat: 53.55359584489592,
        lng: 9.938698851834559,
      },
    ],
    title: 'Altona-Nord Zone',
    vehicle_list: [6],
  },
  {
    map_data: [
      {
        lat: 53.552900783985656,
        lng: 9.99719098378501,
      },
      {
        lat: 53.5575916338039,
        lng: 10.017103703511573,
      },
      {
        lat: 53.549076336838944,
        lng: 10.022596867574073,
      },
      {
        lat: 53.54545554172716,
        lng: 10.00268414784751,
      },
    ],
    title: 'Hamburg Railway Zone',
    vehicle_list: [2, 1, 5],
  },
];

export const vehicleData = [
  {
    id: 0,
    vehicle_number: 'VN01',
    number_of_rides: 1,
    performance: '98%',
    battery_level: 97,
    current_location: 'City Bus Stand',
    current_status: 'Out of service',
  },
  {
    id: 1,
    vehicle_number: 'VN02',
    number_of_rides: 2,
    performance: '73%',
    battery_level: 72,
    current_location: 'Railway Station Park',
    current_status: 'Available for Service',
  },
  {
    id: 2,
    vehicle_number: 'VN03',
    number_of_rides: 1,
    performance: '68%',
    battery_level: 67,
    current_location: 'City Walk Road',
    current_status: 'Available for Service',
  },
  {
    id: 3,
    vehicle_number: 'VN04',
    number_of_rides: 2,
    performance: '63%',
    battery_level: 12,
    current_location: 'Airport Highway',
    current_status: 'Available for Service',
  },
  {
    id: 4,
    vehicle_number: 'VN05',
    number_of_rides: 1,
    performance: '58%',
    battery_level: 57,
    current_location: 'City Bus Stand',
    current_status: 'Available for Service',
  },
  {
    id: 5,
    vehicle_number: 'VN06',
    number_of_rides: 2,
    performance: '30%',
    battery_level: 29,
    current_location: 'Railway Station Park',
    current_status: 'Vehicle with Warning',
  },
  {
    id: 6,
    vehicle_number: 'VN07',
    number_of_rides: 1,
    performance: '40%',
    battery_level: 76,
    current_location: 'Railway Station Park',
    current_status: 'Vehicle with Warning',
  },
];

export const vehicleLocation = [
  {
    id: 0,
    vehicle_number: 'VN01',
    latitude: 53.56622316366142,
    longitude: 9.951664202286693,
  },
  {
    id: 1,
    vehicle_number: 'VN02',
    latitude: 53.55035944627742,
    longitude: 10.006320115706226,
  },
  {
    id: 2,
    vehicle_number: 'VN03',
    latitude: 53.5201194559735,
    longitude: 10.048256125329264,
  },
  {
    id: 3,
    vehicle_number: 'VN04',
    latitude: 53.5491,
    longitude: 9.9872,
  },
  {
    id: 4,
    vehicle_number: 'VN05',
    latitude: 53.5492,
    longitude: 9.9872,
  },
  {
    id: 5,
    vehicle_number: 'VN06',
    latitude: 53.5493,
    longitude: 9.9872,
  },
  {
    id: 6,
    vehicle_number: 'VN07',
    latitude: 53.5494,
    longitude: 9.9872,
  },
  // {
  //   id: 7,
  //   vehicle_number: 'VN01',
  //   latitude: 53.3498,
  //   longitude: 9.9872,
  // },
  // {
  //   id: 8,
  //   vehicle_number: 'VN02',
  //   latitude: 53.3499,
  //   longitude: 9.9872,
  // },
  // {
  //   id: 9,
  //   vehicle_number: 'VN03',
  //   latitude: 53.35,
  //   longitude: 9.9872,
  // },
  // {
  //   id: 10,
  //   vehicle_number: 'VN04',
  //   latitude: 53.3501,
  //   longitude: 9.9872,
  // },
  // {
  //   id: 11,
  //   vehicle_number: 'VN05',
  //   latitude: 53.3502,
  //   longitude: 9.9872,
  // },
  // {
  //   id: 12,
  //   vehicle_number: 'VN06',
  //   latitude: 53.3503,
  //   longitude: 9.9872,
  // },
  // {
  //   id: 13,
  //   vehicle_number: 'VN07',
  //   latitude: 53.3504,
  //   longitude: 9.9872,
  // },
];

export const riderDetails = [
  {
    id: 0,
    rider_name: 'Ty Ayelloribbin',
    number_of_rides: 1,
    'performance ': '98%',
    is_riding: true,
    auto_allocation: 0,
  },
  {
    id: 1,
    rider_name: 'Hugo First',
    number_of_rides: 1,
    'performance ': '93%',
    is_riding: false,
    auto_allocation: 1,
  },
  {
    id: 2,
    rider_name: 'Percy Vere',
    number_of_rides: 3,
    'performance ': '88%',
    is_riding: false,
    auto_allocation: 2,
  },
  {
    id: 3,
    rider_name: 'Jack Aranda',
    number_of_rides: 1,
    'performance ': '83%',
    is_riding: true,
    auto_allocation: 0,
  },
  {
    id: 4,
    rider_name: 'Olive Tree',
    number_of_rides: 2,
    'performance ': '78%',
    is_riding: false,
    auto_allocation: 3,
  },
  {
    id: 5,
    rider_name: 'Fran G. Pani',
    number_of_rides: 1,
    'performance ': '73%',
    is_riding: false,
    auto_allocation: 4,
  },
  {
    id: 6,
    rider_name: 'Fran G. Pani',
    number_of_rides: 1,
    'performance ': '73%',
    is_riding: true,
    auto_allocation: 0,
  },
];

export const activeOrdersMeta = {
  id: { showDefault: false },
  start_time: { showDefault: false },
  actual_trip_duration: { hide: true },
  current_trip_duration: { hide: true },
  dustbin_id: { hide: true },
  panel_id: { hide: true },
  status: { showDefault: false },
  start_lat: { showDefault: false },
  start_long: { showDefault: false },
  end_lat: { showDefault: false },
  end_long: { showDefault: false },
  start_date: { showDefault: false },
  end_date: { showDefault: false },
  vehicle_id: { showDefault: false },
  battery_id: { showDefault: false },
  rider_performance: { showDefault: false },
};

export const activeDeliveries = [
  {
    rider: 'Ty Ayelloribbin',
    id: 0,
    from: 'Tower of City',
    to: 'Cathedral',
    from_lat_lng: { lat: 53.571742, lng: 9.979933 },
    to_lat_lng: { lat: 53.587546, lng: 10.007161 },
    estimated_trip_duration: '15 Minutes',
    current_trip_duration: '12 Minutes',
    co2_emmision: '3%',
    vehicle_performance: '96%',
  },
  {
    id: 1,
    rider: 'John Quil',
    from: 'City Museum',
    to: 'Med Rouge',
    from_lat_lng: { lat: 53.544882, lng: 9.938363 },
    to_lat_lng: { lat: 53.587904, lng: 9.975466 },
    estimated_trip_duration: '20 Minutes',
    current_trip_duration: '20 Minutes',
    co2_emmision: '4%',
    vehicle_performance: '95%',
  },
  {
    id: 2,
    rider: 'John Quil',
    from: 'Cathedral',
    to: 'City Museum',
    from_lat_lng: { lat: 53.51917, lng: 9.919467 },
    to_lat_lng: { lat: 53.515904, lng: 10.043837 },
    estimated_trip_duration: '90 Minutes',
    current_trip_duration: '70 Minutes',
    co2_emmision: '5%',
    vehicle_performance: '94%',
  },
];

export const CustomersData = {
  meta: {
    name: {
      filters: { disableFilter: false, filterType: 'select' },
      hide: false,
    },
    active: {
      filters: { disableFilter: false, filterType: 'select' },
      hide: false,
    },
  },
  data: [
    {
      name: 'Jason Krejza',
      mobile_number: '9001524289',
      rides_: '6',
      active: 'No',
      Customer_ID: '20220312236',
      Email_Adress: 'jason20@gmail.com',
    },
    {
      name: 'Cindra Kamphoff',
      mobile_number: '9110131967',
      rides_: '13',
      active: 'Yes',
      Customer_ID: '20220420334',
      Email_Adress: 'kamcindra@gmail.com',
    },
    {
      name: 'Sydney Smith',
      mobile_number: '8990076543',
      rides_: '9',
      active: 'Yes',
      Customer_ID: '20220430786',
      Email_Adress: 'sydneycool@gmail.com',
    },
    {
      name: 'Jhon Miller',
      mobile_number: '9334460099',
      rides_: '3',
      active: 'No',
      Customer_ID: '20220512675',
      Email_Adress: 'jhon900@gmail.com',
    },
    {
      name: 'Jack Jones',
      mobile_number: '91111588867',
      rides_: '14',
      active: 'Yes',
      Customer_ID: '20220809876',
      Email_Adress: 'jack.jones@gmail.com',
    },
    {
      name: 'David Davis',
      mobile_number: '9966778980',
      rides_: '12',
      active: 'Yes',
      Customer_ID: '20220925908',
      Email_Adress: 'Davidd01@gmail.com',
    },
    {
      name: 'Miley Wiiliams',
      mobile_number: '90000598765',
      rides_: '2',
      active: 'No',
      Customer_ID: '20220931256',
      Email_Adress: 'Mileyhere@gmail.com',
    },
    {
      name: 'Camila Hill',
      mobile_number: '9456789900',
      rides_: '10',
      active: 'Yes',
      Customer_ID: '20221001567',
      Email_Adress: 'camila1995@gmail.com',
    },
    {
      name: 'Selena Perry',
      mobile_number: '9334562345',
      rides_: '11',
      active: 'Yes',
      Customer_ID: '20221119433',
      Email_Adress: 'selenaoct.20@gmail.com',
    },
    {
      name: 'Nick Long',
      mobile_number: '9667784567',
      rides_: '2',
      active: 'No',
      Customer_ID: '20221129770',
      Email_Adress: 'nick.long@gmail.com',
    },
    {
      name: 'Peeter Parker',
      mobile_number: '9552347766',
      rides_: '8',
      active: 'Yes',
      Customer_ID: '20230106543',
      Email_Adress: 'peeterpar@gmail.com',
    },
    {
      name: 'Raven Cox',
      mobile_number: '9445670987',
      rides_: '7',
      active: 'Yes',
      Customer_ID: '20230117932',
      Email_Adress: 'iraven@gmail.com',
    },
    {
      name: 'Rey Ross',
      mobile_number: '9889905566',
      rides_: '5',
      active: 'No',
      Customer_ID: '20230222560',
      Email_Adress: 'rey.60@gmail.com',
    },
    {
      name: 'Garry Diaz',
      mobile_number: '9778854321',
      rides_: '2',
      active: 'No',
      Customer_ID: '20230231156',
      Email_Adress: 'happygarry@gmail.com',
    },
  ],
};

export const pendingDeliveries = [
  {
    id: 0,
    allocated_rider: 'Hugo First',
    from: 'Tower of City',
    to: 'City Museum',
    from_lat_lng: { lat: 53.51594709567214, lng: 10.120413250316917 },
    to_lat_lng: { lat: 53.472238745635885, lng: 10.18152470051223 },
    estimated_trip_duration: '15 Minutes',
  },
  {
    id: 1,
    allocated_rider: 'Percy Vere',
    from: 'City Museum',
    to: 'Med Rouge',
    from_lat_lng: { lat: 53.52073628667017, lng: 9.924511191249875 },
    to_lat_lng: { lat: 53.47019519750065, lng: 9.954931682934104 },
    estimated_trip_duration: '90 Minutes',
  },
  {
    id: 2,
    allocated_rider: 'Olive Tree',
    from: 'Med Rouge',
    to: 'Cathedral',
    from_lat_lng: { lat: 53.43579774037629, lng: 9.926793300289498 },
    to_lat_lng: { lat: 53.414513683813624, lng: 9.978488713536539 },
    estimated_trip_duration: '90 Minutes',
  },
  {
    id: 3,
    allocated_rider: 'Fran G. Pani',
    from: 'Cathedral',
    to: 'Tower of City',
    from_lat_lng: { lat: 53.371245, lng: 9.9097 },
    to_lat_lng: { lat: 53.378209, lng: 9.961211 },
    estimated_trip_duration: '90 Minutes',
  },
];

export const activeDeliveriesData = [{}];

export let allSimulationData = [
  {
    id: '1',
    start_time: '0',
    estimated_trip_duration: '5',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '5',
    start_date: '20 Sept 2022',
    end_date: '20 Sept 2022',
    from: 'Eimsbüttel',
    start_lat: '53.571742',
    start_long: '9.97024',
    to: 'Dorotheenstraße',
    end_lat: '53.587546',
    end_long: '10.007161',
    vehicle_id: 'VN01',
    battery_id: '211876',
    name: 'Aldous Hudghton',
    carbon_emission: '1%',
    rider_performance: '95%',
    vehicle_performance: '95%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '2',
    start_time: '10',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '20',
    start_date: '24 Sept 2022',
    end_date: '24 Sept 2022',
    from: 'Wilhelmsburg',
    start_lat: '53.496603',
    start_long: '9.957948',
    to: 'Neuland',
    end_lat: '53.461504',
    end_long: '10.038801',
    vehicle_id: 'VN02',
    battery_id: '256782',
    name: 'Jack Aranda',
    carbon_emission: '2%',
    rider_performance: '94%',
    vehicle_performance: '94%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '3',
    start_time: '0',
    estimated_trip_duration: '15',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '15',
    start_date: '26 Sept 2022',
    end_date: '26 Sept 2022',
    from: 'Harburg',
    start_lat: '53.460823',
    start_long: '9.973724',
    to: 'Meckelfeld',
    end_lat: '53.418048',
    end_long: '10.019039',
    vehicle_id: 'VN03',
    battery_id: '312445',
    name: 'Olive Tree',
    carbon_emission: '5%',
    rider_performance: '96%',
    vehicle_performance: '96%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '4',
    start_time: '5',
    estimated_trip_duration: '5',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '10',
    start_date: '30 Sept 2022',
    end_date: '30 Sept 2022',
    from: 'Moorburg',
    start_lat: '53.491072',
    start_long: '9.943379',
    to: 'Hausebruch',
    end_lat: '53.48341',
    end_long: '9.901624',
    vehicle_id: 'VN04',
    battery_id: '213441',
    name: 'Fran G. Pani',
    carbon_emission: '3%',
    rider_performance: '95%',
    vehicle_performance: '95%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '5',
    start_time: '20',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '30',
    start_date: '13 Oct 2022',
    end_date: '13 Oct 2022',
    from: 'Rothenburgsort',
    start_lat: '53.52056',
    start_long: '10.060411',
    to: 'Billbrook',
    end_lat: '53.53072',
    end_long: '10.071598',
    vehicle_id: 'VN05',
    battery_id: '678435',
    name: 'John Quil',
    carbon_emission: '1%',
    rider_performance: '94%',
    vehicle_performance: '94%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '6',
    start_time: '30',
    estimated_trip_duration: '15',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '45',
    start_date: '18 Oct 2022',
    end_date: '19 Oct 2022',
    from: 'Waltershof',
    start_lat: '53.51705',
    start_long: '9.897585',
    to: 'Finkenwerder',
    end_lat: '53.523147',
    end_long: '9.877387',
    vehicle_id: 'VN06',
    battery_id: '987234',
    name: 'Percy Vere',
    carbon_emission: '2%',
    rider_performance: '96%',
    vehicle_performance: '96%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '7',
    start_time: '10',
    estimated_trip_duration: '12',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '22',
    start_date: '01 Nov 2022',
    end_date: '02 Nov 2022',
    from: 'Bergstedt',
    start_lat: '53.659465',
    start_long: '10.13478',
    to: 'Sasel',
    end_lat: '53.641906',
    end_long: '10.11485',
    vehicle_id: 'VN02',
    battery_id: '256782',
    name: 'Brady Blamire',
    carbon_emission: '5%',
    rider_performance: '95%',
    vehicle_performance: '95%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '8',
    start_time: '15',
    estimated_trip_duration: '6',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '21',
    start_date: '12 Nov 2022',
    end_date: '12 Nov 2022',
    from: 'Niendorf',
    start_lat: '53.61205',
    start_long: '9.945947',
    to: 'Loketedt',
    end_lat: '53.595317',
    end_long: '9.967221',
    vehicle_id: 'VN03',
    battery_id: '312445',
    name: 'Maud Caverhill',
    carbon_emission: '3%',
    rider_performance: '94%',
    vehicle_performance: '94%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '9',
    start_time: '25',
    estimated_trip_duration: '8',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '33',
    start_date: '18 Nov 2022',
    end_date: '18 Nov 2022',
    from: 'Blankenese',
    start_lat: '53.57334',
    start_long: '9.815298',
    to: 'Rissen',
    end_lat: '53.590685',
    end_long: '9.781989',
    vehicle_id: 'VN02',
    battery_id: '256782',
    name: 'Bank Wellen',
    carbon_emission: '1%',
    rider_performance: '96%',
    vehicle_performance: '96%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '10',
    start_time: '30',
    estimated_trip_duration: '4',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '34',
    start_date: '19 Nov 2022',
    end_date: '20 Nov 2022',
    from: 'Osdorf',
    start_lat: '53.584789',
    start_long: '9.848022',
    to: 'Nilenstedten',
    end_lat: '53.561193',
    end_long: '9.837504',
    vehicle_id: 'VN01',
    battery_id: '312445',
    name: 'Thorn Stapele',
    carbon_emission: '2%',
    rider_performance: '95%',
    vehicle_performance: '95%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '11',
    start_time: '40',
    estimated_trip_duration: '5',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '45',
    start_date: '21 Nov 2022',
    end_date: '22 Nov 2022',
    from: 'Harvestehude',
    start_lat: '53.579238',
    start_long: '9.983886',
    to: 'Rotherbaum',
    end_lat: '53.570737',
    end_long: '9.988561',
    vehicle_id: 'VN04',
    battery_id: '213441',
    name: 'Percy Vere',
    carbon_emission: '5%',
    rider_performance: '94%',
    vehicle_performance: '94%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '12',
    start_time: '50',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '60',
    start_date: '23 Nov 2022',
    end_date: '23 Nov 2022',
    from: 'Hausbruch',
    start_lat: '53.471586',
    start_long: '9.898929',
    to: 'Neugraben-Fischbek',
    end_lat: '53.457067',
    end_long: '9.83408',
    vehicle_id: 'VN05',
    battery_id: '678435',
    name: 'Olive Tree',
    carbon_emission: '3%',
    rider_performance: '96%',
    vehicle_performance: '96%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '13',
    start_time: '45',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '55',
    start_date: '25 Nov 2022',
    end_date: '26 Nov 2022',
    from: 'Billstedt',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Horn',
    end_lat: '53.545452',
    end_long: '10.075637',
    vehicle_id: 'VN06',
    battery_id: '987234',
    name: 'Ty Ayelloribbin',
    carbon_emission: '1%',
    rider_performance: '95%',
    vehicle_performance: '95%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '14',
    start_time: '40',
    estimated_trip_duration: '5',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '45',
    start_date: '27 Nov 2022',
    end_date: '28 Nov 2022',
    from: 'Billwerder',
    start_lat: '53.497752',
    start_long: '10.139271',
    to: 'Allermöhe',
    end_lat: '53.497752',
    end_long: '10.100177',
    vehicle_id: 'VN02',
    battery_id: '256782',
    name: 'John Quil',
    carbon_emission: '2%',
    rider_performance: '94%',
    vehicle_performance: '94%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '15',
    start_time: '20',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '30',
    start_date: '30 Nov 2022',
    end_date: '30 Nov 2022',
    from: 'Francop',
    start_lat: '53.516193',
    start_long: '9.878909',
    to: 'Finkenwerder',
    end_lat: '53.529993',
    end_long: '9.842729',
    vehicle_id: 'VN03',
    battery_id: '312445',
    name: 'John Quil',
    carbon_emission: '5%',
    rider_performance: '96%',
    vehicle_performance: '96%',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '16',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '01 Dec 2022',
    end_date: '01 Dec 2022',
    from: 'Eimsbüttel',
    start_lat: '53.58434',
    start_long: '9.949767',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567234',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '17',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '04 Dec 2022',
    end_date: '04 Dec 2022',
    from: 'Neuenfeld',
    start_lat: '53.52315',
    start_long: '9.793212',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567231',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '18',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '04 Dec 2022',
    end_date: '05 Dec 2022',
    from: 'Duvenstedt',
    start_lat: '53.699133',
    start_long: '10.088469',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567232',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '19',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '07 Dec 2022',
    end_date: '08 Dec 2022',
    from: 'Niendorf',
    start_lat: '53.636483',
    start_long: '9.95526',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567233',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '20',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '10 Dec 2022',
    end_date: '12 Dec 2022',
    from: 'Eidelstedt',
    start_lat: '53.605531',
    start_long: '9.885222',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567238',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '21',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '18 Dec 2022',
    end_date: '18 Dec 2022',
    from: 'Schnelsen',
    start_lat: '53.646253',
    start_long: '9.919555',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN08',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '22',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '25 Dec 2022',
    end_date: '25 Dev 2022',
    from: 'Duvenstedt',
    start_lat: '53.707262',
    start_long: '10.118682',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN07',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '23',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '30 Dec 2022',
    end_date: '31 Dec 2022',
    from: 'Altenwerder',
    start_lat: '53.505188',
    start_long: '9.893462',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN09',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '24',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '01 Jan 2023',
    end_date: '01 Jan 2023',
    from: 'Sasel',
    start_lat: '53.654393',
    start_long: '10.126922',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN10',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '25',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '12 Jan 2023',
    end_date: '13 Jan 2023',
    from: 'Billstedt - warehouse',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN11',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '',
    panel_id: '',
    station_id: '',
  },
  {
    id: '26',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Neuengamme',
    start_lat: '53.447976',
    start_long: '10.179107',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '123653',
    panel_id: '2345677',
    station_id: '',
  },
  {
    id: '27',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Rothenburgsort',
    start_lat: '53.5305',
    start_long: '10.052764',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '124568',
    panel_id: '8765456',
    station_id: '',
  },
  {
    id: '28',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Bahrenfeld',
    start_lat: '53.563954',
    start_long: '9.922301',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '128976',
    panel_id: '3456783',
    station_id: '',
  },
  {
    id: '29',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Altona',
    start_lat: '53.562323',
    start_long: '9.871489',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '124567',
    panel_id: '8761234',
    station_id: '',
  },
  {
    id: '30',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Bahrenfeld',
    start_lat: '53.563139',
    start_long: '9.916808',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '120987',
    panel_id: '9865345',
    station_id: '',
  },
  {
    id: '31',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Altona',
    start_lat: '53.557429',
    start_long: '9.886596',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '128890',
    panel_id: '2376523',
    station_id: '',
  },
  {
    id: '32',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Langenhorn',
    start_lat: '53.551086',
    start_long: '9.993682',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '134567',
    panel_id: '2987667',
    station_id: '',
  },
  {
    id: '33',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Lemsahl-Mellingstedt',
    start_lat: '53.6248',
    start_long: '9.988',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '138743',
    panel_id: '2678456',
    station_id: '',
  },
  {
    id: '34',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Rissen',
    start_lat: '53.606346',
    start_long: '9.778106',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '147890',
    panel_id: '6565789',
    station_id: '',
  },
  {
    id: '35',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Neugraben-Fischbek',
    start_lat: '53.463513',
    start_long: '9.841277',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '146578',
    panel_id: '4321123',
    station_id: '',
  },
  {
    id: '36',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Wellingsbüttel',
    start_lat: '53.637298',
    start_long: '10.077483',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '143219',
    panel_id: '2343234',
    station_id: '',
  },
  {
    id: '38',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Billbrook',
    start_lat: '53.520704',
    start_long: '10.089843',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '150987',
    panel_id: '5889098',
    station_id: '',
  },
  {
    id: '39',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Fuhlsbüttel',
    start_lat: '53.620196',
    start_long: '9.977233',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '156789',
    panel_id: '8790986',
    station_id: '',
  },
  {
    id: '40',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Kirchwerder',
    start_lat: '53.410338',
    start_long: '10.17636',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '154432',
    panel_id: '6578943',
    station_id: '',
  },
  {
    id: '41',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Billwerder',
    start_lat: '53.496203',
    start_long: '10.164',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '167890',
    panel_id: '5432567',
    station_id: '',
  },
  {
    id: '42',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Hamburg Central Station',
    start_lat: '53.55303574100883',
    start_long: '10.006628794585712',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '167890',
    panel_id: '5432567',
    station_id: '1',
  },
  {
    id: '43',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Hamburg Airport',
    start_lat: '53.63310032352028',
    start_long: '9.99400102854245',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '167890',
    panel_id: '5432567',
    station_id: '2',
  },
  {
    id: '44',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'IKEA Hamburg',
    start_lat: '53.51236096486612',
    start_long: '10.092749234979541',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '167890',
    panel_id: '5432567',
    station_id: '3',
  },
  {
    id: '45',
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '',
    end_date: '',
    from: 'Stadtpark Hamburg',
    start_lat: '53.59739246258671',
    start_long: '10.021997170307525',
    to: '',
    end_lat: '',
    end_long: '',
    vehicle_id: '',
    battery_id: '',
    name: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    dustbin_id: '167890',
    panel_id: '5432567',
    station_id: '4',
  },
];

export let allSimulationData1 = [
  {
    id: 1,
    rider: 'Aldous Hudghton',
    start_time: '0',
    estimated_trip_duration: '5',
    actual_trip_duration: '',
    status: 'active',
    from: 'Eimsbüttel',
    start_lat: '53.571742',
    start_long: '9.97024',
    to: 'Dorotheenstraße',
    current_trip_duration: '12 Min',
    end_lat: '53.587546',
    end_long: '10.007161',
    start_date: '20 Sept 2022 11:22:22',
    end_date: '20 Sept 2022 15:22:22',
    vehicle_id: 'VN01',
    battery_id: '211876',
    carbon_emission: '0.01',
    rider_performance: '0.95',
    vehicle_performance: '0.95',
    distance_covered: 12,
  },
  {
    id: 2,
    start_time: '10',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '16 Min',
    status: 'active',
    from: 'Wilhelmsburg',
    start_date: '24 Sept 2022 15:22:22',
    end_date: '24 Sept 2022 17:22:22',
    start_lat: '53.496603',
    start_long: '9.957948',
    to: 'Neuland',
    end_lat: '53.461504',
    end_long: '10.038801',
    vehicle_id: 'VN02',
    battery_id: '256782',
    rider: 'John Quil',
    carbon_emission: '0.02',
    rider_performance: '0.94',
    vehicle_performance: '0.94',
    distance_covered: 18,
  },
  {
    id: 3,
    rider: 'John Quil',
    start_time: '0',
    estimated_trip_duration: '15',
    actual_trip_duration: '',
    current_trip_duration: '18 Min',
    status: 'pending',
    from: 'Harburg',
    start_date: '26 Sept 2022 17:22:22',
    end_date: '26 Sept 2022 18:22:22',
    start_lat: '53.460823',
    start_long: '9.973724',
    to: 'Meckelfeld',
    end_lat: '53.418048',
    end_long: '10.019039',
    vehicle_id: 'VN03',
    battery_id: '312445',
    carbon_emission: '0.05',
    rider_performance: '0.96',
    vehicle_performance: '0.96',
    distance_covered: 23,
  },
  {
    id: 4,
    start_time: '5',
    estimated_trip_duration: '5',
    actual_trip_duration: '',
    current_trip_duration: '24 Min',
    status: 'active',
    from: 'Moorburg',
    start_lat: '53.491072',
    start_long: '9.943379',
    to: 'Hausebruch',
    start_date: '30 Sept 2022 15:22:22',
    end_date: '30 Sept 2022 17:22:22',
    end_lat: '53.48341',
    end_long: '9.901624',
    vehicle_id: 'VN04',
    battery_id: '213441',
    rider: 'Hugo First',
    carbon_emission: '0.03',
    rider_performance: '0.95',
    vehicle_performance: '0.95',
    distance_covered: 15,
  },
  {
    id: 5,
    start_time: '20',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '20 Min',
    status: 'completed',
    from: 'Rothenburgsort',
    start_lat: '53.52056',
    start_long: '10.060411',
    to: 'Billbrook',
    start_date: '13 OCt 2022 15:22:22',
    end_date: '13 Oct 2022 19:22:22',
    end_lat: '53.53072',
    end_long: '10.071598',
    vehicle_id: 'VN05',
    battery_id: '678435',
    rider: 'Percy Vere',
    carbon_emission: '0.01',
    rider_performance: '0.94',
    vehicle_performance: '0.94',
    distance_covered: 18,
  },
  {
    id: 6,
    start_time: '30',
    estimated_trip_duration: '15',
    actual_trip_duration: '',
    current_trip_duration: '24 Min',
    status: 'pending',
    start_date: '18 Oct 2022 19:22:22',
    end_date: '19 Oct 2022 20:22:22',
    from: 'Waltershof',
    start_lat: '53.51705',
    start_long: '9.897585',
    to: 'Finkenwerder',
    end_lat: '53.523147',
    end_long: '9.877387',
    vehicle_id: 'VN06',
    battery_id: '987234',
    rider: 'Olive Tree',
    carbon_emission: '0.02',
    rider_performance: '0.96',
    vehicle_performance: '0.96',
    distance_covered: 23,
  },
  {
    id: 7,
    start_time: '10',
    estimated_trip_duration: '12',
    actual_trip_duration: '',
    current_trip_duration: '8 Min',
    status: 'completed',
    from: 'Bergstedt',
    start_date: '01 Nov 2022 01:22:22',
    end_date: '02 Nov 2022 02:22:22',
    start_lat: '53.659465',
    start_long: '10.13478',
    to: 'Sasel',
    end_lat: '53.641906',
    end_long: '10.11485',
    vehicle_id: 'VN02',
    battery_id: '256782',
    rider: 'Ty Ayelloribbin',
    carbon_emission: '0.05',
    rider_performance: '0.95',
    vehicle_performance: '0.95',
    distance_covered: 8,
  },
  {
    id: 8,
    start_time: '15',
    estimated_trip_duration: '6',
    actual_trip_duration: '',
    current_trip_duration: '17 Min',
    status: 'active',
    from: 'Niendorf',
    start_lat: '53.61205',
    start_long: '9.945947',
    to: 'Loketedt',
    start_date: '12 Nov 2022 14:22:22',
    end_date: '12 Nov 2022 15:22:22',
    end_lat: '53.595317',
    end_long: '9.967221',
    vehicle_id: 'VN03',
    battery_id: '312445',
    rider: 'John Quil',
    carbon_emission: '0.03',
    rider_performance: '0.94',
    vehicle_performance: '0.94',
    distance_covered: 6,
  },
  {
    id: 9,
    start_time: '25',
    estimated_trip_duration: '8',
    actual_trip_duration: '',
    current_trip_duration: '15 Min',
    status: 'pending',
    from: 'Blankenese',
    start_lat: '53.57334',
    start_long: '9.815298',
    to: 'Rissen',
    start_date: '18 Nov 2022 15:22:22',
    end_date: '18 Nov 2022 16:22:22',
    end_lat: '53.590685',
    end_long: '9.781989',
    vehicle_id: 'VN02',
    battery_id: '256782',
    rider: 'John Quil',
    carbon_emission: '0.01',
    rider_performance: '0.96',
    vehicle_performance: '0.96',
    distance_covered: 7,
  },
  {
    id: 10,
    start_time: '30',
    estimated_trip_duration: '4',
    actual_trip_duration: '',
    current_trip_duration: '13 Min',
    status: 'completed',
    from: 'Osdorf',
    start_lat: '53.584789',
    start_long: '9.848022',
    to: 'Nilenstedten',
    start_date: '19 Nov 2022 15:22:22',
    end_date: '20 Nov 2022 17:22:22',
    end_lat: '53.561193',
    end_long: '9.837504',
    vehicle_id: 'VN01',
    battery_id: '312445',
    rider: 'Hugo First',
    carbon_emission: '0.02',
    rider_performance: '0.95',
    vehicle_performance: '0.95',
    distance_covered: 10,
  },
  {
    id: 11,
    start_time: '40',
    estimated_trip_duration: '5',
    actual_trip_duration: '',
    current_trip_duration: '45 Min',
    status: 'pending',
    from: 'Harvestehude',
    start_lat: '53.579238',
    start_long: '9.983886',
    start_date: '21 Nov 2022 17:22:22',
    end_date: '22 Nov 2022 18:22:22',
    to: 'Rotherbaum',
    end_lat: '53.570737',
    end_long: '9.988561',
    vehicle_id: 'VN04',
    battery_id: '213441',
    rider: 'Percy Vere',
    carbon_emission: '0.05',
    rider_performance: '0.94',
    vehicle_performance: '0.94',
    distance_covered: 12,
  },
  {
    id: 12,
    start_time: '50',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '16 Min',
    status: 'active',
    from: 'Hausbruch',
    start_lat: '53.471586',
    start_date: '23 Nov 2022 19:22:22',
    end_date: '23 Nov 2022 20:22:22',
    start_long: '9.898929',
    to: 'Neugraben-Fischbek',
    end_lat: '53.457067',
    end_long: '9.83408',
    vehicle_id: 'VN05',
    battery_id: '678435',
    rider: 'Olive Tree',
    carbon_emission: '0.03',
    rider_performance: '0.96',
    vehicle_performance: '0.96',
    distance_covered: 17,
  },
  {
    id: 13,
    start_time: '45',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '35 Min',
    status: 'pending',
    from: 'Billstedt',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Horn',
    start_date: '25 Nov 2022 10:22:22',
    end_date: '26 Nov 2022 12:22:22',
    end_lat: '53.545452',
    end_long: '10.075637',
    vehicle_id: 'VN06',
    battery_id: '987234',
    rider: 'Ty Ayelloribbin',
    carbon_emission: '0.01',
    rider_performance: '0.95',
    vehicle_performance: '0.95',
    distance_covered: 30,
  },
  {
    id: 14,
    start_time: '40',
    estimated_trip_duration: '5',
    actual_trip_duration: '',
    current_trip_duration: '18 Min',
    status: 'completed',
    from: 'Billwerder',
    start_lat: '53.497752',
    start_long: '10.139271',
    to: 'Allermöhe',
    start_date: '27 Nov 2022 14:22:22',
    end_date: '28 Nov 2022 16:21:22',
    end_lat: '53.497752',
    end_long: '10.100177',
    vehicle_id: 'VN02',
    battery_id: '256782',
    rider: 'John Quil',
    carbon_emission: '0.02',
    rider_performance: '0.94',
    vehicle_performance: '0.94',
    distance_covered: 12,
  },
  {
    id: 15,
    start_time: '20',
    estimated_trip_duration: '10',
    actual_trip_duration: '',
    current_trip_duration: '10 Min',
    status: 'pending',
    from: 'Francop',
    start_lat: '53.516193',
    start_date: '30 Nov 2022 15:22:22',
    end_date: '30 Nov 2022 19:22:22',
    start_long: '9.878909',
    to: 'Finkenwerder',
    end_lat: '53.529993',
    end_long: '9.842729',
    vehicle_id: 'VN03',
    battery_id: '312445',
    rider: 'John Quil',
    carbon_emission: '0.05',
    rider_performance: '0.96',
    vehicle_performance: '0.96',
    distance_covered: 23,
  },
  {
    id: 16,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '7 Min',
    status: '',
    start_date: '01 Dec 2022 20:22:22',
    end_date: '01 Dec 2022 22:22:22',
    from: 'Francop - charging station',
    start_lat: '53.516193',
    start_long: '9.878909',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567234',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 21,
  },
  {
    id: 17,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '10 Min',
    status: '',
    start_date: '04 Dec 2022 12:12:32',
    end_date: '04 Dec 2022 15:22:22',
    from: 'Francop - charging station',
    start_lat: '53.516193',
    start_long: '9.878909',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567231',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 14,
  },
  {
    id: 18,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '23 Min',
    status: '',
    start_date: '04 Dec 2022 16:12:32',
    end_date: '05 Dec 2022 20:12:32',
    from: 'Francop - charging station',
    start_lat: '53.516193',
    start_long: '9.878909',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567232',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 18,
  },
  {
    id: 19,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '17 Min',
    current_trip_duration: '',
    status: '',
    start_date: '07 Dec 2022 07:12:32',
    end_date: '08 Dec 2022 08:12:32',
    from: 'Francop - charging station',
    start_lat: '53.516193',
    start_long: '9.878909',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567233',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 2314,
  },
  {
    id: 20,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '9 Min',
    status: '',
    start_date: '10 Dec 2022 22:12:32',
    end_date: '12 Dec 2022 23:12:32',
    from: 'Francop - charging station',
    start_lat: '53.516193',
    start_long: '9.878909',
    to: 'Francop - charging station',
    end_lat: '53.516193',
    end_long: '9.878909',
    vehicle_id: '',
    battery_id: '567238',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 32,
  },
  {
    id: 21,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '22 Min',
    status: '',
    start_date: '18 Dec 2022 08:12:32',
    end_date: '18 Dec 2022 09:12:32',
    from: 'Billstedt - warehouse',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN08',
    battery_id: '',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 23,
  },
  {
    id: 22,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '34 Min',
    status: '',
    start_date: '25 Dec 2022 22:12:32',
    end_date: '25 Dec 2022 23:12:32',
    from: 'Billstedt - warehouse',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN07',
    battery_id: '',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 23,
  },
  {
    id: 23,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '23 Min',
    status: '',
    start_date: '30 Dec 2022 10:12:32',
    end_date: '31 Dec 2022 12:12:32',
    from: 'Billstedt - warehouse',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN09',
    battery_id: '',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 23,
  },
  {
    id: 24,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '22 Min',
    status: '',
    from: 'Billstedt - warehouse',
    start_date: '01 Jan 2023 12:12:32',
    end_date: '01 Jan 2023 15:12:32',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN10',
    battery_id: '',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 23,
  },
  {
    id: 25,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '44 Min',
    status: '',
    start_date: '12 Jan 2023 13:12:32',
    end_date: '13 Jan 2023 14:12:32',
    from: 'Billstedt - warehouse',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: 'VN11',
    battery_id: '',
    rider: '',
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 23,
  },
  {
    id: 26,
    start_time: '',
    estimated_trip_duration: '',
    actual_trip_duration: '',
    current_trip_duration: '',
    status: '',
    start_date: '12 Jan 2023 13:12:32',
    end_date: '13 Jan 2023 14:12:32',
    from: 'Billstedt - warehouse',
    start_lat: '53.538747',
    start_long: '10.115783',
    to: 'Billstedt - warehouse',
    end_lat: '53.538747',
    end_long: '10.115783',
    vehicle_id: '',
    battery_id: '',
    rider: '',
    panel_id: 2345677,
    carbon_emission: '',
    rider_performance: '',
    vehicle_performance: '',
    distance_covered: 23,
  },
];

export const serviceIdActions = [
  {
    service_id: '20220812234',
    vehicle_id: 'VN01',
    service_point: 'Service Reminder',
    status: 'Completed',
    description: 'vehicle pickup to service station',
    name: 'Aldous Hudghton',
    date: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220812234',
    vehicle_id: 'VN01',
    service_point: 'Inspection',
    status: 'In Progress',
    description: 'Breaks are not working',
    name: 'Jack Aranda',
    date: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220812234',
    vehicle_id: 'VN01',
    service_point: 'Inspection',
    status: 'In Progress',
    description: 'Acceleration is not working',
    name: 'Percy Vere',
    date: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220812234',
    vehicle_id: 'VN01',
    service_point: 'Issues',
    status: 'In Progress',
    description: 'Battery issue in the bike',
    name: 'Bank Wellen',
    date: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220814321',
    vehicle_id: 'VN02',
    service_point: 'Service Reminder',
    status: 'In Progress',
    description: 'For Regular check',
    name: 'Sandy Reide',
    date: 'Wed Mar 02 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220814321',
    vehicle_id: 'VN02',
    service_point: 'Inspection',
    status: 'In Progress',
    description: 'Battery overheating issue',
    name: 'Thorn Stapele',
    date: 'Wed Mar 02 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220814321',
    vehicle_id: 'VN02',
    service_point: 'Operator Report',
    status: 'In Progress',
    description: 'Battery overheating issue',
    name: 'Percy Vere',
    date: 'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220814321',
    vehicle_id: 'VN02',
    service_point: 'Issues',
    status: 'In Progress',
    description: 'Battery overheating issue',
    name: 'John Quil',
    date: 'Thu Sep 22 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220814321',
    vehicle_id: 'VN02',
    service_point: 'Work Order',
    status: 'Completed',
    description: 'Battery issue resolved',
    name: 'Fran G. Pani',
    date: 'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220814232',
    vehicle_id: 'VN04',
    service_point: 'Service Reminder',
    status: 'In Progress',
    description: '3 Months warranty check',
    name: 'Jack Aranda',
    date: 'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220814232',
    vehicle_id: 'VN04',
    service_point: 'Operator Report',
    status: 'In Progress',
    description: 'Battery issue in the bike',
    name: 'Olive Tree',
    date: '22 Sept 2022',
  },
  {
    service_id: '20220814232',
    vehicle_id: 'VN04',
    service_point: 'Issues',
    status: 'In Progress',
    description: 'Battery issue not resolved',
    name: 'Percy Vere',
    date: 'Sat Mar 26 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220814232',
    vehicle_id: 'VN04',
    service_point: 'Work Order',
    status: 'In Progress',
    description: 'Battery issue not resolved',
    name: 'Maud Caverhill',
    date: '22 Sept 2022',
  },
  {
    service_id: '20220112212',
    vehicle_id: 'VN06',
    service_point: 'Service Reminder',
    status: 'In Progress',
    description: 'Reminder for Annual Maintance',
    name: 'Aldous Hudghton',
    date: 'Wed Jan 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220112212',
    vehicle_id: 'VN06',
    service_point: 'Inspection',
    status: 'In Progress',
    description: 'Breaks are not working',
    name: 'John Quil',
    date: 'Sat Jun 18 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220112212',
    vehicle_id: 'VN06',
    service_point: 'Operator Report',
    status: 'In Progress',
    description: 'Breaks are not working',
    name: 'Thorn Stapele',
    date: 'Wed Jan 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220112212',
    vehicle_id: 'VN06',
    service_point: 'Issues',
    status: 'In Progress',
    description: 'Rear Break is not working',
    name: 'Bank Wellen',
    date: 'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220112212',
    vehicle_id: 'VN06',
    service_point: 'Work Order',
    status: 'Completed',
    description: 'Break issue Resolved',
    name: 'Percy Vere',
    date: 'Wed Jan 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220212133',
    vehicle_id: 'VN01',
    service_point: 'Service Reminder',
    status: 'Completed',
    description: 'Second Service Reminder',
    name: 'Fran G. Pani',
    date: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220212133',
    vehicle_id: 'VN01',
    service_point: 'Inspection',
    status: 'In Progress',
    description: 'Vehicle is not working',
    name: 'Bank Wellen',
    date: 'Thu Jan 12 2023 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220212133',
    vehicle_id: 'VN01',
    service_point: 'Operator Report',
    status: 'In Progress',
    description: 'Battery issue in the bike',
    name: 'Maud Caverhill',
    date: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220212133',
    vehicle_id: 'VN01',
    service_point: 'Issues',
    status: 'In Progress',
    description: 'Acceleration is not working',
    name: 'Thorn Stapele',
    date: 'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220212133',
    vehicle_id: 'VN01',
    service_point: 'Work Order',
    status: 'Approval Pending',
    description: 'Battery issue in the bike',
    name: 'Fran G. Pani',
    date: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220419765',
    vehicle_id: 'VN04',
    service_point: 'Service Reminder',
    status: 'In Progress',
    description: 'For Regular Inspection',
    name: 'Bank Wellen',
    date: 'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220419765',
    vehicle_id: 'VN02',
    service_point: 'Inspection',
    status: 'In Progress',
    description: 'Acceleration is not working',
    name: 'Aldous Hudghton',
    date: 'Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220419765',
    vehicle_id: 'VN01',
    service_point: 'Inspection',
    status: 'In Progress',
    description: 'Battery issue in the bike',
    name: 'Brady Blamire',
    date: 'Fri Sep 02 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220419765',
    vehicle_id: 'VN01',
    service_point: 'Operator Report',
    status: 'In Progress',
    description: 'Rear Break is not working',
    name: 'Percy Vere',
    date: 'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220419765',
    vehicle_id: 'VN01',
    service_point: 'Issues',
    status: 'Completed',
    description: 'Acceleration is not working',
    name: 'Bank Wellen',
    date: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220419765',
    vehicle_id: 'VN01',
    service_point: 'Work Order',
    status: 'Completed',
    description: 'Rear Break issue resolved',
    name: 'Maud Caverhill',
    date: 'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220529432',
    vehicle_id: 'VN05',
    service_point: 'Service Reminder',
    status: 'In Progress',
    description: 'Reminder First Service',
    name: 'Aldous Hudghton',
    date: 'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220529432',
    vehicle_id: 'VN05',
    service_point: 'Inspection',
    status: 'In Progress',
    description: 'Acceleration is not working',
    name: 'Fran G. Pani',
    date: 'Tue Oct 03 2023 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220529432',
    vehicle_id: 'VN01',
    service_point: 'Operator Report',
    status: 'In Progress',
    description: 'Vehicle is not Starting',
    name: 'Percy Vere',
    date: 'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
  {
    service_id: '20220529432',
    vehicle_id: 'VN01',
    service_point: 'Work Order',
    status: 'Completed',
    description: 'Vehicle is not working issue resolved',
    name: 'Maud Caverhill',
    date: 'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)',
  },
];

export const WebsiteMeta = {
  logo: {
    hide: true,
  },
  url: {
    hide: true,
  },
  permissions: {
    hide: true,
  },
};

export const Websites = [
  {
    name: 'Master',
    logo: '/assets/images/logo.png',
    map_icon: '/assets/images/logo.png',
    url: '',
    primary: '#ffffff',
    secondary: '#0B96CE',
    id: 'master',
    type: ACCESS_TYPES.MASTER,
    icon_type: 'motorcycle',
    permissions: [
      PERMISSIONS.VEHICLE,
      PERMISSIONS.DELIVERIES,
      PERMISSIONS.BATTERY,
      PERMISSIONS.DUSTBINS,
      PERMISSIONS.SOLAR_PANELS,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.RIDERS,
    ],
    instructions: [],
  },
  {
    name: 'Keego',
    logo: '/assets/images/keego_logo.png',
    map_icon: '/assets/images/media/van1.png',
    url: '',
    primary: '#ffffff',
    secondary: '#0B96CE',
    id: 'keego',
    type: ACCESS_TYPES.RIDESHARE,
    icon_type: 'motorcycle',
    permissions: [
      PERMISSIONS.VEHICLE,
      PERMISSIONS.BATTERY,
      PERMISSIONS.SERVICE_STAFF,

      PERMISSIONS.ADD_GEOFENCING,
      PERMISSIONS.LIST_GEOFENCING,
      PERMISSIONS.CRM_CUSTOMERS,
      PERMISSIONS.BILLING,
      PERMISSIONS.BILLING_MODEL,
      PERMISSIONS.STATISTICS,
      PERMISSIONS.SERVICE_INSPECTION,
      PERMISSIONS.SERVICE_ISSUES,
      PERMISSIONS.SERVICE_REMINDER,
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.SERVICE_OPERATOR_REPORT,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.PENDING_DELIVERIES,
      PERMISSIONS.COMPLETED_DELIVERIES,
      PERMISSIONS.ACTIVE_DELIVERIES,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.TILE_CONFIGURATION,
    ],
    instructions: [],
  },
  {
    name: 'Gramobility',
    logo: '/assets/images/gramobility.png',
    map_icon: '/assets/images/media/van1.png',
    url: '',
    id: 'gramobility',
    primary: 'black',
    secondary: '#D4D747',
    type: ACCESS_TYPES.RIDESHARE,
    icon_type: 'scooter',
    permissions: [
      PERMISSIONS.VEHICLE,
      PERMISSIONS.BATTERY,
      PERMISSIONS.SERVICE_STAFF,

      PERMISSIONS.ADD_GEOFENCING,
      PERMISSIONS.LIST_GEOFENCING,
      PERMISSIONS.CRM_CUSTOMERS,
      PERMISSIONS.BILLING,
      PERMISSIONS.BILLING_MODEL,
      PERMISSIONS.STATISTICS,
      PERMISSIONS.SERVICE_INSPECTION,
      PERMISSIONS.SERVICE_ISSUES,
      PERMISSIONS.SERVICE_REMINDER,
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.SERVICE_OPERATOR_REPORT,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.PENDING_DELIVERIES,
      PERMISSIONS.COMPLETED_DELIVERIES,
      PERMISSIONS.ACTIVE_DELIVERIES,
    ],
    instructions: [],
  },
  {
    name: 'Moby',
    logo: '/assets/images/moby.png',
    map_icon: '/assets/images/media/van1.png',
    url: '',
    id: 'moby',
    primary: '#ffffff',
    secondary: 'black',
    type: ACCESS_TYPES.RIDESHARE,
    icon_type: 'motorcycle',
    permissions: [
      PERMISSIONS.VEHICLE,
      PERMISSIONS.BATTERY,
      PERMISSIONS.SERVICE_STAFF,

      PERMISSIONS.ADD_GEOFENCING,
      PERMISSIONS.LIST_GEOFENCING,
      PERMISSIONS.CRM_CUSTOMERS,
      PERMISSIONS.BILLING,
      PERMISSIONS.BILLING_MODEL,
      PERMISSIONS.STATISTICS,
      PERMISSIONS.SERVICE_INSPECTION,
      PERMISSIONS.SERVICE_ISSUES,
      PERMISSIONS.SERVICE_REMINDER,
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.SERVICE_OPERATOR_REPORT,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.PENDING_DELIVERIES,
      PERMISSIONS.COMPLETED_DELIVERIES,
      PERMISSIONS.ACTIVE_DELIVERIES,
    ],
    instructions: [],
  },
  {
    name: 'Citkar',
    logo: '/assets/images/citkar.png',
    map_icon: '/assets/images/media/van1.png',
    url: '',
    primary: '#ffffff',
    secondary: 'black',
    id: 'citkar',
    type: ACCESS_TYPES.DELIVERY_SERVICE,
    icon_type: 'van',
    permissions: [
      PERMISSIONS.DELIVERIES,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.RIDERS,

      PERMISSIONS.ADD_GEOFENCING,
      PERMISSIONS.LIST_GEOFENCING,
      PERMISSIONS.CRM_CUSTOMERS,
      PERMISSIONS.BILLING,
      PERMISSIONS.BILLING_MODEL,
      PERMISSIONS.STATISTICS,
      PERMISSIONS.SERVICE_INSPECTION,
      PERMISSIONS.SERVICE_ISSUES,
      PERMISSIONS.SERVICE_REMINDER,
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.SERVICE_OPERATOR_REPORT,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.PENDING_DELIVERIES,
      PERMISSIONS.COMPLETED_DELIVERIES,
      PERMISSIONS.ACTIVE_DELIVERIES,
      PERMISSIONS.DELIVERY_VANS,
    ],
    instructions: [],
  },
  {
    name: 'Diageo',
    logo: '/assets/images/diageo.png',
    map_icon: '/assets/images/media/van1.png',
    url: '',
    primary: '#ffffff',
    secondary: '#A50034',
    id: 'diageo',
    type: ACCESS_TYPES.DELIVERY_SERVICE,
    icon_type: 'motorcycle',
    permissions: [
      PERMISSIONS.DELIVERIES,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.RIDERS,

      PERMISSIONS.ADD_GEOFENCING,
      PERMISSIONS.LIST_GEOFENCING,
      PERMISSIONS.CRM_CUSTOMERS,
      PERMISSIONS.BILLING,
      PERMISSIONS.BILLING_MODEL,
      PERMISSIONS.STATISTICS,
      PERMISSIONS.SERVICE_INSPECTION,
      PERMISSIONS.SERVICE_ISSUES,
      PERMISSIONS.SERVICE_REMINDER,
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.SERVICE_OPERATOR_REPORT,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.PENDING_DELIVERIES,
      PERMISSIONS.COMPLETED_DELIVERIES,
      PERMISSIONS.ACTIVE_DELIVERIES,
      PERMISSIONS.DELIVERY_VANS,
    ],
    instructions: [],
  },
  {
    name: 'ABC Solar',
    logo: '/assets/images/abcsolor.jpg',
    map_icon: '/assets/images/media/van1.png',
    url: '',
    id: 'abc_solar',
    type: ACCESS_TYPES.OPERATOR_SERVICE,
    primary: '#CE732B',
    secondary: '#E7CE56',
    icon_type: 'motorcycle',
    permissions: [
      PERMISSIONS.SOLAR_PANELS,
      PERMISSIONS.SERVICE_STAFF,

      PERMISSIONS.ADD_GEOFENCING,
      PERMISSIONS.LIST_GEOFENCING,
      PERMISSIONS.CRM_CUSTOMERS,
      PERMISSIONS.BILLING,
      PERMISSIONS.BILLING_MODEL,
      PERMISSIONS.STATISTICS,
      PERMISSIONS.SERVICE_INSPECTION,
      PERMISSIONS.SERVICE_ISSUES,
      PERMISSIONS.SERVICE_REMINDER,
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.SERVICE_OPERATOR_REPORT,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.PENDING_DELIVERIES,
      PERMISSIONS.COMPLETED_DELIVERIES,
      PERMISSIONS.ACTIVE_DELIVERIES,
    ],
    icon_type: 'solar',
    instructions: [],
  },
  {
    name: 'Superfy',
    logo: '/assets/images/superfy.png',
    map_icon: '/assets/images/media/van1.png',
    url: '',
    id: 'superfy',
    type: ACCESS_TYPES.OPERATOR_SERVICE,
    icon_type: 'bin',
    primary: 'black',
    secondary: '#00E0AE',
    permissions: [
      PERMISSIONS.DUSTBINS,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.RIDERS,

      PERMISSIONS.ADD_GEOFENCING,
      PERMISSIONS.LIST_GEOFENCING,
      PERMISSIONS.CRM_CUSTOMERS,
      PERMISSIONS.BILLING,
      PERMISSIONS.BILLING_MODEL,
      PERMISSIONS.STATISTICS,
      PERMISSIONS.SERVICE_INSPECTION,
      PERMISSIONS.SERVICE_ISSUES,
      PERMISSIONS.SERVICE_REMINDER,
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.SERVICE_OPERATOR_REPORT,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.PENDING_DELIVERIES,
      PERMISSIONS.COMPLETED_DELIVERIES,
      PERMISSIONS.ACTIVE_DELIVERIES,
    ],
    instructions: [],
  },
  {
    name: 'Bombay Softwares',
    logo: '/assets/images/bs.png',
    map_icon: '/assets/images/media/van1.png',
    url: '',
    id: 'bombay_softwares',
    type: ACCESS_TYPES.RIDESHARE,
    icon_type: 'motorcycle',
    primary: '#fecf39',
    secondary: '#ffffff',
    permissions: [
      PERMISSIONS.VEHICLE,
      PERMISSIONS.BATTERY,
      PERMISSIONS.SERVICE_STAFF,

      PERMISSIONS.ADD_GEOFENCING,
      PERMISSIONS.LIST_GEOFENCING,
      PERMISSIONS.CRM_CUSTOMERS,
      PERMISSIONS.BILLING,
      PERMISSIONS.BILLING_MODEL,
      PERMISSIONS.STATISTICS,
      PERMISSIONS.SERVICE_INSPECTION,
      PERMISSIONS.SERVICE_ISSUES,
      PERMISSIONS.SERVICE_REMINDER,
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.SERVICE_OPERATOR_REPORT,
      PERMISSIONS.SERVICE_STAFF,
      PERMISSIONS.PENDING_DELIVERIES,
      PERMISSIONS.COMPLETED_DELIVERIES,
      PERMISSIONS.ACTIVE_DELIVERIES,
    ],
    instructions: [],
  },
];

export const ServiceWithMetaData = {
  meta: {
    status: {
      type: 'text',
      showDefault: true,
    },
    vehicle_id: {
      showDefault: true,
      type: 'button',
      action: 'openModal()',
    },
  },
  data: [
    {
      service_id: 20220812234,
      status: 'Sent',
      vehicle_id: 'VN01',
      created_at: moment(
        'Fri Aug 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      creator_id: '21',
      frequency_id: '3',
      sent_to: 'Aldous Hudghton',
      service_due_date: moment(
        'Fri Aug 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: '10,000 Km Check',
    },
    {
      service_id: 20220814321,
      status: 'Pending',
      vehicle_id: 'VN02',
      created_at: moment(
        'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      creator_id: '13',
      frequency_id: '3',
      sent_to: 'Aldous Hudghton',
      service_due_date: moment(
        'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'For Regular Inspection',
    },
    {
      service_id: 20220814232,
      status: 'Pending',
      vehicle_id: 'VN04',
      sent_to: 'Sandy Reide',
      created_at: moment(
        'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      creator_id: '23',
      frequency_id: '3',
      service_due_date: moment(
        'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: '3 Months Warrenty Check',
    },
    {
      service_id: 20220112212,
      status: 'Sent',
      vehicle_id: 'VN06',
      created_at: moment(
        'Wed Jan 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      creator_id: '12',
      frequency_id: '3',
      sent_to: 'Bank Wellen',
      service_due_date: moment(
        'Wed Jan 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'Reminder for Annual Mantainance	',
    },
    {
      service_id: 20220212133,
      status: 'Completed',
      vehicle_id: 'VN01',
      sent_to: 'Percy Vere',
      created_at: moment(
        'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      creator_id: '20',
      frequency_id: '3',
      service_due_date: moment(
        'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: '2nd Service Reminder',
    },
    // {
    //   service_id: '20220326237',
    //   status: 'Completed',
    //   vehicle_id: 'VN04',
    //   sent_to: 'Jack Aranda',
    //   created_at: moment(
    //     'Sat Mar 26 2022 00:00:00 GMT+0530 (India Standard Time)'
    //   ).format('DD MMM YYYY'),
    //   creator_id: '30',
    //   frequency_id: '3',
    //   service_due_date: moment(
    //     'Sat Mar 26 2022 00:00:00 GMT+0530 (India Standard Time)'
    //   ).format('DD MMM YYYY'),
    //   urgency_level: 'High',
    //   description: '12,000 Km Check',
    // },
    // {
    //   service_id: '20220328238',
    //   status: 'Sent',
    //   vehicle_id: 'VN05',
    //   sent_to: 'Jack Aranda',
    //   created_at: moment(
    //     'Mon Mar 28 2022 00:00:00 GMT+0530 (India Standard Time)'
    //   ).format('DD MMM YYYY'),
    //   creator_id: '40',
    //   frequency_id: '3',
    //   service_due_date: moment(
    //     'Mon Mar 28 2022 00:00:00 GMT+0530 (India Standard Time)'
    //   ).format('DD MMM YYYY'),
    //   urgency_level: 'High',
    //   description: '13 Months Warrenty Check',
    // },
    {
      service_id: '20220419765',
      status: 'Sent',
      sent_to: 'Fran G. Pani',
      vehicle_id: 'VN04',
      created_at: moment(
        'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      creator_id: '36',
      frequency_id: '3',
      service_due_date: moment(
        'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'For Regular Inspection',
    },
    {
      service_id: '20220529432',
      status: 'Sent',
      sent_to: 'John Quil',
      vehicle_id: 'VN01',
      created_at: moment(
        'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      creator_id: '22',
      frequency_id: '3',
      service_due_date: moment(
        'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'Reminder for First Service	',
    },
  ],
};

export const IssuesDatatable = {
  meta: {
    vehicle_id: {
      showDefault: true,
      type: 'button',
      action: 'openModal()',
    },
    submitted_by: {
      header: 'Submitted_by',
      showDefault: true,
      type: 'button', //button, link
      action: "openServicePersonDetails('person')",
    },
  },
  data: [
    {
      service_id: 20220812234,
      issue_id: '234',
      battery_id: '123123',
      battery_level: '23',
      status: 'Open',
      vehicle_id: 'VN01',
      created_at: moment(
        'Fri Aug 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Ty Ayelloribbin',
      urgency_level: 'High',
      description: 'Breaks are not working',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220812234,
      issue_id: '321',
      status: 'Closed',
      battery_id: '124433',
      battery_level: '52',
      vehicle_id: 'VN01',
      created_at: moment(
        'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Hugo First',
      urgency_level: 'Moderate',
      description: 'Battery overheating issue',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220814321,
      issue_id: '232',
      status: 'Open',
      vehicle_id: 'VN02',
      battery_id: '112333',
      battery_level: '32',
      created_at: '22 Sept 2022',
      submitted_by: 'Percy Vere',
      urgency_level: 'Moderate',
      description: 'Battery issue in the bike',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220812234,
      issue_id: '212',
      status: 'In Progress',
      vehicle_id: 'VN01',
      battery_id: '344473',
      battery_level: '82',
      created_at: moment(
        'Wed Jan 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Jack Aranda',
      urgency_level: 'Low',
      description: 'Battery issue in the bike',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220419765,
      issue_id: '133',
      status: 'Closed',
      vehicle_id: 'VN01',
      battery_id: '234573',
      battery_level: '92',
      created_at: moment(
        'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Olive Tree',
      urgency_level: 'Moderate',
      description: 'Breaks are not working',
      files_uploaded: 'No',
    },
    {
      service_id: 20220529432,
      issue_id: '234',
      status: 'In Progress',
      vehicle_id: 'VN04',
      battery_id: '981323',
      battery_level: '71',
      created_at: moment(
        'Sat Mar 26 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Fran G. Pani',
      urgency_level: 'High',
      description: 'Battery issue in the bike',
      files_uploaded: 'No',
    },
    {
      service_id: 20220419765,
      issue_id: '234',
      status: 'Open',
      vehicle_id: 'VN05',
      battery_id: '234573',
      battery_level: '92',
      created_at: moment(
        'Mon Mar 28 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'John Quil',
      urgency_level: 'High',
      description: 'Acceleration is not working',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220814321,
      issue_id: '765',
      status: 'Open',
      vehicle_id: 'VN02',
      battery_id: '344473',
      battery_level: '82',
      created_at: moment(
        'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Jack Aranda',
      urgency_level: 'Moderate',
      description: 'Acceleration is not working',
      files_uploaded: 'No',
    },
    {
      service_id: 20220112212,
      issue_id: '432',
      status: 'Closed',
      vehicle_id: 'VN06',
      battery_id: '344213',
      battery_level: '32',
      created_at: moment(
        'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Aldous Hudghton',
      urgency_level: 'Low',
      description: 'Rear break is not working',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220212133,
      issue_id: '765',
      status: 'Open',
      battery_id: '657882',
      battery_level: '99',
      vehicle_id: 'VN01',
      created_at: moment(
        'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Jack Aranda',
      urgency_level: 'Moderate',
      description: 'Acceleration is not working',
      files_uploaded: 'No',
    },
    {
      service_id: 20220212133,
      issue_id: '432',
      status: 'Closed',
      vehicle_id: 'VN01',
      battery_id: '567893',
      battery_level: '44',
      created_at: moment(
        'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_by: 'Aldous Hudghton',
      urgency_level: 'Low',
      description: 'Rear break is not working',
      files_uploaded: 'Yes',
    },
  ],
};

export const InspectionWithMetaData = {
  meta: {
    inspection_type: {
      header: 'Inspection Type',
      showDefault: true,
    },
    vehicle_id: {
      header: 'Vehicle ID',
      showDefault: true,
      type: 'button',
      action: 'openModal()',
    },
    submitted_at: {
      header: 'COMPLETED AT',
      type: 'date',
      date_format: 'DD MMM YYYY',
    },
    started_at: {
      header: 'SUBMITTED AT',
      showDefault: true,
      type: 'date',
      date_format: 'DD MMM YYYY',
    },
    battery_level: {
      suffix: '%',
    },
    submitted_by: {
      header: 'Submitted by',
      showDefault: true,
      type: 'button',
      action: "openServicePersonDetails('person')",
    },
    tire_pressure: {
      suffix: ' psi',
    },
  },
  data: [
    {
      service_id: 20220812234,
      inspection_type: 'Auto',
      vehicle_id: 'VN01',
      started_at: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
      submitted_at: 'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)',
      duration: '5 hrs',
      odometer_read: 25,
      motor: 'Pass',
      battery_level: 23,
      nominal_volatage: 'Pass',
      transmission: 'Fail',
      horn: 'Pass',
      break: 'Pass',
      f_r_light: 'Pass',
      tire_pressure: '22',
      submitted_by: 'Aldous Hudghton',
      description: 'Breaks are not working',
      file_uploaded: 'Yes',
    },
    {
      service_id: 20220814321,
      odometer_read: 35,
      motor: 'Pass',
      battery_level: 83,
      nominal_volatage: 'Pass',
      transmission: 'Fail',
      horn: 'Pass',
      break: 'Pass',
      f_r_light: 'Pass',
      tire_pressure: '30',
      vehicle_id: 'VN02',
      inspection_type: 'Auto',
      started_at: 'Wed Mar 02 2022 00:00:00 GMT+0530 (India Standard Time)',
      submitted_at: 'Fri Mar 04 2022 00:00:00 GMT+0530 (India Standard Time)',
      duration: '5 hrs',
      submitted_by: 'Deonne Philo',
      description: 'Battery overheating issue',
      file_uploaded: 'No',
    },

    {
      service_id: 20220419765,
      odometer_read: 30,
      motor: 'Pass',
      battery_level: 90,
      nominal_volatage: 'Pass',
      transmission: 'Fail',
      horn: 'Pass',
      break: 'Pass',
      f_r_light: 'Pass',
      tire_pressure: '35',

      vehicle_id: 'VN01',
      inspection_type: 'Auto',
      started_at: 'Sat Aug 27 2022 00:00:00 GMT+0530 (India Standard Time)',
      submitted_at: '2 Sept 2022',
      duration: '5 hrs',
      submitted_by: 'Alika Brayley',
      description: 'Battery issue in the bike',
      file_uploaded: 'Yes',
    },
    {
      service_id: 20220529432,
      odometer_read: 10,
      motor: 'Pass',
      battery_level: 12,
      nominal_volatage: 'Pass',
      transmission: 'Fail',
      horn: 'Pass',
      break: 'Pass',
      f_r_light: 'Pass',
      tire_pressure: '28',
      vehicle_id: 'VN05',
      inspection_type: 'Auto',
      started_at: '30 Sept 2023',
      submitted_at: 'Tue Oct 03 2023 00:00:00 GMT+0530 (India Standard Time)',
      duration: '8 hrs',
      submitted_by: 'Bank Wellen',
      description: 'Acceleration is not working',
      file_uploaded: 'No',
    },
    {
      service_id: 20220419765,
      odometer_read: 30,
      motor: 'Pass',
      battery_level: 33,
      nominal_volatage: 'Pass',
      transmission: 'Fail',
      horn: 'Pass',
      break: 'Pass',
      f_r_light: 'Pass',
      tire_pressure: '27',
      vehicle_id: 'VN02',
      inspection_type: 'Auto',
      started_at: 'Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)',
      submitted_at: 'Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)',
      duration: '2 hrs',
      submitted_by: 'Aleda Ridgewell',
      description: 'Acceleration is not working',
      file_uploaded: 'Yes',
    },
    {
      service_id: 20220112212,
      odometer_read: 50,
      motor: 'Pass',
      battery_level: 43,
      nominal_volatage: 'Pass',
      transmission: 'Fail',
      horn: 'Pass',
      break: 'Pass',
      f_r_light: 'Pass',
      tire_pressure: '32',

      vehicle_id: 'VN03',
      inspection_type: 'Auto',
      started_at: 'Thu Dec 22 2022 00:00:00 GMT+0530 (India Standard Time)',
      submitted_at: 'Thu Dec 22 2022 00:00:00 GMT+0530 (India Standard Time)',
      duration: '8 hrs',
      submitted_by: 'Thorn Stapele',
      description: 'Rear break is not working',
      file_uploaded: 'No',
    },
    {
      service_id: 20220212133,
      vehicle_id: 'VN01',
      inspection_type: 'Auto',
      started_at: 'Thu Jan 12 2023 00:00:00 GMT+0530 (India Standard Time)',
      submitted_at: 'Thu Jan 12 2023 00:00:00 GMT+0530 (India Standard Time)',
      duration: '5 hrs',
      odometer_read: 40,
      motor: 'Pass',
      battery_level: 71,
      nominal_volatage: 'Pass',
      transmission: 'Fail',
      horn: 'Pass',
      break: 'Pass',
      f_r_light: 'Pass',
      tire_pressure: '33',
      submitted_by: 'Sandy Reide',
      description: 'Vehicle is not starting',
      file_uploaded: 'Yes',
    },
  ],
};

export const InspectionData = {
  columns: [
    {
      id: 'inspection_type',
      text: 'Inspection Type',
      meta: {
        type: 'text', //text, element
        element: (row) => {
          return (
            <a
              onClick={() => {
                // console.log(row);
              }}
            >
              Link
            </a>
          );
        },
      },
    },
    {
      id: 'odometer_read',
      text: 'ODOMETER READ',
    },
    {
      id: 'motor',
      text: 'MOTOR',
    },
    {
      id: 'battery_level',
      text: 'Battery Level',
    },
    {
      id: 'nominal_volatage',
      text: 'NOMINAL VOLTAGE',
    },
    {
      id: 'transmission',
      text: 'TRANSMISSION',
    },
    {
      id: 'horn',
      text: 'HORN',
    },
    {
      id: 'break',
      text: 'BREAK',
    },
    {
      id: 'f_r_light',
      text: 'F/R LIGHTS',
    },
    {
      id: 'tire_pressure',
      text: 'TIRE PRESSUER',
    },
    {
      id: 'vehicle_id',
      text: 'VEHICLE ID',
    },
    {
      id: 'started_at',
      text: 'SUBMITTED AT',
    },
    {
      id: 'submitted_at',
      text: 'SUBMITTED AT',
    },
    {
      id: 'duration',
      text: 'DURATION',
    },
    {
      id: 'submitted_by',
      text: 'SUBMITTED BY',
    },
    {
      id: 'description',
      text: 'DESCRIPTION',
    },
    {
      id: 'file_uploaded',
      text: 'FILE UPLOADED',
    },
    // {
    //   id: 'action',
    //   text: 'ACTION',
    //   meta: {
    //     type: 'text',
    //     element: (
    //       <>
    //         <button className="btn btn-primary">Edit</button>
    //         <button className="mx-2 btn btn-danger">Delete</button>
    //       </>
    //     ),
    //   },
    // },
  ],
  data: [
    {
      inspection_type: 'Auto',
      vehicle_id: 'VN01',
      started_at: moment(
        'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_at: moment(
        'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      duration: '5 hrs',
      submitted_by: 'Aldous Hudghton',
      description: 'Breaks are not working',
      file_uploaded: 'Yes',
      odometer_read: 25,
      motor: 'fail',
      battery_level: 56,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
    {
      inspection_type: 'Manual',
      vehicle_id: 'VN02',
      started_at: moment(
        'Wed Mar 02 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_at: moment(
        'Fri Mar 04 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      duration: '5 hrs',
      submitted_by: 'Deonne Philo',
      description: 'Battery overheating issue',
      file_uploaded: 'No',
      odometer_read: 30,
      motor: 'fail',
      battery_level: 21,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
    {
      inspection_type: 'Manual',
      vehicle_id: 'VN03',
      started_at: moment(
        'Sat Jun 18 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_at: moment(
        'Tue Jun 28 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      duration: '10 hrs',
      submitted_by: 'Brady Blamire',
      description: 'Battery issue in the bike',
      file_uploaded: 'Yes',
      odometer_read: 45,
      motor: 'fail',
      battery_level: 12,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
    {
      inspection_type: 'Auto',
      vehicle_id: 'VN04',
      started_at: moment(
        'Sat Jun 18 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_at: moment(
        'Mon Jul 18 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      duration: '3 hrs',
      submitted_by: 'Maud Caverhill',
      description: 'Breaks are not working',
      file_uploaded: 'Yes',
      odometer_read: 15,
      motor: 'fail',
      battery_level: 20,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
    {
      inspection_type: 'Auto',
      vehicle_id: 'VN05',
      started_at: moment(
        'Sat Aug 27 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_at: moment('2 Sept 2022').format('DD MMM YYYY'),
      duration: '5 hrs',
      submitted_by: 'Alika Brayley',
      description: 'Battery issue in the bike',
      file_uploaded: 'Yes',
      odometer_read: 25,
      motor: 'fail',
      battery_level: 56,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
    {
      inspection_type: 'Auto',
      vehicle_id: 'VN06',
      started_at: '30 Sept 2023',
      submitted_at: moment(
        'Tue Oct 03 2023 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      duration: '8 hrs',
      submitted_by: 'Bank Wellen',
      description: 'Acceleration is not working',
      file_uploaded: 'No',
      odometer_read: 55,
      motor: 'fail',
      battery_level: 88,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
    {
      inspection_type: 'Manual',
      vehicle_id: 'VN07',
      started_at: moment(
        'Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_at: moment(
        'Sat Oct 01 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      duration: '2 hrs',
      submitted_by: 'Aleda Ridgewell',
      description: 'Acceleration is not working',
      file_uploaded: 'Yes',
      odometer_read: 40,
      motor: 'fail',
      battery_level: 72,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
    {
      inspection_type: 'Manual',
      vehicle_id: 'VN08',
      started_at: moment(
        'Thu Dec 22 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_at: moment(
        'Thu Dec 22 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      duration: '8 hrs',
      submitted_by: 'Thorn Stapele',
      description: 'Rear break is not working',
      file_uploaded: 'No',
      odometer_read: 20,
      motor: 'fail',
      battery_level: 61,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
    {
      inspection_type: 'Auto',
      vehicle_id: 'VN09',
      started_at: moment(
        'Thu Jan 12 2023 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      submitted_at: moment(
        'Thu Jan 12 2023 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      duration: '5 hrs',
      submitted_by: 'Sandy Reide',
      description: 'Vehicle is not starting',
      file_uploaded: 'Yes',
      odometer_read: 10,
      motor: 'fail',
      battery_level: 10,
      nominal_volatage: 'fail',
      transmission: 'pass',
      horn: 'fail',
      break: 'pass',
      f_r_light: 'fail',
      tire_pressure: 'fail',
    },
  ],
};

export const OperatorReportDatatable = {
  meta: {
    vehicle_id: {
      header: 'Vehicle ID',
      showDefault: true,
      type: 'button', //button, link
      action: 'openModal()',
    },
    submitted_by: {
      header: 'Submitted_by',
      showDefault: true,
      type: 'button', //button, link
      action: "openServicePersonDetails('person')",
    },
  },
  data: [
    {
      service_id: 20220812234,
      submitted_by: 'Aldous Hudghton',
      status: 'High',
      vehicle_id: 'VN01',
      created_at: moment(
        'Fri Aug 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'Breaks are not working',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220814321,
      submitted_by: 'Deonne Philo',
      status: 'Moderate',
      vehicle_id: 'VN02',
      created_at: moment(
        'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Moderate',
      description: 'Battery overheating issue',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220814232,
      submitted_by: 'Brady Blamire',
      status: 'Moderate',
      vehicle_id: 'VN04',
      created_at: '22 Sept 2022',
      urgency_level: 'Moderate',
      description: 'Battery issue in the bike',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220112212,
      submitted_by: 'Maud Caverhill',
      status: 'Low',
      vehicle_id: 'VN06',
      created_at: moment(
        'Wed Jan 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Low',
      description: 'Breaks are not working',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220212133,
      submitted_by: 'Alika Brayley',
      status: 'Moderate',
      vehicle_id: 'VN01',
      created_at: moment(
        'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Moderate',
      description: 'Battery issue in the bike',
      files_uploaded: 'No',
    },
    {
      service_id: 20220812234,
      submitted_by: 'Bank Wellen',
      status: 'High',
      vehicle_id: 'VN01',
      created_at: moment(
        'Sat Mar 26 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'Acceleration is not working',
      files_uploaded: 'No',
    },
    {
      service_id: 20220812234,
      submitted_by: 'Aleda Ridgewell',
      status: 'High',
      vehicle_id: 'VN01',
      created_at: moment(
        'Mon Mar 28 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'Acceleration is not working',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220419765,
      submitted_by: 'Thorn Stapele',
      status: 'Moderate',
      vehicle_id: 'VN04',
      created_at: moment(
        'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Moderate',
      description: 'Rear break is not working',
      files_uploaded: 'No',
    },
    {
      service_id: 20220529432,
      submitted_by: 'Sandy Reide',
      status: 'Low',
      vehicle_id: 'VN01',
      created_at: moment(
        'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Low',
      description: 'Vehicle is not starting',
      files_uploaded: 'Yes',
    },
  ],
};

export const WorkOrderDatatable = {
  meta: {
    vehicle_id: {
      header: 'Vehicle ID',
      showDefault: true,
      type: 'button', //button, link
      action: 'openModal()',
    },
    submitted_by: {
      header: 'Submitted_by',
      showDefault: true,
      type: 'button', //button, link
      action: "openServicePersonDetails('person')",
    },
  },
  data: [
    {
      service_id: 20220812234,
      submitted_by: 'Aldous Hudghton',
      status: 'In Progress',
      vehicle_id: 'VN01',
      created_at: moment(
        'Fri Aug 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'Breaks are not working',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220814321,
      submitted_by: 'Deonne Philo',
      status: 'Completed',
      vehicle_id: 'VN02',
      created_at: moment(
        'Sun Aug 14 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Moderate',
      description: 'Battery overheating issue	',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220814232,
      submitted_by: 'Brady Blamire',
      status: 'In Progress',
      vehicle_id: 'VN04',
      created_at: '22 Sept 2022',
      urgency_level: 'Moderate',
      description: 'Battery issue in the bike	',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220112212,
      submitted_by: 'Maud Caverhill',
      status: 'Completed',
      vehicle_id: 'VN06',
      created_at: moment(
        'Wed Jan 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Low',
      description: 'Breaks are not working	',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220212133,
      submitted_by: 'Alika Brayley',
      status: 'Approval Pending',
      vehicle_id: 'VN01',
      created_at: moment(
        'Sat Feb 12 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Moderate',
      description: 'Battery issue in the bike	',
      files_uploaded: 'No',
    },
    {
      service_id: 20220812234,
      submitted_by: 'Bank Wellen',
      status: 'Approval Pending',
      vehicle_id: 'VN01',
      created_at: moment(
        'Sat Mar 26 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'Acceleration is not working	',
      files_uploaded: 'No',
    },
    {
      service_id: 20220812234,
      submitted_by: 'Aleda Ridgewell',
      status: 'Completed',
      vehicle_id: 'VN01',
      created_at: moment(
        'Mon Mar 28 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'High',
      description: 'Acceleration is not working	',
      files_uploaded: 'Yes',
    },
    {
      service_id: 20220419765,
      submitted_by: 'Thorn Stapele',
      status: 'Completed',
      vehicle_id: 'VN04',
      created_at: moment(
        'Tue Apr 19 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Moderate',
      description: 'Rear break is not working	',
      files_uploaded: 'No',
    },
    {
      service_id: 20220529432,
      submitted_by: 'Sandy Reide',
      status: 'Completed',
      vehicle_id: 'VN01',
      created_at: moment(
        'Sun May 29 2022 00:00:00 GMT+0530 (India Standard Time)'
      ).format('DD MMM YYYY'),
      urgency_level: 'Low',
      description: 'Vehicle is not starting',
      files_uploaded: 'Yes',
    },
  ],
};

export const AdminUsersData = {
  meta: {
    id: {
      hide: true,
    },
    user_type: {
      hide: true,
    },
    password: {
      hide: true,
    },
  },
  data: [
    {
      id: '1',
      name: 'Admin User',
      email: 'admin@efleety.com',
      password: 'admin123',
      user_type: [
        'master',
        'keego',
        'citkar',
        'moby',
        'diageo',
        'gramobility',
        'abc_solar',
        'superfy',
        'bombay_softwares',
      ],
    },
    {
      id: '2',
      name: 'Sydney Smith',
      email: 'citkar@efleety.com',
      password: 'citkar',
      user_type: ['citkar'],
    },
    {
      id: '3',
      name: 'David Davis',
      email: 'moby@efleety.com',
      password: 'moby',
      user_type: ['moby'],
    },
    {
      id: '4',
      name: 'Miley Wiiliams',
      email: 'diageo@eflety.com',
      password: 'diageo',
      user_type: ['diageo'],
    },
    {
      id: '5',
      name: 'Camila Hill',
      email: 'gramobility@eflety.com',
      password: 'gramobility',
      user_type: ['gramobility'],
    },
    {
      id: '6',
      name: 'Nick Long',
      email: 'abcsolar@eflety.com',
      password: 'abcsolar',
      user_type: ['abc_solar'],
    },
    {
      id: '7',
      name: 'Phil Corri',
      email: 'phil@efleety.com',
      password: 'phil',
      user_type: ['citkar', 'moby', 'master', 'keego'],
    },
    {
      id: '8',
      name: 'Jhon Miller',
      email: 'keego@efleety.com',
      password: 'keego',
      user_type: ['keego'],
    },
    {
      id: '9',
      name: 'Mukul Jain',
      email: 'mukul@yopmail.com',
      password: 'Test@123',
      user_type: ['citkar'],
    },
  ],
};

export const GeofencingDatatable = {
  meta: {
    map_data: {
      hide: true,
    },
    vehicle_list: {
      hide: true,
    },
  },
  data: [
    {
      map_data: [
        {
          lat: 53.58499371245946,
          lng: 9.898873412381434,
        },
        {
          lat: 53.59314518926144,
          lng: 9.982644164334559,
        },
        {
          lat: 53.567054928327686,
          lng: 9.971657836209559,
        },
        {
          lat: 53.55359584489592,
          lng: 9.938698851834559,
        },
      ],
      title: 'Altona-Nord Zone',
      vehicle_list: [6],
      number_of_vehicles: 1,
    },
    {
      map_data: [
        {
          lat: 53.552900783985656,
          lng: 9.99719098378501,
        },
        {
          lat: 53.5575916338039,
          lng: 10.017103703511573,
        },
        {
          lat: 53.549076336838944,
          lng: 10.022596867574073,
        },
        {
          lat: 53.54545554172716,
          lng: 10.00268414784751,
        },
      ],
      title: 'Hamburg Railway Zone',
      vehicle_list: [2, 1, 5],
      number_of_vehicles: 3,
    },
  ],
};

export let DashboardStatistics = [
  {
    module: 'dashboard',
    heading: 'Total Vehicles',
    type: 'vehicle',
    action: 'total',
    order: 0,
  },
  {
    module: 'dashboard',
    heading: 'Total Batteries',
    type: 'battery',
    action: 'total',
    order: 1,
  },
  {
    module: 'dashboard',
    heading: 'Total Issues',
    type: 'issues',
    action: 'total',
    order: 3,
  },
  {
    module: 'dashboard',
    heading: 'In Transit',
    type: 'battery',
    action: 'total',
    order: 2,
  },
  {
    module: 'dashboard',
    heading: 'Total Distance Travel',
    action: 'total_distance_travelled',
    order: 4,
  },
  {
    module: 'dashboard',
    heading: 'Vehicle Active Time',
    action: 'vehicle_active_time',
    order: 5,
  },
  {
    module: 'dashboard',
    heading: 'Vehicle Downtime',
    action: 'vehicle_down_time',
    order: 6,
  },
  {
    module: 'dashboard',
    heading: 'Vehicle In Service',
    action: 'vehicle_in_service',
    order: 7,
  },

  {
    module: 'vehicle',
    heading: 'Total Vehicles',
    type: 'vehicle',
    action: 'total',
    order: 4,
  },
  {
    module: 'vehicle',
    heading: 'In Transit',
    type: 'vehicle',
    action: 'in_transit',
    order: 5,
  },
  {
    module: 'vehicle',
    heading: 'Total Issues',
    type: 'issues',
    action: 'total',
    order: 6,
  },
  {
    module: 'vehicle',
    heading: 'Work Orders',
    type: 'workorder',
    action: 'total',
    order: 7,
  },

  {
    module: 'batteries',
    heading: 'Total Battries',
    type: 'battery',
    action: 'total',
    order: 5,
  },
  {
    module: 'batteries',
    heading: 'In Vehicle',
    type: 'battery',
    action: 'battery_in_vehicle',
    order: 6,
  },
  {
    module: 'batteries',
    heading: 'In Charging Stations',
    type: 'battery',
    action: 'battery_in_charging_station',
    order: 7,
  },
  {
    module: 'batteries',
    heading: 'Overheating',
    type: 'battery',
    action: 'battery_with_overheating',
    order: 7,
  },
];
