import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import {
  batteryDatatable,
  vehicleBatteryHistory,
  VehicleDatatable,
} from '../DummyData';
import moment from 'moment';
import AssetLocationMap from '../../screens/Maps/AssetLocationMap';

export const Datatable = ({ hasAddFeature, columns, data }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowDetail, setModalShowDetail] = React.useState(false);
  const [historyData, setHistoryData] = useState({
    type: 'vehicle_history',
    data: [],
    history: [],
  });
  const [contacts, setContacts] = useState(data);
  const [addFormData, setAddFormData] = useState({
    fullName: '',
    position: '',
    start: '',
    salary: '',
    email: '',
  });

  const [editFormData, setEditFormData] = useState({
    fullName: '',
    position: '',
    start: '',
    salary: '',
    email: '',
  });

  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    // const newContact = {
    //   id: nanoid(),
    //   fullName: addFormData.fullName,
    //   position: addFormData.position,
    //   start: addFormData.start,
    //   salary: addFormData.salary,
    //   email: addFormData.email,
    //   current_status:addFormData.current_status
    // };

    // const newContacts = [...contacts, newContact];
    // setContacts(newContacts);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedContact = {
      id: editContactId,
      fullName: editFormData.fullName,
      position: editFormData.position,
      start: addFormData.start,
      salary: editFormData.salary,
      email: editFormData.email,
    };

    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === editContactId);

    newContacts[index] = editedContact;

    setContacts(newContacts);
    setEditContactId(null);
  };

  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);

    const formValues = {
      fullName: contact.fullName,
      position: contact.position,
      start: contact.start,
      salary: contact.salary,
      email: contact.email,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === contactId);

    newContacts.splice(index, 1);

    setContacts(newContacts);
  };

  return (
    <div className="app-container">
      <form onSubmit={handleEditFormSubmit}>
        <div style={{ textAlign: 'right' }}>
          {hasAddFeature && (
            <Button
              variant=""
              className="btn btn-primary mb-3"
              onClick={() => setModalShow(true)}
            >
              Add
            </Button>
          )}
        </div>
        <table
          id="delete-datatable"
          className="table  table-responsive table-bordered text-nowrap border-bottom"
        >
          <thead>
            <tr>
              {columns.map((column) => {
                return <th>{column.name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <Fragment key={contact.id}>
                {editContactId === contact.id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    columns={columns}
                    setHistoryData={setHistoryData}
                    setModalShowDetail={() => {
                      setModalShowDetail(true);
                    }}
                    contact={contact}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </form>
      <Modal
        show={modalShowDetail}
        onHide={() => setModalShowDetail(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {historyData.type == 'vehicle_history'
              ? 'Vehicle Battery Usage'
              : 'Battery Vehicle Usage'}
          </Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => setModalShowDetail(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          {historyData.type == 'vehicle_history' ? (
            <>
              {/* <div>Vehicle Number</div> */}
              <table className="table table-responsive table-bordered text-nowrap border-bottom">
                <thead>
                  <tr>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Battery Id</th>
                    <th>Battery Charging Status</th>
                    <th>Battery Firmware Version</th>
                    <th>Battery Cell Voltage</th>
                  </tr>
                </thead>
                <tbody>
                  {historyData.history.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td>{moment(data.start_date).format('DD MMM YYYY')}</td>
                        <td>{moment(data.start_date).format('DD MMM YYYY')}</td>
                        <td>{data.battery_id}</td>
                        <td>{data.battery?.battery_charging_state}</td>
                        <td>{data.battery?.battery_firmware_version}</td>
                        <td>{data.battery?.battery_cell_voltage}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <>
              {/* <div>Vehicle Number</div> */}
              <table className="table table-responsive table-bordered text-nowrap border-bottom">
                <thead>
                  <tr>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Vehicle Id</th>
                    <th>IMEI</th>
                    <th>Number of Rides</th>
                    <th>Current Location</th>
                    <th>Current Status</th>
                  </tr>
                </thead>

                <tbody>
                  {historyData.history.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td>{moment(data.start_date).format('DD MMM YYYY')}</td>
                        <td>{moment(data.start_date).format('DD MMM YYYY')}</td>
                        <td>{data.vehicle_id}</td>
                        <td>{data.vehicle.imei}</td>
                        <td>{data.vehicle.number_of_rides}</td>
                        <td>{data.vehicle.current_location}</td>
                        <td>{data.vehicle.current_status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            onClick={() => setModalShowDetail(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Add</Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => setModalShow(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddFormSubmit}>
            <input
              type="text"
              name="fullName"
              required
              placeholder="Enter a vehicle number..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="text"
              name="position"
              required
              placeholder="Enter an total rides..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="number"
              name="start"
              required
              placeholder="Enter a performance..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="number"
              name="salary"
              required
              placeholder="Enter a battery level..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="text"
              name="email"
              required
              placeholder="Enter an current location..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="text"
              name="current_status"
              required
              placeholder="Enter an current status..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="number"
              name="imei_number"
              required
              placeholder="Enter an IMEI Number..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <Button variant="" className="btn btn-primary me-2" type="submit">
              Add
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


// Add New Row with Edit Table
export const Savetable = ({
  table_type = 'battery-datatable',
  hasAddFeature,
  columns,
  data,
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowDetail, setModalShowDetail] = React.useState(false);
  const [historyData, setHistoryData] = useState({
    type: 'vehicle_history',
    data: [],
    history: [],
  });
  const [contacts, setContacts] = useState(data);
  const [addFormData, setAddFormData] = useState({
    fullName: '',
    position: '',
    start: '',
    salary: '',
    email: '',
  });

  const [editFormData, setEditFormData] = useState({
    fullName: '',
    position: '',
    start: '',
    salary: '',
    email: '',
  });

  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    // const newContact = {
    //   id: nanoid(),
    //   fullName: addFormData.fullName,
    //   position: addFormData.position,
    //   start: addFormData.start,
    //   salary: addFormData.salary,
    //   email: addFormData.email,
    //   current_status:addFormData.current_status
    // };

    // const newContacts = [...contacts, newContact];
    // setContacts(newContacts);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedContact = {
      id: editContactId,
      fullName: editFormData.fullName,
      position: editFormData.position,
      start: addFormData.start,
      salary: editFormData.salary,
      email: editFormData.email,
    };

    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === editContactId);

    newContacts[index] = editedContact;

    setContacts(newContacts);
    setEditContactId(null);
  };

  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);

    const formValues = {
      fullName: contact.fullName,
      position: contact.position,
      start: contact.start,
      salary: contact.salary,
      email: contact.email,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === contactId);

    newContacts.splice(index, 1);

    setContacts(newContacts);
  };

  return (
    <div className="app-container">
      <form onSubmit={handleEditFormSubmit}>
        <div style={{ textAlign: 'right' }}>
          {hasAddFeature && (
            <Button
              variant=""
              className="btn btn-primary mb-3"
              onClick={() => setModalShow(true)}
            >
              Add
            </Button>
          )}
        </div>
        <div className="table-responsive">
          <table className="table table-bordered text-nowrap border-bottom">
            <thead>
              <tr>
                {columns.map((column) => {
                  return <th>{column.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <Fragment key={contact.id}>
                  {table_type == 'vehicle-datatable' && (
                    <ReadOnlyRow
                      columns={columns}
                      setHistoryData={setHistoryData}
                      setModalShowDetail={() => {
                        setModalShowDetail(true);
                      }}
                      contact={contact}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                    />
                  )}
                  {table_type == 'rider-datatable' && (
                    <ReadOnlyRowRiderDatatable
                      columns={columns}
                      setHistoryData={setHistoryData}
                      setModalShowDetail={() => {
                        setModalShowDetail(true);
                      }}
                      contact={contact}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                    />
                  )}
                  {table_type == 'battery-datatable' && (
                    <ReadOnlyRowBatteryDatatable
                      columns={columns}
                      setHistoryData={setHistoryData}
                      setModalShowDetail={() => {
                        setModalShowDetail(true);
                      }}
                      contact={contact}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                    />
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </form>
      <Modal
        show={modalShowDetail}
        onHide={() => setModalShowDetail(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {historyData.type == 'vehicle_history'
              ? 'Vehicle Battery Usage' 
              : historyData.type == 'rider_history'
              ? 'Rider Location' 
              : 'Battery Vehicle Usage'}
          </Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => setModalShowDetail(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          {table_type == 'vehicle-datatable' && (
            <div className="table-responsive">
              {/* {historyData ? (
                <AssetLocationMap id={historyData.id} type={historyData.type} />
              ) : (
                ''
              )} */}
              {/* <div>Vehicle Number</div> */}
              <table
                className="table table-bordered text-nowrap border-bottom"
                border={1}
                width="100%"
              >
                <thead>
                  <tr>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Battery Id</th>
                    <th>Battery Charging Status</th>
                    <th>Battery Firmware Version</th>
                    <th>Battery Cell Voltage</th>
                  </tr>
                </thead>
                <tbody>
                  {historyData.history.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td>{moment(data.start_date).format('DD MMM YYYY')}</td>
                        <td>{moment(data.start_date).format('DD MMM YYYY')}</td>
                        <td>{data.battery_id}</td>
                        <td>{data.battery?.battery_charging_state}</td>
                        <td>{data.battery?.battery_firmware_version}</td>
                        <td>{data.battery?.battery_cell_voltage}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {table_type == 'battery-datatable' && (
            <div className="table-responsive">
              {/* {historyData ? (
                <AssetLocationMap id={historyData.id} type={historyData.type} />
              ) : (
                ''
              )} */}
              {/* <div>Vehicle Number</div> */}
              <table
                className="table table-bordered text-nowrap border-bottom"
                border={1}
                width="100%"
              >
                <thead>
                  <tr>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Battery Id</th>
                    <th>Battery Charging Status</th>
                    <th>Battery Firmware Version</th>
                    <th>Battery Cell Voltage</th>
                  </tr>
                </thead>
                <tbody>
                  {historyData.history.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td>{moment(data.start_date).format('DD MMM YYYY')}</td>
                        <td>{moment(data.start_date).format('DD MMM YYYY')}</td>
                        <td>{data.battery_id}</td>
                        <td>{data.battery?.battery_charging_state}</td>
                        <td>{data.battery?.battery_firmware_version}</td>
                        <td>{data.battery?.battery_cell_voltage}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {/* {table_type == 'rider-datatable' && historyData ? (
            <AssetLocationMap id={historyData.id} type={historyData.type} />
          ) : ''} */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            onClick={() => setModalShowDetail(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Add</Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => setModalShow(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddFormSubmit}>
            <input
              type="text"
              name="fullName"
              required
              placeholder="Enter a vehicle number..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="text"
              name="position"
              required
              placeholder="Enter an total rides..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="number"
              name="start"
              required
              placeholder="Enter a performance..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="number"
              name="salary"
              required
              placeholder="Enter a battery level..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="text"
              name="email"
              required
              placeholder="Enter an current location..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="text"
              name="current_status"
              required
              placeholder="Enter an current status..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="number"
              name="imei_number"
              required
              placeholder="Enter an IMEI Number..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <Button variant="" className="btn btn-primary me-2" type="submit">
              Add
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Savetable.defaultProps = {
  hasAddFeature: true,
  data: [],
};

// Add New Row with Edit Table
export const SavetableServices = ({ hasAddFeature, data }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [contacts, setContacts] = useState(data);
  const [addFormData, setAddFormData] = useState({
    fullName: '',
    position: '',
    start: '',
    salary: '',
    email: '',
  });

  const [editFormData, setEditFormData] = useState({
    fullName: '',
    position: '',
    start: '',
    salary: '',
    email: '',
  });

  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const newContact = {
      id: nanoid(),
      fullName: addFormData.fullName,
      position: addFormData.position,
      start: addFormData.start,
      salary: addFormData.salary,
      email: addFormData.email,
    };

    const newContacts = [...contacts, newContact];
    setContacts(newContacts);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedContact = {
      id: editContactId,
      fullName: editFormData.fullName,
      position: editFormData.position,
      start: addFormData.start,
      salary: editFormData.salary,
      email: editFormData.email,
    };

    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === editContactId);

    newContacts[index] = editedContact;

    setContacts(newContacts);
    setEditContactId(null);
  };

  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);

    const formValues = {
      fullName: contact.fullName,
      position: contact.position,
      start: contact.start,
      salary: contact.salary,
      email: contact.email,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === contactId);

    newContacts.splice(index, 1);

    setContacts(newContacts);
  };

  return (
    <div className="app-container table-responsive">
      <form onSubmit={handleEditFormSubmit}>
        <div style={{ textAlign: 'right' }}>
          {hasAddFeature && (
            <Button
              variant=""
              className="btn btn-primary mb-3"
              onClick={() => setModalShow(true)}
            >
              Add
            </Button>
          )}
        </div>
        <table
          id="delete-datatable"
          className="table table-bordered text-nowrap border-bottom"
        >
          <thead>
            <tr>
              <th>VEHICLE</th>
              <th>NUMBER OF RIDES</th>
              <th>PERFORMANCE</th>
              <th>BATTERY LEVEL</th>
              <th>CURRENT LOCATION</th>
              <th>CURRENT STATUS</th>
              <th>IMEI</th>
              <th>BATTERY ID</th>
              <th>BATTERY CHARGING STATE</th>
              <th>BATTERY FIREWARE VERSION</th>
              <th>CONTROLLER/TRACKER ID</th>
              <th>BATTERY CELL VOLTAGE</th>
              <th>CONTROLLER/TRACKER FIRMWARE VER.</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <Fragment key={contact.id}>
                {editContactId === contact.id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRowServices
                    contact={contact}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </form>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Add</Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => setModalShow(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddFormSubmit}>
            <input
              type="text"
              name="fullName"
              required
              placeholder="Enter a name..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="text"
              name="position"
              required
              placeholder="Enter an addres..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            {/* <input
              type="date"
              name="Start Date"
              required
              
              onChange={handleAddFormChange}
              className="form-control mb-2"
            /> */}
            <input
              type="number"
              name="salary"
              required
              placeholder="Enter a phone number..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="email"
              name="email"
              required
              placeholder="Enter an email..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <input
              type="number"
              name="imei_number"
              required
              placeholder="Enter an IMEI Number..."
              onChange={handleAddFormChange}
              className="form-control mb-2"
            />
            <Button variant="" className="btn btn-primary me-2" type="submit">
              Add
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

SavetableServices.defaultProps = {
  hasAddFeature: true,
  data: [],
};

const ReadOnlyRow = ({
  columns,
  setHistoryData,
  contact,
  setModalShowDetail,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <tr>
      <td>
        <a
          onClick={(event) => {
            event.preventDefault(); 
            let history = vehicleBatteryHistory.filter(
              (data) => data.vehicle_id == contact.vehicle_id
            );

            history.map((data) => {
              data.battery = batteryDatatable.data.find(
                (battery) => battery.battery_id == data.battery_id
              );
            });

            let battery = history.map((data) => data.battery_id);

            let vehicleData = VehicleDatatable.data.filter((data) =>
              battery.includes(data.battery_id)
            );

            setHistoryData({
              id: contact.vehicle_id,
              type: 'vehicle_history',
              data: [...vehicleData],
              history: history,
            });

            setModalShowDetail(true);
          }}
          href="#"
        >
          {contact.vehicle_id}
        </a>
      </td>
      <td>{contact.number_of_rides}</td>
      <td>{contact.performance}</td>
      <td>{contact.battery_level}</td>
      <td>{contact.current_location}</td>
      <td>{contact.current_status}</td>
      <td>{contact.imei}</td>
      <td>
        <a
          onClick={(event) => {
            event.preventDefault();
            let history = vehicleBatteryHistory.filter(
              (data) => data.battery_id == contact.battery_id
            );
            let vehicles = history.map((data) => data.vehicle_id);
            let batteryData = VehicleDatatable.data.filter((data) =>
              vehicles.includes(data.vehicle_id)
            );

            let singleBatteryData = batteryData[0];

            setHistoryData({
              id: contact.battery_id,
              type: 'battery_history',
              data: [...batteryData],
              history: history.map((data) => ({
                ...data,
                vehicle: singleBatteryData,
              })),
            });
            setModalShowDetail(true);
          }}
          href="#"
        >
          {contact.battery_id}
        </a>
      </td>
      <td>{contact.battery_charging_state}</td>
      <td>{contact.battery_firmware_version}</td>
      <td>{contact.controller_id}</td>
      <td>{contact.battery_cell_voltage}</td>
      <td>{contact.controller_firmware_version}</td>
      <td>
        <Button
          variant=""
          className="btn btn-primary me-1"
          type="button"
          // onClick={(event) => handleEditClick(event, contact)}
        >
          Edit
        </Button>
        <Button
          variant=""
          className="btn btn-danger me-1"
          type="button"
          // onClick={() => handleDeleteClick(contact.id)}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
};

const ReadOnlyRowRiderDatatable = ({
  columns,
  setHistoryData,
  contact,
  setModalShowDetail,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <tr>
      <td>
        <a
          onClick={(event) => {
            event.preventDefault();
            setHistoryData({
              id: contact.name,
              type: 'rider_history',
            });
            setModalShowDetail(true);
          }}
          href="#"
        >
          {contact.name}
        </a>
      </td>
      <td>{contact.total_rides}</td>
      <td>{contact.performance}</td>
      <td>{contact.is_riding}</td>
      <td>{contact.auto_allocation}</td>
    </tr>
  );
};

const ReadOnlyRowBatteryDatatable = ({
  columns,
  setHistoryData,
  contact,
  setModalShowDetail,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <tr>
      <td>{contact.vehicle_id}</td>
      <td>
        <a
          onClick={(event) => {
            event.preventDefault();
            let history = vehicleBatteryHistory.filter(
              (data) => data.vehicle_id == contact.vehicle_id
            );

            history.map((data) => {
              data.battery = batteryDatatable.data.find(
                (battery) => battery.battery_id == data.battery_id
              );
            });

            let battery = history.map((data) => data.battery_id);

            let vehicleData = VehicleDatatable.data.filter((data) =>
              battery.includes(data.battery_id)
            );

            setHistoryData({
              id: contact.vehicle_id,
              type: 'vehicle_history',
              data: [...vehicleData],
              history: history,
            });

            setModalShowDetail(true);
          }}
          href="#"
        >
          {contact.battery_id}
        </a>
      </td>
      <td>{contact.battery_charging_state}</td>
      <td>{contact.battery_firmware_version}</td>
      <td>{contact.battery_cell_voltage}</td>
      <td>{contact.battery_level}</td>
    </tr>
  );
};

// const ReadOnlyRowBatteryDatatable = ({
//   columns,
//   setHistoryData,
//   contact,
//   setModalShowDetail,
//   handleEditClick,
//   handleDeleteClick,
// }) => {
//   return (
//     <tr>
//       {columns.map((column) => {
//         return column.cell ? (
//           <td>{column.cell(contact)}</td>
//         ) : (
//           <td>{contact[column.accessor]}</td>
//         );
//       })}
//     </tr>
//   );
// };

const ReadOnlyRowServices = ({
  contact,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <tr>
      <td>{contact.vehicle_id}</td>
      <td>{contact.number_of_rides}</td>
      <td>{contact.performance}</td>
      <td>{contact.battery_level}</td>
      <td>{contact.current_location}</td>
      <td>{contact.current_status}</td>
      <td>{contact.imei}</td>
      <td>{contact.battery_id}</td>
      <td>{contact.battery_charging_state}</td>
      <td>{contact.battery_firmware_version}</td>
      <td>{contact.controller_id}</td>
      <td>{contact.battery_cell_voltage}</td>
      <td>{contact.controller_firmware_version}</td>
      <td>
        <Button
          variant=""
          className="btn btn-primary me-1"
          type="button"
          // onClick={(event) => handleEditClick(event, contact)}
        >
          Service
        </Button>
        {/* <Button
          variant=""
          className="btn btn-danger me-1"
          type="button"
          onClick={() => handleDeleteClick(contact.id)}
        >
          Delete
        </Button> */}
      </td>
    </tr>
  );
};
