import React from 'react';
import SuperfyDashboard from './SuperfyDashboard';
import Layout from '../../Layouts/Layout';
import KeegoDashboard from './KeegoDashboard';
import WavSmartDashboard from './WavSmartDashboard';

function Dashboard() {
  let app = sessionStorage.getItem('app_key');

  const renderComponent = () => {
    switch (app) {
      case 'superfy':
        return <SuperfyDashboard />;
      case 'wav_smart':
        return <WavSmartDashboard />;
      default:
        return <KeegoDashboard />;
    }
  };

  return <Layout>{
    renderComponent()
    }</Layout>;
}

export default Dashboard;
