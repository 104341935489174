import Layout from '../../Layouts/Layout';
import MobyRouting from './Routing';
import WavSmartRouting from './WavSmartRouting';
import GeneralRouting from './GeneralRouting';
import FilteredContainerHistory from '../FilteredContainerHistory/FilteredContainerHistory';

function Routing() {
  let app = sessionStorage.getItem('app_key');

  const renderComponent = () => {
    switch (app) {
      case 'wav_smart':
        return <FilteredContainerHistory />;
      default:
        return <MobyRouting />;
      // default:
      //   return <GeneralRouting />;
    }
  };

  return renderComponent();
}

export default Routing;
