import http from '../../http';
import { enqueueSnackbar } from 'notistack';

let app = sessionStorage.getItem('app');

export const getPermissions = async () => {
  return await http(
    'GET',
    `api/v1/fleet-manager-permissions?fleet_manager=${sessionStorage.getItem(
      'app_key'
    )}`,  
    {}
  )
    .then((result) => {
      console.log('Fleet-manager-permissions: ', result);
      if (result) {
        return result.data;
      } else throw Error('Something is wrong');
    })
    .catch((er) => {
      enqueueSnackbar(er, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      });
    });
};

export const deleteFormData = async ({ apiBody, id }) => {
  switch (apiBody.module) {
    case 'billing':
      await http('DELETE', `api/v1/billing/${apiBody.id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Deleted Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'batteries':
      await http('DELETE', `api/v1/battery/${apiBody.battery_id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Deleted Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'iot':
      console.log('ASdsad: ');
      await http('DELETE', `api/v1/iot/${apiBody.id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Deleted Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          console.log('ER IOT : ', er.message);
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'stations':
      await http('DELETE', `api/v1/station/${apiBody.station_id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Deleted Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    default:
      await http('DELETE', `api/v1/vehicle/${id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Deleted Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
  }
};

export const addFormData = async (apiBody) => {
  console.log('ADD FORM DATA: ', apiBody);
  switch (apiBody.module) {
    case 'end_ride':
      await http('POST', 'api/v1/trip/end-by-admin', {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'billing':
      delete apiBody.module;
      await http('POST', 'api/v1/billing', {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'geofencing_rules':
      delete apiBody.module;
      await http('POST', 'api/v1/geofencing_rule', {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'batteries':
      await http('POST', 'api/v1/battery', {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'geofencing':
      await http('POST', 'api/v1/geofencing', {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'iot':
      console.log('IOR API CALL');
      await http('POST', 'api/v1/iot', {
        apiData: apiBody,
      })
        .then((result) => {
          console.log('IOR API CALL: ', result);
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          console.log('IOR API CALL: ', er);
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'stations':
      await http('POST', 'api/v1/station', {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'service_staff':
      await http('POST', 'api/v1/service_staff', {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    default:
      await http('POST', 'api/v1/vehicle', {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Added Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
  }
};

export const editFormData = async ({ apiBody, id }) => {
  console.log('apiBody: ', apiBody, id);
  switch (apiBody.module) {
    case 'billing':
      await http('PATCH', `api/v1/billing/${apiBody.id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Edited Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'batteries':
      await http('PATCH', `api/v1/battery/${apiBody.battery_id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Edited Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'service_staff':
      await http('PATCH', `api/v1/service_staff/${apiBody.service_staff_id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Edited Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'iot':
      await http('PATCH', `api/v1/iot/${apiBody.id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Edited Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    case 'stations':
      await http('PATCH', `api/v1/station/${apiBody.station_id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Edited Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
      break;
    default:
      await http('PATCH', `api/v1/vehicle/${id}`, {
        apiData: apiBody,
      })
        .then((result) => {
          if (result) {
            enqueueSnackbar('Data Edited Successfully', {
              variant: 'success',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
              },
            });
          } else throw Error('Something is wrong');
        })
        .catch((er) => {
          enqueueSnackbar(er, {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        });
  }
};

export const fetchPageData = async (apiBody) => {
  let response;
  let data;

  switch (app) {
    default:
      response = await http('POST', 'api/v1/data', {
        apiData: apiBody,
      });
      data = response;
    // default:
    //   response = VehicleDatatable;
    //   data = response.data;
  }

  return data;
};
