import { all } from 'redux-saga/effects';
import UserSaga from './sagas/AuthSaga';
import { AssetSaga } from './assets/saga';
import IOTSaga from './iot/saga';
import { InspectionSaga } from './inspection/saga';
import TemplateDataSaga from './templateData/saga';
import GeneralDataSaga from './general/saga';

function* rootSaga() {
  yield all([
    UserSaga(),
    AssetSaga(),
    IOTSaga(),
    InspectionSaga(),
    TemplateDataSaga(),
    GeneralDataSaga()
  ]);
}

export default rootSaga;
