import Layout from "../../Layouts/Layout";
import BSVehicles from "./BSVehicles";
import GeneralVehicles from "./GeneralVehicles";

function Vehicles() {
  let app = sessionStorage.getItem('app');

  const renderComponent = () => {
    switch (app) {
      default:
        return <BSVehicles />;
    }
  };

  return <Layout>{renderComponent()}</Layout>;
}

export default Vehicles;
