import { Card, Col, Row } from 'react-bootstrap';
import styles from './DataTable.module.scss';
import {
  BasicDataTable,
  ResponsiveDataTable,
  DataTabless,
  ExportCSV,
} from '../../Data/TablesData/TableData';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  MarkerClusterer,
} from '@react-google-maps/api';
import { useState, useEffect, useMemo } from 'react';
import {
  activeOrdersMeta,
  allSimulationData,
  vehicleData,
} from '../../Data/DummyData';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { DashboardStatistics } from '../../Data/DummyData';
import { useInfoCtx } from '../../providers/InfoCardProvider';

let currentSimulationData = allSimulationData.filter((d) => d.start_time != '');

let simulation = [];
let activeInteral;

changeSimulationData();

function changeSimulationData() {
  simulation = currentSimulationData.map((d) => {
    let currentTime = new Date();
    currentTime = currentTime.getMinutes();
    let totalTime =
      parseInt(d.start_time) + parseInt(d.estimated_trip_duration);

    let status;
    if (currentTime >= totalTime) {
      status = 'completed';
    } else if (currentTime < d.start_time) {
      status = 'pending';
    } else {
      status = 'active';
    }
    d = { ...d, status: status };

    return d;
  });

  simulation = simulation.filter((d) => d.status == 'completed');
}

function CompletedDeliveries() {
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();

  const [stateValue, setStateValue] = useState();

  const [activeOrders, setActiveOrders] = useState(
    simulation.filter((data) => data.status == 'completed')
  );

  const columns = useMemo(() => {
    return computeColumns({
      data: simulation,
      meta: activeOrdersMeta,
      action: ({ row, meta }) => {
        setRowData(row);
        setColumnMetaData(meta);
        setModalStatus(true);
      },
    });
  }, []);

  const { renderInfoCardData } = useInfoCtx();
  const data = useMemo(() => simulation, [simulation]);

  let { isLoaded } = useJsApiLoader({
    id: 'drawing-manager-example',
    googleMapsApiKey: 'AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac',
    libraries: ['drawing'],
  });

  const [centerPosition, setCurrentPosition] = useState({
    lat: vehicleData[0].latitude,
    lng: vehicleData[0].longitude,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  const getCurrentActive = () => {
    changeSimulationData();
    setStateValue(new Date());
  };

  useEffect(() => {
    getCurrentActive();
    activeInteral = setInterval(() => getCurrentActive(), 10000);
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(activeInteral);
    };
  }, []);

  // console.log('simulation', simulation);
  return (
    <div className={styles.DataTable}>
      <PageHeader titles="Deliveries" active="Deliveries" items={['Tables']} />

      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            {DashboardStatistics.filter(
              (item) => item.module === 'completed_deliveries'
            ).map((card, index) => (
              <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                <Card className="overflow-hidden">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="mt-2">
                        <h6 className="">{card.heading}</h6>
                        <h2 className="mb-0 number-font">
                          {renderInfoCardData(card)}
                        </h2>
                      </div>
                      <div className="ms-auto">
                        <div className="chart-wrapper mt-1"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Completed Deliveries</h6>
                      <h2 className="mb-0 number-font">150</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Deliveries Reviewed</h6>
                      <h2 className="mb-0 number-font">130</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Rejected Deliveries</h6>
                      <h2 className="mb-0 number-font">80</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Delayed Deliveries</h6>
                      <h2 className="mb-0 number-font">80</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>
      {/* <!-- Row --> */}
      <Row className="row-sm">
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h3">Basic Datatable</Card.Title>
            </Card.Header>
            <Card.Body>
              <Datatable
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
              />
              {/* <BasicDataTable COLUMNS={CompletedDeliveriesData.COLUMNS} DATATABLE={simulation} /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <!-- End Row --> */}
    </div>
  );
}

export default CompletedDeliveries;
