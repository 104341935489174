import { createReducer } from '@reduxjs/toolkit';
import initialState from './init';
import { setCurrentMenuItem, setRDXModule } from './actions';

const CurrentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setRDXModule, (state, action) => {
      state.redux.module = action.payload;
    })
    .addCase(setCurrentMenuItem,(state,action)=>{
      // console.log("setCurrentMenuItem ACTION is dispached: ",action)
      state.menu=action.payload;
    })
    .addDefaultCase((state, action) => state);
});

export default CurrentReducer;
