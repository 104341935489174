import { Button, Card, Col, Row } from 'react-bootstrap';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { VehicleDatatable, WorkOrderDatatable } from '../../Data/DummyData';
import AssetLocationMap from '../Maps/AssetLocationMap';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RoutingMap from '../Maps/RoutingMap';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import { RouterMetaData } from '../../Data/WebsiteData/moby';
import {
  selectDatatable,
  selectFilteredData,
  selectModuleSpecificData,
  selectModuleSpecificRawData,
} from '../../redux/templateData/selectors';
import {
  addPage,
  fetchData,
  saveRoute,
  setFilteredData,
  setModule,
} from '../../redux/templateData/actions';
import { initialState } from '../../redux/templateData/init';
import _ from 'lodash';
import MapsWithMultipleRoute from '../Maps/MapWithMultipleRoute';
import { Grid } from '@mui/material';
import moment from 'moment';
// import { compute } from '../../Data/WebsiteData/Statistics/interpreter';

export const formatAmount = (amountToFillLiters) => {
  if (typeof amountToFillLiters === 'number') {
    return amountToFillLiters.toLocaleString('en-US');
  } else {
    return null;
  }
};

function WavSmartRouting() {
  const mapReference = useRef();
  const datatable = useSelector(selectDatatable);
  const [informationToDisplay, setInformationToView] = useState({});
  const [coordinatesOriginDestination, setCoordinatesOriginDestination] =
    useState([]);
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();
  const filteredData = useSelector(selectFilteredData);
  const dispatch = useDispatch();
  const module = 'containers';
  const stations = useSelector(selectModuleSpecificData('stations'));
  const data = useMemo(() => datatable.data, [datatable.data]);
  const [stationGroupBySustance, setStationGroupBySustance] = useState([]);
  const [historyModalStatus, setHistoryModalStatus] = useState(false);
  const [containerGroupBySustance, setContainerGroupBySustance] = useState([]);
  //we are setting the filterdData like
  const [filteredResult, setFilteredResult] = useState([]);
  const vehicleHisotory =
    useSelector(selectModuleSpecificData('containers_history')) || [];
  const containerHistoryRawData =
    useSelector(selectModuleSpecificRawData('containers_history')) || {};

  const columns2 = useMemo(
    () =>
      computeColumns({
        data: vehicleHisotory,
        meta: containerHistoryRawData?.datatable?.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [vehicleHisotory, containerHistoryRawData],
  );

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        }),
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  useEffect(() => {
    setStationGroupBySustance(
      _.groupBy(stations, ({ storage_type }) => storage_type.toLowerCase()),
    );
    setContainerGroupBySustance(
      _.groupBy(filteredData, ({ substance }) =>
        substance ? substance.toLowerCase() : '',
      ),
    );
  }, [filteredData]);

  const columns = useMemo(
    () =>
      computeColumns({
        data: datatable.data,
        meta: datatable.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [datatable.data],
  );

  const filterRoutingData = () => {
    setFilteredData(filteredData);
    setFilteredResult(filteredData);
  };

  const handleSearch = useCallback((event) => {
    // console.log(event.target.value);
  }, []);

  const estimatedRunoutDays = _.sumBy(filteredData, function (object) {
    return object.estimated_runout_days;
  });

  const amountToFillLiters = _.sumBy(filteredData, function (object) {
    return object.amount_to_fill_litres;
  });

  const remainingFuelLitres = _.sumBy(filteredData, function (object) {
    return object.remaining_fuel_litres;
  });

  const drawPaths = () => {
    let routes = [];
    let nearestStations = null;
    let nearestContainerToStation = null;
    Object.keys(containerGroupBySustance).map((substance) => {
      let waypoints = [];

      if (Object.keys(stationGroupBySustance).includes(substance)) {
        containerGroupBySustance[substance].map((container, index) => {
          nearestStations =
            stationGroupBySustance[container.substance.toLowerCase()][0]; //selecting first station as we don't have any other stations or nearest stasion

          if (index == 0) {
            nearestContainerToStation = container; // default considering fisrt station should be closer by default
          } else {
            if (container.latitude && container.longitude)
              waypoints.push({
                latitude: container.latitude,
                longitude: container.longitude,
              });
          }
          //here first need to find nearest stations to container and associate each container with station
          //group the container by stations
          //find nearest container need to cover first and other would be in waypoints

          // console.log('nearestStations:', nearestStations);

          // console.log('nearestContainerToStation:', nearestContainerToStation);
        });

        if (nearestStations)
          routes.push({
            station: nearestStations,
            waypoints: waypoints,
            origin: [nearestStations.latitude, nearestStations.longitude],
            destination: [
              nearestContainerToStation.latitude,
              nearestContainerToStation.longitude,
            ],
          });
      }
    });

    mapReference.current.coordinates = routes;

    console.log('routes: ', coordinatesOriginDestination);
    setCoordinatesOriginDestination(routes);
  };

  return (
    <div>
      <PageHeader titles="Routing" active="Routing" items={['Tables']} />
      <div id="map">
        {stations && (
          <MapsWithMultipleRoute
            ref={mapReference}
            coordinates={coordinatesOriginDestination}
            data={[...filteredData]}
            stations={[...stations]}
          />
        )}
      </div>
      <div className="row">
        <Col lg={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Total Containers:</h6>
                  <h2 className="mb-0 number-font"> {filteredData.length}</h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Estimated Runout Days:</h6>
                  <h2 className="mb-0 number-font">
                    {formatAmount(estimatedRunoutDays)}
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Amount To Fill Litres:</h6>
                  <h2 className="mb-0 number-font">
                    {formatAmount(amountToFillLiters)}
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Remaining Fuel Litres</h6>
                  <h2 className="mb-0 number-font">
                    {formatAmount(remainingFuelLitres)}
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12}>
          {/* <div style={{ marginBottom: '20px', textAlign: 'right' }}>
            <Button
              size={'sm'}
              variant="secondary"
              onClick={() => filterRoutingData()}
              // style={{ position: 'absolute', right: '3.3%', top: '7%' }}
            >
              Routing
            </Button>
          </div> */}
          <Card>
            <Card.Body>
              <Datatable
                addActionName="Routing"
                showextraActionbtn={coordinatesOriginDestination.length > 0}
                extraActionbtnName="Save"
                extraBtnAction={() => {
                  dispatch(
                    saveRoute({
                      container_ids: filteredData.map((item) => item.device_id),
                      total_containers: filteredData.length,
                      total_estimated_runout_days: estimatedRunoutDays,
                      total_amount_to_fill_litres: amountToFillLiters,
                      total_remaining_fuel_litres: remainingFuelLitres,
                      total_distance: _.sumBy(
                        mapReference.coordinates,
                        function (object) {
                          return object.distance;
                        },
                      ),
                      routes: mapReference.coordinates,
                      substance: [
                        ...new Set(filteredData.map((item) => item.substance)),
                      ].join(','), // This key (substance) was added for sending comma separated string values (unique), for example "LPG,Kerosene,RedDiesel"
                    }),
                  );
                }}
                addAction={() => {
                  drawPaths();
                  mapReference.current.createRoute();
                }}
                refreshAction={() => {
                  setCoordinatesOriginDestination([]);
                  mapReference.current.routesReset();
                  dispatch(fetchData({ module }));
                }}
                datatableTitle="Routing"
                sortable_key="vehicle_id"
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
                actions={[
                  { name: 'location', type: 'location', buttonType: 'icon' },
                  { name: 'history', type: 'history', buttonType: 'icon' },
                ]}
                onActionClick={(event, { type, data, trip_id }) => {
                  setInformationToView(data);
                  if (type == 'location') {
                    mapReference.current.routesReset();
                    mapReference.current.setCenter({
                      lat: data.values.latitude,
                      lng: data.values.longitude,
                    });
                    mapReference.current.setZoom(15);
                    document.getElementById('map').scrollIntoView();
                  } else if (type == 'history') {
                    dispatch(
                      addPage({
                        key: 'containers_history',
                        data: initialState.vehicles,
                      }),
                    );
                    dispatch(
                      fetchData(
                        {
                          module: 'containers_history',
                          device_id: data.values.device_id,
                        },
                        {
                          reUpdateSourceData: false,
                        },
                      ),
                    );
                    setHistoryModalStatus(true);
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </div>
      <Modal
        disableSave={true}
        fullWidth
        maxWidth="md"
        onClose={() => {
          const datatableId = 'container_history';
          const app = sessionStorage.getItem('app');

          localStorage.setItem(
            window.location.pathname + app + datatableId + '_filter',
            JSON.stringify([]),
          );
          localStorage.setItem(
            window.location.pathname + app + datatableId + '_sort',
            JSON.stringify([]),
          );
          localStorage.setItem(
            window.location.pathname + app + datatableId + '_pageIndex',
            0,
          );
          setHistoryModalStatus(false);
        }}
        modalHeading="Container History"
        status={historyModalStatus}
        onSave={() => {
          setHistoryModalStatus(false);
        }}
      >
        <div style={{ padding: '0px 40px' }}>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">ID</div>
                <div>{vehicleHisotory[0]?.id}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Device ID</div>
                <div>{vehicleHisotory[0]?.device_id}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">
                  API Trigger Level Percent
                </div>
                <div>{vehicleHisotory[0]?.api_trigger_level_percent}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Substance</div>
                <div>{vehicleHisotory[0]?.substance}</div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">
                  Amount To Fill Litres
                </div>
                <div>{vehicleHisotory[0]?.amount_to_fill_litres}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">
                  Remaining Fuel Litres
                </div>
                <div>{vehicleHisotory[0]?.remaining_fuel_litress || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Ullage</div>
                <div>{vehicleHisotory[0]?.ullage || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Raw Distance</div>
                <div>{vehicleHisotory[0]?.raw_distance || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Temprature</div>
                <div>{vehicleHisotory[0]?.temprature || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Pick Up Event</div>
                <div>{vehicleHisotory[0]?.pick_up_event || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Fill Level</div>
                <div>{vehicleHisotory[0]?.fill_level || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Fill Error</div>
                <div>{vehicleHisotory[0]?.fill_error || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">SNR</div>
                <div>{vehicleHisotory[0]?.snr || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">RSSI</div>
                <div>{vehicleHisotory[0]?.rssi || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Is Extended Uplink</div>
                <div>{vehicleHisotory[0]?.is_extended_uplink || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Type</div>
                <div>{vehicleHisotory[0]?.type || '-'}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Created At</div>
                <div>
                  {moment(vehicleHisotory[0]?.created_at).format(
                    'DD-MMM-YYYY HH:mm:ss',
                  ) || '-'}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Average Usage</div>
                <div>
                  {formatAmount(vehicleHisotory[0]?.average_usage || '-')}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div class="container-history-heading">Standard Deviation</div>
                <div>
                  {formatAmount(vehicleHisotory[0]?.std_deviation || '-')}
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div>
                <div class="container-history-heading">Last Contact</div>
                <div>{vehicleHisotory[0]?.last_contact.toLocaleString()}</div>
              </div>
            </Grid> */}
          </Grid>
        </div>
        <Grid style={{ marginTop: '20px' }} container>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            <Datatable
              hasFilterAffectsMap={false}
              datatableTitle="History"
              id="container_history"
              modalSize="xl"
              refreshAction={() => {
                let { values } = informationToDisplay;
                dispatch(
                  addPage({
                    key: 'containers_history',
                    data: initialState.vehicles,
                  }),
                );
                dispatch(
                  fetchData(
                    {
                      module: 'containers_history',
                      device_id: values.device_id,
                    },
                    {
                      reUpdateSourceData: false,
                    },
                  ),
                );
              }}
              isMultiSelect={false}
              columns={columns2}
              data={vehicleHisotory}
            />
          </Grid>
        </Grid>
      </Modal>

      {/* <!-- End Row --> */}
    </div>
  );
}

export default WavSmartRouting;
