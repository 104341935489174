import GeneralBillingModel from './GeneralBillingModel';
import BillingModel from './BillingModel';

function Routing() {
  let app = sessionStorage.getItem('app');

  const renderComponent = () => {
    switch (app) {
      default:
        return <BillingModel />;
      // default:
      //   return <GeneralBillingModel />;
    }
  };

  return renderComponent();
}

export default Routing;
