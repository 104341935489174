import { createAction } from '@reduxjs/toolkit';
import TYPES from './types';

export const setAuthUser = createAction(TYPES.SET_AUTH_USER);
export const setWebsites = createAction(TYPES.SET_WEBSITES);
export const setContainersData = createAction(TYPES.SET_CONTAINERS_DATA);
export const authenticateUser = createAction(TYPES.AUTHENTICATE_USER);
export const logoutUser = createAction(TYPES.LOGOUT_USER);
export const logout = createAction(TYPES.RESET_AUTH_STATE);

export const setPageStatus = createAction(TYPES.PAGE_LOADING_STATUS);
export const setTableModal = createAction(TYPES.TABLE_STATUS);
export const openWebsite = createAction(TYPES.WEBSITE);
export const setActiveWebsiteData = createAction(TYPES.SET_ACTIVE_WEBSITE);
export const addData = createAction(TYPES.ADD_DATA);
export const editData = createAction(TYPES.EDIT_DATA);
export const deleteData = createAction(TYPES.DELETE_DATA);

export const setModalStatus = createAction(TYPES.SET_MODAL_STATUS);
