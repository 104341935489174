import { PERMISSIONS } from '../../redux/redux_constants';

export const GENERAL_MENUITEMS = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    icon: 'fe fe-home',
    type: 'link',
    active: true, // Maintaining this active true to keep the sidebar manu dashboard selected when we visit the dashboard route
    selected: false,
    template: 'map_template',
    title: 'Dashboard',
    module: PERMISSIONS.SERVICE_OPERATOR,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    icon: 'fe fe-home',
    type: 'link',
    active: true,  // Maintaining this active true to keep the sidebar manu dashboard selected when we visit the dashboard route
    selected: false,
    title: 'Dashboard',
    module: PERMISSIONS.DELIVERY_DASHBOARD,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    icon: 'fe fe-home',
    type: 'link',
    active: true,  // Maintaining this active true to keep the sidebar menu dashboard selected when we visit the dashboard route
    selected: false,
    title: 'Dashboard',
    module: PERMISSIONS.WAV_SMART_DASHBOARD,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    icon: 'fe fe-home',
    type: 'link',
    active: true,
    selected: false,
    title: 'Dashboard',
    module: PERMISSIONS.OPERATOR_DASHBOARD,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    icon: 'fe fe-home',
    type: 'link',
    active: true,
    selected: false,
    title: 'Dashboard',
    module: PERMISSIONS.RIDESHARE_DASHBOARD, // This was added to display the Dashboard sidebar menu in other modules where we are receiving data "rideshare_dashboard"
  },
  {
    icon: 'fe fe-truck',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'Asset management',
    module: PERMISSIONS.ASSETS,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/vehicles`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Vehicles', 
        module: PERMISSIONS.VEHICLE,
      },
      {
        path: `${process.env.PUBLIC_URL}/containers`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Containers', 
        module: PERMISSIONS.CONTAINERS,
      },
      {
        path: `${process.env.PUBLIC_URL}/deliveries`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Delivery Vans',
        module: PERMISSIONS.DELIVERY_VANS,
      },
      {
        path: `${process.env.PUBLIC_URL}/battries`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Battery',
        module: PERMISSIONS.BATTERY,
      },
      {
        path: `${process.env.PUBLIC_URL}/dustbins`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Dustbins',
        module: PERMISSIONS.DUSTBINS,
      },
      {
        path: `${process.env.PUBLIC_URL}/solar-panels`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Solar Panels',
        module: PERMISSIONS.SOLAR_PANELS,
      },
      {
        path: `${process.env.PUBLIC_URL}/maintenance`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Service Staff',
        module: PERMISSIONS.SERVICE_STAFF,
      },
      {
        path: `${process.env.PUBLIC_URL}/stations`,
        type: 'link',
        active: false,
        selected: false,
        template: 'map-datatable',
        pagename: 'stations',
        title: 'Stations',
        module: PERMISSIONS.STATIONS,
      },
      {
        path: `${process.env.PUBLIC_URL}/riders`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Drivers',
        module: PERMISSIONS.RIDERS,
      },
    ],
  },
  {
    icon: 'fe fe-activity',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'Services',
    module: PERMISSIONS.SERVICES,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/services/inspection`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Pre Release Inspection',
        module: PERMISSIONS.SERVICE_INSPECTION,
      },
      {
        path: `${process.env.PUBLIC_URL}/services/service-reminder`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Service Reminder',
        module: PERMISSIONS.SERVICE_REMINDER,
      },
      {
        path: `${process.env.PUBLIC_URL}/services/operator-report`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Operator Report',
        module: PERMISSIONS.SERVICE_OPERATOR_REPORT,
      },
      {
        path: `${process.env.PUBLIC_URL}/services/issues`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Open Reported Issues',
        module: PERMISSIONS.SERVICE_ISSUES,
      },
      {
        path: `${process.env.PUBLIC_URL}/services/work-order`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Work Order',
        module: PERMISSIONS.WORK_ORDER,
      },
    ],
  },
  {
    icon: 'fe fe-slack',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'Deliveries',
    module: PERMISSIONS.DELIVERIES,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/orders/active`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Active Delivers',
        module: PERMISSIONS.ACTIVE_DELIVERIES,
      },
      {
        path: `${process.env.PUBLIC_URL}/orders/pending`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Pending Delivers',
        module: PERMISSIONS.PENDING_DELIVERIES,
      },
      {
        path: `${process.env.PUBLIC_URL}/orders/delivered`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Completed Delivers',
        module: PERMISSIONS.COMPLETED_DELIVERIES,
      },
    ],
  },
  // {
  //   module: PERMISSIONS.GEOFENCING,
  //   path: `${process.env.PUBLIC_URL}/geofencing/create-geofencing`,
  //   icon: 'fe fe-home',
  //   title: 'Geo-Fencing',
  //   type: 'link',
  //   Name: '',
  //   Names: '',
  //   active: false,
  //   selected: false,
    // children: [
    //   {
    //     path: `${process.env.PUBLIC_URL}/geofencing/create-geofencing`,
    //     type: 'link',
    //     active: false,
    //     selected: false,
    //     title: 'Add Fencing',
    //     module: PERMISSIONS.ADD_GEOFENCING,
    //   },
    //   {
    //     path: `${process.env.PUBLIC_URL}/geofencing/manage-geofencing`,
    //     type: 'link',
    //     active: false,
    //     selected: false,
    //     title: 'List Fencing',
    //     module: PERMISSIONS.LIST_GEOFENCING,
    //   },
    // ],
  // },
  // {
  //   module: PERMISSIONS.RULES,
  //   path: `${process.env.PUBLIC_URL}/rules`,
  //   icon: 'fe fe-home',
  //   title: 'Rules',
  //   type: 'link',
  //   Name: '',
  //   Names: '',
  //   active: false,
  //   selected: false,
  // },
  {
    icon: 'fe fe-home',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'Geo-Fencing',
    module: PERMISSIONS.GEOFENCING,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/geofencing/create-geofencing`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Geo-Fencing',
        module: PERMISSIONS.ADD_GEOFENCING,
      },
      {
        path: `${process.env.PUBLIC_URL}/geofencing/rules`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Rules',
        module: PERMISSIONS.RULES,
      },
      {
        path: `${process.env.PUBLIC_URL}/geofencing/rules-on-geofencing`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Rules On Geo-Fencing',
        module: PERMISSIONS.RULES_ON_GEOFENCING,
      },
      {
        path: `${process.env.PUBLIC_URL}/geofencing/rules-violation`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Rules Violation',
        module: PERMISSIONS.RULES_VIOLATION,
      }
    ],
  },
  {
    icon: 'fe fe-user',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'CRM',
    module: PERMISSIONS.CRM,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/customers`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Customers',
        module: PERMISSIONS.CRM_CUSTOMERS,
      },
      {
        path: `${process.env.PUBLIC_URL}/customer-cards`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Customer Cards',
        module: PERMISSIONS.CRM_CUSTOMER_CARDS,
      },
      {
        
        path: `${process.env.PUBLIC_URL}/customer-card-transaction`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Customer Card Transaction',
        module: PERMISSIONS.CUSTOMER_CARD_TRANSACTION,
      }
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/trips`,
    icon: 'fe fe-home',
    type: 'link',
    active: false,
    selected: false,
    title: 'Trips',
    module: PERMISSIONS.TRIPS,
  },
  {
    path: `${process.env.PUBLIC_URL}/iot`,
    icon: 'fe fe-home',
    type: 'link',
    active: false,
    selected: false,
    title: 'IOT',
    module: PERMISSIONS.IOT,
  },
  {
    path: `${process.env.PUBLIC_URL}/users`,
    icon: 'fe fe-home',
    type: 'link',
    active: false,
    selected: false,
    title: 'Admin Users',
    module: PERMISSIONS.ADMIN_USER,
  },
  {
    path: `${process.env.PUBLIC_URL}/reporting`,
    icon: 'fe fe-flag',
    type: 'link',
    active: false,
    selected: false,
    title: 'Reporting',
    module: PERMISSIONS.REPORTING,
  },
  {
    path: `${process.env.PUBLIC_URL}/routing`,
    icon: 'fe fe-compass',
    type: 'link',
    active: false,
    selected: false,
    title: 'Routing',
    module: PERMISSIONS.ROUTING,
  },
  {
    path: `${process.env.PUBLIC_URL}/chat`,
    icon: 'fe fe-message-square',
    type: 'link',
    active: false,
    selected: false,
    title: 'Chat',
    module: PERMISSIONS.CHAT,
  },
  {
    path: `${process.env.PUBLIC_URL}/contact`,
    icon: 'fe fe-phone-call',
    type: 'link',
    active: false,
    selected: false,
    title: 'Contact us',
    module: PERMISSIONS.CONTACT_US,
  },
  {
    icon: 'fe fe-dollar-sign',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'Billing',
    module: PERMISSIONS.BILLING,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/subscriptions`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Subscriptions',
        module: PERMISSIONS.SUBSCRIPTIONS,
      },
      {
        path: `${process.env.PUBLIC_URL}/billing-model`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Billing Model',
        module: PERMISSIONS.BILLING_MODEL,
      },
    ],
  },
  {
    icon: 'fe fe-wind',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'Business Insights',
    module: PERMISSIONS.BUSINESS_INSIGHTS,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/statistics`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Statistics',
        module: PERMISSIONS.STATISTICS,
      },
    ],
  },
  {
    icon: 'fe fe-wind',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'Settings',
    module: PERMISSIONS.SETTINGS,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/configuration`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Configuration',
        module: PERMISSIONS.TILE_CONFIGURATION,
      },
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/fleet-managers`,
    icon: 'fe fe-home',
    type: 'link',
    active: false,
    selected: false,
    title: 'Operator List',
    module: PERMISSIONS.OPERATOR_LIST,
  },
  {
    path: `${process.env.PUBLIC_URL}/operator-modules`,
    icon: 'fe fe-home',
    type: 'link',
    active: false,
    selected: false,
    title: 'Operator Modules',
    module: PERMISSIONS.OPERATOR_MODULES,
  },
  {
    path: `${process.env.PUBLIC_URL}/subscriptions`,
    icon: 'fe fe-home',
    type: 'link',
    active: false,
    selected: false,
    title: 'Operator Licenses',
    module: PERMISSIONS.OPERATOR_LICENSES,
  },
  // {
  //   icon: 'fe fe-truck',
  //   type: 'sub',
  //   Name: '',
  //   Names: '',
  //   active: false,
  //   selected: false,
  //   title: 'Asset management',
  //   module:PERMISSIONS.ASSETS,
  //   children: [
  //     {
  //       path: `${process.env.PUBLIC_URL}/vehicles`,
  //       type: 'link',
  //       active: false,
  //       selected: false,
  //       title: 'Vehicle',
  //     },
  //     {
  //       path: `${process.env.PUBLIC_URL}/battries`,
  //       type: 'link',
  //       active: false,
  //       selected: false,
  //       title: 'Battery',
  //     },
  //     {
  //       path: `${process.env.PUBLIC_URL}/riders`,
  //       type: 'link',
  //       active: false,
  //       selected: false,
  //       title: 'Drivers',
  //     },
  //     {
  //       path: `${process.env.PUBLIC_URL}/maintenance`,
  //       type: 'link',
  //       active: false,
  //       selected: false,
  //       title: 'Maintenance Staff',
  //     },
  //   ],
  // },
];

export const GENERAL_MENUITEMS_DYNAMIC = [
  {
    icon: 'fe fe-truck',
    type: 'sub',
    Name: '',
    Names: '',
    active: false,
    selected: false,
    title: 'Asset management',
    module: PERMISSIONS.ASSETS,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/vehicle`,
        type: 'link',
        active: false,
        selected: false,
        template: 'map-datatable',
        pagename: 'vehicles',
        title: 'Vehicle',
        module: PERMISSIONS.VEHICLE,
      },
      {
        path: `${process.env.PUBLIC_URL}/deliveries`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Delivery Vans',
        module: PERMISSIONS.DELIVERY_VANS,
      },
      {
        path: `${process.env.PUBLIC_URL}/battery`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Battery',
        template: 'map-datatable',
        pagename: 'batteries',
        module: PERMISSIONS.BATTERY,
      },
      {
        path: `${process.env.PUBLIC_URL}/dustbins`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Dustbins',
        module: PERMISSIONS.DUSTBINS,
      },
      {
        path: `${process.env.PUBLIC_URL}/solar-panels`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Solar Panels',
        module: PERMISSIONS.SOLAR_PANELS,
      },
      {
        path: `${process.env.PUBLIC_URL}/maintenance-staff`,
        type: 'link',
        active: false,
        selected: false,
        template: 'map-datatable',
        pagename: 'service_staff',
        title: 'Service Staff',
        module: PERMISSIONS.SERVICE_STAFF,
      },
      {
        path: `${process.env.PUBLIC_URL}/stations`,
        type: 'link',
        active: false,
        selected: false,
        template: 'map-datatable',
        pagename: 'stations',
        title: 'Stations',
        module: PERMISSIONS.STATIONS,
      },
      {
        path: `${process.env.PUBLIC_URL}/riders`,
        type: 'link',
        active: false,
        selected: false,
        title: 'Drivers',
        module: PERMISSIONS.RIDERS,
      },
    ],
  },
];
