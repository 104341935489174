import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  MarkerClusterer,
  InfoWindow,
} from '@react-google-maps/api';
import { Button } from 'react-bootstrap';
import { Assets } from '../../assets';
import { useSelector } from 'react-redux';
import { selectWebsiteData } from '../../redux/general/selectors';

const containerStyle = {
  width: '100%',
  height: '600px',
};

const MapsWithMultipleRoute = forwardRef(({ data, stations }, ref) => {
  const websiteData = useSelector(selectWebsiteData);
  const [popupInfo, setPopupInfo] = useState('');

  const [centerPosition, setCurrentPosition] = useState(
    localStorage.getItem(websiteData.name + 'map_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'map_data'))
      : {
          lat: parseFloat(websiteData.home_location_lat),
          lng: parseFloat(websiteData.home_location_long),
        }
  );

  const [zoom, setZoom] = useState(6);

  useImperativeHandle(ref, () => {
    return {
      coordinates: {},
      createRoute: drawRoute2,
      routesReset: routesReset,
      setCenter: setCurrentPosition,
      setZoom,
    };
  });

  const [map, setMap] = useState(null);
  const [displayDirectionObj] = useState([]);

  let { isLoaded } = useJsApiLoader({
    id: 'drawing-manager-example',
    googleMapsApiKey: 'AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac',
    libraries: ['drawing'],
  });

  const [markers, setMarkers] = useState([...data]);

  const routesReset = useCallback(() => {
    displayDirectionObj.map((displayDirection) => {
      displayDirection.setMap(null);
    });
  }, [displayDirectionObj]);

  const onLoad = useCallback(
    function callback(map) {
      console.log('Load Map');
      //Map Instance
      setMap(map);
    },
    [markers]
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const renderIcon = (data) => {
    if (data.type == 'container') return Assets.GreenTank;
    else if (data.type === 'pickup') return Assets.PickPoint;
  };

  const renderMarker = ({ data, index, cluster }) => {
    return (
      <MarkerF
        key={index}
        icon={renderIcon(data)}
        // label={{text: ' ', color:'black', fontSize: '18px'}}
        position={{
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude),
        }}
        onClick={() => setPopupInfo(data)}
        // clusterer={cluster}
      />
    );
  };

  const drawRoute = useCallback(() => {
    var start = new google.maps.LatLng(53.999366, -6.396571);
    var end = new google.maps.LatLng(53.997551144596116, -6.406638667506018);

    var display = new google.maps.DirectionsRenderer();
    var services = new google.maps.DirectionsService();

    var request = {
      origin: start,
      destination: end,
      travelMode: 'DRIVING',
    };

    services.route(request, function (result, status) {
      if (status == 'OK') {
        display.setDirections(result);
      }
    });

    display.setMap(map);
  }, [map]);

  const drawRoute2 = useCallback(() => {
    let routes = ref.current.coordinates;

    console.log('routes: ', routes);

    let directionsService = new google.maps.DirectionsService();

    let routesWithAdditionalProperties = routes.map(function (route, index) {
      let routeData = { ...route };
      let start = new google.maps.LatLng(route.origin[0], route.origin[1]);
      let waypoints = [];
      let end = new google.maps.LatLng(route.destination[0], [
        route.destination[1],
      ]);

      if (route.waypoints)
        waypoints = route.waypoints.map((waypoint) => {
          return {
            location: new google.maps.LatLng(
              waypoint.latitude,
              waypoint.longitude
            ),
            stopover: false,
          };
        });
      else waypoints = [];

      var request = {
        origin: start,
        destination: end,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoints,
        optimizeWaypoints: true,
      };

      var rendererOptions = {
        preserveViewport: true,
        suppressMarkers: true,
        routeIndex: index,
      };

      let directionsDisplay = new google.maps.DirectionsRenderer();

      directionsDisplay.setMap(map);
      directionsService.route(request, function (result, status) {
        console.log(
          'result:directionsService ',
          result.routes[0].legs[0].distance.value
        );
        routeData.distance = result.routes[0].legs[0].distance.value;
        if (status == google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(result);
        }
      });

      displayDirectionObj.push(directionsDisplay);

      return routeData;
    });

    ref.coordinates = routesWithAdditionalProperties;
    console.log('routes here:', routesWithAdditionalProperties);
  }, [map]);

  useEffect(() => {
    setMarkers([...data]);
  }, [data]);

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          id="marker-example"
          mapContainerStyle={containerStyle}
          zoom={zoom}
          center={centerPosition}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <div style={{ position: 'absolute', top: 12, right: 60 }}>
            <Button
              variant="secondary"
              onClick={() => {
                setCurrentPosition({
                  lat: parseFloat(52.660587),
                  lng: parseFloat(-8.627705),
                });
              }}
            >
              Home Location
            </Button>
          </div>
          <MarkerClusterer minimumClusterSize={2}>
            {(clusterer) =>
              markers.map((d, i) => {
                return renderMarker({
                  data: d,
                  index: i,
                  cluster: clusterer,
                });
              })
            }
          </MarkerClusterer>
          <MarkerClusterer minimumClusterSize={2}>
            {(clusterer) =>
              stations.map((d, i) => {
                return renderMarker({
                  data: d,
                  index: i,
                  cluster: clusterer,
                });
              })
            }
          </MarkerClusterer>
          {popupInfo && (
            <InfoWindow
              position={{
                lat: popupInfo?.lat || popupInfo?.latitude,
                lng: popupInfo?.long || popupInfo?.longitude,
              }}
              onCloseClick={() => setPopupInfo()}
            >
              <div>
                {Object.entries(popupInfo).map(([key, value], index) => {
                  const transformedKey = key.replace(/_/g, ' '); // Replace underscore with space
                  return (
                    <div key={index}>
                      <strong style={{ textTransform: 'capitalize' }}>
                        {transformedKey}:
                      </strong>{' '}
                      {value}
                      <br />
                    </div>
                  );
                })}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : null}
      <br />
      {/* <button onClick={drawRoute}>Draw Route</button>
      <button onClick={drawRoute2}>Draw Route2</button> */}
    </div>
  );
});

export default MapsWithMultipleRoute;
