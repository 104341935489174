import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import TYPES from '../types';
import { setIOTAssetData } from './actions';
import { VehicleIOTDatatable } from '../../Data/DummyData';
import { deviceUnlock } from './apis';

function* fetchIOTData() {
  yield put(setIOTAssetData(VehicleIOTDatatable));
}

function* unlockDevice({ payload }) {
  console.log('PAYLOAD: ', payload);
  yield call(deviceUnlock,payload);
}

function* IOTSaga() {
  yield takeLatest(TYPES.DEVICE_UNLOCK, unlockDevice);
  yield takeEvery(TYPES.FETCH_IOT_DATA, fetchIOTData);
}

export default IOTSaga;
