import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import {
  BarChart,
  AreaChart,
  LineChart,
} from '../../Data/DataChart/DataChartJs';

function Statistics() {
  return (
    <div>
      <PageHeader titles="Chart" active="Chart" items={['Apps']} />
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <LineChart />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <BarChart />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <BarChart />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <AreaChart />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Statistics;
