import { createReducer } from '@reduxjs/toolkit';
import { initialState } from './init';
import {
  addPage,
  setFilteredData,
  setFilteredDataFetchApi,
  setFilters,
  setLoadingState,
  setModule,
  setModuleData,
} from './actions';

const TemplateDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setModule, (state, action) => {
      state.module = action.payload;
    })
    .addCase(setModuleData, (state, action) => {
      state[action.payload.module].datatable.data = action.payload.data;
      state[action.payload.module].datatable.meta = action.payload.meta;
      state[action.payload.module].page_info = action.payload.page_info;
      // state[action.payload.module].statistics = action.payload.statistics;

      return state;
    })
    .addCase(setLoadingState, (state, action) => {
      state.loading = action.payload;
    })
    .addCase(setFilters, (state, action) => {
      state[state.module].datatable.filters = action.payload;
      return state;
    })
    .addCase(setFilteredData, (state, action) => {
      state[state.module].datatable.filteredData = action.payload;

      return state;
    })
    .addCase(setFilteredDataFetchApi, (state, action) => {
      let { data, page_name } = action.payload;
      state[page_name].datatable.filteredData = data;

      return state;
    })
    .addCase(addPage, (state, action) => {
      state = { ...state, [action.payload.key]: action.payload.data };
      return state;
    })
    .addDefaultCase((state, action) => state);
});

export default TemplateDataReducer;
