import { createSelector } from '@reduxjs/toolkit';

export const selectPageLoadingState = (state) =>
  state.general.page_loading_status;

export const selectWebsiteData = (state) => state.general.website;
export const selectPermissions = (state) => state.general.permissions;

export const selectFleetManagerPermissions=state=>state.general.fleet_manager_permissions;
export const selectOperatortypePermissions=state=>state.general.operator_type_permissions
export const selectTableStatus = (state) => state.general.table.status;
export const selectModalStatus = (state) => state.general.modalStatus;

export const selectWebsiteSubmodulePermissions = createSelector(
  selectWebsiteData,
  (websiteData) => websiteData.permissions
);

export const selectPermissionsOfAuthUser = createSelector(
  selectPermissions,
  selectWebsiteData,
  (state, websiteData) => {
    return state[websiteData.app_key];
  }
);

// export const selectGeneralApiData = ({ key }) => {
//   return (state) => state?.;
// };

export const selectGeneralApiData = ({ key }) => {
  return (state) => {
    return state?.general?.general_apis ? state?.general?.general_apis[key] : null;
  };
};
