import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IOT_INSTRUCTIONS, VehicleIOTDatatable } from '../../Data/DummyData';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { selectIOTAssetData } from '../../redux/iot/selector';
import { Grid, IconButton } from '@mui/material';
import {
  addPage,
  fetchContainersByFilter,
  fetchData,
  setModule,
} from '../../redux/templateData/actions';
import { initialState } from '../../redux/templateData/init';
import {
  selectDatatable,
  selectFilteredData,
  selectTemplateData,
} from '../../redux/templateData/selectors';
import { selectContainers } from '../../redux/auth/selectors';
import RoutingMap from '../Maps/RoutingMap';
import MapsWithMultipleRoute from '../Maps/MapWithMultipleRoute';
import Modal from '../../components/Modal';
import NearMeIcon from '@mui/icons-material/NearMe';
import { formatAmount } from '../Routing/WavSmartRouting';

// import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
// import styles from './DataTable.module.css';

function FilteredContainerHistory() {
  const mapReference = useRef();
  const module = 'container_filter_history';
  const [historyModalStatus, setHistoryModalStatus] = useState(false);
  const containersData = useSelector(selectContainers);
  const dispatch = useDispatch();
  const { stations } = useSelector(selectTemplateData);
  const [coordinatesOriginDestination, setCoordinatesOriginDestination] =
    useState([]);
  const datatable = useSelector(selectDatatable);
  const filteredResult = useSelector(selectFilteredData);
  const [openInstructionPanelStatus, setInstructionPanelStatus] =
    useState(false);
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();
  const [report, setReport] = useState({
    routes: [],
    total_routes: 0,
    total_estimated_runout_days: 0,
    total_amount_to_fill_litres: 0,
    total_remaining_fuel_litres: 0,
  });

  const columns = React.useMemo(
    () =>
      computeColumns({
        data: datatable.data,
        meta: datatable.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [datatable.data]
  );

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        })
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  const data = React.useMemo(() => datatable.data, [datatable.data]);

  return (
    <div
    // className={styles.DataTable}
    >
      <div id="map">
        {stations && (
          <MapsWithMultipleRoute
            ref={mapReference}
            coordinates={coordinatesOriginDestination}
            data={[...containersData]}
            stations={[...stations?.datatable?.data]}
          />
        )}
        {/* <RoutingMap
          filteredResult={containersData}
          filteredData={containersData}
          stations={stations?.datatable?.data}
          allVehicles={containersData}
        /> */}
      </div>

      <Offcanvas
        placement="end"
        name="end"
        show={openInstructionPanelStatus}
        onHide={() => setInstructionPanelStatus(false)}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Instructions</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {IOT_INSTRUCTIONS.map((instruction) => {
            return (
              <div
                style={{
                  marginBottom: '10px',
                  justifyContent: 'space-between',
                }}
                className="d-flex align-items-center"
              >
                <div>{instruction.name}</div>
                <div>
                  <button className="btn btn-secondary">Send</button>
                </div>
              </div>
            );
          })}
        </Offcanvas.Body>
      </Offcanvas>
      <PageHeader titles="Vehicles" active="Vehicles" items={['Tables']} />
      <div className="row">
        <Col lg={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Total Routes:</h6>
                  <h2 className="mb-0 number-font"> {report.total_routes}</h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Estimated Runout Days:</h6>
                  <h2 className="mb-0 number-font">
                    {formatAmount(report.total_estimated_runout_days)}
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Amount To Fill Litres:</h6>
                  <h2 className="mb-0 number-font">
                    {formatAmount(report.total_amount_to_fill_litres)}
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Remaining Fuel Litres</h6>
                  <h2 className="mb-0 number-font">
                    {formatAmount(report.total_remaining_fuel_litres)}
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
      <div className="row">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Datatable
                sortable_key="id"
                isMultiSelect={false}
                rowSelected={(selection) => {
                  // console.log(selection);
                }}
                actions={[
                  { buttonType: 'icon', type: 'location' },
                  { name: 'Edit', type: 'edit', buttonType: 'icon' },
                ]}
                datatableTitle="History"
                // modalHeading="Device History"
                modalStatus={status}
                // modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
                refreshAction={() => {
                  dispatch(fetchData({ module }));
                }}
                onActionClick={(event, { type, data }) => {
                  if (type == 'location') {
                    document.getElementById('map').scrollIntoView();
                    setCoordinatesOriginDestination(data.values.routes);
                    setReport({
                      ...data.values,
                      total_routes: data.values.routes.length,
                    });
                    mapReference.current.coordinates = data.values.routes;
                    mapReference.current.createRoute();
                    dispatch(
                      fetchContainersByFilter(data.values.container_ids)
                    );
                    // console.log('DATA: ', data.values);
                  } else if (type == 'edit') {
                    setHistoryModalStatus(true);
                    setReport({
                      ...data.values,
                      total_routes: data.values.routes.length,
                    });
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </div>
      <Modal
        disableSave={true}
        fullWidth
        maxWidth="sm"
        onClose={() => setHistoryModalStatus(false)}
        modalHeading="Routes"
        status={historyModalStatus}
        onSave={() => {
          setHistoryModalStatus(false);
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <th>Route</th>
                  <th>Distance (KM)</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {report.routes.map((route, index) => {
                    return (
                      <tr>
                        <td>Route- {index + 1}</td>
                        <td>{route.distance / 1000}</td>
                        <td>
                          <IconButton
                            onClick={() => {
                              mapReference.current.routesReset();
                              setCoordinatesOriginDestination([route]);
                              mapReference.current.coordinates = [route];
                              mapReference.current.createRoute();
                              setHistoryModalStatus(false);
                            }}
                          >
                            <NearMeIcon />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <Datatable
              modalSize="xl"
              isMultiSelect={false}
              columns={columns2}
              data={vehicleHisotory}
            /> */}
          </Grid>
        </Grid>
      </Modal>
      {/* <!-- End Row --> */}
    </div>
  );
}

export default FilteredContainerHistory;
