import { InspectionWithMetaData } from "../../Data/DummyData"
import http from "../../http"

export const fetchInspectionDataApi=()=>{
    switch(sessionStorage.getItem('app')){
        // case 'keego':
        //     return http('GET','data/keego/inspection.json',{
        //         baseURL:process.env.REACT_APP_PUBLIC_URL
        //     })
        default:
            return InspectionWithMetaData
    }
}