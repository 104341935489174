import { Button, Card, Col, Row } from 'react-bootstrap';
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  MarkerClusterer,
  DirectionsRenderer,
  InfoWindow,
} from '@react-google-maps/api';
import { useState, useEffect } from 'react';

import motorcycleRed from '../../assets/images/media/motorcycle_red.png';
import motorcycleGreen from '../../assets/images/media/motorcycle_green.png';
import motorcycleBlue from '../../assets/images/media/motorcycle_blue.png';

import scooterRed from '../../assets/images/media/scooter_red.png';
import scooterGreen from '../../assets/images/media/scooter_green.png';
import scooterBlue from '../../assets/images/media/scooter_blue.png';

import kg3Red from '../../assets/images/media/kg3_red.png';
import kg3Green from '../../assets/images/media/kg3_green.png';
import kg3Blue from '../../assets/images/media/kg3_blue.png';

import kg4Red from '../../assets/images/media/kg4_red.png';
import kg4Green from '../../assets/images/media/kg4_green.png';
import kg4Blue from '../../assets/images/media/kg4_blue.png';

import vanRed1 from '../../assets/images/media/van_red.png';
import vanGreen1 from '../../assets/images/media/van_green.png';
import vanBlue from '../../assets/images/media/van_blue.png';

import solarPanelRed from '../../assets/images/media/solar_panel_red.png';
import solarPanelGreen from '../../assets/images/media/solar_panel_green.png';

import binRed from '../../assets/images/media/bin_red.png';
import binGreen from '../../assets/images/media/bin_green.png';

import pick_point from '../../assets/images/media/pick_point.png';
import drop_point from '../../assets/images/media/drop_point.png';
import pick_drop_point from '../../assets/images/media/pick_drop_point.png';

import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectWebsiteData } from '../../redux/general/selectors';
import GreenTank from '../../assets/images/media/green-tank.png';

let directionsService;
let directionsDisplay;
var line = [];

let directions = [];
const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15,
};

// let currentSimulationData = [];
// let simulation = [];
let shortestRoute = [];
let mapref = null;
let totalDistance = 0;
let totalDuration = 0;

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4,
};

function RoutingMap(props) {
  let { isLoaded } = useJsApiLoader({
    id: 'drawing-manager-example',
    googleMapsApiKey: 'AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac',
    libraries: ['drawing'],
  });

  const [currentSimulationData, setCurrentSimulationData] = useState([
    ...props.filteredResult,
  ]);

  const websiteData = useSelector(selectWebsiteData);
  const [centerPosition, setCurrentPosition] = useState(
    localStorage.getItem(websiteData.name + 'map_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'map_data'))
      : {
          lat: parseFloat(websiteData.home_location_lat),
          lng: parseFloat(websiteData.home_location_long),
        }
  );
  const [zoomLevel, setZoomLevel] = useState(
    localStorage.getItem(websiteData.name + 'zoom_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'zoom_data'))
      : 12
  );
  const [routePoints, setRoutePoints] = useState([]);
  const [popupInfo, setPopupInfo] = useState('');
  let routePolyline = '';

  const getShortestPath = () => {
    directions = [];
    let dataFromSimulation = {};
    currentSimulationData.map((d) => {
      if (d?.lat || d?.latitude) {
        dataFromSimulation[d?.vehicle_id || d?.device_id || d?.station_id] = [
          parseFloat(d?.lat || d?.latitude),
          parseFloat(d?.long || d?.longitude),
        ];
      }
      return d;
    });

    let formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        project: 'efleety',
        format_type: 'json',
        model: 'tspv1',
        data: JSON.stringify({ ...dataFromSimulation }),
      })
    );

    axios({
      method: 'POST',
      url: 'https://sv.activ.space/api/v1/sv-infer-F',
      data: formData,
    }).then(function (response) {
      shortestRoute = response.data.data.route;
      console.log('nearest data aaaaa', shortestRoute);
      getDirections();
    });
  };

  useEffect(() => {
    if (isLoaded) {
      directions = [];
      totalDistance = 0;
      totalDuration = 0;

      if (line.length > 0) {
        directionsDisplay?.setDirections(null);
        for (let i = 0; i < line.length; i++) {
          line[i].setMap(null);
        }

        line = [];
      }

      getShortestPath();
    }
  }, [currentSimulationData, isLoaded]);

  useEffect(() => {
    setCurrentSimulationData([]);
    let nearestStation = findNearestStation(
      props.filteredResult,
      props.stations
    );

    if (props.filteredResult.length > 0) {
      if (nearestStation) {
        setCurrentSimulationData((prevData) => [
          nearestStation,
          ...props.filteredResult,
        ]);
      } else {
        setTimeout(
          () =>
            alert(
              'No nearest station found, please select container closer to a station.'
            ),
          2000
        );
      }
    }
  }, [props.filteredResult]);

  function findNearestStation(vehicleData, stationsData) {
    let nearestStation = null;
    let shortestDistance = Infinity;

    // Calculate the maximum distance between any two vehicles
    const maxVehicleDistance = getMaxVehicleDistance(vehicleData);

    // Check if the maximum vehicle distance is within the threshold
    if (maxVehicleDistance <= 100) {
      // Iterate through each vehicle
      for (let i = 0; i < vehicleData.length; i++) {
        const vehicle = vehicleData[i];

        // Calculate the distance between the vehicle and each station
        for (let j = 0; j < stationsData.length; j++) {
          const station = stationsData[j];
          const distance = calculateDistance(
            vehicle.lat || vehicle.latitude,
            vehicle.long || vehicle.longitude,
            station.latitude,
            station.longitude
          );
          // Check if the distance is within the 20km range
          if (distance <= 100) {
            // Update the nearest station if a shorter distance is found
            if (distance < shortestDistance) {
              shortestDistance = distance;
              nearestStation = station;
            }
          }
        }
      }
    }

    return nearestStation;
  }

  // Function to calculate the distance between two coordinates using the Haversine formula
  function calculateDistance(lat1, lon1, lat2, lon2) {
    const radius = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = radius * c;

    return distance;
  }

  function getMaxVehicleDistance(vehicleData) {
    let maxDistance = 0;

    for (let i = 0; i < vehicleData.length - 1; i++) {
      const vehicle1 = vehicleData[i];

      for (let j = i + 1; j < vehicleData.length; j++) {
        const vehicle2 = vehicleData[j];
        const distance = calculateDistance(
          vehicle1.lat,
          vehicle1.long,
          vehicle2.lat,
          vehicle2.long
        );

        if (distance > maxDistance) {
          maxDistance = distance;
        }
      }
    }

    return maxDistance;
  }

  const onMapLoad = (map) => {
    mapref = map;
    google.maps.event.addListener(map, 'dragend', function () {
      const newCenter = mapref.getCenter();
      localStorage.setItem(
        websiteData.name + 'map_data',
        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() })
      );
    });

    google.maps.event.addListener(map, 'zoom_changed', function () {
      const newCenter = mapref.getZoom();
      localStorage.setItem(
        websiteData.name + 'zoom_data',
        JSON.stringify(newCenter)
      );
    });

    directionsService = new google.maps.DirectionsService();
    directionsDisplay = new google.maps.DirectionsRenderer();
  };

  const convertData = (arr) => {
    const newArr = [];

    for (let i = 0; i < arr.length; i++) {
      // if (arr[i][0] == '0') {
      //   arr[i][0] = 'Airport';
      // }

      if (i === arr.length - 1) {
        // newArr.push([arr[i], arr[0]]);
      } else {
        newArr.push([arr[i], arr[i + 1]]);
      }
    }

    return newArr;
  };

  const getDirections = () => {
    let dataToMap = convertData(shortestRoute);
    directions = [];

    changeDirection(
      {
        lat: parseFloat(dataToMap[0][0][1]),
        lng: parseFloat(dataToMap[0][0][2]),
      },
      {
        lat: parseFloat(dataToMap[0][1][1]),
        lng: parseFloat(dataToMap[0][1][2]),
      },
      0,
      dataToMap
    );
  };

  //function that is calling the directions service
  const changeDirection = (origin, destination, id, dataToMap) => {
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: 'DRIVING',
        // preserveViewport: true
      },
      (result, status) => {
        if (status == 'OK') {
          directionsDisplay.setDirections(result);
          routePolyline = new google.maps.Polyline({
            path: result.routes[0].overview_path,
            strokeColor: '#0071ff',
            strokeOpacity: id == props.filteredResult.length ? 0 : 1.0,
            strokeWeight: 2,
            icons: [
              id == props.filteredResult.length
                ? {
                    icon: lineSymbol,
                    offset: '0',
                    repeat: '20px',
                  }
                : {},
            ],
          });

          line.push(routePolyline);
          routePolyline.setMap(mapref);

          let distance = result.routes.map((d) =>
            d.legs.reduce((partialSum, a) => partialSum + a.distance.value, 0)
          );
          distance = distance[0];
          totalDistance += distance;

          let duration = result.routes.map((d) =>
            d.legs.reduce((partialSum, a) => partialSum + a.duration.value, 0)
          );
          duration = duration[0];
          totalDuration += duration;

          //changing the state of directions to the result of direction service
          let data = directions;
          let routeData = result.routes[0].legs[0].steps.map((d) =>
            d.lat_lngs.map((la) => ({ lat: la.lat(), lng: la.lng() }))
          );

          routeData = routeData.concat.apply([], routeData);
          let routeValue = routePoints;

          routeValue.push({ id: id, path: routeData });
          setRoutePoints([...routeValue]);

          data.push({ ...result, id: id });
          directions = [...data];

          if (dataToMap[id + 1]) {
            setTimeout(
              () =>
                changeDirection(
                  {
                    lat: parseFloat(dataToMap[id + 1][0][1]),
                    lng: parseFloat(dataToMap[id + 1][0][2]),
                  },
                  {
                    lat: parseFloat(dataToMap[id + 1][1][1]),
                    lng: parseFloat(dataToMap[id + 1][1][2]),
                  },
                  id + 1,
                  dataToMap
                ),
              100
            );
          }
        } else {
          setTimeout(
            () =>
              changeDirection(
                {
                  lat: parseFloat(dataToMap[id][0][1]),
                  lng: parseFloat(dataToMap[id][0][2]),
                },
                {
                  lat: parseFloat(dataToMap[id][1][1]),
                  lng: parseFloat(dataToMap[id][1][2]),
                },
                id,
                dataToMap
              ),
            4000
          );
        }
      }
    );
  };

  useEffect(() => {
    return () => {
      totalDistance = 0;
      totalDuration = 0;
      if (line.length > 0) {
        directionsDisplay?.setDirections(null);
        // if we have an existing routePoly.length > 0 we unset it
        for (let i = 0; i < line.length; i++) {
          line[i].setMap(null); //or line[i].setVisible(false);
        }

        line = [];
      }
    };
  }, []);

  const markerIconData = (d) => {
    return d.type == 'container'
      ? GreenTank
      : d.station_id
      ? d.type == 'pickup'
        ? pick_point
        : d.type == 'dropoff'
        ? drop_point
        : pick_drop_point
      : websiteData.id == 'bombay_softwares'
      ? d.vehicle_type == 'Bike'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? motorcycleGreen
          : motorcycleRed
        : d.vehicle_type == 'Scooter'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? scooterGreen
          : scooterRed
        : d.vehicle_type == 'Delivery - KG3'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? kg3Green
          : kg3Red
        : d.vehicle_type == 'Delivery - KG4'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? kg4Green
          : kg4Red
        : websiteData.icon_type == 'scooter'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? scooterGreen
          : scooterRed
        : websiteData.icon_type == 'van'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? vanGreen1
          : vanRed1
        : websiteData.icon_type == 'solar'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? solarPanelGreen
          : solarPanelRed
        : websiteData.icon_type == 'bin'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? binGreen
          : binRed
        : d.motion_status == 'moving' && d.ignition == 'on'
        ? motorcycleGreen
        : motorcycleRed
      : d.vehicle_type == 'Bike'
      ? d.trip_status == 'inprogress'
        ? motorcycleBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? motorcycleGreen
        : motorcycleRed
      : d.vehicle_type == 'Scooter'
      ? d.trip_status == 'inprogress'
        ? scooterBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? scooterGreen
        : scooterRed
      : d.vehicle_type == 'Delivery - KG3'
      ? d.trip_status == 'inprogress'
        ? kg3Blue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? kg3Green
        : kg3Red
      : d.vehicle_type == 'Delivery - KG4'
      ? d.trip_status == 'inprogress'
        ? kg4Blue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? kg4Green
        : kg4Red
      : websiteData.icon_type == 'scooter'
      ? d.trip_status == 'inprogress'
        ? scooterBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? scooterGreen
        : scooterRed
      : websiteData.icon_type == 'van'
      ? d.trip_status == 'inprogress'
        ? vanBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? vanGreen1
        : vanRed1
      : websiteData.icon_type == 'solar'
      ? d.trip_status == 'inprogress'
        ? solarPanelGreen
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? solarPanelGreen
        : solarPanelRed
      : websiteData.icon_type == 'bin'
      ? d.trip_status == 'inprogress'
        ? binGreen
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? binGreen
        : binRed
      : d.trip_status == 'inprogress'
      ? motorcycleBlue
      : d.status == 'Locked' && d.current_status == 'Available for Service'
      ? motorcycleGreen
      : motorcycleRed;
  };

  return (
    <div>
      <div>
        <Row>
          <Col lg={12} md={12} sm={12} xl={12}>
            <Row>
              <Col lg={6} md={6} sm={12} xxl={3}>
                <Card className="overflow-hidden">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="mt-2">
                        <h6 className="">Total</h6>
                        <h2 className="mb-0 number-font">
                          {props.filteredResult.length}
                        </h2>
                      </div>
                      <div className="ms-auto">
                        <div className="chart-wrapper mt-1"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} md={6} sm={12} xxl={3}>
                <div className="card overflow-hidden">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="mt-2">
                        <h6 className="">Total Distance</h6>
                        <h2 className="mb-0 number-font">
                          {parseFloat(totalDistance / 1000).toFixed(2)}KM
                        </h2>
                      </div>
                      <div className="ms-auto">
                        <div className="chart-wrapper mt-1"></div>
                      </div>
                    </div>
                  </Card.Body>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xxl={3}>
                <Card className="overflow-hidden">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="mt-2">
                        <h6 className="">Total Duration</h6>
                        <h2 className="mb-0 number-font">
                          {parseFloat(totalDuration / 60).toFixed(2)}MIN
                        </h2>
                      </div>
                      <div className="ms-auto">
                        <div className="chart-wrapper mt-1"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          {isLoaded ? (
            <GoogleMap
              id="marker-example"
              mapContainerStyle={{
                width: '100%',
                height: '600px',
              }}
              zoom={zoomLevel}
              onLoad={(map) => onMapLoad(map)}
              center={centerPosition}
              onClick={(ev) => {
                setPopupInfo();
              }}
            >
              <div style={{ position: 'absolute', top: 12, right: 60 }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setCurrentPosition({
                      lat: parseFloat(websiteData.home_location_lat),
                      lng: parseFloat(websiteData.home_location_long),
                    });
                    localStorage.setItem(
                      websiteData.name + 'map_data',
                      JSON.stringify({
                        lat: parseFloat(websiteData.home_location_lat),
                        lng: parseFloat(websiteData.home_location_long),
                      })
                    );
                  }}
                >
                  Home Location
                </Button>
              </div>
              <MarkerClusterer
                minimumClusterSize={2}
                styles={[
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                    height: 55,
                    width: 55,
                    lineHeight: 58,
                    textColor: 'white',
                    textSize: '18',
                  },
                ]}
              >
                {(clusterer) => [
                  ...[...props.filteredData, ...(props?.stations || [])]
                    // .filter(
                    //   (d) =>
                    //     props.filteredResult.findIndex(
                    //       (fv) =>
                    //         fv.vehicle_id == d.vehicle_id || d.station_id
                    //     ) != -1
                    // )
                    .map((data, i) => {
                      return (
                        <MarkerF
                          key={data?.vehicle_id || data?.station_id}
                          label={{
                            text: ' ',
                            color: 'black',
                            fontSize: '18px',
                          }}
                          icon={markerIconData(data)}
                          position={{
                            lat: parseFloat(data?.lat || data?.latitude),
                            lng: parseFloat(data?.long || data?.longitude),
                          }}
                          onClick={() => setPopupInfo(data)}
                        />
                      );
                    }),
                ]}
              </MarkerClusterer>
              {popupInfo && (
                <InfoWindow
                  position={{
                    lat: popupInfo?.lat || popupInfo?.latitude,
                    lng: popupInfo?.long || popupInfo?.longitude,
                  }}
                  onCloseClick={() => setPopupInfo()}
                >
                  <div>
                    {Object.entries(popupInfo).map(([key, value], index) => {
                      const transformedKey = key.replace(/_/g, ' '); // Replace underscore with space
                      return (
                        <div key={index}>
                          <strong style={{ textTransform: 'capitalize' }}>
                            {transformedKey}:
                          </strong>{' '}
                          {value}
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          ) : null}
          <br />
        </Col>
      </Row>
    </div>
  );
}

export default RoutingMap;
