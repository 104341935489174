import { call, put, takeLatest } from 'redux-saga/effects';
import TYPES from '../types';
import { httpApiCall } from './api';
import { setResponse,setMapWaypointResponse } from './actions';

function* apiCall({ payload }) {
  let data = yield call(httpApiCall, payload);
  yield put(
    setResponse({
      key: payload.key,
      data,
    })
  );
}

function* apiCallMAPWaypoints({ payload }) {
  let data = yield call(httpApiCall, payload);
  yield put(
    setMapWaypointResponse({
      key: payload.key,
      data:data.route,
    })
  );
}

function* GeneralDataSaga() {
  yield takeLatest(TYPES.GENERAL_API_CALL, apiCall);
  yield takeLatest(TYPES.MAP_WAYPOINTS_API_CALL, apiCallMAPWaypoints);
}

export default GeneralDataSaga;
