import React, { useEffect, useMemo } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { polygonData } from '../../Data/DummyData';
import {useNavigate} from "react-router-dom";
import { GeofencingDatatable } from "../../Data/WebsiteData/moby";
import { useDatatableCtx } from "../../components/Datatable/DatatableContext";
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { useDispatch, useSelector } from "react-redux";
import { selectDatatable } from "../../redux/templateData/selectors";
import { initialState } from "../../redux/templateData/init";
import { addPage, fetchData, setModule } from "../../redux/templateData/actions";


function ManageGeofencing() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const datatable = useSelector(selectDatatable);
    const module = 'geofencing';
  
    const { setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx(); 

    const columns = useMemo(() =>
      computeColumns({
        data: datatable.data,
        meta: datatable.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      })
    );
    
    useEffect(() => {
      if (!initialState.hasOwnProperty(module)) {
        dispatch(
          addPage({
            key: module,
            data: initialState.vehicles,
          })
        );
      }
  
      dispatch(setModule(module));
      dispatch(fetchData({ module }));
    }, [module]);
  
    const data = useMemo(() => datatable.data, [datatable.data]);

    return <div>
    <Row className="row-sm">
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title as='h3'>My Geofencing</Card.Title>
          </Card.Header>
          <Card.Body>
            {/* <div className="table-responsive">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>No of vehicle</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {polygonData.map((d,i) => {
                        return(
                            <tr>
                                <td>{i+1}</td>
                                <td>{d.title}</td>
                                <td>{d.vehicle_list.length}</td>
                                <td>                    
                                    <Button size={"sm"} variant="primary" onClick={() => {history(`/geofencing/create-geofencing?id=${i}`)}}>View</Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
                </Table>
            </div> */}
            <Datatable
              columns={columns}
              data={data}
              actions={[
                { name: 'Open Geofencing', type: 'open_geofencing' },
              ]}
              onActionClick={(event, { type, data }) => {
                if (type == 'open_geofencing') {
                  history(`/geofencing/create-geofencing?id=${data.index}`)
                }
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </div>;
}

export default ManageGeofencing;
