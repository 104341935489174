import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useRowSelect } from 'react-table/dist/react-table.development';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { renderStyleSheet } from '../../css/styles';
import { selectWebsiteData } from '../../redux/general/selectors';
import { defaultMeta } from '../DummyData';
import moment from 'moment';
import { setFilteredData } from '../../redux/templateData/actions';

let hiddenColumns = [];
export const computeColumns = ({ data, meta, action }) => {
  let columns = [];

  if (data) {
    let keys = Object.keys(data[0] || []);

    columns = keys.map((value, index) => {
      let metaData = { ...defaultMeta, ...meta }[value];
      // console.log('metadata', metaData);
      if (metaData?.hide) {
        hiddenColumns.push(value);
      }

      return {
        id: value,
        Header: metaData?.header || value.replace(/_/g, ' '),
        isVisible: false,
        accessor: value,
        Cell: ({
          value,
          cell: {
            row: { original },
          },
        }) => {
          switch (metaData?.type) {
            case 'button':
              return (
                <div
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                  }}
                  onClick={() => {
                    action({ row: original, meta: metaData });
                  }}
                  href="#"
                >
                  {value + (metaData?.suffix || '')}
                </div>
              );

            case 'date':
              return (
                <div>
                  {moment(value).format(metaData?.date_format) +
                    (metaData?.suffix || '')}
                </div>
              );

            default:
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {metaData?.icons?.[value] && (
                    <img
                      alt={value}
                      src={metaData?.icons[value]}
                      style={{
                        marginRight: '8px',
                        height: '10px',
                        width: '10px',
                      }}
                    />
                  )}
                  {value + (metaData?.suffix || '')}
                </div>
              );
          }
        },
        renderFilter: ({ filterType, data, onChange }) => {
          let jsx = null;
          switch (filterType) {
            case 'select':
              const options = new Set();
              data.preFilteredRows.forEach((row) => {
                options.add(row.values[data.id]);
              });
              let optionData = [...options.values()];
              // const optionData = React.useMemo(() => {
              //   const options = new Set();
              //   data.preFilteredRows.forEach((row) => {
              //     options.add(row.values[data.id]);
              //   });
              //   return [...options.values()];
              // }, [data.id, data.preFilteredRows]);

              jsx = (
                <Form.Select
                  type="text"
                  placeholder="Type Here"
                  onChange={onChange}
                >
                  <option value="">Select</option>
                  {/* <option value="">Select-1</option>
                  <option value="">Select-2</option> */}
                  {optionData.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
              );
              break;
            default:
              jsx = (
                <Form.Control
                  type="text"
                  style={{ minWidth: '120px' }}
                  placeholder="Type Here"
                  onChange={onChange}
                />
              );
          }

          return data.canFilter && jsx;
        },
        disableFilters: metaData?.filters?.disableFilter,
        ...metaData,
      };
    });
  }
  // console.log('Columns----: ', hiddenColumns);
  return columns;
};

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <span className="d-flex ms-auto">
        <input
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          className="form-control mb-4"
          placeholder="Search..."
        />
      </span>
    </>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function TemplateDatatable({
  handleModalStatus,
  columns,
  data,
  modalStatus,
  filteredResult,
  onActionClick,
  rowSelected,
  modalSize,
  actions,
  isMultiSelect,
  modalHeading,
  modalContent,
  rdx_key,
}) {
  const websiteData = useSelector(selectWebsiteData);
  renderStyleSheet(websiteData);
  const [filterModalStatus, handleFilterModalStatus] = useState(false);
  const { row, columnMeta } = useDatatableCtx();
  const dispatch = useDispatch();

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setFilter,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    page,
    pageSize,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    toggleRowSelected,
    selectedFlatRows,
    ...useTableProps
  } = useTable(
    {
      columns,
      data,
      enableRowSelection: true,
      enableMultiRowSelection: true,
      filterTypes,
      initialState: {
        pageIndex: 0,
        hiddenColumns: hiddenColumns,
        selectedRowIds: {},
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    return () => {
      hiddenColumns = [];
    };
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      let debounceEffect = setTimeout(() => {
        dispatch(setFilteredData(page.map((data) => data.original)));
      }, 1000);

      return () => {
        clearTimeout(debounceEffect);
      };
    }
  }, [state.globalFilter, state.filters]);

  return (
    <>
      {/* {JSON.stringify(useTableProps)} */}
      <div className="e-table px-5 pb-5">
        <div className="d-block">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {/* {!!state.filters.length && (
                <button
                  onClick={() => setAllFilters([])}
                  style={{ height: '40px' }}
                  className="btn btn-secondary"
                >
                  Clear Filter
                </button>
              )}
              <button
                onClick={() => handleFilterModalStatus(true)}
                style={{ height: '40px', marginLeft: '10px' }}
                className="btn btn-secondary"
              >
                Filter
              </button> */}
            </div>
          </div>
          {/* <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          /> */}
        </div>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table stripe text-nowrap border-bottom"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {isMultiSelect && (
                    <th>
                      #
                      {/* <input type="checkbox" onChange={()=>{
                      alert("select All")
                    }} /> */}
                    </th>
                  )}
                  {headerGroup.headers.map((column, index2) => (
                    <th key={index2}>
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </div>
                      {column.canFilter ? (
                        <div style={{ marginTop: '10px' }}>
                          {column.renderFilter({
                            filterType: column?.filters?.filterType,
                            data: column,
                            onChange: (event) => {
                              setFilter(column.id, event.target.value);
                            },
                          })}
                          {/* <input
                            type="text"
                            onChange={(event) => {
                              setFilter(column.id, event.target.value);
                            }}
                            placeholder="Search Here"
                          /> */}
                          {/* <Filter column={header.column} table={table} /> */}
                        </div>
                      ) : null}
                    </th>
                  ))}
                  {!!actions.length && <th>Action </th>}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <tr key={i} {...row.getRowProps()}>
                    {isMultiSelect && (
                      <td>
                        <input
                          checked={row.isSelected}
                          type="checkbox"
                          onChange={(e) => {
                            toggleRowSelected(row.id, e.target.checked);
                            rowSelected(selectedFlatRows);
                          }}
                          // {...useTableProps.getToggleAllPageRowsSelectedProps()}
                        />
                      </td>
                    )}
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                    {!!actions.length && (
                      <td>
                        {actions.map((action) => {
                          return (
                            <Button
                              onClick={(event) =>
                                onActionClick(event, { type: action.type })
                              }
                              style={{ marginRight: '10px' }}
                              variant="secondary"
                              size="sm"
                            >
                              {action.name}
                            </Button>
                          );
                        })}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-block d-sm-flex mt-4 ">
        <span className="">
          Page{' '}
          <strong>
            {state.pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span className="ms-sm-auto">
          <Button
            variant=""
            className="btn-default tablebutton d-sm-inline d-block me-2 my-2"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {' Previous '}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-2"
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            {' << '}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-2"
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            {' < '}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-2"
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            {' > '}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-2"
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            {' >> '}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-2"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {' Next '}
          </Button>
        </span>
        <div style={{ marginTop: '8px' }}>
          <select
            className="table-border me-1"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Modal
        show={filterModalStatus}
        onHide={() => handleFilterModalStatus(false)}
        size={modalSize}
        aria-labelledby="react-modal contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Filters</Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => handleFilterModalStatus(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {/* {useTableProps.columns.map((column,index) => {
                let jsx = null;
                switch (column?.filters?.filterType) {
                  case 'select':
                    const options = React.useMemo(() => {
                      const options = new Set();
                      column.preFilteredRows.forEach((row) => {
                        options.add(row.values[column.id]);
                      });
                      return [...options.values()];
                    }, [column.id, column.preFilteredRows]);

                    jsx = (
                      <Col key={index} xs={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ textTransform: 'capitalize' }}>
                            {column.Header}
                          </Form.Label>
                          <Form.Select
                            type="text"
                            placeholder="Type Here"
                            onChange={(event) => {
                              setFilter(column.id, event.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            {options.map((option, i) => (
                              <option key={i} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    );
                    break;
                  default:
                    jsx = (
                      <Col key={index} xs={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ textTransform: 'capitalize' }}>
                            {column.Header}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type Here"
                            onChange={(event) => {
                              setFilter(column.id, event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    );
                }

                return column.canFilter && jsx;
              })} */}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => handleFilterModalStatus(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalStatus}
        onHide={() => handleModalStatus(false)}
        size={modalSize}
        aria-labelledby="react-modal contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalHeading ? modalHeading : 'Modal'}
          </Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => handleModalStatus(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>{modalContent(row, columnMeta)}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => handleModalStatus(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TemplateDatatable;

TemplateDatatable.defaultProps = {
  modalStatus: false,
  isMultiSelect: false,
  actions: [],
  modalSize: 'lg',
  columns: [],
  data: [],
  modalContent: function () {},
  filteredResult: function () {},
};
