import GeneralRules from './GeneralRules';
import Rules from './Rules';

function Routing() {
  let app = sessionStorage.getItem('app');

  const renderComponent = () => {
    switch (app) {
      case 'bombay_softwares':
        return <Rules />;
      default:
        return <Rules />;
    }
  };

  return renderComponent();
}

export default Routing;
