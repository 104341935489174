import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useRowSelect } from 'react-table/dist/react-table.development';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { renderStyleSheet } from '../../css/styles';
// import { setFilteredData } from '../../redux/assets/actions';
import { selectWebsiteData } from '../../redux/general/selectors';
import { defaultMeta } from '../WebsiteData/efleety';
import moment from 'moment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UnfoldMoreIcon from '@mui/icons-material/SwapVert';
import _ from 'lodash';
import { setFilteredData as setFilteredDataTemplate } from '../../redux/templateData/actions';
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import NearMeIcon from '@mui/icons-material/NearMe';
import 'moment-timezone';
import SendIcon from '@mui/icons-material/Send';
import LockIcon from '@mui/icons-material/Lock';
import { selectMetaInformation } from '../../redux/templateData/selectors';
import { IOT_META } from '../WebsiteData/moby';
import SettingsIcon from '@mui/icons-material/Settings';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import './datatable.css';
import Slider from '@mui/material/Slider';
import CustomModal from '../../components/Modal';
import { CSVLink, CSVDownload } from 'react-csv';
import { Assets } from '../../assets/images/images';

let hiddenColumns = [];
export const convertUTCToLocal = (utcDt, utcDtFormat) => {
  let zone_name = moment.tz.guess();
  var toDt = moment.utc(utcDt, utcDtFormat);
  let utcepoc = toDt.unix();
  let current_time = moment.unix(utcepoc).tz(zone_name);

  let diffInMinutes = moment().diff(current_time, 'minutes');

  return {
    difference: diffInMinutes,
    time: current_time.format('YYYY-MM-DD hh:mm:ss a'),
  };
};

export const computeColumns = ({ data, meta, action }) => {
  hiddenColumns = []; // Making this empty to insert new array of hidden columns
  let columns = [];
  // const convertUTCToLocal = (utcDt, utcDtFormat) => {
  //   const toDt = new Date(utcDt);
  //   const utcepoc = toDt.getTime();
  //   const current_time = new Date(utcepoc - toDt.getTimezoneOffset() * 60000).toLocaleString();

  //   const diffInMinutes = (new Date() - toDt) / (60 * 1000);

  //   return {
  //     difference: diffInMinutes,
  //     time: current_time,
  //   };
  // };

  if (data) {
    let keys = Object.keys(data[0] || []);
    let conversion = { difference: 0, time: '' };
    let styledData = '';

    columns = keys.map((value) => {
      let metaData = { ...defaultMeta, ...meta }[value];
      // console.log('metadata', metaData);
      if (metaData?.hide) {
        hiddenColumns.push(value);
      }

      return {
        id: value,
        Header: metaData?.header || value.replace(/_/g, ' '),
        isVisible: false,
        accessor: value,
        Cell: ({
          value,
          cell: {
            row: { original },
          },
        }) => {
          switch (metaData?.type) {
            case 'styled_on_battery_low':
              return (
                <div
                  style={{
                    color: value <= 30 ? 'red ' : 'green',
                    // maxWidth: '250px',
                    // whiteSpace: 'normal',
                    // wordWrap: 'break-word',
                  }}
                  href="#"
                >
                  {value ? value + (metaData?.suffix || '') : 'N/A'}
                </div>
              );
            case 'styled_lock_status':
              return (
                <div
                  style={{
                    color:
                      value == 'Locked'
                        ? 'red '
                        : value == 'unLocked'
                        ? 'green'
                        : '',
                    // maxWidth: '250px',
                    // whiteSpace: 'normal',
                    // wordWrap: 'break-word',
                  }}
                  href="#"
                >
                  {value ? value + (metaData?.suffix || '') : 'N/A'}
                </div>
              );
            case 'styled_on_update':
              conversion = convertUTCToLocal(original.updated_at);
              if (
                conversion.difference < 5 &&
                original.battery_level_percentage > 30
              ) {
                styledData = 'green';
              } else if (
                conversion.difference < 10 &&
                original.battery_level_percentage > 30
              ) {
                styledData = 'orange';
              } else styledData = 'red';

              return (
                <div
                  style={{
                    color: styledData,
                    // maxWidth: '250px',
                    // whiteSpace: 'normal',
                    // wordWrap: 'break-word',
                  }}
                  href="#"
                >
                  {/* {JSON.stringify(metaData.styling.color)} 
                  conversion.difference*/}
                  {value ? value + (metaData?.suffix || '') : 'N/A'}
                </div>
              );

            case 'button':
              return (
                <div
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    // maxWidth: '250px',
                    // whiteSpace: 'normal',
                    // wordWrap: 'break-word',
                  }}
                  onClick={() => {
                    action({ row: original, meta: metaData });
                  }}
                  href="#"
                >
                  {value ? value + (metaData?.suffix || '') : 'N/A'}
                </div>
              );

            case 'date':
              conversion = convertUTCToLocal(value);
              if (conversion.difference < 5) {
                styledData = 'green';
              } else if (conversion.difference < 10) {
                styledData = 'orange';
              } else styledData = 'red';

              return value ? (
                <div
                  style={
                    {
                      // maxWidth: '250px',
                      // whiteSpace: 'normal',
                      // wordWrap: 'break-word',
                    }
                  }
                >
                  {metaData?.icons?.[styledData] && (
                    <img
                      src={metaData.icons[styledData]}
                      style={{
                        marginRight: '8px',
                        height: '10px',
                        width: '10px',
                      }}
                    />
                  )}
                  {conversion.time + (metaData?.suffix || '')}
                </div>
              ) : (
                '-'
              );

            default:
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                    // maxWidth: '250px',
                    // whiteSpace: 'normal',
                    // wordWrap: 'break-word',
                  }}
                >
                  {metaData?.icons?.[value] && (
                    <img
                      alt={value}
                      src={metaData?.icons[value]}
                      style={{
                        marginRight: '8px',
                        height: '10px',
                        width: '10px',
                      }}
                    />
                  )}
                  {typeof value == 'boolean'
                    ? value == true
                      ? 'Yes'
                      : 'No'
                    : typeof value == 'number'
                    ? value + (metaData?.suffix || '')
                    : value
                    ? value + (metaData?.suffix || '')
                    : 'N/A'}
                </div>
              );
          }
        },
        renderFilter: ({
          filterType,
          data,
          onChange,
          value,
          defaultValue,
          handleRangeModal,
          handleRangeChange,
          rangefilterValue,
        }) => {
          let jsx = null;
          switch (filterType) {
            case 'select':
              const options = new Set();
              data.preFilteredRows.forEach((row) => {
                options.add(row.values[data.id]);
              });
              // console.log('META INFORMATION COLUMNS: ', metaData);
              let optionData = [...options.values()];
              // const optionData = React.useMemo(() => {
              //   const options = new Set();
              //   data.preFilteredRows.forEach((row) => {
              //     options.add(row.values[data.id]);
              //   });
              //   return [...options.values()];
              // }, [data.id, data.preFilteredRows]);

              jsx = (
                <Form.Select
                  type="text"
                  placeholder={`${_.capitalize(data.Header)}`}
                  onChange={onChange}
                  value={value}
                  defaultValue={defaultValue}
                >
                  <option value="">Select</option>
                  {/* <option value="">Select-1</option>
                  <option value="">Select-2</option> */}
                  {optionData.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
              );
              break;
            case 'range-slider':
              jsx = (
                <>
                  <Slider
                    value={rangefilterValue}
                    onChange={handleRangeChange}
                    valueLabelDisplay="auto"
                  />
                </>
              );
              // jsx = (
              //   <Form.Control
              //     readOnly
              //     onClick={(e) => {
              //       handleRangeModal(e);
              //     }}
              //     type="text"
              //     // value={JSON.stringify(rangefilterValue)}
              //     style={{ minWidth: '120px' }}
              //     placeholder={`${_.capitalize(data.Header)}`}
              //     // onChange={onChange}
              //     defaultValue={defaultValue}
              //   />
              // );
              break;
            default:
              jsx = (
                <Form.Control
                  type="text"
                  value={value}
                  style={{ minWidth: '50px' }}
                  placeholder={`${_.capitalize(data.Header)}`}
                  onChange={onChange}
                  defaultValue={defaultValue}
                />
              );
          }

          return data.canFilter && jsx;
        },
        disableFilters: metaData?.filters?.disableFilter,
        width: 50,
        ...metaData,
      };
    });
  }
  // console.log('Columns----: ', hiddenColumns);
  return columns;
};

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <span className="d-flex ms-auto">
        <input
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          className="form-control mb-4"
          placeholder="Search..."
        />
      </span>
    </>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function DataTable({
  handleModalStatus,
  datatableTitle,
  columns,
  data,
  module,
  id,
  modalStatus,
  refreshAction,
  addAction,
  addActionName,
  filteredResult,
  className,
  onActionClick,
  hasFilterAffectsMap,
  extraBtnAction,
  showextraActionbtn,
  extraActionbtnName,
  sortable_key,
  rowSelected,
  modalSize,
  actions,
  isMultiSelect,
  modalHeading,
  modalContent,
  rdx_key,
  ...props
}) {
  const appName = sessionStorage.getItem('app_key');
  const app = sessionStorage.getItem('app');
  const pathName = window.location.pathname.replace(/\//g, '');

  const websiteData = useSelector(selectWebsiteData);
  renderStyleSheet(websiteData);
  const [filterModalStatus, handleFilterModalStatus] = useState(false);
  const [rangeSliderValue, setValue] = React.useState([0, 100]);
  const [rangeSliderModal, setRangeSliderModal] = useState(false);
  const [csvData, setCsvData] = useState(null);

  const handleRangeSliderChange = (event, newValue) => {
    console.log('newValue: ', newValue);
    setValue(newValue);
    setFilter('percent_full', newValue);
  };

  const { row, columnMeta, heading } = useDatatableCtx();
  const dispatch = useDispatch();

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      'min-max': (rows, id, filterValue) => {
        const filteredArray = rows.filter((row) => {
          const rowValue = row.values[id[0]];
          return rowValue >= filterValue[0] && rowValue <= filterValue[1];
        });

        return filteredArray;
      },
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const datatableId = id ? id : '_id';
  // const oldFilters = localStorage.getItem(window.location.pathname+websiteData.id+'_filter')
  // const oldSorting = localStorage.getItem(window.location.pathname+websiteData.id+'_sort')
  const storedFilters =
    JSON.parse(
      localStorage.getItem(
        window.location.pathname + app + datatableId + '_filter'
      )
    ) || [];
  const storedSorting =
    JSON.parse(
      localStorage.getItem(
        window.location.pathname + app + datatableId + '_sort'
      )
    ) || [];
  const storedPageIndex =
    parseInt(
      localStorage.getItem(
        window.location.pathname + app + datatableId + '_pageIndex'
      )
    ) || 0;

  const storedData = JSON.parse(localStorage.getItem(appName)) || undefined;
  console.log(storedData, 'storedData', pathName);

  const [filters, setFilters] = useState(storedFilters);
  const [sorting, setSorting] = useState(storedSorting);
  const [pageIndex, setPageIndex] = useState(storedPageIndex);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setFilter,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setAllFilters,
    toggleRowSelected,
    selectedFlatRows,
    setPageSize,
    state: { filters: tableFilters, sortBy: tableSorting, pageSize },
    ...useTableProps
  } = useTable(
    {
      columns,
      data,
      enableRowSelection: true,
      enableMultiRowSelection: true,
      filterTypes,
      state: {
        pageSize:
          storedData && storedData[pathName] ? storedData[pathName] : 10,
      },
      initialState: {
        // sortBy: [
        //   {
        //     id: sortable_key,
        //     desc: false,
        //   },
        // ],
        pageIndex: pageIndex,
        hiddenColumns: hiddenColumns,
        selectedRowIds: {},
        filters,
        sortBy: sorting,
        pageSize:
          storedData && storedData[pathName] ? storedData[pathName] : 10,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  console.log('state++++++++++', storedFilters);

  // let appName = sessionStorage.getItem('app_key');
  // let pathName = window.location.pathname.replace(/\//g, '');

  const updateLocalStoragePageSize = (value) => {
    let existingData = JSON.parse(localStorage.getItem(appName)) || {};
    existingData[pathName] = value;
    localStorage.setItem(appName, JSON.stringify(existingData));
  };

  const headerWidths = {};

  useEffect(() => {
    if (pageIndex > pageOptions.length - 1) {
      setPageIndex(0);
    }
  }, [pageIndex, pageOptions]);

  useEffect(() => {
    // console.log('tableFilters: ', tableFilters);
    setFilters(tableFilters);
  }, [tableFilters]);

  useEffect(() => {
    setSorting(tableSorting);
  }, [tableSorting]);

  useEffect(() => {
    setPageIndex(state.pageIndex);
  }, [state.pageIndex]);

  useEffect(() => {
    console.log(
      'USEEFFECT: ',
      window.location.pathname + app + datatableId + '_filter'
    );

    localStorage.setItem(
      window.location.pathname + app + datatableId + '_filter',
      JSON.stringify(filters)
    );
    localStorage.setItem(
      window.location.pathname + app + datatableId + '_sort',
      JSON.stringify(sorting)
    );
    localStorage.setItem(
      window.location.pathname + app + datatableId + '_pageIndex',
      pageIndex
    );
  }, [filters, sorting, pageIndex]);

  useEffect(() => {
    console.log('useTableProps; ', useTableProps);
    return () => {
      hiddenColumns = [];
    };
  }, []);

  useEffect(() => {
    if (data.length > 0 && hasFilterAffectsMap == true) {
      console.log('hasFilterAffectsMap: ', data);
      let debounceEffect = setTimeout(() => {
        if (state.filters.length > 0)
          dispatch(setFilteredDataTemplate(page.map((data) => data.original)));
        else dispatch(setFilteredDataTemplate(data));
      }, 1000);

      return () => {
        clearTimeout(debounceEffect);
      };
    }
  }, [state.filters, data, hasFilterAffectsMap]);

  // useEffect(() => {
  //   const storedData = JSON.parse(localStorage.getItem(appName));
  //   if (storedData) {
  //     setPageSize(storedData[pathName]);
  //   } else {
  //     setPageSize(10);
  //   }
  // }, []);

  const clearFilter = () => {
    refreshAction();
    setFilters([]);
    setSorting([]);
    setPageIndex(0);
    localStorage.setItem(
      window.location.pathname + app + datatableId + '_filter',
      JSON.stringify([])
    );
    localStorage.setItem(
      window.location.pathname + app + datatableId + '_sort',
      JSON.stringify([])
    );
    localStorage.setItem(
      window.location.pathname + app + datatableId + '_pageIndex',
      0
    );
    setAllFilters([]);
    setPageIndex(0);
    setPageSize(10);
    setValue([0, 100]);
  };

  //this variable is used to change the background color of the icons likes history, edit etc
  const backgroundColor =
    appName === 'keego'
      ? '#0b96cebd'
      : appName === 'wav_smart'
      ? '#0ebb83c2'
      : appName === 'bombay_softwares'
      ? '#fecf39b5'
      : appName === 'gramobility'
      ? '#30873bcc'
      : appName === 'moby'
      ? '#c9fe00c7'
      : appName === 'diageo'
      ? '#a50034b5'
      : appName === 'biketek'
      ? '#1a1c28e0'
      : appName === 'volti'
      ? '#0063b0de'
      : appName === 'move_scooters'
      ? '#fecf39b5'
      : appName === 'ewaka'
      ? '#1a1c28e0'
      : 'rgba(211, 211, 211, 0.5)';

  const convertToCSV = () => {
    const csv = rows.map((row) => Object.values(row.values).join(','));
    const headerRow = Object.keys(rows[0].values).join(',');
    csv.unshift(headerRow);
    const csvContent = csv.join('\n');
    setCsvData(csvContent);
  };

  return (
    <>
      <Box className="main-datatable-container">
        {datatableTitle && (
          <Card.Header>
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              height="43px"
              justifyContent="space-between"
            >
              <Box>
                <Card.Title as="h3">{datatableTitle}</Card.Title>
              </Box>
              <Box style={{ display: 'flex' }}>
                <button
                  onClick={convertToCSV}
                  style={{ height: '40px', marginRight: '10px' }}
                  className="btn btn-secondary responsive-btn"
                >
                  <img
                    alt="icons"
                    style={{ height: '25px' }}
                    src={Assets.DownloadIcon}
                  />
                </button>

                {csvData && (
                  <CSVDownload
                    data={csvData}
                    filename={'table_data.csv'}
                    className="btn btn-primary"
                    target="_blank"
                  >
                    Download CSV
                  </CSVDownload>
                )}

                {!!state.filters.length && (
                  <button
                    onClick={() => clearFilter()}
                    style={{ height: '40px', marginRight: '10px' }}
                    className="btn btn-secondary responsive-btn"
                  >
                    Clear Filter
                  </button>
                )}
                {!!refreshAction && (
                  <>
                    <button
                      onClick={refreshAction}
                      style={{ height: '40px' }}
                      className="btn btn-secondary responsive-btn"
                    >
                      Refresh
                    </button>
                    <button
                      onClick={refreshAction}
                      style={{ height: '40px', fontSize: 10 }}
                      className="btn btn-secondary responsive-btn-icon"
                    >
                      <RefreshIcon />
                    </button>
                  </>
                )}
                {!!addAction && (
                  <>
                    <button
                      onClick={addAction}
                      style={{ height: '40px', marginLeft: '10px' }}
                      className="btn btn-secondary responsive-btn"
                    >
                      {addActionName}
                    </button>
                    <button
                      onClick={addAction}
                      style={{
                        height: '40px',
                        fontSize: 10,
                        marginLeft: '10px',
                      }}
                      className="btn btn-secondary responsive-btn-icon"
                    >
                      <AddIcon />
                    </button>
                  </>
                )}
                {!!extraBtnAction && showextraActionbtn && (
                  <>
                    <button
                      onClick={extraBtnAction}
                      style={{ height: '40px', marginLeft: '10px' }}
                      className="btn btn-secondary responsive-btn"
                    >
                      {extraActionbtnName}
                    </button>
                    <button
                      onClick={extraBtnAction}
                      style={{
                        height: '40px',
                        fontSize: 10,
                        marginLeft: '10px',
                      }}
                      className="btn btn-secondary responsive-btn-icon"
                    >
                      <AddIcon />
                    </button>
                  </>
                )}
              </Box>
            </Box>
          </Card.Header>
        )}
        <div
          style={{ marginTop: '20px', maxHeight: '500px', overflowY: 'auto' }}
          className="e-table px-5 pb-5"
        >
          {/*    <div className="d-block">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
             <button
              onClick={() => handleFilterModalStatus(true)}
              style={{ height: '40px', marginLeft: '10px' }}
              className="btn btn-secondary"
            >
              Filter
            </button> 
          </div>
      <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          /> 
        </div> */}
          <div>
            {/* <RoutingMap
            filteredResult={filteredResult}
            filteredData={filteredData}
            stations={stations}
            allVehicles={data}
          /> */}
          </div>
          <div
            className={`${className}`}
            // className={`table-responsive  ${className}`}
          >
            <table
              {...getTableProps()}
              className="table stripe text-nowrap border-bottom"
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {isMultiSelect && (
                      <th>
                        #
                        {/* <input type="checkbox" onChange={()=>{
                      alert("select All")
                    }} /> */}
                      </th>
                    )}
                    {headerGroup.headers.map((column, index2) => {
                      headerWidths[column.Header] = column.width;
                      const width = headerWidths[column.Header]
                        ? `${headerWidths[column.Header]}px`
                        : '';

                      return (
                        <th
                          key={index2}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            minWidth: width,
                            overflowWrap: 'break-word',
                          }}
                        >
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render('Header')}
                            <span>
                              &nbsp;
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowDropUpIcon />
                                )
                              ) : (
                                <>
                                  <UnfoldMoreIcon />
                                </>
                              )}
                            </span>
                          </div>
                          {column.canFilter ? (
                            <div
                              style={{
                                marginTop: '10px',
                                width: '100%',
                              }}
                            >
                              {column.renderFilter({
                                filterType: column?.filters?.filterType,
                                data: column,
                                onChange: (event) => {
                                  if (event.target.value)
                                    setFilter(column.id, event.target.value);
                                  else setFilter(column.id, null);
                                },
                                defaultValue:
                                  filters.find((f) => f.id === column.id)
                                    ?.value || '',
                                value:
                                  filters.find((f) => f.id === column.id)
                                    ?.value || '',
                                rangefilterValue: rangeSliderValue,
                                handleRangeChange: (e, newValue) => {
                                  console.log('newValue: ', newValue);
                                  setValue(newValue);
                                  setFilter('percent_full', newValue);
                                  // setRangeSliderModal(true);
                                },
                                handleRangeModal: () => {
                                  setRangeSliderModal(true);
                                },
                              })}
                            </div>
                          ) : null}
                        </th>
                      );
                    })}
                    {!!actions.length && (
                      <th
                        style={{
                          position: 'sticky',
                          right: '-34px',
                          background: 'white',
                        }}
                      >
                        Action
                      </th>
                    )}
                    :
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);

                  return (
                    <tr key={i} {...row.getRowProps()}>
                      {isMultiSelect && (
                        <td>
                          <input
                            checked={row.isSelected}
                            type="checkbox"
                            onChange={(e) => {
                              toggleRowSelected(row.id, e.target.checked);
                              rowSelected(selectedFlatRows);
                            }}
                            // {...useTableProps.getToggleAllPageRowsSelectedProps()}
                          />
                        </td>
                      )}
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps()}
                            style={{ wordBreak: 'break-all', padding: '10px' }}
                          >
                            {/* {IOT_META[headerGroups[0].headers[index].id] &&
                            console.log(
                              cell.row.original,
                              row,
                              data,
                              IOT_META[headerGroups[0].headers[index].id].textColorStyling?.color
                            )} */}
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                      {!!actions.length && (
                        <td
                          style={{
                            position: 'sticky',
                            background: 'white',
                            right: '-34px',
                          }}
                        >
                          <div
                            style={{
                              // position: 'absolute',
                              left: 0,
                              width: '100%',
                              top: 0,
                              height: '100%',
                              background: backgroundColor,
                              display: 'flex',
                              alignItems: 'flex-end',
                              justifyContent: 'center',
                            }}
                          >
                            {actions.map((action, index) => {
                              if (action.buttonType == 'icon') {
                                return (
                                  <IconButton
                                    key={index}
                                    onClick={(event) =>
                                      onActionClick(event, {
                                        type: action.type,
                                        data: row,
                                      })
                                    }
                                    style={{
                                      marginRight: '10px',
                                      // backgroundColor: backgroundColor,
                                    }}
                                    variant="secondary"
                                    size="sm"
                                  >
                                    {action.type == 'edit' ? (
                                      <EditIcon style={{ color: 'white' }} />
                                    ) : action.type == 'history' ? (
                                      <HistoryIcon style={{ color: 'white' }} />
                                    ) : action.type == 'send_instruction' ? (
                                      <SendIcon style={{ color: 'white' }} />
                                    ) : action.type == 'lock' ? (
                                      <LockIcon style={{ color: 'white' }} />
                                    ) : action.type == 'location' ? (
                                      <NearMeIcon style={{ color: 'white' }} />
                                    ) : action.type == 'end_ride' ? (
                                      <SettingsIcon
                                        style={{ color: 'white' }}
                                      />
                                    ) : (
                                      <DeleteIcon style={{ color: 'white' }} />
                                    )}
                                  </IconButton>
                                );
                              } else
                                return (
                                  <Button
                                    key={index}
                                    onClick={(event) =>
                                      onActionClick(event, {
                                        type: action.type,
                                        data: row,
                                      })
                                    }
                                    style={{ marginRight: '10px' }}
                                    variant="secondary"
                                    size="sm"
                                  >
                                    {action.name}
                                  </Button>
                                );
                            })}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-block d-sm-flex mt-4 ">
          <div>
            <span className="">
              Page{' '}
              <strong>
                {state.pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>

            <div className="">
              Total Records:
              <strong>{data.length}</strong>{' '}
            </div>
          </div>
          <div className="ms-sm-auto">
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-2"
              onClick={() => {
                gotoPage(0);
              }}
              disabled={!canPreviousPage}
            >
              {' << '}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-2"
              onClick={() => {
                previousPage();
              }}
              disabled={!canPreviousPage}
            >
              {' < '}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-2"
              onClick={() => {
                nextPage();
              }}
              disabled={!canNextPage}
            >
              {' > '}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-2"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {' >> '}
            </Button>
            {/* <Button
            variant=""
            className="btn-default tablebutton me-2 my-2"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {' Next '}
          </Button> */}
          </div>
          <div style={{ marginTop: '8px' }}>
            {/* {console.log(pageSize, 'pageSize')} */}
            <select
              className="table-border me-1"
              value={pageSize}
              onChange={(e) => {
                const selectedPageSize = Number(e.target.value);
                setPageSize(selectedPageSize);
                updateLocalStoragePageSize(selectedPageSize);
              }}
            >
              {[10, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Box>
      {/* <Modal
        show={filterModalStatus}
        onHide={() => handleFilterModalStatus(false)}
        size={modalSize}
        aria-labelledby="react-modal contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Filters</Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => handleFilterModalStatus(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {useTableProps.columns.map((column,index) => {
                let jsx = null;
                switch (column?.filters?.filterType) {
                  case 'select':
                    const options = React.useMemo(() => {
                      const options = new Set();
                      column.preFilteredRows.forEach((row) => {
                        options.add(row.values[column.id]);
                      });
                      return [...options.values()];
                    }, [column.id, column.preFilteredRows]);

                    jsx = (
                      <Col key={index} xs={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ textTransform: 'capitalize' }}>
                            {column.Header}
                          </Form.Label>
                          <Form.Select
                            type="text"
                            placeholder="Type Here"
                            onChange={(event) => {
                              setFilter(column.id, event.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            {options.map((option, i) => (
                              <option key={i} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    );
                    break;
                  default:
                    jsx = (
                      <Col key={index} xs={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ textTransform: 'capitalize' }}>
                            {column.Header}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type Here"
                            onChange={(event) => {
                              setFilter(column.id, event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    );
                }

                return column.canFilter && jsx;
              })}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => handleFilterModalStatus(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={modalStatus}
        onHide={() => handleModalStatus(false)}
        size={modalSize}
        aria-labelledby="react-modal contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {heading}
            {/* {modalHeading !== '' ? modalHeading : 'Details'} */}
          </Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => handleModalStatus(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>{modalContent(row, columnMeta)}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => handleModalStatus(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <CustomModal
        status={rangeSliderModal}
        onHide={() => setRangeSliderModal(false)}
        maxWidth={'xs'}
        onClose={() => {
          setRangeSliderModal(false);
        }}
        onSave={() => {
          setRangeSliderModal(false);
        }}
        modalHeading="Select Range"
        aria-labelledby="react-modal contained-modal-title-vcenter"
        centered
      >
        <Box>
          Selected Range is {rangeSliderValue[0]}-{rangeSliderValue[1]}
        </Box>
        <Slider
          value={rangeSliderValue}
          onChange={handleRangeSliderChange}
          valueLabelDisplay="auto"
        />
        <Box display="flex">
          <Box fontWeight={'bold'}>Total Containers: &nbsp;&nbsp;&nbsp;</Box>
          <Box>{rows.length}</Box>
        </Box>
        <Box display="flex">
          <Box fontWeight={'bold'}>
            Estimated Runout Days: &nbsp;&nbsp;&nbsp;
          </Box>
          <Box>
            {_.sumBy(
              rows.map((row) => row.values),
              function (object) {
                return object.estimated_runout_days;
              }
            )}
          </Box>
        </Box>
        <Box display="flex">
          <Box fontWeight={'bold'}>
            Amount To Fill Litres:&nbsp;&nbsp;&nbsp;{' '}
          </Box>
          <Box>
            {' '}
            {_.sumBy(
              rows.map((row) => row.values),
              function (object) {
                return object.amount_to_fill_litres;
              }
            )}
          </Box>
        </Box>
        <Box display="flex">
          <Box fontWeight={'bold'}>
            Remaining Fuel Litres:&nbsp;&nbsp;&nbsp;{' '}
          </Box>
          <Box>
            {' '}
            {_.sumBy(
              rows.map((row) => row.values),
              function (object) {
                return object.remaining_fuel_litres;
              }
            )}
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}

export default DataTable;

DataTable.defaultProps = {
  modalStatus: false,
  datatableTitle: '',
  hasFilterAffectsMap: true,
  modalHeading: '',
  isMultiSelect: false,
  actions: [],
  modalSize: 'lg',
  columns: [],
  data: [],
  modalContent: function () {},
  refreshAction: function () {},
  filteredResult: function () {},
};
