import Layout from "../../Layouts/Layout";
import Customers from "./Customers";
import GeneralCustomers from "./GeneralCustomers";

function Vehicles() {
  let app = sessionStorage.getItem('app');

  const renderComponent = () => {
    switch (app) {
      default:
        return <Customers />;
      // default:
      //   return <GeneralCustomers />;
    }
  };

  return renderComponent();
}

export default Vehicles;
