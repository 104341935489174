import React, { useEffect, useMemo } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { useDispatch, useSelector } from 'react-redux';
import { selectDatatable } from '../../redux/templateData/selectors';
import { initialState } from '../../redux/templateData/init';
import {
  addPage,
  fetchData,
  setModule,
} from '../../redux/templateData/actions';

function RulesViolation() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const datatable = useSelector(selectDatatable);
  const module = 'geofencing_rules_violation';

  const { setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx();

  const columns = useMemo(() =>
    computeColumns({
      data: datatable.data,
      meta: datatable.meta,
      action: ({ row, meta }) => {
        setRowData(row);
        setColumnMetaData(meta);
        setModalStatus(true);
      },
    })
  );

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        })
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  const data = useMemo(() => datatable.data, [datatable.data]);

  return (
    <div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Datatable
                refreshAction={() => {
                  dispatch(fetchData({ module }));
                }}
                datatableTitle="Rules Violation"
                sortable_key="id"
                columns={columns}
                data={data}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default RulesViolation;
