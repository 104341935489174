import { Button, Card, Col, Row } from 'react-bootstrap';

import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  MarkerClusterer,
  DirectionsRenderer,
  InfoWindow,
} from '@react-google-maps/api';
import { useState, useEffect } from 'react';
import {
  ActiveData,
  allSimulationData,
  batteryDatatable,
  VehicleDatatable,
  vehicleLocation,
} from '../../Data/DummyData';
// import { activeDeliveries } from '../../Data/DummyData';
import homeIcon from '../../assets/images/media/endMarker.png';
import pickUpIcon from '../../assets/images/media/pickupMarker.png';
// import scooter from '../../assets/images/media/scooter.png';
// import van from '../../assets/images/media/van.png';
// import scooterRed from '../../assets/images/media/scooter_red.png';
// import vanRed from '../../assets/images/media/van_red.png';
import { selectWebsiteData } from '../../redux/general/selectors';
import { useSelector } from 'react-redux';

import motorcycleRed from '../../assets/images/media/motorcycle_red.png';
import motorcycleGreen from '../../assets/images/media/motorcycle_green.png';
import motorcycleBlue from '../../assets/images/media/motorcycle_blue.png';

import scooterRed from '../../assets/images/media/scooter_red.png';
import scooterGreen from '../../assets/images/media/scooter_green.png';
import scooterBlue from '../../assets/images/media/scooter_blue.png';

import kg3Red from '../../assets/images/media/kg3_red.png';
import kg3Green from '../../assets/images/media/kg3_green.png';
import kg3Blue from '../../assets/images/media/kg3_blue.png';

import kg4Red from '../../assets/images/media/kg4_red.png';
import kg4Green from '../../assets/images/media/kg4_green.png';
import kg4Blue from '../../assets/images/media/kg4_blue.png';

import vanRed1 from '../../assets/images/media/van_red.png';
import vanGreen1 from '../../assets/images/media/van_green.png';
import vanBlue from '../../assets/images/media/van_blue.png';

import solarPanelRed from '../../assets/images/media/solar_panel_red.png';
import solarPanelGreen from '../../assets/images/media/solar_panel_green.png';

import binRed from '../../assets/images/media/bin_red.png';
import binGreen from '../../assets/images/media/bin_green.png';

import pick_point from '../../assets/images/media/pick_point.png';
import drop_point from '../../assets/images/media/drop_point.png';
import pick_drop_point from '../../assets/images/media/pick_drop_point.png';

import GreenTank from '../../assets/images/media/green-tank.png';

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15,
};

let mapref = null;
function AssetLocationMapNew(props) {
  let { isLoaded } = useJsApiLoader({
    id: 'drawing-manager-example',
    googleMapsApiKey: 'AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac',
    libraries: ['drawing'],
  });

  const websiteData = useSelector(selectWebsiteData);

  const [centerPosition, setCurrentPosition] = useState(
    localStorage.getItem(websiteData.name + 'map_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'map_data'))
      : {
          lat: parseFloat(websiteData.home_location_lat),
          lng: parseFloat(websiteData.home_location_long),
        }
  );

  const [zoomLevel, setZoomLevel] = useState(
    localStorage.getItem(websiteData.name + 'zoom_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'zoom_data'))
      : 12
  );

  const [popupInfo, setPopupInfo] = useState('');

  const onMapLoad = (map) => {
    mapref = map;
    google.maps.event.addListener(map, 'dragend', function () {
      const newCenter = mapref.getCenter();
      localStorage.setItem(
        websiteData.name + 'map_data',
        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() })
      );
    });

    google.maps.event.addListener(map, 'zoom_changed', function () {
      const newCenter = mapref.getZoom();
      setZoomLevel(newCenter);
      localStorage.setItem(
        websiteData.name + 'zoom_data',
        JSON.stringify(newCenter)
      );
    });
  };

  useEffect(() => {
    if (
      props?.clickedVehicleLocation?.lat &&
      props?.clickedVehicleLocation?.lng
    ) {
      setCurrentPosition({ ...props.clickedVehicleLocation });
    }
  }, [props.clickedVehicleLocation]);

  const markerIconData = (d) => {
    console.log('ddddd', d);
    return props.type == 'container_id'
      ? GreenTank
      : props.type == 'station_id'
      ? d.type == 'pickup'
        ? pick_point
        : d.type == 'dropoff'
        ? drop_point
        : pick_drop_point
      : websiteData.id == 'bombay_softwares'
      ? d.vehicle_type == 'Bike'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? motorcycleGreen
          : motorcycleRed
        : d.vehicle_type == 'Scooter'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? scooterGreen
          : scooterRed
        : d.vehicle_type == 'Delivery - KG3'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? kg3Green
          : kg3Red
        : d.vehicle_type == 'Delivery - KG4'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? kg4Green
          : kg4Red
        : websiteData.icon_type == 'scooter'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? scooterGreen
          : scooterRed
        : websiteData.icon_type == 'van'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? vanGreen1
          : vanRed1
        : websiteData.icon_type == 'solar'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? solarPanelGreen
          : solarPanelRed
        : websiteData.icon_type == 'bin'
        ? d.motion_status == 'moving' && d.ignition == 'on'
          ? binGreen
          : binRed
        : d.motion_status == 'moving' && d.ignition == 'on'
        ? motorcycleGreen
        : motorcycleRed
      : d.vehicle_type == 'Bike'
      ? d.trip_status == 'inprogress'
        ? motorcycleBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? motorcycleGreen
        : motorcycleRed
      : d.vehicle_type == 'Scooter'
      ? d.trip_status == 'inprogress'
        ? scooterBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? scooterGreen
        : scooterRed
      : d.vehicle_type == 'Delivery - KG3'
      ? d.trip_status == 'inprogress'
        ? kg3Blue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? kg3Green
        : kg3Red
      : d.vehicle_type == 'Delivery - KG4'
      ? d.trip_status == 'inprogress'
        ? kg4Blue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? kg4Green
        : kg4Red
      : websiteData.icon_type == 'scooter'
      ? d.trip_status == 'inprogress'
        ? scooterBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? scooterGreen
        : scooterRed
      : websiteData.icon_type == 'van'
      ? d.trip_status == 'inprogress'
        ? vanBlue
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? vanGreen1
        : vanRed1
      : websiteData.icon_type == 'solar'
      ? d.trip_status == 'inprogress'
        ? solarPanelGreen
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? solarPanelGreen
        : solarPanelRed
      : websiteData.icon_type == 'bin'
      ? d.trip_status == 'inprogress'
        ? binGreen
        : d.status == 'Locked' && d.current_status == 'Available for Service'
        ? binGreen
        : binRed
      : d.trip_status == 'inprogress'
      ? motorcycleBlue
      : d.status == 'Locked' && d.current_status == 'Available for Service'
      ? motorcycleGreen
      : motorcycleRed;
  };

  console.log('paopoa', props.filteredResult);
  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          {isLoaded ? (
            <GoogleMap
              id={'marker-example' + props.type}
              mapContainerStyle={{
                width: '100%',
                height: '600px',
              }}
              zoom={zoomLevel}
              onLoad={(map) => onMapLoad(map)}
              center={centerPosition}
              // options={{
              //   styles: []
              // }}
              onClick={(ev) => {
                setPopupInfo();
              }}
            >
              <div style={{ position: 'absolute', top: 12, right: 60 }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    console.log("websiteData.home_location_lat: ",websiteData.home_location_lat)
                    console.log("websiteData.home_location_long: ",websiteData.home_location_long)
                    setCurrentPosition({
                      lat: parseFloat(websiteData.home_location_lat),
                      lng: parseFloat(websiteData.home_location_long),
                    });
                    localStorage.setItem(
                      websiteData.name + 'map_data',
                      JSON.stringify({
                        lat: parseFloat(websiteData.home_location_lat),
                        lng: parseFloat(websiteData.home_location_long),
                      })
                    );
                  }}
                >
                  Home Location
                </Button>
              </div>
              {zoomLevel >= 18
                ? props?.filteredResult.map((d, i) => {
                    console.log('value of filter', d);
                    return (
                      <MarkerF
                        key={
                          props.type == 'station_id'
                            ? d.station_id + d?.latitude + d?.longitude
                            : d.vehicle_id +
                              d?.current_status +
                              d?.trip_status +
                              d?.status
                        }
                        icon={markerIconData(d)}
                        position={{
                          lat: d?.lat || d?.latitude,
                          lng: d?.long || d?.longitude,
                        }}
                        onClick={() => setPopupInfo(d)}
                      />
                    );
                  })
                : ''}
              {zoomLevel < 18 ? (
                <MarkerClusterer
                  minimumClusterSize={2}
                  styles={[
                    {
                      url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                      height: 55,
                      width: 55,
                      lineHeight: 58,
                      textColor: 'white',
                      textSize: '18',
                    },
                  ]}
                >
                  {(clusterer) =>
                    props?.filteredResult.map((d, i) => {
                      return (
                        <MarkerF
                          key={
                            props.type == 'station_id'
                              ? d.station_id +
                                d?.latitude +
                                d?.longitude +
                                'cluster'
                              : d.vehicle_id +
                                d?.status +
                                d?.current_status +
                                d?.trip_status +
                                'cluster'
                          }
                          clusterer={clusterer}
                          icon={markerIconData(d)}
                          position={{
                            lat: d?.lat || d?.latitude,
                            lng: d?.long || d?.longitude,
                          }}
                          onClick={() => setPopupInfo([...d])}
                        />
                      );
                    })
                  }
                </MarkerClusterer>
              ) : (
                ''
              )}
              {popupInfo && (
                <InfoWindow
                  position={{
                    lat: popupInfo?.lat || popupInfo?.latitude,
                    lng: popupInfo?.long || popupInfo?.longitude,
                  }}
                  onCloseClick={() => setPopupInfo()}
                >
                  <div>
                    {Object.entries(popupInfo).map(([key, value], index) => {
                      const transformedKey = key.replace(/_/g, ' '); // Replace underscore with space
                      return (
                        <div key={index}>
                          <strong style={{ textTransform: 'capitalize' }}>
                            {transformedKey}:
                          </strong>{' '}
                          {value}
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          ) : null}
          <br />
        </Col>
      </Row>
    </div>
  );
}

export default AssetLocationMapNew;
