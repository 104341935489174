import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { Button } from 'react-bootstrap';

function Modal({
  disableSave,
  children,
  maxWidth,
  modalHeading,
  status,
  onSave,
  onClose,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth || 'md'}
      open={status}
      onClose={() => onClose}
    >
      <DialogTitle>{modalHeading || 'Modal'}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        {!disableSave && (
          <Button variant="secondary" onClick={onSave}>
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
