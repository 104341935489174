import React, { Fragment, Suspense, useEffect } from 'react';
import Footer from '../Layouts/Footer/Footer';
import BacktoTop from '../Layouts/Backtotop/Backtotop';
// import Sidebar from '../Layouts/Sidebar/Sidebar';
import Header from '../Layouts/Header/Header';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openWebsite, setActiveWebsiteData } from '../redux/actions';
import { selectWebsites } from '../redux/auth/selectors';

const Sidebar = React.lazy(() => import('../Layouts/Sidebar/Sidebar'));

const Layout = ({ children }) => {
  const Websites = useSelector(selectWebsites);
  const dispatch = useDispatch();

  useEffect(() => {
    let website = Websites.find(
      (website) => website.id == sessionStorage.getItem('app')
    );
    dispatch(openWebsite(website));
  }, []);

  return (
    <Fragment>
      <Suspense fallback="">
        <div className="horizontalMenucontainer">
          <div className="page">
            <div className="page-main">
              <Header />
              <div className="sticky" style={{ paddingTop: '-74px' }}>
                <Sidebar />
              </div>
              <div
                className="jumps-prevent"
                style={{ paddingTop: '74px' }}
              ></div>
              <div className="main-content app-content mt-0">
                <div className="side-app">
                  <div className="main-container container-fluid">
                    {children}
                  </div>
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
          <BacktoTop />
        </div>
      </Suspense>
    </Fragment>
  );
};

export default Layout;
