import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCurrentMenuItem } from '../../../redux/current/selector';
import {
  addPage,
  fetchData,
  setModule,
} from '../../../redux/templateData/actions';
import {
  selectDatatable,
  selectLoadingState,
  selectPageInfo,
  selectStatistics,
} from '../../../redux/templateData/selectors';
import { initialState } from '../../../redux/templateData/init';
import LoadingState from '../LoadingState';

const MapDatatableDataLayer = (Component) => {
  return () => {
    const params = useParams();
    const dispatch = useDispatch();

    const { template, pagename: module } = useSelector(selectCurrentMenuItem);
    const datatable = useSelector(selectDatatable);
    const page_info = useSelector(selectPageInfo);
    const statistics = useSelector(selectStatistics);
    const loading = useSelector(selectLoadingState);

    // const [sampleResponse, sampleResponse] = useState({
    //   statistics: [],
    //   data: [],
    //   meta: {},
    //   page_info: {
    //     map_data_filteration_key: '',
    //   },
    // });

    useEffect(() => {
      if (!initialState.hasOwnProperty(module)) {
        dispatch(
          addPage({
            key: module,
            data: initialState.vehicles,
          })
        );
      }

      dispatch(setModule(module));
      dispatch(fetchData({ module }));
    }, [module]);

    return (
      <>
        {loading ? (
          <LoadingState />
        ) : (
          <Component
            {...params}
            statistics={statistics}
            page_info={page_info}
            template={template}
            module={module}
            data={datatable}
          />
        )}
      </>
    );
  };
};

export default MapDatatableDataLayer;
