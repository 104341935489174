import { Card, Col, Row } from 'react-bootstrap';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { useMemo, useState } from 'react';
import { OperatorReportDatatable, IssuesDatatable } from '../../Data/DummyData';
import {
  ModalContent,
  ServicePersonDetails,
} from '../../Data/TablesData/Modals/ModalContent';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import {
  DashboardStatistics,
  renderInfoCardDataFunction,
} from '../../Data/WebsiteData/keego';

function OperatorReport() {
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();

  const columns = useMemo(() =>
    computeColumns({
      data: OperatorReportDatatable.data,
      meta: OperatorReportDatatable.meta,
      action: ({ row, meta }) => {
        setRowData(row);
        setColumnMetaData(meta);
        setModalStatus(true);
      },
    })
  );

  const data = useMemo(() => OperatorReportDatatable.data, []);

  return (
    <div>
      <PageHeader
        titles="Operator Report"
        active="Operator Report"
        items={['Tables']}
      />
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            {DashboardStatistics.filter(
              (item) => item.module === 'operator_report'
            ).map((card, index) => (
              <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                <Card className="overflow-hidden">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="mt-2">
                        <h6 className="">{card.heading}</h6>
                        <h2 className="mb-0 number-font">
                          {renderInfoCardDataFunction(card)}
                        </h2>
                      </div>
                      <div className="ms-auto">
                        <div className="chart-wrapper mt-1"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Reported Issues</h6>
                      <h2 className="mb-0 number-font">
                        {OperatorReportDatatable.data.length}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Under Review</h6>
                      <h2 className="mb-0 number-font">
                        {
                          IssuesDatatable.data.filter(
                            (issue) => issue.status == 'Open'
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Issues Confirmed</h6>
                      <h2 className="mb-0 number-font">
                        {
                          IssuesDatatable.data.map(
                            (report) => report.status == 'Closed'
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Resolved</h6>
                      <h2 className="mb-0 number-font">
                        {
                          IssuesDatatable.data.map(
                            (report) => report.status == 'Closed'
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <div className="row">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Datatable
                datatableTitle="Operator Report"
                sortable_key="service_id"
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
              />
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
}

export default OperatorReport;
