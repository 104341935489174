import { combineReducers } from '@reduxjs/toolkit';
import AuthReducer from './auth/AuthReducer';
import GeneralReducer from './general/GeneralReducer';
import { RDX_MODULES } from './redux_constants';
import AssetReducer from './assets/reducer';
import IOTReducer from './iot/reducer';
import InspectionReducer from './inspection/reducer';
import CurrentReducer from './current/reducer';
import TemplateDataReducer from './templateData/reducer';

const rootReducers = combineReducers({
  auth: AuthReducer,
  current: CurrentReducer,
  [RDX_MODULES.GENERAL]: GeneralReducer,
  [RDX_MODULES.ASSETS]: AssetReducer,
  [RDX_MODULES.IOT]: IOTReducer,
  [RDX_MODULES.INSPECTION]: InspectionReducer,
  [RDX_MODULES.TEMPLATE_DATA]:TemplateDataReducer
});

export default rootReducers;
