import { createReducer } from '@reduxjs/toolkit';
import {
  setActiveWebsiteData,
  setTableModal,
  setPageStatus,
  setModule,
  setModalStatus,
} from '../actions';
import { ACCESS_TYPES, PERMISSIONS } from '../redux_constants';
import { setResponse, setMapWaypointResponse } from './actions';

const initialState = {
  website: {
    name: '',
    logo: '',
    url: '',
    type: '',
  },
  operator_type_permissions: [],
  fleet_manager_permissions: [],
  table: {
    status: false,
  },
  general_apis: {},
  permissions: {
    [ACCESS_TYPES.MASTER]: [
      PERMISSIONS.OPERATOR_DASHBOARD,
      PERMISSIONS.OPERATOR_LIST,
      PERMISSIONS.OPERATOR_MODULES,
      PERMISSIONS.OPERATOR_LICENSES,
      PERMISSIONS.ADMIN_USER,
      PERMISSIONS.REPORTING
      // PERMISSIONS.ASSETS
    ],
    [ACCESS_TYPES.DELIVERY_SERVICE]: [
      PERMISSIONS.DELIVERIES,
      PERMISSIONS.DELIVERY_DASHBOARD,
      PERMISSIONS.ASSETS,
      PERMISSIONS.SERVICES,
      PERMISSIONS.IOT,
      PERMISSIONS.ROUTING,
      PERMISSIONS.BILLING,
      PERMISSIONS.BUSINESS_INSIGHTS,
      PERMISSIONS.CHAT,
      PERMISSIONS.REPORTING
      // PERMISSIONS.CONTACT_US,
      // PERMISSIONS.SETTINGS
    ],
    [ACCESS_TYPES.RIDESHARE]: [
      PERMISSIONS.RIDESHARE_DASHBOARD,
      PERMISSIONS.ASSETS,
      // PERMISSIONS.SERVICES,
      PERMISSIONS.GEOFENCING,
      PERMISSIONS.CRM,
      PERMISSIONS.RULES,
      PERMISSIONS.IOT,
      PERMISSIONS.ROUTING,
      PERMISSIONS.CHAT,
      // PERMISSIONS.CONTACT_US,
      PERMISSIONS.BILLING,
      PERMISSIONS.BUSINESS_INSIGHTS,
      PERMISSIONS.TRIPS,
      PERMISSIONS.REPORTING
      // PERMISSIONS.SETTINGS
    ],
    [ACCESS_TYPES.OPERATOR_SERVICE]: [
      PERMISSIONS.SERVICE_OPERATOR,
      PERMISSIONS.ASSETS,
      PERMISSIONS.SERVICES,
      PERMISSIONS.GEOFENCING,
      PERMISSIONS.CRM,
      PERMISSIONS.IOT,
      PERMISSIONS.ROUTING,
      PERMISSIONS.CHAT,
      // PERMISSIONS.CONTACT_US,
      PERMISSIONS.BILLING,
      PERMISSIONS.BUSINESS_INSIGHTS,
      PERMISSIONS.REPORTING
      // PERMISSIONS.SETTINGS
    ],
    [ACCESS_TYPES.RIDESHARE_BIKETEK]: [
      PERMISSIONS.RIDESHARE_DASHBOARD,
      PERMISSIONS.ASSETS,
      // PERMISSIONS.SERVICES,
      PERMISSIONS.GEOFENCING,
      PERMISSIONS.CRM,
      PERMISSIONS.IOT,
      PERMISSIONS.ROUTING,
      PERMISSIONS.REPORTING
      // PERMISSIONS.CHAT,
      // PERMISSIONS.CONTACT_US,
      // PERMISSIONS.BILLING,
      // PERMISSIONS.BUSINESS_INSIGHTS,
    ],
  },
  page_loading_status: false,
  modalStatus: false,
};

const GeneralReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setResponse, (state, action) => {
      state.general_apis[action.payload.key] = action.payload.data;
    })
    .addCase(setMapWaypointResponse, (state, action) => {
      state.general_apis[action.payload.key] = action.payload.data;
    })
    .addCase(setTableModal, (state, action) => {
      state.table.status = action.payload;
    })
    .addCase(setPageStatus, (state, action) => {
      state.page_loading_status = action.payload;
    })
    .addCase(setModalStatus, (state, action) => {
      state.modalStatus = action.payload;
    })
    .addCase(setActiveWebsiteData, (state, action) => {
      state.website = action.payload.website;
      state.operator_type_permissions = action.payload.operator_type_permissions;
      state.fleet_manager_permissions = action.payload.fleet_manager_permissions;
    })
    .addDefaultCase((state, action) => state);
});

export default GeneralReducer;
