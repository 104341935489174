import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { DustbinDatatable } from '../../Data/DummyData';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import AssetLocationMap from '../Maps/AssetLocationMap';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialState } from '../../redux/templateData/init';
import {
  addPage,
  fetchData,
  setModule,
} from '../../redux/templateData/actions';
import {
  selectDatatable,
  selectFilteredData,
  selectModuleSpecificData,
} from '../../redux/templateData/selectors';
import { DashboardStatistics } from '../../Data/DummyData';
import { useInfoCtx } from '../../providers/InfoCardProvider';
import { useFormik } from 'formik';
import { useModalCtx } from '../../providers/ModalProvider';
import Modal from '../../components/Modal';
import { Grid, MenuItem, TextField } from '@mui/material';
import { addData, deleteData, editData } from '../../redux/actions';
import { StationsValidations } from '../../validations';
import LocationSelectorMap from '../Maps/LocationSelectorMap';
import AssetLocationMapNew from '../Maps/AssetLocationMapNew';

function Stations() {
  const datatable = useSelector(selectDatatable);
  const filteredResult = useSelector(selectFilteredData);
  const dispatch = useDispatch();
  const stationHistory = useSelector(
    selectModuleSpecificData('station_history'),
  );
  const [dialogConfirmationStatus, setDialogConfirmationStatus] =
    useState(false);
  const { renderInfoCardData } = useInfoCtx();
  const [isEditMode, setEditMode] = useState(false);
  const modalContext = useModalCtx();
  let {
    modalStatus: formModalStatus,
    modalStatusHandler: handleFormModalStatus,
  } = modalContext;
  const module = 'stations';
  const [historyModalStatus, setHistoryModalStatus] = useState(false);
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();

  const {
    values,
    errors,
    touched,
    resetForm,
    handleBlur,
    handleChange,
    setFieldValue,
    setValues,
    submitForm,
  } = useFormik({
    initialValues: {
      location: '',
      latitude: '',
      longitude: '',
      type: 'pickup',
    },
    onSubmit: () => {
      let obj = { ...values };
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === '') {
          obj[key] = null;
        }
      });

      if (isEditMode) {
        dispatch(editData(obj));
      } else {
        dispatch(addData(obj));
      }
    },
    validationSchema: StationsValidations,
  });

  const columns = useMemo(() =>
    computeColumns({
      data: datatable.data,
      meta: datatable.meta,
      action: ({ row, meta }) => {
        setRowData(row);
        setColumnMetaData(meta);
        setModalStatus(true);
      },
    }),
  );

  const columns2 = React.useMemo(
    () =>
      computeColumns({
        data: stationHistory,
        meta: DustbinDatatable.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [stationHistory],
  );

  const data = useMemo(() => datatable.data, [datatable.data]);

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        }),
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  const handleLocationSelect = (location) => {
    setFieldValue('latitude', location.lat);
    setFieldValue('longitude', location.lng);
  };

  return (
    <div>
      <PageHeader titles="Stations" active="Stations" items={['Tables']} />
      <Row>
        {DashboardStatistics.filter((item) => item.module === 'stations').map(
          (card, index) => (
            <Col lg={6} key={index} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">{card.heading}</h6>
                      <h2 className="mb-0 number-font">
                        {renderInfoCardData(card)}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ),
        )}
      </Row>

      <div>
        <AssetLocationMapNew
          ids={data.map((d) => d.station_id)}
          type={'station_id'}
          filteredResult={filteredResult}
        />
      </div>
      {/* <Row>
        <Col lg={12} style={{ textAlign: 'right' }}>
          <div style={{ marginBottom: '20px' }}>
            <Button
              onClick={() => {
                setEditMode(false);
                handleFormModalStatus(true);
                resetForm();
              }}
              variant="secondary"
            >
              Add Station
            </Button>
          </div>
        </Col>
      </Row> */}
      <div className="row">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Datatable
                addActionName="Add Station"
                addAction={() => {
                  setEditMode(false);
                  handleFormModalStatus(true);
                  resetForm();
                }}
                datatableTitle="Stations"
                refreshAction={() => {
                  dispatch(fetchData({ module }));
                }}
                sortable_key="station_id"
                actions={[
                  { name: 'history', type: 'history', buttonType: 'icon' },
                  { name: 'Edit', type: 'edit', buttonType: 'icon' },
                  { name: 'Delete', type: 'delete', buttonType: 'icon' },
                ]}
                onActionClick={(event, { type, data }) => {
                  setValues(data.original);
                  if (type == 'edit') {
                    setEditMode(true);
                    // console.log('data.original: ', data.original);
                    handleFormModalStatus(true);
                  } else if (type == 'delete') {
                    setDialogConfirmationStatus(true);
                  } else if (type == 'history') {
                    dispatch(
                      addPage({
                        key: 'station_history',
                        data: initialState.vehicles,
                      }),
                    );
                    dispatch(
                      fetchData({
                        module: 'station_history',
                        station_id: data.values.station_id,
                      }),
                    );
                    setHistoryModalStatus(true);
                  }
                }}
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
              />
            </Card.Body>
          </Card>
        </Col>
        <Modal
          modalHeading={isEditMode ? 'Edit' : 'Add'}
          fullWidth
          maxWidth="md"
          status={formModalStatus}
          onClose={() => {
            resetForm();
            handleFormModalStatus(false);
          }}
          onSave={submitForm}
        >
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocationSelectorMap
                onLocationSelect={handleLocationSelect}
                defaultLocation={{
                  lat: values.latitude,
                  lng: values.longitude,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {/* battery_id, battery_firmware_version, battery_cell_voltage */}
              <TextField
                label="Location*"
                name="location"
                // disabled={isEditMode ? true : false}
                error={errors.location && touched.location ? true : false}
                helperText={
                  errors.location && touched.location ? errors.location : false
                }
                value={values.location}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Location"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Latitude*"
                name="latitude"
                error={errors.latitude && touched.latitude ? true : false}
                helperText={
                  errors.latitude && touched.latitude ? errors.latitude : false
                }
                value={values.latitude}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Latitude"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Longitude*"
                name="longitude"
                error={errors.longitude && touched.longitude ? true : false}
                helperText={
                  errors.longitude && touched.longitude
                    ? errors.longitude
                    : false
                }
                value={values.longitude}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Longitude"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Type"
                name="type"
                select
                error={errors.type && touched.type ? true : false}
                helperText={errors.type && touched.type ? errors.type : false}
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Station Type"
              >
                <MenuItem value="pickup">Pickup</MenuItem>
                <MenuItem value="dropoff">Drop off</MenuItem>
                <MenuItem value="pickup_dropoff_both">Both</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          fullWidth
          maxWidth="sm"
          onClose={() => setDialogConfirmationStatus(false)}
          modalHeading="Confirm"
          status={dialogConfirmationStatus}
          onSave={() => {
            dispatch(deleteData(values));
            setDialogConfirmationStatus(false);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              Are you sure want to delete this record?
            </Grid>
          </Grid>
        </Modal>
        <Modal
          fullWidth
          maxWidth="md"
          onClose={() => setHistoryModalStatus(false)}
          modalHeading="Battery History"
          status={historyModalStatus}
          disableSave={true}
          onSave={() => {
            setHistoryModalStatus(false);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              {/* <Datatable
                hasFilterAffectsMap={false}
                modalSize="xl"
                isMultiSelect={false}
                columns={columns2}
                data={stationHistory}
              /> */}
            </Grid>
          </Grid>
        </Modal>
      </div>
      {/* <!-- End Row --> */}
    </div>
  );
}

export default Stations;
