import { VehicleDatatable, batteryDatatable } from '../../Data/DummyData';
import { RidersData } from '../../Data/WebsiteData/moby';
import http from '../../http';

let app = sessionStorage.getItem('app');

export const fetchBatteries = async () => {
  let response;
  let data;

  switch (app) {
    // default:
    // response = batteryDatatable;
    // data = response.data;

    default:
      response = await http('POST', 'api/v1/data', {
        apiData: {
          page_name: 'batteries',
        },
      });
      data = response.data;
      break;
  }

  return data;
};

export const fetchVehicle = async () => {
  let response;
  let data;
  switch (app) {
    // default:
    //   response = VehicleDatatable;
    //   data = response.data;

    default:
      response = await http('POST', 'api/v1/data', {
        apiData: {
          page_name: 'vehicles',
        },
      });
      data = response.data;
  }

  return data;
};

export const fetchMaintainencePerson = async () => {
  let response;
  let data;

  switch (app) {
    default:
      response = await http('POST', 'api/v1/data', {
        apiData: {
          page_name: 'service_staff',
        },
      });
      data = response.data;
  }

  return data;
};
