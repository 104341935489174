import { Button, Card, Col, Row } from 'react-bootstrap';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { VehicleDatatable, WorkOrderDatatable } from '../../Data/DummyData';
import AssetLocationMap from '../Maps/AssetLocationMap';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import RoutingMap from '../Maps/RoutingMap';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { useDispatch, useSelector } from 'react-redux';

import { RouterMetaData } from '../../Data/WebsiteData/moby';
import {
  selectDatatable,
  selectFilteredData,
  selectModuleSpecificData,
} from '../../redux/templateData/selectors';
import {
  addPage,
  fetchData,
  setFilteredData,
  setModule,
} from '../../redux/templateData/actions';
import { initialState } from '../../redux/templateData/init';

function Routing() {
  const datatable = useSelector(selectDatatable);
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();
  const filteredData = useSelector(selectFilteredData);
  const dispatch = useDispatch();
  const module = 'vehicles';
  const stations = useSelector(selectModuleSpecificData('stations'));
  const data = useMemo(() => datatable.data, [datatable.data]);

  //we are setting the filterdData like
  const [filteredResult, setFilteredResult] = useState([]);

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        })
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  // useEffect(() => {
  //   dispatch(setFilteredData(data));
  //   setFilteredResult(data);
  // }, [data]);

  const columns = useMemo(
    () =>
      computeColumns({
        data: datatable.data,
        meta: datatable.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [datatable.data]
  );

  const filterRoutingData = () => {
    setFilteredData(filteredData);
    setFilteredResult(filteredData);
  };

  const handleSearch = useCallback((event) => {
    // console.log(event.target.value);
  }, []);

  return (
    <div>
      <PageHeader titles="Routing" active="Routing" items={['Tables']} />
      <div>
        <RoutingMap
          filteredResult={filteredResult}
          filteredData={filteredData}
          stations={stations}
          allVehicles={data}
        />
      </div>
      <div className="row">
        <Col lg={12}>
          {/* <div style={{ marginBottom: '20px', textAlign: 'right' }}>
            <Button
              size={'sm'}
              variant="secondary"
              onClick={() => filterRoutingData()}
              // style={{ position: 'absolute', right: '3.3%', top: '7%' }}
            >
              Routing
            </Button>
          </div> */}
          {/* {console.log('Station are', stations)}  */}
          <Card>
            <Card.Body>
              <Datatable
                addActionName="Routing"
                addAction={() => {
                  filterRoutingData();
                }}
                refreshAction={() => {
                  dispatch(fetchData({ module }));
                }}
                datatableTitle="Routing"
                sortable_key="vehicle_id"
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
              />
            </Card.Body>
          </Card>
        </Col>
      </div>
      {/* <!-- End Row --> */}
    </div>
  );
}

export default Routing;
