import React, { memo, useEffect, useImperativeHandle, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const JMap = React.forwardRef(
  ({ config, markerLocation, icons, type }, ref) => {
    let additionalOptions = {};
    let { center } = config;

    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const markersRef = useRef([]);
    const directionRef = useRef(null);
    const directionServiceRef = useRef(null);

    useImperativeHandle(ref, () => ({
      drawRoute,
      clearMarkers,
    }));

    useEffect(() => {
      const loader = new Loader({
        apiKey: 'AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac',
        version: 'weekly',
        ...additionalOptions,
      });

      loader.load().then(() => {
        const map = new google.maps.Map(mapContainerRef.current, {
          center: { lat: center?.latitude, lng: center?.longitude },
          zoom: 10,
        });

        directionServiceRef.current = new window.google.maps.DirectionsService({
          map: map,
        });

        directionRef.current = new window.google.maps.DirectionsRenderer({
          map: map,
        });

        mapRef.current = map;
      });
    }, []);

    useEffect(() => {
      if (mapRef.current !== null) {
        updateMarkers();
        console.log('MAP REF: ', mapRef);
      }
    }, [mapRef.current, markerLocation]);

    const updateMarkers = () => {
      clearMarkers();
      const markers = markerLocation.map((position, i) => {
        const marker = new google.maps.Marker({
          position,
          icon: icons(position, type),
          map: mapRef.current,
        });

        markersRef.current.push(marker);

        return marker;
      });

      new MarkerClusterer({ markers, map: mapRef.current });
    };

    const drawRoute = ({ to, destination, waypoints }) => {
      var request = {
        origin: new window.google.maps.LatLng(to.lat, to.lng),
        destination: new window.google.maps.LatLng(
          destination.lat,
          destination.lng
        ),
        waypoints: waypoints,
        travelMode: 'DRIVING',
      };

      directionServiceRef.current.route(request, function (response, status) {
        if (status == 'OK') {
          directionRef.current.setDirections(response);
          directionRef.current.setMap(mapRef.current);
        }
      });
    };

    const clearMarkers = () => {
      markersRef.current.forEach((marker) => {
        marker.setMap(null);
      });

      markersRef.current = [];
    };

    const setCenter = (position) => {
      mapRef.current.setCenter(position);
    };

    return (
      <div>
        {/* <button
          onClick={() =>
            drawRoute({
              to: { lat: 23.1309, lng: 72.5852 },
              destination: { lat: 23.2248, lng: 72.6464 },
              waypoints: [
                {
                  location: new window.google.maps.LatLng(23.17, 72.5294),
                  stopover: false,
                },
              ],
            })
          }
        >
          Draw Dout
        </button> */}
        <div ref={mapContainerRef} style={{ height: '300px' }} />
      </div>
    );
  }
);

export default memo(JMap);
