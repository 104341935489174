import moment from 'moment';

export const total = ({
  source,
  condition,
}: {
  source: object[];
  condition: Array<{ type: string; value: string }> | undefined;
}) => {
  let filteredData = source;

  if (condition)
    filteredData = source.filter((data) =>
      condition.every((c) => data[c.type] === c.value)
    );

  return filteredData.length.toLocaleString('en-IN');
};

export const computeInTime = ({
  source,
  unit,
  of,
}: {
  source: object[];
  unit: string;
  of: { start: string; end: string };
}) => {
  let result = source.reduce((result, data) => {
    let difference = moment(data[of.end]).diff(data[of.start], 'minutes');
    if (difference) return result + difference;
    return result;
  }, 0);

  return result.toLocaleString('en-IN') + unit;
};

export const compute = ({
  source,
  hasFrontUnit,
  unit,
  condition,
  of,
}: {
  // source: [{name:string}];
  hasFrontUnit: boolean;
  source: object[];
  unit: string;
  condition: Array<{ type: string; value: string }> | undefined;
  of: string;
}) => {
  let filteredData = source;
  if (condition)
    filteredData = source.filter((data) =>
      condition.every((c) => data[c.type] === c.value)
    );

  let result;
  if (hasFrontUnit)
    result =
      unit +
      filteredData
        .reduce((result, data) => {
          if (data[of]) {
            return result + parseFloat(data[of]);
          } else return result + 0;
        }, 0)
        .toLocaleString('en-US');
  else
    result =
      filteredData
        .reduce((result, data) => {
          if (data[of]) {
            return result + parseFloat(data[of]);
          } else return result + 0;
        }, 0)
        .toLocaleString('en-US') +
      ' ' +
      unit;

  return result;
};
