import { createSelector } from '@reduxjs/toolkit';
import { RDX_MODULES } from '../redux_constants';

export const selectTemplateData = (state) => state[RDX_MODULES.TEMPLATE_DATA];
export const selectModule = (state) => state[RDX_MODULES.TEMPLATE_DATA].module;
export const selectLoadingState = (state) =>
  state[RDX_MODULES.TEMPLATE_DATA].loading;

export const selectModuleData = createSelector(
  selectTemplateData,
  selectModule,
  (template_data, module) => template_data[module]
);

export const selectDatatable = createSelector(
  selectModuleData,
  (module_data) => module_data?.datatable
);

export const selectData = createSelector(
  selectDatatable,
  (datatable) => datatable.data
);

export const selectPageInfo = createSelector(
  selectModuleData,
  (data) => data.page_info
);

export const selectStatistics = createSelector(
  selectModuleData,
  (data) => data.statistics
);

export const selectMetaInformation = createSelector(
  selectModuleData,
  (data) => data.datatable.meta
);

export const selectFilteredData = createSelector(
  selectDatatable,
  (datatable) => datatable.filteredData
);

export const selectModuleSpecificData = (module) => (state) =>
  state?.template_data[module]?.datatable?.data;


export const selectModuleSpecificRawData = (module) => (state) =>
state?.template_data[module];
