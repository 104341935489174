import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import AssetLocationMap from '../Maps/AssetLocationMap';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardStatistics } from '../../Data/DummyData';
import { useInfoCtx } from '../../providers/InfoCardProvider';
import {
  addPage,
  fetchData,
  setModule,
} from '../../redux/templateData/actions';
import {
  selectData,
  selectDatatable,
  selectFilteredData,
} from '../../redux/templateData/selectors';
import { initialState } from '../../redux/templateData/init';
import { RidersData } from '../../Data/DummyData';
import Modal from '../../components/Modal';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useModalCtx } from '../../providers/ModalProvider';
import { useFormik } from 'formik';
import { addData, editData } from '../../redux/actions';
import { ServiceStaffFormValidations } from '../../validations';

function MaintenanceStaff() {
  let module = 'service_staff';
  const datatableData = useSelector(selectDatatable);
  const dispatch = useDispatch();
  const [dialogConfirmationStatus, setDialogConfirmationStatus] =
    useState(false);
  const { renderInfoCardData } = useInfoCtx();
  const [isEditMode, setEditMode] = useState(false);
  const modalContext = useModalCtx();
  let {
    modalStatus: formModalStatus,
    modalStatusHandler: handleFormModalStatus,
  } = modalContext;
  const filteredResult = useSelector(selectFilteredData);
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();

  const {
    values,
    errors,
    touched,
    resetForm,
    handleBlur,
    handleChange,
    setValues,
    submitForm,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      contact_number: '',
      total_rides: '',
      is_riding: '',
      on_duty: '',
      performance: '',
    },
    onSubmit: () => {
      let obj = { ...values };
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === '') {
          obj[key] = null;
        }
      });

      if (isEditMode) {
        dispatch(editData(obj));
      } else {
        dispatch(addData(obj));
      }
    },
    validationSchema: ServiceStaffFormValidations,
  });

  const columns = React.useMemo(
    () =>
      computeColumns({
        data: datatableData.data,
        meta: datatableData.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [datatableData.data]
  );

  const data = React.useMemo(
    () => datatableData.data,
    // .filter((staff) =>
    //   ['all', 'service'].includes(staff.employee_type)
    // )
    [datatableData.data]
  );

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        })
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  return (
    <div>
      <PageHeader
        titles="Maintenance Staff"
        active="Maintenance Staff"
        items={['Tables']}
      />

      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            {DashboardStatistics.filter(
              (item) => item.module === 'service_staff'
            ).map((card, index) => (
              <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                <Card className="overflow-hidden">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="mt-2">
                        <h6 className="">{card.heading}</h6>
                        <h2 className="mb-0 number-font">
                          {renderInfoCardData(card)}
                        </h2>
                      </div>
                      <div className="ms-auto">
                        <div className="chart-wrapper mt-1"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Staff</h6>
                      <h2 className="mb-0 number-font">
                        {
                          RidersData.data.filter((data) =>
                            ['service', 'all'].includes(data.employee_type)
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">On Duty</h6>
                      <h2 className="mb-0 number-font">
                        {
                          RidersData.data.filter(
                            (data) =>
                              ['service', 'all'].includes(data.employee_type) &&
                              data.on_duty
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Off Duty</h6>
                      <h2 className="mb-0 number-font">
                        {
                          RidersData.data.filter(
                            (data) =>
                              ['service', 'all'].includes(data.employee_type) &&
                              !data.on_duty
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Row className="row-sm">
        <Col lg={12}>
          <div>
            <AssetLocationMap
              ids={RidersData.data.map((d) => d.name)}
              type={'name'}
              filteredResult={filteredResult}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} style={{ textAlign: 'right' }}>
          <div style={{ marginBottom: '20px' }}>
            <Button
              onClick={() => {
                setEditMode(false);
                handleFormModalStatus(true);
                resetForm();
              }}
              variant="secondary"
            >
              Add Staff
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="row-sm">
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h3">Datatable</Card.Title>
            </Card.Header>
            <Card.Body>
              <Datatable
                sortable_key="service_staff_id"
                actions={[
                  { name: 'Edit', type: 'edit', buttonType: 'icon' },
                  { name: 'Delete', type: 'delete', buttonType: 'icon' },
                ]}
                onActionClick={(event, { type, data }) => {
                  setValues(data.original);
                  if (type == 'edit') {
                    setEditMode(true);
                    // console.log('data.original: ', data.original);
                    handleFormModalStatus(true);
                  } else if (type == 'delete') {
                    setDialogConfirmationStatus(true);
                  }
                }}
                filteredResult={(result) => {
                  dispatch(setFilteredData(result));
                }}
                modalSize="xl"
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
              />
              {/* <Savetable
                table_type="rider-datatable"
                hasAddFeature={false}
                columns={RidersData.COLUMNS}
                data={RidersData.DATATABLE}
              /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        modalHeading={isEditMode ? 'Edit' : 'Add'}
        fullWidth
        maxWidth="md"
        status={formModalStatus}
        onClose={() => {
          resetForm();
          handleFormModalStatus(false);
        }}
        onSave={submitForm}
      >
        <br />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* battery_id, battery_firmware_version, battery_cell_voltage */}
            <TextField
              label="Name*"
              name="name"
              disabled={isEditMode ? true : false}
              error={errors.name && touched.name ? true : false}
              helperText={errors.name && touched.name ? errors.name : false}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              placeholder="Name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email*"
              name="email"
              error={errors.email && touched.email ? true : false}
              helperText={errors.email && touched.email ? errors.email : false}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              placeholder="Email"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Contact Number*"
              type="number"
              name="contact_number"
              error={
                errors.contact_number && touched.contact_number ? true : false
              }
              helperText={
                errors.contact_number && touched.contact_number
                  ? errors.contact_number
                  : false
              }
              value={values.contact_number}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              placeholder="Contact Number"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Total Rides"
              type="number"
              name="total_rides"
              error={errors.total_rides && touched.total_rides ? true : false}
              helperText={
                errors.total_rides && touched.total_rides
                  ? errors.total_rides
                  : false
              }
              value={values.total_rides}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              placeholder="Total rides"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Performance"
              type="number"
              name="performance"
              error={errors.performance && touched.performance ? true : false}
              helperText={
                errors.performance && touched.performance
                  ? errors.performance
                  : false
              }
              value={values.performance}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              placeholder="Performance"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Is Riding"
              name="is_riding"
              error={errors.is_riding && touched.is_riding ? true : false}
              select
              helperText={
                errors.is_riding && touched.is_riding ? errors.is_riding : false
              }
              value={values.is_riding}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              placeholder="Is Riding"
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Autoallocation"
              type="number"
              name="auto_allocation"
              error={
                errors.auto_allocation && touched.auto_allocation ? true : false
              }
              helperText={
                errors.auto_allocation && touched.auto_allocation
                  ? errors.auto_allocation
                  : false
              }
              value={values.auto_allocation}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              placeholder="Auto Allocation"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="On Duty*"
              type="number"
              name="on_duty"
              select
              error={errors.on_duty && touched.on_duty ? true : false}
              helperText={
                errors.on_duty && touched.on_duty ? errors.on_duty : false
              }
              value={values.on_duty}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              placeholder="On Duty"
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        fullWidth
        maxWidth="sm"
        onClose={() => setDialogConfirmationStatus(false)}
        modalHeading="Confirm"
        status={dialogConfirmationStatus}
        onSave={() => {
          dispatch(deleteData(values));
          setDialogConfirmationStatus(false);
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            Are you sure want to delete this record?
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

export default MaintenanceStaff;
