import { createAction } from '@reduxjs/toolkit';
import TYPES from '../types';

export const setModuleData = createAction(TYPES.SET_MODULE_DATA);

export const setFilteredData = createAction(TYPES.SET_MODULE_FILTERED_DATA);

export const fetchData = ({ module }) => {
  let ACTION = '';

  // console.log('module variable in Actions: ', module);

  switch (module) {
    case 'solar_panel':
      ACTION = TYPES.FETCH_SOLAR_PANEL_DATA;
      break;
    case 'vehicles':
      ACTION = TYPES.FETCH_VEHICLE_DATA;
      break;
    case 'batteries':
      ACTION = TYPES.FETCH_BATTERY_DATA;
      break;
    case 'service_staff':
      ACTION = TYPES.FETCH_SERVICE_DATA;
      break;
    case 'delivery_vans':
      ACTION = TYPES.FETCH_DELIVERY_VANS;
      break;
    case 'drivers':
      ACTION = TYPES.FETCH_DRIVERS_DATA;
      break;
    case 'dustbins':
      ACTION = TYPES.FETCH_DUSTBINS_DATA;
      break;
    default:
      ACTION = TYPES.FETCH_DRIVERS_DATA;
  }

  return {
    type: ACTION,
  };
};

// createAction(TYPES.FETCH_BATTERY_DATA)
