import { createSelector } from "@reduxjs/toolkit";
import { RDX_MODULES } from "../redux_constants";

export const selectAssets=(state)=>state[RDX_MODULES.ASSETS];
export const selectModule=(state)=>state[RDX_MODULES.ASSETS].module;
export const selectDatatable=createSelector(selectAssets,selectModule,(assets,module)=>assets[module].datatable)
export const selectData=createSelector(selectDatatable,(datatable=>datatable.data))
// export const selectModule=(state=>state[])



export const selectFilteredData=createSelector(selectDatatable,(datatable=>datatable.filteredData))
