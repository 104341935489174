import { Card, Col, Row } from 'react-bootstrap';
import { CusotmersData, CustomersData } from '../../Data/DummyData';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { BasicDataTable } from '../../Data/TablesData/TableData';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { useEffect, useMemo } from 'react';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import { initialState } from '../../redux/templateData/init';
import {
  addPage,
  fetchData,
  setModule,
} from '../../redux/templateData/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectDatatable } from '../../redux/templateData/selectors';
import { renderInfoCardDataFunction } from '../../Data/WebsiteData/keego';
import { DashboardStatistics } from '../../Data/WebsiteData/moby';
import { useInfoCtx } from '../../providers/InfoCardProvider';

function Customers() {
  const dispatch = useDispatch();
  const datatable = useSelector(selectDatatable);
  const { renderInfoCardData } = useInfoCtx();
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();
  const module = 'customers';
  const columns = useMemo(
    () =>
      computeColumns({
        data: datatable.data,
        meta: datatable.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [datatable.data]
  );

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        })
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  const data = useMemo(() => datatable.data, [datatable.data]);

  return (
    <div>
      <PageHeader titles="Customers" active="Customers" items={['Tables']} />

      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            {DashboardStatistics.filter(
              (item) => item.module === 'customers'
            ).map((card, index) => (
              <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                <Card className="overflow-hidden">
                  <Card.Body>
                    <div className="d-flex">
                      <div className="mt-2">
                        <h6 className="">{card.heading}</h6>
                        <h2 className="mb-0 number-font">
                          {renderInfoCardData(card)}
                        </h2>
                      </div>
                      <div className="ms-auto">
                        <div className="chart-wrapper mt-1"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Customers</h6>
                      <h2 className="mb-0 number-font">
                        {CustomersData.data.length}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Active Customers</h6>
                      <h2 className="mb-0 number-font">
                        {
                          CustomersData.data.filter(
                            (data) => data.active == 'Yes'
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Inactive Customers</h6>
                      <h2 className="mb-0 number-font">
                        {
                          CustomersData.data.filter(
                            (data) => data.active == 'No'
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Inactive Customers</h6>
                      <h2 className="mb-0 number-font">5</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Row className="row-sm">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Datatable
                refreshAction={() => {
                  dispatch(fetchData({ module }));
                }}
                datatableTitle="Customers"
                sortable_key="name"
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Customers;
