import { Button, Col, Row } from 'react-bootstrap';

import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  MarkerClusterer,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import {
  VehicleDatatable,
  allSimulationData,
  batteryDatatable
} from '../../Data/DummyData';
// import { activeDeliveries } from '../../Data/DummyData';
import motorcycle from '../../assets/images/media/motorcycle.png';
import motorcycle_red from '../../assets/images/media/motorcycle_red.png';
// import scooter from '../../assets/images/media/scooter.png';
// import van from '../../assets/images/media/van.png';
// import vanRed from '../../assets/images/media/van_red.png';
import { useSelector } from 'react-redux';
import bin from '../../assets/images/media/bin.png';
import binRed from '../../assets/images/media/bin_red.png';
import scooter1 from '../../assets/images/media/scooter1.png';
import scooterRed from '../../assets/images/media/scooter_red.png';
import solarPanel from '../../assets/images/media/solar_panel.png';
import solarPanelRed from '../../assets/images/media/solar_panel_red.png';
import van1 from '../../assets/images/media/van1.png';
import vanRed1 from '../../assets/images/media/van_red1.png';
import { selectWebsiteData } from '../../redux/general/selectors';
// import { selectVehicleData, selectVehicleFilteredData } from '../../redux/assets/vehicle/selectors';

let directionsService;
const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15,
};

let mapref = null;

let currentSimulationData = allSimulationData;
let simulation = [];

let intervalCall;
// let mapInterval;

function changeSimulationData() {
  simulation = currentSimulationData.map((d) => {
    let currentTime = new Date();
    currentTime = currentTime.getMinutes();
    let totalTime =
      parseInt(d.start_time) + parseInt(d.estimated_trip_duration);

    let status;

    if (!d.start_time) {
      status = 'idle';
    } else if (currentTime >= totalTime) {
      status = 'completed';
    } else if (currentTime < d.start_time) {
      status = 'pending';
    } else {
      status = 'active';
    }

    d = { ...d, status: status };

    return d;
  });
}

function TemplateAssetLocationMap(props) {
  let { isLoaded } = useJsApiLoader({
    id: 'drawing-manager-example',
    googleMapsApiKey: 'AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac',
    libraries: ['drawing'],
  });

  const websiteData = useSelector(selectWebsiteData);

  const [centerPosition, setCurrentPosition] = useState(
    localStorage.getItem(websiteData.name+'map_data')
      ? JSON.parse(localStorage.getItem(websiteData.name+'map_data'))
      : { lat: parseFloat(websiteData.home_location_lat), lng: parseFloat(websiteData.home_location_long) }
  );
  const [zoomLevel, setZoomLevel] = useState(
    localStorage.getItem(websiteData.name+'zoom_data')
      ? JSON.parse(localStorage.getItem(websiteData.name+'zoom_data'))
      : 12
  );
  const [directions, setDirections] = useState([]);
  const [routePoints, setRoutePoints] = useState([]);
  const [markersToDraw, setMarkersToDraw] = useState([]);
  const [popupInfo, setPopupInfo] = useState('');

  const onMapLoad = (map) => {
    mapref = map;
    changeSimulationData();
    google.maps.event.addListener(map, 'dragend', function () {
      const newCenter = mapref.getCenter();
      localStorage.setItem(
        websiteData.name+'map_data',
        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() })
      );
    });

    google.maps.event.addListener(map, 'zoom_changed', function () {
      const newCenter = mapref.getZoom();
      localStorage.setItem(websiteData.name+'zoom_data', JSON.stringify(newCenter));
    });

    directionsService = new google.maps.DirectionsService();
    //load default origin and destination
    let mapSimulationData = [];
    props.ids.forEach((data) => {
      // console.log('popaoapoapaopoa', props.type);
      let simulationObj = simulation.filter((d) => d[props.type] == data);

      if (simulationObj.findIndex((d) => d.status == 'active') != -1) {
        simulationObj = simulationObj.find((d) => d.status == 'active');
      } else if (simulationObj.findIndex((d) => d.status == 'pending') != -1) {
        simulationObj = simulationObj.find((d) => d.status == 'pending');
      } else if (
        simulationObj.findIndex((d) => d.status == 'completed') != -1
      ) {
        simulationObj = simulationObj.find((d) => d.status == 'completed');
      } else {
        simulationObj = simulationObj.find((d) => d.status == 'idle');
      }

      mapSimulationData.push({ ...simulationObj });
    });
    simulation = mapSimulationData;

    getDirections();
  };

  const getDirections = () => {
    simulation.forEach((data) => {
      changeDirection(
        { lat: parseFloat(data.start_lat), lng: parseFloat(data.start_long) },
        { lat: parseFloat(data.end_lat), lng: parseFloat(data.end_long) },
        data.id
      );
    });
  };

  //function that is calling the directions service
  const changeDirection = (origin, destination, id) => {
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: 'DRIVING',
        // preserveViewport: true
      },
      (result, status) => {
        if (status) {
          //changing the state of directions to the result of direction service
          let data = directions;
          let routeData = result.routes[0].legs[0].steps.map((d) =>
            d.lat_lngs.map((la) => ({ lat: la.lat(), lng: la.lng() }))
          );
          routeData = routeData.concat.apply([], routeData);
          let routeValue = routePoints;
          routeValue.push({ id: id, path: routeData });
          setRoutePoints([...routeValue]);

          data.push(result);
          setDirections([...data]);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const checkForPosition = (filldata) => {
    let deliveryData = simulation;
    // console.log('filldata', filldata, deliveryData);
    let markerDraw = deliveryData.map((d, i) => {
      if (filldata.findIndex((f) => f[props.type] == d[props.type]) != -1) {
        let currentTime = new Date();
        let timeInSec = parseInt(currentTime.getSeconds());
        currentTime = currentTime.getMinutes() + timeInSec / 60;

        let getRouterPoints = routePoints.find((rou) => rou.id == d.id);

        if (getRouterPoints) {
          let completedPre =
            (currentTime - parseInt(d.start_time)) /
            parseInt(d.estimated_trip_duration);
          //condition to only draw when trip is active
          if (completedPre > 0 && completedPre < 1) {
            let index = Math.floor(completedPre * getRouterPoints.path.length);
            let findVehicleData = VehicleDatatable.data.find(
              (veh) => veh?.vehicle_id == d?.vehicle_id
            );
            let findBatteryData = batteryDatatable.data.find(
              (veh) => veh?.battery_id == d?.battery_id
            );
            return (
              <MarkerF
                key={i}
                icon={
                  websiteData.icon_type == 'scooter'
                    ? scooter1
                    : websiteData.icon_type == 'van'
                    ? van1
                    : websiteData.icon_type == 'solar'
                    ? solarPanel
                    : websiteData.icon_type == 'bin'
                    ? bin
                    : motorcycle
                }
                position={{ ...getRouterPoints.path[index] }}
                onClick={() =>
                  setPopupInfo({
                    ...getRouterPoints.path[index],
                    vehicleData: { ...findVehicleData },
                    simulationData: { ...d },
                    batteryData: { ...findBatteryData },
                  })
                }
              />
            );
          } else {
            return drawCompletedPendingIdle(d, i);
          }
        } else {
          return drawCompletedPendingIdle(d, i);
        }
      }
    });

    setMarkersToDraw(markerDraw);
  };

  const drawCompletedPendingIdle = (d, i) => {
    if (d.status == 'completed') {
      let findVehicleData = VehicleDatatable.data.find(
        (veh) => veh?.vehicle_id == d?.vehicle_id
      );
      let findBatteryData = batteryDatatable.data.find(
        (veh) => veh?.battery_id == d?.battery_id
      );
      return (
        <MarkerF
          key={i}
          icon={
            websiteData.icon_type == 'scooter'
              ? scooter1
              : websiteData.icon_type == 'van'
              ? van1
              : websiteData.icon_type == 'solar'
              ? solarPanel
              : websiteData.icon_type == 'bin'
              ? bin
              : motorcycle
          }
          position={{ lat: parseFloat(d.end_lat), lng: parseFloat(d.end_long) }}
          onClick={() =>
            setPopupInfo({
              ...{ lat: parseFloat(d.end_lat), lng: parseFloat(d.end_long) },
              vehicleData: { ...findVehicleData },
              simulationData: { ...d },
              batteryData: { ...findBatteryData },
            })
          }
        />
      );
    } else {
      let findVehicleData = VehicleDatatable.data.find(
        (veh) => veh?.vehicle_id == d?.vehicle_id
      );
      let findBatteryData = batteryDatatable.data.find(
        (veh) => veh?.battery_id == d?.battery_id
      );
      return (
        <MarkerF
          key={i}
          icon={
            websiteData.icon_type == 'scooter'
              ? scooterRed
              : websiteData.icon_type == 'van'
              ? vanRed1
              : websiteData.icon_type == 'solar'
              ? solarPanelRed
              : websiteData.icon_type == 'bin'
              ? binRed
              : motorcycle_red
          }
          position={{
            lat: parseFloat(d.start_lat),
            lng: parseFloat(d.start_long),
          }}
          onClick={() =>
            setPopupInfo({
              ...{
                lat: parseFloat(d.start_lat),
                lng: parseFloat(d.start_long),
              },
              vehicleData: { ...findVehicleData },
              simulationData: { ...d },
              batteryData: { ...findBatteryData },
            })
          }
        />
      );
    }
  };

  // useEffect(() => {
  //   setTimeout(() => checkForPosition(props.filteredResult || simulation), 1000);
  //   intervalCall = setInterval(() => checkForPosition(props.filteredResult || simulation), 10000);
  // }, []);

  useEffect(() => {
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  useEffect(() => {
    checkForPosition(props.filteredResult);
    clearInterval(intervalCall);
    if (props.filteredResult.length > 0) {
      intervalCall = setInterval(
        () => checkForPosition(props.filteredResult),
        1000
      );
    }
  }, [props.filteredResult]);

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          {isLoaded ? (
            <GoogleMap
              id={'marker-example' + props.type}
              mapContainerStyle={{
                width: '100%',
                height: '600px',
              }}
              zoom={zoomLevel}
              onLoad={(map) => onMapLoad(map)}
              center={centerPosition}
              onClick={(ev) => {
                setPopupInfo();
              }}
            >
              <div style={{ position: 'absolute', top: 12, right: 60 }}>
                <Button variant="secondary" onClick={() => {
                  setCurrentPosition({lat: parseFloat(websiteData.home_location_lat), lng: parseFloat(websiteData.home_location_long)})
                  localStorage.setItem(
                    websiteData.name+'map_data',
                    JSON.stringify({ lat: parseFloat(websiteData.home_location_lat), lng: parseFloat(websiteData.home_location_long) })
                  );
                }}>Home Location</Button>
              </div>
              {directions !== null &&
                directions.map((m, i) => {
                  return (
                    <DirectionsRenderer
                      key={i}
                      directions={m}
                      options={{
                        preserveViewport: true,
                        suppressMarkers: true,
                        suppressPolylines: true,
                        polylineOptions: {
                          strokeOpacity: 1,
                          strokeColor: '#0071ff',
                        },
                      }}
                    />
                  );
                })}
              <MarkerClusterer
                minimumClusterSize={2}
                styles={[
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                    height: 55,
                    width: 55,
                    lineHeight: 58,
                    textColor: 'white',
                    textSize: '18',
                  },
                ]}
              >
                {(clusterer) => {
                  let markDown = [...markersToDraw];
                  return markDown;
                }}
              </MarkerClusterer>
              {popupInfo && (
                <InfoWindow
                  position={{ lat: popupInfo.lat, lng: popupInfo.lng }}
                  onCloseClick={() => setPopupInfo()}
                >
                  <div style={divStyle}>
                    <div>
                      <b>Vehicle Number:</b> {popupInfo.vehicleData?.vehicle_id}
                    </div>
                    <div>
                      <b>Vehicle Performance:</b>{' '}
                      {popupInfo.vehicleData?.performance}
                    </div>
                    <div>
                      <b>Vehicle IMEI:</b> {popupInfo.vehicleData?.imei}
                    </div>
                    <div>
                      <b>Vehicle Service Status:</b>{' '}
                      {popupInfo.vehicleData?.current_status}
                    </div>
                    <br />
                    <div>
                      <b>Rider:</b> {popupInfo.simulationData?.name}
                    </div>
                    <div>
                      <b>Trip Status:</b> {popupInfo.simulationData?.status}
                    </div>
                    <div>
                      <b>From:</b> {popupInfo.simulationData?.from}
                    </div>
                    <div>
                      <b>To:</b> {popupInfo.simulationData?.to}
                    </div>
                    <br />
                    <div>
                      <b>Battery Id:</b> {popupInfo.batteryData?.battery_id}
                    </div>
                    <div>
                      <b>Battery Level:</b>{' '}
                      {popupInfo.batteryData?.battery_level}
                    </div>
                    <div>
                      <b>Battery Cell Voltage:</b>{' '}
                      {popupInfo.batteryData?.battery_cell_voltage}
                    </div>
                    <div>
                      <b>Battery Charging State:</b>{' '}
                      {popupInfo.batteryData?.battery_charging_state}
                    </div>
                    <div>
                      <b>Battery Firmware Version:</b>{' '}
                      {popupInfo.batteryData?.battery_firmware_version}
                    </div>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          ) : null}
          <br />
        </Col>
      </Row>
    </div>
  );
}

export { simulation };

export default TemplateAssetLocationMap;
