import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import './contact.css'

const Contact = () => {
  return (
    <div>
      <div className='contact-header'>
        {/* <PageHeader titles="Contact" active="Contact" items={['Apps']} /> */}
      </div>
      <Container
        style={{
          padding: '30px',
          borderRadius: '10px',
          backgroundColor: '#fff',
        }}
        fluid
      >
        <Row className="row-sm">
          <Col sm={12} md={12} lg={12} xxl={6}>
            <div className="heading">Company</div>
            <div className="subheading">Demo</div>
          </Col>
          <Col sm={12} md={12} lg={12} xxl={6}>
            <div className="heading">Email Address</div>
            <div>demo@trackap.com</div>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: '20px' }} sm={12} md={12} lg={12} xxl={12}>
            <textarea
              style={{
                width: '100%',
                height: '300px',
                padding: '20px',
                outline: 'none',
                border: '1px solid #0000002b',
                borderRadius: '5px',
              }}
              placeholder="write here"
            ></textarea>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: '20px' }} sm={12} md={12} lg={12} xxl={12}>
            <Button variant="primary">Submit</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
