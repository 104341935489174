import { Col, Container, Row } from 'react-bootstrap';
import Logo from '../../assets/images/brand/keego_logo.png';

function DeviceRestrictionPreview() {
  return (
    <Container>
      <Row >
        <Col style={{display:"contents"}}>
            <img
              style={{ height: '35px' }}
              src={Logo}
              className="header-brand-img desktop-logo"
              alt="logo"
            />
            <div style={{marginTop:"10px"}}>
              You can't access the admin portal with screen resolution below
              600X768. suggested devices to interact with portal is tablets and
              laptops.
            </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DeviceRestrictionPreview;
