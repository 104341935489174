import { enqueueSnackbar } from 'notistack';
import http from '../../http';

export const httpApiCall = async (payload) => {
  let { method, endpoint, key, params } = payload;
  let response = await http(method, endpoint);
  response.data.master = ['master'];
  console.log('response.data: ', response.data);
  return response.data;
};

export const login = async (apiBody) => {
  let { payload } = apiBody;
  let response = await http('POST', 'api/v1/authenticate-user', {
    apiData: payload,
  });

  return response.data;
};

export const fetchContainerByFilterApiCall = async (apiBody) => {
  let { payload } = apiBody;
  let response = await http('POST', 'api/v1/list-containers/', {
    apiData: { container_ids: payload },
  });

  return response.data.containers_data;
};

export const saveRouteApiCall = async (apiBody) => {
  let { payload } = apiBody;
  let response = await http('POST', 'api/v1/container-filter-history/add', {
    apiData: payload,
  });

  enqueueSnackbar({
    message: 'Route Saved Successfully',
    variant: 'success',
  });

  return response.data;
};
