const data = {
  datatable: {
    data: [],
    filteredData: [],
    meta: {},
    filters: [],
  },
  page_info: {
    map_data_filteration_key: '',
  },
  statistics: [],
};

export const initialState = {
  loading: true,
  module: 'vehicles',
  vehicles: data,
};
