import React from 'react';
import { createRoot } from 'react-dom/client';
import AppRoutes from './AppRoutes';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import './css/index.css';
import DeviceRestrictionPreview from './screens/DeviceRestictionPreview/DeviceRestrictionPreview';
import AppProvider from './providers/AppProvider';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AppProvider>
    <AppRoutes />
    {/* <div className="istablet">
      <AppRoutes />
    </div> */}
    {/* <div className="isMobile">
      <DeviceRestrictionPreview />
    </div> */}
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
