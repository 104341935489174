import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Layout from './Layouts/Layout';
import Chat from './screens/Chat/Chat';
import Login from './screens/Login';
import Contact from './screens/Contact/Contact';
import Statistics from './screens/Statistics/Statistics';
import Vehicles from './screens/Vehicles';
import CreateGeofencing from './screens/Geofencing/CreateGeofencing';
import ManageGeofencing from './screens/Geofencing/';
import MapsData from './screens/Maps/MapsData';
import ActiveOrder from './screens/OrderManagement/ActiveOrder';
import PendingDeliveries from './screens/OrderManagement/PendingDeliveries';
import CompletedDeliveries from './screens/OrderManagement/CompletedDeliveries';
import Customers from './screens/CRM';
import Riders from './screens/CRM/Riders';
import Subscription from './screens/Subscriptions/Subscription';
import Services from './screens/Services/Services';
import BillingModel from './screens/BillingModel';
import Battery from './screens/Battery';
import Inspection from './screens/Inspection/Inspection';
import ServiceReminder from './screens/ServiceReminder/ServiceReminder';
import OperatorReport from './screens/OperatorReport/OperatorReport';
import Issues from './screens/Issues/Issues';
import WorkOrder from './screens/WorkOrder/WorkOrder';
import MaintenanceStaff from './screens/CRM/MaintenanceStaff';
import { useSelector } from 'react-redux';
import { selectAuthUser, selectIsAuthenticated } from './redux/auth/selectors';
import Website from './screens/Website';
import NotFound from './screens/NotFound';
import FleetManagers from './screens/FleetManagers/FleetManagers';
import Routing from './screens/Routing';
import OperatorModule from './screens/OperatorModules/OperatorModule';
import Deliveries from './screens/Deliveries';
import Dustbins from './screens/Assets/Dustbins';
import SolarPanels from './screens/Assets/SolarPanels';
import IOT from './screens/IOT';
import Configurations from './screens/Settings/Configurations';
import Dashboard from './screens/Dashboard';
import GeneralPageLayout from './screens/GeneralPageLayout/GeneralPageLayout';
import AdminUsers from './screens/AdminUsers';
import Form from './screens/Forms';
import Stations from './screens/Stations';
import Rules from './screens/Rules';
import RulesOnGeofencing from './screens/RulesOnGeofencing';
import Trips from './screens/Trips/Trips';
import CustomerCards from './screens/CRM/CustomerCards';
import CustomerCardTransaction from './screens/CRM/CustomerCardTransaction';
import RulesViolation from './screens/RulesOnGeofencing/RulesViolation';
import Containers from './screens/Assets/Containers';
import WavSmartRouting from './screens/Routing/WavSmartRouting';

export default function AppRoutes() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectAuthUser);
  // const navigate=useNavigate()

  // useEffect(()=>{
  //   if(user.user_type.length==1){
  //     dispatch(openWebsite(Websites.find(website=>website.id==user.user_type[0])))
  //     navigate('/dashboard')
  //   }
  // },[])

  // The menuitems are removed from localstorage when a user changes a route manually.
  useEffect(() => {
    if (location.pathname && location.pathname == '/') {
      localStorage.removeItem('menuitems');
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              element={<Website />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/dashboard`}
              element={<Dashboard />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/configuration`}
              element={
                <Layout>
                  <Configurations />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/chat`}
              element={
                <Layout>
                  <Chat />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/vehicles`}
              element={<Vehicles />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/stations`}
              element={
                <Layout>
                  <Stations />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/vehicles/add`}
              element={
                <Layout>
                  <Form />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/deliveries`}
              element={
                <Layout>
                  <Deliveries />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/battries`}
              element={
                <Layout>
                  <Battery />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/dustbins`}
              element={
                <Layout>
                  <Dustbins />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/containers`}
              element={
                <Layout>
                  <WavSmartRouting />
                  {/* <Containers /> */}
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/iot`}
              element={
                <Layout>
                  <IOT />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/trips`}
              element={
                <Layout>
                  <Trips />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/users`}
              element={
                <Layout>
                  <AdminUsers />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/solar-panels`}
              element={
                <Layout>
                  <SolarPanels />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/services`}
              element={
                <Layout>
                  <Services />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/subscriptions`}
              element={
                <Layout>
                  <Subscription />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/:page_name`}
              element={
                <Layout>
                  <GeneralPageLayout />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/billing-model`}
              element={
                <Layout>
                  <BillingModel />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/statistics`}
              element={
                <Layout>
                  <Statistics />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              element={
                <Layout>
                  <Contact />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/services/inspection`}
              element={
                <Layout>
                  <Inspection />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/services/service-reminder`}
              element={
                <Layout>
                  <ServiceReminder />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/services/operator-report`}
              element={
                <Layout>
                  <OperatorReport />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/reporting`}
              element={<Dashboard />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/routing`}
              element={
                <Layout>
                  <Routing />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/services/issues`}
              element={
                <Layout>
                  <Issues />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/services/work-order`}
              element={
                <Layout>
                  <WorkOrder />
                </Layout>
              }
            />
            {/* <Route
          exact
          path={`${process.env.PUBLIC_URL}/map`}
          element={
            <Layout>
              <Map />
            </Layout>
          }
        /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/map`}
              element={
                <Layout>
                  <MapsData />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/customers`}
              element={
                <Layout>
                  <Customers />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/customer-cards`}
              element={
                <Layout>
                  <CustomerCards />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/customer-card-transaction`}
              element={
                <Layout>
                  <CustomerCardTransaction />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/fleet-managers`}
              element={
                <Layout>
                  <FleetManagers />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/riders`}
              element={
                <Layout>
                  <Riders />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/maintenance`}
              element={
                <Layout>
                  <MaintenanceStaff />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/operator-modules`}
              element={
                <Layout>
                  <OperatorModule />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/orders/active`}
              element={
                <Layout>
                  <ActiveOrder />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/orders/pending`}
              element={
                <Layout>
                  <PendingDeliveries />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/orders/delivered`}
              element={
                <Layout>
                  <CompletedDeliveries />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/geofencing/create-geofencing`}
              element={
                <Layout>
                  <CreateGeofencing />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/geofencing/manage-geofencing`}
              element={
                <Layout>
                  <ManageGeofencing />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/geofencing/rules`}
              element={
                <Layout>
                  <Rules />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/geofencing/rules-on-geofencing`}
              element={
                <Layout>
                  <RulesOnGeofencing />
                </Layout>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/geofencing/rules-violation`}
              element={
                <Layout>
                  <RulesViolation />
                </Layout>
              }
            />
          </>
        ) : (
          <>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              element={<Login />}
            />
            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}
