import { Button, Card, Col, Row } from 'react-bootstrap';
import styles from './DataTable.module.scss';
import {
  BasicDataTable,
  ResponsiveDataTable,
  ExportCSV,
} from '../../Data/TablesData/TableData';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  MarkerClusterer,
  DirectionsRenderer,
  InfoWindow,
} from '@react-google-maps/api';
import { useState, useEffect, useMemo } from 'react';
import {
  PendingDeliveriesData,
  pendingDeliveries,
  vehicleLocation,
  vehicleData,
  allSimulationData,
  activeOrdersMeta,
} from '../../Data/DummyData';
import homeIcon from '../../assets/images/media/endMarker.png';
import pickUpIcon from '../../assets/images/media/pickupMarker.png';
import motorcycle from '../../assets/images/media/motorcycle.png';
import motorcycle_red from '../../assets/images/media/motorcycle_red.png';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import scooter from '../../assets/images/media/scooter.png';
import van from '../../assets/images/media/van.png';
import scooterRed from '../../assets/images/media/scooter_red.png';
import vanRed from '../../assets/images/media/van_red.png';
import { useSelector } from 'react-redux';
import { selectWebsiteData } from '../../redux/general/selectors';
import vanRed1 from '../../assets/images/media/van_red1.png';
import van1 from '../../assets/images/media/van1.png';
import scooter1 from '../../assets/images/media/scooter1.png';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import solarPanel from '../../assets/images/media/solar_panel.png';
import solarPanelRed from '../../assets/images/media/solar_panel_red.png';
import bin from '../../assets/images/media/bin.png';
import binRed from '../../assets/images/media/bin_red.png';
import { DashboardStatistics } from '../../Data/DummyData';
import { useInfoCtx } from '../../providers/InfoCardProvider';

let directionsService;
let activeInteral;

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15,
};

let currentSimulationData = allSimulationData.filter((d) => d.start_time != '');

let simulation = [];
let activeRider = [];
let mapref = null;
let mapInterval;

function changeSimulationData() {
  simulation = currentSimulationData.map((d) => {
    let currentTime = new Date();
    currentTime = currentTime.getMinutes();
    let totalTime =
      parseInt(d.start_time) + parseInt(d.estimated_trip_duration);
    // console.log('simulation id', d.id, currentTime, totalTime, d.start_time);
    let status;
    if (currentTime >= totalTime) {
      status = 'completed';
    } else if (currentTime < d.start_time) {
      status = 'pending';
    } else {
      status = 'active';
    }

    d = { ...d, status: status };

    return d;
  });

  // console.log('simulation12', simulation);
  activeRider = simulation.filter((d) => d.status == 'active');
  simulation = simulation.filter((d) => d.status == 'pending');
}

changeSimulationData();

function PendingDeliveries() {
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();

  // const [activeOrders, setActiveOrders] = useState(
  //   simulation.filter((data) => data.status == 'pending')
  // );

  const columns = useMemo(() => {
    return computeColumns({
      data: simulation,
      meta: activeOrdersMeta,
      action: ({ row, meta }) => {
        setRowData(row);
        setColumnMetaData(meta);
        setModalStatus(true);
      },
    });
  }, []);

  const { renderInfoCardData } = useInfoCtx();
  const data = useMemo(() => simulation, [simulation]);

  let { isLoaded } = useJsApiLoader({
    id: 'drawing-manager-example',
    googleMapsApiKey: 'AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac',
    libraries: ['drawing'],
  });

  const websiteData = useSelector(selectWebsiteData);
  const [centerPosition, setCurrentPosition] = useState(
    localStorage.getItem(websiteData.name + 'map_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'map_data'))
      : {
          lat: parseFloat(websiteData.home_location_lat),
          lng: parseFloat(websiteData.home_location_long),
        }
  );
  const [zoomLevel, setZoomLevel] = useState(
    localStorage.getItem(websiteData.name + 'zoom_data')
      ? JSON.parse(localStorage.getItem(websiteData.name + 'zoom_data'))
      : 12
  );
  const [directions, setDirections] = useState([]);
  const [popupInfo, setPopupInfo] = useState('');
  // const [updateStatus, setUpdateStatus] = useState(false);
  const [stateValue, setStateValue] = useState();

  const onMapLoad = (map) => {
    mapref = map;
    google.maps.event.addListener(map, 'dragend', function () {
      const newCenter = mapref.getCenter();
      localStorage.setItem(
        websiteData.name + 'map_data',
        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() })
      );
    });

    google.maps.event.addListener(map, 'zoom_changed', function () {
      const newCenter = mapref.getZoom();
      localStorage.setItem(
        websiteData.name + 'zoom_data',
        JSON.stringify(newCenter)
      );
    });
    directionsService = new google.maps.DirectionsService();
    //load default origin and destination
    getDirections();
  };

  const getDirections = () => {
    // let id = 0;
    simulation.forEach((data) => {
      setTimeout(
        () =>
          changeDirection(
            {
              lat: parseFloat(data.start_lat),
              lng: parseFloat(data.start_long),
            },
            { lat: parseFloat(data.end_lat), lng: parseFloat(data.end_long) },
            data.id
          ),
        data.id * 10
      );
      // id = id+1;
    });
  };

  //function that is calling the directions service
  const changeDirection = (origin, destination) => {
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: 'DRIVING',
        // preserveViewport: true
      },
      (result, status) => {
        if (status) {
          //changing the state of directions to the result of direction service
          let data = directions;
          data.push(result);
          setDirections([...data]);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const getCurrentActive = () => {
    changeSimulationData();
    setStateValue(new Date());
    // directionsService.setMap(null);
    // getDirections();
  };

  useEffect(() => {
    if (isLoaded == true) {
      // setTimeout(
      //   () =>
      //     setCurrentPosition(
      //       localStorage.getItem(websiteData.name+'map_data')
      //         ? JSON.parse(localStorage.getItem(websiteData.name+'map_data'))
      //         : ''
      //     ),
      //   1000
      // );
      getCurrentActive();
      activeInteral = setInterval(() => getCurrentActive(), 10000);
      // setTimeout(() => setUpdateStatus(true), 2000);
      // setTimeout(
      //   () =>
      //     setZoomLevel(
      //       localStorage.getItem(websiteData.name+'zoom_data')
      //         ? JSON.parse(localStorage.getItem(websiteData.name+'zoom_data'))
      //         : ''
      //     ),
      //   2000
      // );
    }
  }, [isLoaded]);

  useEffect(() => {
    return () => {
      clearInterval(activeInteral);
    };
  }, []);

  let homeIconMarker;
  let pickUpIconMarker;

  useEffect(() => {
    if (isLoaded == true) {
      homeIconMarker = new window.google.maps.MarkerImage(
        homeIcon,
        null /* size is determined at runtime */,
        null /* origin is 0,0 */,
        null /* anchor is bottom center of the scaled image */,
        new window.google.maps.Size(20, 20)
      );

      pickUpIconMarker = new window.google.maps.MarkerImage(
        pickUpIcon,
        null /* size is determined at runtime */,
        null /* origin is 0,0 */,
        null /* anchor is bottom center of the scaled image */,
        new window.google.maps.Size(20, 20)
      );
    }
  }, [isLoaded]);

  // const updateLastMapValue = () => {
  //   if (mapref && updateStatus) {
  //     const newCenter = mapref.getCenter();
  //     localStorage.setItem(
  //       websiteData.name+'map_data',
  //       JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() })
  //     );
  //   }
  // };

  // const updateLastZoomValue = () => {
  //   if (mapref && updateStatus) {
  //     const newZoom = mapref.getZoom();
  //     localStorage.setItem(websiteData.name+'zoom_data', JSON.stringify(newZoom));
  //   }
  // };

  // const zoomLevelChange = () => {
  //   if (mapref && updateStatus) {
  //     const newZoom = mapref.getZoom();
  //     // console.log('zoomlevelchange', newZoom);
  //     localStorage.setItem(websiteData.name+'zoom_data', JSON.stringify(newZoom));
  //   }
  // };

  // useEffect(() => {
  //   return () => {
  //     updateLastMapValue();
  //   };
  // }, []);

  // const mapZoomCheck = () => {
  //   const newZoom = mapref.getZoom();
  //   // console.log('mapCheck', newZoom)
  //   localStorage.setItem(websiteData.name+'zoom_data', JSON.stringify(newZoom));
  // }

  // useEffect(() => {
  //   clearInterval(mapInterval);
  //   mapInterval = setInterval(() => mapZoomCheck(), 2000);
  // }, [])

  return (
    <div className={styles.DataTable}>
      <PageHeader titles="Deliveries" active="Deliveries" items={['Tables']} />

      <Row>
        {DashboardStatistics.filter(
          (item) => item.module === 'pending_deliveries'
        ).map((card, index) => (
          <Col lg={6} key={index} md={6} sm={12} xxl={3}>
            <Card className="overflow-hidden">
              <Card.Body>
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="">{card.heading}</h6>
                    <h2 className="mb-0 number-font">
                      {renderInfoCardData(card)}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1"></div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {/* <Col lg={6} md={6} sm={12} xxl={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Pending Orders</h6>
                  <h2 className="mb-0 number-font">150</h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={12} xxl={3}>
          <div className="card overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Ready to Dispatch</h6>
                  <h2 className="mb-0 number-font">130</h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} xxl={3}>
          <div className="card overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">New Orders</h6>
                  <h2 className="mb-0 number-font">20</h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </div>
        </Col> */}
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          {isLoaded ? (
            <GoogleMap
              id="marker-example"
              mapContainerStyle={{
                width: '100%',
                height: '600px',
              }}
              zoom={zoomLevel}
              // onZoomChanged={zoomLevelChange}
              onLoad={(map) => onMapLoad(map)}
              // onCenterChanged={() => updateLastMapValue()}
              center={centerPosition}
              onClick={(ev) => {
                setPopupInfo();
                // console.log('latitide = ', ev.latLng.lat());
                // console.log('longitude = ', ev.latLng.lng());
              }}
            >
              <div style={{ position: 'absolute', top: 12, right: 60 }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setCurrentPosition({
                      lat: parseFloat(websiteData.home_location_lat),
                      lng: parseFloat(websiteData.home_location_long),
                    });
                    localStorage.setItem(
                      websiteData.name + 'map_data',
                      JSON.stringify({
                        lat: parseFloat(websiteData.home_location_lat),
                        lng: parseFloat(websiteData.home_location_long),
                      })
                    );
                  }}
                >
                  Home Location
                </Button>
              </div>
              {directions !== null &&
                directions.map((m, i) => {
                  return (
                    <DirectionsRenderer
                      directions={m}
                      options={{
                        preserveViewport: true,
                        suppressMarkers: true,
                        polylineOptions: {
                          strokeOpacity: 1,
                          strokeColor: '#FF0000',
                        },
                      }}
                    />
                  );
                })}
              <MarkerClusterer
                minimumClusterSize={2}
                styles={[
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                    height: 55,
                    width: 55,
                    lineHeight: 58,
                    textColor: 'white',
                    textSize: '18',
                  },
                ]}
              >
                {(clusterer) => [
                  ...vehicleLocation.map((d, i) => {
                    let findVehicleData = vehicleData.find(
                      (veh) => veh.vehicle_number == d.id
                    );
                    let activeVehicle = activeRider.findIndex(
                      (veh) => veh.vehicle_id == d.vehicle_number
                    );
                    // console.log('acvite', activeVehicle);
                    return activeVehicle == -1 ? (
                      <MarkerF
                        key={i}
                        icon={
                          activeVehicle != -1
                            ? websiteData.icon_type == 'scooter'
                              ? scooter1
                              : websiteData.icon_type == 'van'
                              ? van1
                              : websiteData.icon_type == 'solar'
                              ? solarPanel
                              : websiteData.icon_type == 'bin'
                              ? bin
                              : motorcycle
                            : websiteData.icon_type == 'scooter'
                            ? scooterRed
                            : websiteData.icon_type == 'van'
                            ? vanRed1
                            : websiteData.icon_type == 'solar'
                            ? solarPanelRed
                            : websiteData.icon_type == 'bin'
                            ? binRed
                            : motorcycle_red
                        }
                        // label={{text: ' ', color:'black', fontSize: '18px'}}
                        position={{ lat: d.latitude, lng: d.longitude }}
                        clusterer={clusterer}
                        onClick={() =>
                          setPopupInfo({
                            lat: d.latitude,
                            lng: d.longitude,
                            ...findVehicleData,
                          })
                        }
                      />
                    ) : (
                      ''
                    );
                  }),
                  ...simulation.map((data, i) => {
                    let currentDate = new Date();
                    return (
                      <MarkerF
                        key={i + 'pickup'}
                        label={{
                          text: currentDate.getHours() + ':' + data.start_time,
                          color: 'black',
                          fontSize: '16px',
                        }}
                        icon={pickUpIconMarker}
                        position={{
                          lat: parseFloat(data.start_lat),
                          lng: parseFloat(data.start_long),
                        }}
                      />
                    );
                  }),
                  //marker for drop location
                  ...simulation.map((data, i) => {
                    return (
                      <MarkerF
                        key={i + 'drop'}
                        icon={homeIconMarker}
                        position={{
                          lat: parseFloat(data.end_lat),
                          lng: parseFloat(data.end_long),
                        }}
                      />
                    );
                  }),
                ]}
              </MarkerClusterer>
              {popupInfo && (
                <InfoWindow
                  position={{ lat: popupInfo.lat, lng: popupInfo.lng }}
                  onCloseClick={() => setPopupInfo()}
                >
                  <div style={divStyle}>
                    <div>Vehicle Number: {popupInfo.vehicle_number}</div>
                    <div>Number Of Rides: {popupInfo.number_of_rides}</div>
                    <div>Performance: {popupInfo.performance}</div>
                    <div>Battery Level: {popupInfo.battery_level}</div>
                    <div>Current Status: {popupInfo.current_status}</div>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          ) : null}
          <br />
        </Col>
      </Row>
      {/* <!-- Row --> */}
      <Row className="row-sm">
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h3">Basic Datatable</Card.Title>
            </Card.Header>
            <Card.Body>
              <Datatable
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <!-- End Row --> */}
    </div>
  );
}

export default PendingDeliveries;
