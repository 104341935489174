import GeneralIOT from './GeneralIOT';
import IOT from './IOT';
import BSIOT from './BSIOT';
import DiageoIOT from './DiageoIOT';
import WavSmartIOT from './WAVSmart';

function Routing() {
  let app = sessionStorage.getItem('app_key');

  const renderComponent = () => {
    switch (app) {
      case 'superfy':
        return <IOT />;
      case 'abc_solar':
        return <IOT />;
      case 'citkar':
        return <IOT />;
      case 'diageo':
        return <DiageoIOT />;
      case 'wav_smart':
        return <WavSmartIOT/>;
      default:
        return <BSIOT />;
    }
  };

  return renderComponent();
}

export default Routing;
