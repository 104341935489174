import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import DatatableContext from '../components/Datatable/DatatableContext';
import {
  // persistor,
  store,
} from '../redux/store';
import InfoCardProvider from './InfoCardProvider';
import { SnackbarProvider } from 'notistack';
import ModalProvider from './ModalProvider';
import RootErrorBoundary from '../ErrorBoundaries/RootErrorBoundary';

function AppProvider({ children }) {
  return (
    <Fragment>
      <RootErrorBoundary>
        <Provider store={store}>
          <ModalProvider>
            <SnackbarProvider>
              {/* <PersistGate persistor={persistor}> */}
              <InfoCardProvider>
                <DatatableContext>{children}</DatatableContext>
              </InfoCardProvider>
              {/* </PersistGate> */}
            </SnackbarProvider>
          </ModalProvider>
        </Provider>
      </RootErrorBoundary>
    </Fragment>
  );
}

export default AppProvider;
