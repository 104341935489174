import { takeEvery, put, call, takeLatest, select } from 'redux-saga/effects';
import TYPES from '../types';
import {
  addFormData,
  deleteFormData,
  editFormData,
  fetchPageData,
  getPermissions,
} from './api';
import {
  setFilteredData,
  setLoadingState,
  fetchData as fetchTemplateData,
  setModuleData,
  setFilteredDataFetchApi,
} from './actions';
import { selectModule, selectFilteredData } from './selectors';
import { enqueueSnackbar } from 'notistack';
import { setActiveWebsiteData, setModalStatus } from '../actions';
import _ from 'lodash';

function* fetchData({ payload, options }) {
  let { page_name } = payload;
  let { reUpdateSourceData } = options;
  yield put(setLoadingState(true));
  console.log('UDPATEIGN FILTERED SOURCE DATA {options}: ', options);
  // console.log('MODAULE HERE: ', payload);
  let response = yield call(fetchPageData, payload);
  let { data, meta, page_info, statistics } = response;
  if (reUpdateSourceData == true) {
    console.log('UDPATEIGN FILTERED SOURCE DATA: ', reUpdateSourceData);
    yield put(setFilteredDataFetchApi({ data, page_name }));
  }
  yield put(
    setModuleData({
      module: page_name,
      data,
      meta,
      page_info,
      statistics,
    })
  );
  yield put(setLoadingState(false));
}

function* editData({ payload }) {
  let module = yield select(selectModule);
  payload.module = module;

  yield put(setLoadingState(true));

  try {
    yield call(editFormData, {
      apiBody: payload,
      id: payload.vehicle_id,
    });

    yield put(setModalStatus(false));
  } catch (er) {}

  yield put(fetchTemplateData({ module }));

  yield put(setLoadingState(false));
}

function* fetchPositionData({ payload, options }) {
  let { page_name } = payload;
  let filteredData = yield select(selectFilteredData);
  yield put(setLoadingState(true));

  let response = yield call(fetchPageData, payload);
  let { data, meta, page_info, statistics } = response;
  let ids = filteredData.map((data) => data.vehicle_id);
  console.log(
    'FETCHPOSTITON DATA: ',
    data.filter((data) => ids.includes(data.vehicle_id))
  );
  yield put(
    setFilteredData(data.filter((data) => ids.includes(data.vehicle_id)))
  );
  // if (reUpdateSourceData)
  //   yield put(
  //     setModuleData({
  //       module: page_name,
  //       data,
  //       meta,
  //       page_info,
  //       statistics,
  //     })
  //   );
  yield put(setLoadingState(false));
}

function* addData({ payload }) {
  yield put(setLoadingState(true));
  let module = '';

  if (payload.module) {
    module = payload.module;
  } else module = yield select(selectModule);

  let defaultModule = yield select(selectModule);

  payload.module = module;

  try {
    yield call(addFormData, payload);
    yield put(setModalStatus(false));
  } catch (er) {}

  yield put(fetchTemplateData({ module: defaultModule }));

  yield put(setLoadingState(false));
}

function* deleteData({ payload }) {
  let module = yield select(selectModule);

  yield call(deleteFormData, {
    apiBody: { ...payload, module },
    id: payload.vehicle_id,
  });

  yield put(fetchTemplateData({ module }));
}

function* openWebsite({ payload }) {
  console.log('PAYLOAD OPEN WEBSITE: ', payload);
  let data = yield call(getPermissions);
  let { fleet_manager_permissions, operator_type_permissions } = data;

  yield put(
    setActiveWebsiteData({
      website: payload,
      fleet_manager_permissions,
      operator_type_permissions,
    })
  );
}

function* TemplateDataSaga() {
  yield takeLatest(TYPES.ADD_DATA, addData);
  yield takeLatest(TYPES.EDIT_DATA, editData);
  yield takeLatest(TYPES.DELETE_DATA, deleteData);
  yield takeEvery(TYPES.FETCH_MODULE_TEMPLATE_DATA, fetchData);
  yield takeEvery(
    TYPES.FETCH_MODULE_TEMPLATE_UPDATE_POSITION_DATA,
    fetchPositionData
  );
  yield takeEvery(TYPES.WEBSITE, openWebsite);
}

export default TemplateDataSaga;
