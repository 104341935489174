export const renderStyleSheet = (websiteData) => {
  if (websiteData.app_key === 'superfy') {
    import('../Layouts/Sidebar/sidebar-superfy.css');
  } else if (websiteData.app_key === 'keego') {
    import('../Layouts/Sidebar/sidebar-keego.css');
  } else if (websiteData.app_key === 'biketek') {
    import('../Layouts/Sidebar/sidebar-biketek.css');
  } else if (websiteData.app_key === 'gramobility') {
    import('../Layouts/Sidebar/sidebar-gramobility.css');
  } else if (websiteData.app_key === 'moby') {
    import('../Layouts/Sidebar/sidebar-moby.css');
  } else if (websiteData.app_key === 'citkar') {
    import('../Layouts/Sidebar/sidebar-citkar.css');
  } else if (websiteData.app_key === 'diageo') {
    import('../Layouts/Sidebar/sidebar-diageo.css');
  } else if (websiteData.app_key === 'abc_solar') {
    import('../Layouts/Sidebar/sidebar-abc-solar.css');
  } else if (websiteData.app_key === 'bombay_softwares') {
    import('../Layouts/Sidebar/sidebar-bs.css');
  } else if (websiteData.app_key === 'move_scooters') {
    import('../Layouts/Sidebar/sidebar-move_scooters.css');
  } else if (websiteData.app_key === 'volti') {
    import('../Layouts/Sidebar/sidebar-voltie.css');
  } else if (websiteData.app_key === 'ewaka') {
    import('../Layouts/Sidebar/sidebar-ewaka.css');
  } else if (websiteData.app_key === 'master') {
    import('../Layouts/Sidebar/sidebar-master.css');
  } else if (websiteData.app_key === 'wav_smart') {
    import('../Layouts/Sidebar/sidebar-wav_smart.css');
  } else {
    import('../Layouts/Sidebar/sidebar.css');
  }
};
