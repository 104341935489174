import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  IssuesDatatable,
  WorkOrderDatatable,
  InspectionWithMetaData,
  OperatorReportDatatable,
  ServiceWithMetaData,
} from '../Data/DummyData';
import {
  compute,
  computeInTime,
  total,
} from '../Data/WebsiteData/Statistics/interpreter.ts';
import { allSimulationData } from '../Data/DummyData';
import { RidersData } from '../Data/DummyData';
import { selectTemplateData } from '../redux/templateData/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { initialState } from '../redux/templateData/init';
import {
  addPage,
  fetchDashboardStatistics,
  fetchData,
  setModule,
} from '../redux/templateData/actions';
import { selectIsAuthenticated, selectWebsites } from '../redux/auth/selectors';
import {
  selectFleetManagerPermissions,
  selectWebsiteData,
} from '../redux/general/selectors';
import moment from 'moment';
import { convertUTCToLocal } from '../utils';
// import { AdminUsersData, Websites } from '../Data/WebsiteData/efleety';
import { openWebsite, setAuthUser, setWebsites } from '../redux/actions';
const _ = require('lodash');

const InfoCardCtx = createContext();

function InfoCardProvider({ children }) {
  const data = useSelector(selectTemplateData);
  const permissions = useSelector(selectFleetManagerPermissions);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const website = useSelector(selectWebsiteData);
  const Websites = useSelector(selectWebsites);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      let user = localStorage.getItem('user');
      let parsedUser = JSON.parse(user);
      let websites = localStorage.getItem('websites');
      let parsedWebsites = JSON.parse(websites);

      // dispatch(
      //   openWebsite(
      //     Websites.find(
      //       (website) => website.app_key == sessionStorage.getItem('app_key')
      //     )
      //   )
      // );

      if (user) {
        dispatch(setAuthUser(parsedUser));
        dispatch(setWebsites(parsedWebsites));
      }
    }
  }, []);

  useEffect(() => {
    if (
      isAuthenticated &&
      sessionStorage.getItem('app') &&
      sessionStorage.getItem('app') !== 'master' &&
      website?.app_key &&
      permissions
    ) {
      let modules = permissions?.reduce((accumulator, permission) => {
        if (
          [
            'rules',
            'customers',
            'vehicles',
            'stations',
            'iot_types',
            'trips',
          ].includes(permission)
        ) {
          accumulator.push(permission);
        }
        return accumulator;
      }, []);

      if (
        !['citkar', 'master', 'diageo', 'abc_solar'].includes(website.app_key)
      ) {
        modules.push('iot_types');
        modules.push('trips');
      }

      if (['wav_smart'].includes(website.app_key)) {
        modules.push('containers');
      }

      modules.map((module) => {
        if (!initialState.hasOwnProperty(module)) {
          dispatch(
            addPage({
              key: module,
              data: initialState.vehicles,
            })
          );
        }
        dispatch(fetchData({ module }));
      });
    }
  }, [isAuthenticated, website, permissions]);

  const DataSourceMapping = useMemo(
    () => ({
      vehicle: data.vehicles.datatable.data || [],
      battery: data?.batteries?.datatable?.data || [],
      issues: IssuesDatatable.data,
      workorder: WorkOrderDatatable.data,
      inspection: InspectionWithMetaData.data,
      operator_report: OperatorReportDatatable.data,
      service_staff: data?.service_staff?.datatable?.data || [],
      stations: data?.stations?.datatable?.data || [],
      iot: data?.iot?.datatable?.data || [],
      customers: data?.customers?.datatable?.data || [],
      trips: data?.trips?.datatable?.data || [],
      payment_transactions: data?.payment_transactions?.datatable?.data || [],
      payment_cards: data?.payment_cards?.datatable?.data || [],
      containers: data?.containers?.datatable?.data || [],
    }),
    [data]
  );

  function getDataByTimeframe(data) {
    const today = moment().startOf('day');
    const thisWeek = moment().startOf('week');
    const thisMonth = moment().startOf('month');

    const todayData = [];
    const weekData = [];
    const monthData = [];

    const todayTripSortedByEndedAt = [];
    const weekTripSortedByEndedAt = [];
    const monthTripSortedByEndedAt = [];

    data.forEach((obj) => {
      const createdAt = convertUTCToLocal(obj.created_at).obj;
      const ended_at = convertUTCToLocal(obj.ended_at).obj;

      if (ended_at.isSameOrAfter(today)) {
        todayTripSortedByEndedAt.push(obj);
      }

      if (ended_at.isSameOrAfter(thisWeek)) {
        weekTripSortedByEndedAt.push(obj);
      }

      if (ended_at.isSameOrAfter(thisMonth)) {
        monthTripSortedByEndedAt.push(obj);
      }

      if (createdAt.isSameOrAfter(today)) {
        todayData.push(obj);
      }

      if (createdAt.isSameOrAfter(thisWeek)) {
        weekData.push(obj);
      }

      if (createdAt.isSameOrAfter(thisMonth)) {
        monthData.push(obj);
      }
    });

    return {
      today: todayData,
      week: weekData,
      month: monthData,
      todayTripSortedByEndedAt: todayTripSortedByEndedAt,
      weekTripSortedByEndedAt: weekTripSortedByEndedAt,
      monthTripSortedByEndedAt: monthTripSortedByEndedAt,
    };
  }

  const renderInfoCardDataFunction = ({ action, type }) => {
    let todayWeekMonthData = { today: [], week: [], month: [] };
    let validEstimatedRunoutDays;

    if (type == 'trips' || type == 'customers') {
      todayWeekMonthData = getDataByTimeframe(DataSourceMapping[type]);
      // console.log('todayWeekMonthData: ', todayWeekMonthData);
    }

    switch (action) {
      case 'new_customer_today':
        return todayWeekMonthData.today.length;
      case 'new_customer_week':
        return todayWeekMonthData.week.length;
      case 'new_customer_month':
        return todayWeekMonthData.month.length;

      case 'today_tips':
        return todayWeekMonthData.todayTripSortedByEndedAt.length;
      case 'trips_this_week':
        return todayWeekMonthData.weekTripSortedByEndedAt.length;
      case 'trips_this_month':
        return todayWeekMonthData.monthTripSortedByEndedAt.length;
      case 'total':
        return total({ source: DataSourceMapping[type] });
      case 'total_OC32':
        return total({
          source: DataSourceMapping[type].filter(
            (item) => item.model_type == 'OC32'
          ),
        });
      case 'total_OC30':
        return total({
          source: DataSourceMapping[type].filter(
            (item) => item.model_type == 'OC30'
          ),
        });
      case 'total_BL10':
        return total({
          source: DataSourceMapping[type].filter(
            (item) => item.model_type == 'BL10'
          ),
        });
      case 'total_BL11':
        return total({
          source: DataSourceMapping[type].filter(
            (item) => item.model_type == 'BL11'
          ),
        });
      case 'total_XM-IOT':
        return total({
          source: DataSourceMapping[type].filter(
            (item) => item.model_type == 'XM-IOT'
          ),
        });
      case 'revenue_today':
        return compute({
          source: todayWeekMonthData.todayTripSortedByEndedAt,
          unit: website?.currency?.symbol,
          of: 'total_fare',
          hasFrontUnit: true,
        });
      case 'revenue_week':
        return compute({
          source: todayWeekMonthData.weekTripSortedByEndedAt,
          unit: website?.currency?.symbol,
          of: 'total_fare',
          hasFrontUnit: true,
        });
      case 'today_ride_duration':
        return computeInTime({
          source: todayWeekMonthData.todayTripSortedByEndedAt,
          unit: ' minutes',
          of: { start: 'started_at', end: 'ended_at' },
        });
      case 'week_ride_duration':
        return computeInTime({
          source: todayWeekMonthData.weekTripSortedByEndedAt,
          unit: ' minutes',
          of: { start: 'started_at', end: 'ended_at' },
        });
      case 'month_ride_duration':
        return computeInTime({
          source: todayWeekMonthData.monthTripSortedByEndedAt,
          unit: ' minutes',
          of: { start: 'started_at', end: 'ended_at' },
        });
      case 'total_locked_devices':
        return total({
          source: DataSourceMapping[type],
          unit: '',
          // of: 'status',
          condition: [{ type: 'status', value: 'Locked' }],
        });
      case 'total_unlocked_devices':
        return total({
          source: DataSourceMapping[type],
          unit: '',
          // of: 'status',
          condition: [{ type: 'status', value: 'unLocked' }],
        });
      case 'active_rides':
        return total({
          source: todayWeekMonthData.month,
          unit: '',
          of: 'status',
          condition: [{ type: 'status', value: 'inprogress' }],
        });
      case 'revenue_month':
        return compute({
          source: todayWeekMonthData.monthTripSortedByEndedAt,
          unit: website?.currency?.symbol,
          of: 'total_fare',
          hasFrontUnit: true,
        });
      case 'total_distance_travelled':
        return compute({
          source: allSimulationData,
          unit: 'KM',
          condition: [{ type: 'status', value: 'completed' }],
          of: 'distance_covered',
        });
      case 'vehicle_active_time':
        return compute({
          source: DataSourceMapping.vehicle,
          of: 'vehicle_active_time',
          unit: 'Hrs',
        });
      case 'total_verified_email':
        return (
          DataSourceMapping[type].length -
          compute({
            source: DataSourceMapping[type],
            of: 'email_verified_at',
            unit: '',
            condition: [{ type: 'email_verified_at', value: null }],
          })
        );
      case 'total_verified_mobile':
        return (
          DataSourceMapping[type].length -
          total({
            source: DataSourceMapping[type],
            of: 'email_verified_at',
            unit: '',
            condition: [{ type: 'mobile_verified_at', value: null }],
          })
        );
      case 'vehicle_down_time':
        return compute({
          source: DataSourceMapping.vehicle,
          of: 'vehicle_down_time',
          unit: 'Hrs',
        });
      case 'in_transit':
        return compute({
          source: DataSourceMapping.vehicle,
          of: 'vehicle_active_time',
          unit: 'Hrs',
        });
      case 'battery_in_vehicle':
        return total({
          source: DataSourceMapping.battery,
          condition: [{ type: 'vehicle_id', value: '' }],
        });
      case 'battery_in_charging_station':
        return total({
          source: DataSourceMapping.battery,
          condition: [{ type: 'vehicle_id', value: '' }],
        });
      case 'battery_with_overheating':
        return total({
          source: DataSourceMapping.battery,
          condition: [{ type: 'isOverhitting', value: true }],
        });
      case 'total_service_staff':
        return RidersData.data.filter((data) =>
          ['service', 'all'].includes(data.employee_type)
        ).length;
      case 'service_staff_on_duty':
        return RidersData.data.filter(
          (data) =>
            ['service', 'all'].includes(data.employee_type) && data.on_duty
        ).length;
      case 'service_staff_off_duty':
        return RidersData.data.filter(
          (data) =>
            ['service', 'all'].includes(data.employee_type) && !data.on_duty
        ).length;
      case 'vehicle_in_service':
        return RidersData.data.filter(
          (data) =>
            ['service', 'all'].includes(data.employee_type) && !data.on_duty
        ).length;
      case 'auto_inspected_vehicles':
        return InspectionWithMetaData.data.filter(
          (data) => data.inspection_type == 'Auto'
        ).length;
      case 'manual_inspected_vehicles':
        return InspectionWithMetaData.data.filter(
          (data) => data.inspection_type == 'Manual'
        ).length;
      case 'vehicle_under_service':
        return ServiceWithMetaData.data.filter(
          (service) => service.status == 'Sent'
        ).length;
      case 'pending_service':
        return ServiceWithMetaData.data.filter(
          (service) => service.status == 'Pending'
        ).length;
      case 'completed_service':
        return ServiceWithMetaData.data.filter(
          (service) => service.status == 'Completed'
        ).length;
      case 'issues_confirmed':
        return IssuesDatatable.data.map((report) => report.status == 'Closed')
          .length;
      case 'issue_under_review':
        return IssuesDatatable.data.filter((issue) => issue.status == 'Open')
          .length;
      case 'issues_resolved':
        return IssuesDatatable.data.map((report) => report.status == 'Closed')
          .length;
      case 'resolved_reported_issues':
        return IssuesDatatable.data.filter(
          (report) => report.status == 'Closed'
        ).length;
      case 'open_issues':
        return IssuesDatatable.data.filter((report) => report.status == 'Open')
          .length;
      case 'issue_in_progress':
        return IssuesDatatable.data.filter(
          (issue) => issue.status == 'In Progress'
        ).length;
      case 'amount_to_fill_kerosene_quantity':
        return compute({
          source: DataSourceMapping.containers,
          of: 'amount_to_fill_litres',
          unit: '',
          condition: [{ type: 'substance', value: 'Kerosene' }],
        });
      case 'amount_to_fill_water_quantity':
        return compute({
          source: DataSourceMapping.containers,
          of: 'amount_to_fill_litres',
          unit: '',
          condition: [{ type: 'substance', value: 'Water' }],
        });
      case 'amount_to_fill_gas_quantity':
        return compute({
          source: DataSourceMapping.containers,
          of: 'amount_to_fill_litres',
          unit: '',
          condition: [{ type: 'substance', value: 'GasOil' }],
        });
      case 'remaining_kerosene_quantity':
        return compute({
          source: DataSourceMapping.containers,
          of: 'remaining_fuel_litres',
          unit: '',
          condition: [{ type: 'substance', value: 'Kerosene' }],
        });
      case 'remaining_water_quantity':
        return compute({
          source: DataSourceMapping.containers,
          of: 'remaining_fuel_litres',
          unit: '',
          condition: [{ type: 'substance', value: 'Water' }],
        });
      case 'remaining_gas_quantity':
        return compute({
          source: DataSourceMapping.containers,
          of: 'remaining_fuel_litres',
          unit: '',
          condition: [{ type: 'substance', value: 'GasOil' }],
        });
      case 'total_water_containers':
        return total({
          source: DataSourceMapping[type],
          condition: [{ type: 'substance', value: 'Water' }],
        });
      case 'total_gas_containers':
        return total({
          source: DataSourceMapping[type],
          condition: [{ type: 'substance', value: 'GasOil' }],
        });
      case 'total_kerosene_containers':
        return total({
          source: DataSourceMapping[type],
          condition: [{ type: 'substance', value: 'Kerosene' }],
        });
      case 'total_red_diesel_containers':
        return total({
          source: DataSourceMapping[type],
          condition: [{ type: 'substance', value: 'RedDiesel' }],
        });
      case 'total_lpg_containers':
        return total({
          source: DataSourceMapping[type],
          condition: [{ type: 'substance', value: 'LPG' }],
        });
      case 'average_percentage_kerosene':
        return (
          (compute({
            source: DataSourceMapping.containers,
            of: 'percent_full',
            unit: '',
            condition: [{ type: 'substance', value: 'Kerosene' }],
          }) /
          total({
            source: DataSourceMapping[type],
            condition: [{ type: 'substance', value: 'Kerosene' }],
          })).toFixed(2)
        );
      case 'average_percentage_water':
        return (
          (compute({
            source: DataSourceMapping.containers,
            of: 'percent_full',
            unit: '',
            condition: [{ type: 'substance', value: 'Water' }],
          }) /
          total({
            source: DataSourceMapping[type],
            condition: [{ type: 'substance', value: 'Water' }],
          })).toFixed(2)
        );
      case 'average_percentage_gas':
        return (
          (compute({
            source: DataSourceMapping.containers,
            of: 'percent_full',
            unit: '',
            condition: [{ type: 'substance', value: 'GasOil' }],
          }) /
          total({
            source: DataSourceMapping[type],
            condition: [{ type: 'substance', value: 'GasOil' }],
          })).toFixed(2)
        );
      case 'average_percentage_lpg':
        return (
          (compute({
            source: DataSourceMapping.containers,
            of: 'percent_full',
            unit: '',
            condition: [{ type: 'substance', value: 'LPG' }],
          }) /
          total({
            source: DataSourceMapping[type],
            condition: [{ type: 'substance', value: 'LPG' }],
          })).toFixed(2)
        );
      case 'minimun_runout_kerosene':
        validEstimatedRunoutDays = _.compact(
          _.map(
            DataSourceMapping[type].filter(
              (data) => data.substance == 'Kerosene'
            ),
            'estimated_runout_days'
          )
        );

        return _.min(validEstimatedRunoutDays);
      case 'maximum_runout_kerosene':
        validEstimatedRunoutDays = _.compact(
          _.map(
            DataSourceMapping[type].filter(
              (data) => data.substance == 'Kerosene'
            ),
            'estimated_runout_days'
          )
        );

        return _.max(validEstimatedRunoutDays);
      case 'minimun_runout_water':
        validEstimatedRunoutDays = _.compact(
          _.map(
            DataSourceMapping[type].filter((data) => data.substance == 'Water'),
            'estimated_runout_days'
          )
        );

        return _.min(validEstimatedRunoutDays);
      case 'maximum_runout_water':
        validEstimatedRunoutDays = _.compact(
          _.map(
            DataSourceMapping[type].filter((data) => data.substance == 'Water'),
            'estimated_runout_days'
          )
        );

        return _.max(validEstimatedRunoutDays);
      case 'minimun_runout_gas':
        validEstimatedRunoutDays = _.compact(
          _.map(
            DataSourceMapping[type].filter(
              (data) => data.substance == 'GasOil'
            ),
            'estimated_runout_days'
          )
        );

        return _.min(validEstimatedRunoutDays);
      case 'maximum_runout_gas':
        validEstimatedRunoutDays = _.compact(
          _.map(
            DataSourceMapping[type].filter(
              (data) => data.substance == 'GasOil'
            ),
            'estimated_runout_days'
          )
        );
        return _.max(validEstimatedRunoutDays);
      case 'minimun_runout_lpg':
        validEstimatedRunoutDays = _.compact(
          _.map(
            DataSourceMapping[type].filter((data) => data.substance == 'LPG'),
            'estimated_runout_days'
          )
        );

        return _.min(validEstimatedRunoutDays) || '-';
      case 'maximum_runout_lpg':
        validEstimatedRunoutDays = _.compact(
          _.map(
            DataSourceMapping[type].filter((data) => data.substance == 'LPG'),
            'estimated_runout_days'
          )
        );
        return _.max(validEstimatedRunoutDays) || '-';
      default:
        console.error('In renderInfoCardFunction `type` is invalid');
    }
  };

  return (
    <Fragment>
      <InfoCardCtx.Provider
        value={{ renderInfoCardData: renderInfoCardDataFunction }}
      >
        {children}
      </InfoCardCtx.Provider>
    </Fragment>
  );
}

export const useInfoCtx = () => {
  const ctx = useContext(InfoCardCtx);
  return ctx;
};

export default InfoCardProvider;
