import React, { useEffect, useState } from 'react';
import { Card, Col, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IOT_INSTRUCTIONS, VehicleIOTDatatable } from '../../Data/DummyData';
import Datatable, { computeColumns } from '../../Data/TablesData/Datatable';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { useDatatableCtx } from '../../components/Datatable/DatatableContext';
import { selectIOTAssetData } from '../../redux/iot/selector';
import {
  addPage,
  fetchData,
  setModule,
} from '../../redux/templateData/actions';
import { initialState } from '../../redux/templateData/init';
import {
  selectDatatable,
  selectFilteredData,
} from '../../redux/templateData/selectors';
import { ModalContentMemonized } from '../WorkOrder/WorkOrder';
import styles from './DataTable.module.css';

function IOT() {
  const module = 'vehicles';
  const assetData = useSelector(selectIOTAssetData);
  const dispatch = useDispatch();
  const datatable = useSelector(selectDatatable);
  const filteredResult = useSelector(selectFilteredData);
  const [openInstructionPanelStatus, setInstructionPanelStatus] =
    useState(false);
  const { status, setModalStatus, setRowData, setColumnMetaData } =
    useDatatableCtx();

  const columns = React.useMemo(
    () =>
      computeColumns({
        data: datatable.data,
        meta: datatable.meta,
        action: ({ row, meta }) => {
          setRowData(row);
          setColumnMetaData(meta);
          setModalStatus(true);
        },
      }),
    [datatable.data]
  );

  useEffect(() => {
    if (!initialState.hasOwnProperty(module)) {
      dispatch(
        addPage({
          key: module,
          data: initialState.vehicles,
        })
      );
    }

    dispatch(setModule(module));
    dispatch(fetchData({ module }));
  }, [module]);

  const data = React.useMemo(() => datatable.data, [datatable.data]);

  return (
    <div className={styles.DataTable}>
      <Offcanvas
        placement="end"
        name="end"
        show={openInstructionPanelStatus}
        onHide={() => setInstructionPanelStatus(false)}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Instructions</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {IOT_INSTRUCTIONS.map((instruction) => {
            return (
              <div
                style={{
                  marginBottom: '10px',
                  justifyContent: 'space-between',
                }}
                className="d-flex align-items-center"
              >
                <div>{instruction.name}</div>
                <div>
                  <button className="btn btn-secondary">Send</button>
                </div>
              </div>
            );
          })}
        </Offcanvas.Body>
      </Offcanvas>
      <PageHeader titles="Vehicles" active="Vehicles" items={['Tables']} />
      <div className="row">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Datatable
                sortable_key="id"
                isMultiSelect={false}
                rowSelected={(selection) => {
                  // console.log(selection);
                }}
                actions={[
                  { name: 'Send Instruction', type: 'send_instruction' },
                ]}
                // modalHeading="Device History"
                modalStatus={status}
                modalContent={ModalContentMemonized}
                handleModalStatus={setModalStatus}
                columns={columns}
                data={data}
                onActionClick={(event, { type }) => {
                  if (type == 'send_instruction') {
                    setInstructionPanelStatus(true);
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </div>
      {/* <!-- End Row --> */}
    </div>
  );
}

export default IOT;
