import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

// let BASE_URL = 'https://efleety.com/';
let BASE_URL = process.env.REACT_APP_API_URL;

const instance = axios.create();

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // console.log('RESPONSE INTERCEPTER');
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const http = (method, endpoint, body = {}) => {
  let { headers, apiData, formData } = body;

  if (formData) {
    console.log('formDataL');
  } else console.log('apiData');

  if (body.baseURL) {
    BASE_URL = body.baseURL;
  }

  let url = `${BASE_URL}${endpoint}`;

  // console.log('URL: ', url, process.env);

  let configurations = {
    url,
    method,
    headers: {
      'Client-Type': 'web',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'App-Key': sessionStorage.getItem('app_key'),
      ...headers,
    },
    data: formData ? formData : apiData,
    timeout: 5000,
    withCredentials: false,
    responseType: 'json',
    validateStatus: function (status) {
      return status >= 200 && status < 300;
    },
  };

  if (method == 'GET') {
    configurations.params = apiData;
  }

  return instance(configurations)
    .then((result) => {
      let { status } = result;

      let data = result.data.data;
      if (data) {
        if (typeof data === 'object') {
          result.data.data = data;
        } else result.data.data = JSON.parse(data);
        console.group(
          `API RESPONSE GROUP: ${endpoint}; module: ${apiData?.page_name}`
        );
        console.log('data: ', result);
        console.groupEnd('API RESPONSE GROUP');

        return result.data;
      } else return true;
    })
    .catch((er) => {
      console.log("er: ",er)
      if (er.response.status == 400) {
        // console.log(
        //   'er.response.data.error.response: ',
        //   er.response.data.message
        // );
        let error = new Error(er.response.data.message);

        return error;
      }

      enqueueSnackbar(er.response.data.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      });
    });
};

export default http;
